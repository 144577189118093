import ReactDOM from 'react-dom/client'
import './styles/discussion-forum.css'
import './styles/resume-builder.css'
import './styles/file-upload.css'
import './styles/animations.css'
import './styles/graphs.css'
import './styles/index.css'
import './styles/video.css'
import React from 'react'
import App from './App'

const root = ReactDOM.createRoot( document.getElementById( 'root' ) )

if ( 'serviceWorker' in navigator ) {
  window.addEventListener( 'load', () => {
    navigator.serviceWorker
      .register( '/activityreport-service-worker.js' )
      .then( registration => {
        console.log( 'Service Worker registered with scope:', registration.scope )
      } )
      .catch( error => {
        console.error( 'Service Worker registration failed:', error )
      } )
  } )
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

import YearChecklist from '../../../components/presenter/proctoring/YearChecklist'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import { Box, Button, IconButton, Menu, MenuItem, Paper, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { Icons } from '../../../utils/utilities'
import { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import api from '../../../service/api'
import { useUserStore } from '../../../store/user.store'
import BreadcrumbList from '../../../components/common/BreadcrumbList'

const Proctee = () => {
    const [procteeDetails, setProcteeDetails] = useState( {} )
    const [yearListOpen, setYearListOpen] = useState( false )
    const [selectedYear, setSelectedYear] = useState( null )
    const [studentData, setStudentData] = useState( {} )
    const [loading, setLoading] = useState( true )
    const [rules, setRules] = useState( null )

    const UserStore = useUserStore()
    const { student_auid } = useParams()

    const navigate = useNavigate()

    const menuRef = useRef()

    const getData = useCallback( async () => {
        try {
            let data3
            const { data: { data: student } } = data3 = await api.fecthProcteeDetails( student_auid )
            console.log( data3 )

            setProcteeDetails( student || {} )
            const { data } = await api.fetchProcteeAcademicRuleDetails( student_auid, { institute: student.institute_name, department_id: student.course_branch_assignment_id, academic_year: student.ac_year } )
            let rules = []
            for ( let i = 0; i < data.data.checklist.length; i++ ) {
                const checklistItem = data.data.checklist[i]
                if ( checklistItem.session_type === "year" ) {
                    if ( rules[checklistItem.session_number] ) {
                        const temp = rules[checklistItem.session_number]
                        temp.checklist.push( checklistItem )
                    } else {
                        const temp = { checklist: [checklistItem], semester: [] }
                        rules[checklistItem.session_number] = temp
                    }
                } else {
                    const year = Math.ceil( checklistItem.session_number / 2 )
                    if ( rules[year] ) {
                        if ( rules[year]['semester'][checklistItem.session_number] ) {
                            rules[year]['semester'][checklistItem.session_number].push( checklistItem )
                        } else {
                            rules[year]['semester'][checklistItem.session_number] = [checklistItem]
                        }
                    } else {
                        const temp = { checklist: [], semester: [] }
                        temp.semester[checklistItem.session_number] = [checklistItem]
                        rules[year] = temp
                    }
                }
            }
            const studentData = {}
            for ( let k = 0; k < data.data.studentData.length; k++ ) {
                const tempData = data.data.studentData[k]
                studentData[tempData.checklist_id] = tempData
            }
            setStudentData( studentData )
            setRules( rules )
            for ( let k = 0; k < rules.length; k++ ) {
                if ( rules[k] )
                    return setSelectedYear( k )
            }
        } catch ( err ) {
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            } else
                toast( err?.response?.data?.message || "Something went wrong! try again again" )
        } finally {
            setLoading( false )
        }
    }, [student_auid, UserStore] )

    const selectYear = ( year ) => {
        setSelectedYear( year )
        setYearListOpen( false )
    }

    useEffect( () => {
        getData()
    }, [getData] )

    return (
        <Box padding="20px" flexGrow={1} overflow="auto">

            <BreadcrumbList items={[
                { link: '/faculty/proctoring/proctees', label: 'Proctees' },
                student_auid
            ]} />

            <Paper>
                <Box display="flex" alignItems="center" gap="20px" padding="20px" borderBottom="1px solid #d3d3d3">
                    <IconButton onClick={() => navigate( '/faculty/proctoring/proctees' )} size="small">
                        {Icons.default.ArrowBack}
                    </IconButton>
                    <Box>
                        <Typography textTransform="capitalize" color="primaryDark.main" variant='h6'>{procteeDetails?.name?.toLowerCase() || student_auid}</Typography>
                        <Typography variant="body2" color="textSecondary" >{
                            ( procteeDetails.institute_code && procteeDetails.branch && procteeDetails.year && procteeDetails.sem ) ?
                                `${procteeDetails.institute_code} > ${procteeDetails.branch} > Year: ${procteeDetails.year} > Sem: ${procteeDetails.sem}` :
                                `Academic checklist`
                        } </Typography>
                    </Box>
                </Box>
                {loading && <DataLoadingSpinner waitingMessage={`Loading academic checklist for ${student_auid}...`} />}
                {!loading && rules && rules.length > 0 && <Box padding="20px">

                    <Button onClick={() => setYearListOpen( true )} ref={menuRef} endIcon={Icons.default.DropDown} variant='contained' disableElevation color="customThemeColor" >
                        <Typography padding="10px 20px 10px 10px" fontSize="14px" noWrap maxWidth="400px">Year {selectedYear}</Typography>
                    </Button>
                    <Menu anchorEl={menuRef.current} onClose={() => setYearListOpen( false )} open={yearListOpen}>
                        {rules && rules.map( ( year, index ) => (
                            <MenuItem onClick={() => selectYear( index )} sx={{ fontSize: "14px" }} key={index} value={index}>Year {index}</MenuItem>
                        ) )}
                    </Menu>

                    {/* {rules.map( ( year, index ) => ( */}
                    <YearChecklist getData={getData} studentData={studentData} key={0} year={rules[selectedYear]} yearNumber={selectedYear} />
                    {/* ) )} */}
                </Box>}
                {!loading && rules && rules.length === 0 && <Box padding="20px">
                    <Typography variant="subtitle2" color="GrayText">No rules are created for this academic-year! please contact LMS team.</Typography>
                </Box>}
                {!loading && !rules && <Box padding="20px">
                    <Typography variant="subtitle2" color="error.main">Error occured while fetching data! please try again.</Typography>
                </Box>}
            </Paper>
        </Box>
    )
}

export default Proctee

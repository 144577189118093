import { Avatar, Box, CircularProgress, Dialog, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Icons, showDateInIndianDateFormat } from '../../../utils/utilities'
import api from '../../../service/api'
import AdminViewDiscussionComment from '../../admin/discussions/AdminViewDiscussionComment'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import TryAgainMessageBlock from '../../common/TryAgainMessageBlock'

const AuditDiscussionViewer = ( { openState, discussion, handleClose } ) => {

    const [error, setError] = useState( null )
    const [comments, setComments] = useState( [] )
    const [loading, setLoading] = useState( false )

    const { palette } = useTheme()

    const fetchComments = async () => {
        setLoading( true )
        try {
            const { data: { data } } = await api.discussionForum.fetchDiscussionComment( discussion._id )
            setComments( data )
            setError( null )
        } catch ( err ) {
            console.log( err )
            setError( { code: err?.response?.status, message: err?.response?.data?.message || "Opps! An error occured while trying to fetch exam question try again." } )
        } finally {
            setLoading( false )
        }
    }

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "800px" } }} open={openState}>
            <Box sx={{
                background: `linear-gradient(322.12deg, #F3C400 -21.29%, #E25A26 124.22%)`
            }} display="flex" alignItems="center" justifyContent="space-between" padding="20px" >
                <Box display="flex" alignItems="flex-start" gap="20px">
                    <Box color="white">
                        <Tooltip title={discussion.creator_data.name}>
                            <Avatar src={discussion.creator_data?.user_profile_image_url} >{discussion.creator_data.name[0]}</Avatar>
                        </Tooltip>
                    </Box>
                    <Box color="white">
                        <Typography variant='h6' fontSize="18px">{discussion.title}</Typography>
                        <Typography variant='subtitle2' fontSize="12px">@{discussion.creator_data.name}, On: {showDateInIndianDateFormat( discussion.created_at, { month: "short" } )}</Typography>
                    </Box>
                </Box>
                <IconButton onClick={handleClose}>{Icons.default.CloseIcon}</IconButton>
            </Box>
            <Box padding="20px">
                <Typography gutterBottom color="secondary" variant='h6' fontSize="16px">Content</Typography>
                <Box padding="10px" overflow="auto" className='wysiwyg-text-container slim-custom-scrollbar' border="1px solid #d3d3d3" bgcolor={palette.contentBg} borderRadius="5px">
                    <Box dangerouslySetInnerHTML={{ __html: discussion.content }}></Box>
                </Box>
                <Typography onClick={fetchComments} width="fit-content" padding="10px" display="flex" gap="10px" alignItems="center" sx={{ cursor: "pointer", '&:hover': { background: palette.rowHover } }} variant='subtitle2' marginTop="10px" color="textSecondary">
                    Comments ({discussion.comment_count})
                    {loading ? <CircularProgress size={12} /> : Icons.default.DropDown}
                </Typography>
                {!loading && !error && comments.length > 0 && <Box className='custom-scrollbar' maxHeight="400px" overflow="auto" flexGrow={1} position="relative">
                    {comments?.map( ( comment, commentPos ) => (
                        <AdminViewDiscussionComment key={comment._id} comment={comment} />
                    ) )}
                </Box>}
                {loading && !error && <DataLoadingSpinner waitingMessage="Please wait while we are fetching discussion comments..." />}
                {error && <TryAgainMessageBlock err={error.message} code={error.code} loading={loading} getData={fetchComments} />}
            </Box>
        </Dialog>
    )
}

export default AuditDiscussionViewer

import CreateOrUpdateAssignment from '../../components/presenter/CreateOrUpdateAssignment'
import AssignmentsList from '../../components/presenter/AssignmentsList'
import { useAssignmentStore } from '../../store/assignment.store'
import { useSearchParams } from 'react-router-dom'
import { Box, Paper } from '@mui/material'
import React from 'react'

const Assignment = () => {

    const [searchParams, setSearchParams] = useSearchParams( { view: "manage" } )
    const view = searchParams.get( 'view' ) || "manage"

    const setView = ( val ) => {
        setSearchParams( prev => {
            prev.set( 'view', val )
            return prev
        }, { replace: true } )
    }

    const AssignmentStore = useAssignmentStore()

    const editAssignment = ( assignment ) => {
        setView( "update" )
        AssignmentStore.setUpdateStatus( assignment, true )
    }

    return (
        <Box padding="20px" minWidth="0" overflow="auto" display="flex" flexDirection="column" flexGrow={1}>
            <Paper >
                {( view === "create" || view === "update" ) &&
                    <CreateOrUpdateAssignment view={view} setView={setView} />}
                <AssignmentsList editAssignment={editAssignment} setView={setView} />
            </Paper>
        </Box>
    )
}

export default Assignment

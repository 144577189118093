import { Box, Button, FormControl, IconButton, TextField, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Form, Formik, ErrorMessage, Field } from 'formik'
import bgImg from '../../assets/young-student-min.jpg'
import { useAuthStore } from '../../store/auth.store'
import aliveLogo from '../../assets/alivelogo.png'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import * as Yup from 'yup'

export default function Signin() {

    const authStore = useAuthStore()

    const navigate = useNavigate()

    const [isLoggingIn, setIsLoggingIn] = useState( false )
    const [passwordVisibility, setPasswordVisibility] = useState( false )

    const [searchParams] = useSearchParams( { redirect: null } )
    const redirect = searchParams.get( 'redirect' ) || "null"

    const initialValues = {
        email: '',
        password: ''
    }


    const validationSchema = Yup.object().shape( {
        email: Yup.string().required( "This field is required!" ),
        password: Yup.string().required( "Password field is required!" )
    } )

    const toggleVisibility = () => {
        setPasswordVisibility( !passwordVisibility )
    }

    const signIn = async ( values ) => {
        setIsLoggingIn( true )
        let status = await authStore.doAuth( { "user_email": values.email, "user_password": values.password } )
        if ( status ) {
            const redirectValue = decodeURIComponent( redirect )
            navigate( redirectValue && redirectValue !== "null" ? redirectValue : `/admin/home`, { replace: true } )
            setIsLoggingIn( false )
        } else {
            setIsLoggingIn( false )
        }
    }


    return (
        <Box bgcolor="#eee" color="primary.main" display="flex" height="100%" alignItems="center" width="100%" justifyContent="center" flexWrap="wrap" sx={{ overflowX: "hidden", background: `url('/imgs/bg-pattern1.png')` }}>
            <Box height={{ md: "80% !important", xs: "100% !important" }} width={{ md: "900px !important", xs: "100% !important" }} minHeight="615px" borderRadius={{ sm: "5px !important", xs: "0 !important" }} bgcolor="white" alignItems="center" display="grid" gridTemplateColumns={{ md: "50% 50% !important", xs: "0 100% !important" }}>
                <Box height="100%" width="100%" display="flex" flexWrap="wrap" overflow="hidden">
                    <img height="100%" width="100%" style={{ borderRadius: "5px 0 0 5px", objectFit: "cover" }} src={bgImg} alt="Login Background" />
                </Box>
                <Box>
                    <Box height="100%" sx={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                        <img src={aliveLogo} style={{ marginLeft: "-10px", marginBottom: "10px" }} width="100px" alt="Alive Logo" />
                        <Typography variant='h6' component="h1" textAlign="center" gutterBottom>Management & Monitoring Portal</Typography>
                        <Formik initialValues={initialValues} onSubmit={signIn} validationSchema={validationSchema}>
                            {( props ) => (
                                <Form method='post' autoComplete='off' style={{ margin: "20px 0", display: "flex", flexDirection: "column", gap: "10px", width: "100%", alignItems: "center" }}>
                                    <FormControl sx={{ width: { md: "320px", xs: "100%" }, maxWidth: "450px", marginBottom: "10px" }} >
                                        <Field as={TextField} InputLabelProps={{ sx: { color: "primary.main !important", fontSize: "14px" } }} sx={{ borderRadius: "2px", background: "#fff", outline: 'none', width: { sm: "100%", xs: "95% !important" }, margin: { sm: "initial", xs: "auto !important" }, "&:focus": { borderLeft: "5px solid #f9b17a", } }} type="text" name='email' variant='outlined' label="Email" />
                                        <Typography marginLeft="10px" fontSize="12px" color="error" variant='subtitle2'><ErrorMessage name='email' /></Typography>
                                    </FormControl>
                                    <FormControl sx={{ width: { md: "320px", xs: "100%" }, maxWidth: "450px", marginBottom: "10px" }} >
                                        <Field as={TextField} InputProps={{ endAdornment: <IconButton onClick={toggleVisibility}>{passwordVisibility ? <VisibilityOff /> : <Visibility />}</IconButton> }} InputLabelProps={{ sx: { color: "primary.main !important", fontSize: "14px" } }} sx={{ borderRadius: "2px", background: "#fff", outline: 'none', width: { sm: "100%", xs: "95% !important" }, margin: { sm: "initial", xs: "auto !important" }, "&:focus": { borderLeft: "5px solid #f9b17a", } }} type={passwordVisibility ? "text" : "password"} name='password' variant='outlined' label="Password" />
                                        <Typography marginLeft="10px" fontSize="12px" color="error" variant='subtitle2'><ErrorMessage name='password' /></Typography>
                                    </FormControl>
                                    <FormControl sx={{ width: { md: "320px", xs: "100%" }, maxWidth: "450px", marginBottom: "10px" }}>
                                        <Box display="flex" justifyContent="center">
                                            <Button sx={{ textTransform: "capitalize", width: { sm: "100%", xs: "95% !important" }, height: "53px" }} disableElevation type='submit' color='primary' variant='contained' disabled={isLoggingIn}>{isLoggingIn ? "Logging in..." : "Login"}</Button>
                                        </Box>
                                    </FormControl>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}

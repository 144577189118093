import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Fab, Typography } from '@mui/material'
import ResumeTemplatesList from '../../../components/user/resume-builder-components/ResumeTemplatesList'
import ResumeTemplate from '../../../components/user/resume-builder-components/ResumeTemplate'
import { useStudentResumeFormStore } from '../../../store/studentresumeform.store'
import BlockMessage from '../../../components/common/BlockMessage'
import React, { useRef, useEffect, useState } from 'react'
import { Download, ViewComfy } from '@mui/icons-material'
import { useReactToPrint } from 'react-to-print'
import { Icons } from '../../../utils/utilities'
import { useNavigate } from 'react-router-dom'

const ResumePreview = () => {
    const [loading, setLoading] = useState( true )
    const [allRequiredInfoAvailable, setAllRequiredInfoAvailable] = useState( false )
    const StudentResumeFormStore = useStudentResumeFormStore()
    const [selectedTemplate, setSelectedTemplate] = useState( null )
    const [templatesDialogState, setTemplatesDialogState] = useState( false )
    const [downloading, setDownloading] = useState( false )

    const resumeRef = useRef()
    const navigate = useNavigate()
    const handlePrint = useReactToPrint( {
        content: () => resumeRef.current,
    } )

    useEffect( () => {
        const getData = async () => {
            if ( await StudentResumeFormStore.fetchStudentResumeInfo() && await StudentResumeFormStore.fetchStudentForm() && await StudentResumeFormStore.fetchTemplates() ) {
                setAllRequiredInfoAvailable( true )
                setSelectedTemplate( StudentResumeFormStore.getTemplates[0] )
            } else {
                setAllRequiredInfoAvailable( false )
            }
            setLoading( false )
        }
        getData()
    }, [StudentResumeFormStore] )
    return (
        <Box display="flex" overflow="auto" alignItems="stretch" padding="20px 0" flexGrow={1}>
            {!templatesDialogState && StudentResumeFormStore.getTemplates.length > 1 && <Fab onClick={() => setTemplatesDialogState( true )} variant='extended' sx={{ textTransform: "capitalize", position: "fixed", bottom: "20px", right: "20px" }}>
                <ViewComfy sx={{ mr: 1 }} />
                Templates
            </Fab>}


            <Dialog PaperProps={{ sx: { minWidth: "250px" } }} onClose={() => setTemplatesDialogState( false )} open={templatesDialogState}>
                <DialogTitle><Typography sx={{ textOrientation: "mixed" }} fontSize="16px">Templates</Typography></DialogTitle>
                <DialogContent>
                    <ResumeTemplatesList setTemplatesDialogState={setTemplatesDialogState} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} templates={StudentResumeFormStore.getTemplates} />
                </DialogContent>
            </Dialog>
            <Box padding="20px 40px" margin="0 auto">
                <Box paddingBottom="20px">
                    {allRequiredInfoAvailable && StudentResumeFormStore.getStudentResumeInfo.resume_data && <Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Button size="small" sx={{ textTransform: "capitalize", color: "GrayText" }} onClick={() => navigate( `/student/resume-builder` )} startIcon={Icons.default.ChevronLeftIcon}>Back</Button>
                            {/* <ReactToPrint
                                content={resumeRef}
                                trigger={
                                    <Button
                                                onClick={
                                                async () => {
                                            setDownloading( true )
                                            await generatePDF( resumeRef, options )
                                            setDownloading( false )
                                        }}
                                        color='secondary' disabled={downloading} startIcon={downloading ? <CircularProgress size={14} /> : <Download />} size='small' sx={{ textTransform: "capitalize" }}>{downloading ? "Preparing your resume..." : "Save this template"}</Button>
                                } /> */}
                            <Button
                                onClick={
                                    // async () => {
                                    //     setDownloading( true )
                                    //     await generatePDF( resumeRef, options )
                                    //     setDownloading( false )
                                    // }
                                    async ( e ) => {
                                        setDownloading( true )
                                        handlePrint( e )
                                        setDownloading( false )
                                    }
                                }
                                color='secondary' disabled={downloading} startIcon={downloading ? <CircularProgress size={14} /> : <Download />} size='small' sx={{ textTransform: "capitalize" }}>{downloading ? "Preparing your resume..." : "Save this template"}</Button>
                        </Box>
                        <ResumeTemplate ref={resumeRef} loading={loading} layout={selectedTemplate.template_json} data={StudentResumeFormStore.getStudentResumeInfo.resume_data} />
                    </Box>}
                    {!loading && ( !allRequiredInfoAvailable || !StudentResumeFormStore.getStudentResumeInfo.resume_data ) &&
                        <BlockMessage
                            type='warning'
                            message='Please make sure you first fill your details! If you have already filled your details then the templates are not available for your institute yet. Please wait for templates.'
                            actions={
                                [
                                    {
                                        label: "Fill now",
                                        action: () => {
                                            navigate( `/student/resume-builder` )
                                        }
                                    }
                                ]
                            }
                        />}
                </Box>
            </Box>
        </Box>
    )
}

export default ResumePreview

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, Box, IconButton, useTheme, CircularProgress, TextField } from '@mui/material'
import { Warning } from '@mui/icons-material'
import { Icons } from '../../utils/utilities'
import React, { useState } from 'react'
import PropTypes from 'prop-types'

const ConfirmDialog = ( { enableSecondaryConfirmation = false, secondaryConfirmationText = 'confirm', waiting, actionName, confirmAction, message, status, cancelAction, isReversible } ) => {

    const [secondaryConfirmationValue, setSecondaryConfirmationValue] = useState( '' )

    const { palette } = useTheme()

    const triggerCancelAction = () => {
        setSecondaryConfirmationValue( "" )
        cancelAction()
    }

    return (
        <Dialog id="delete-confirm-dialog" PaperProps={{ sx: { width: "400px", minWidth: "200px", maxWidth: "500px" } }} open={status}>
            <DialogTitle sx={{ justifyContent: "space-between", display: "flex", padding: "10px 20px", alignItems: "center", gap: "20px" }}>
                Are you sure?
                <IconButton onClick={triggerCancelAction}>
                    {Icons.default.CloseIcon}
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: 0 }}>
                <Box>
                    {!isReversible && <Typography padding="10px 20px" bgcolor={palette.errorMessage.main} gap="10px" display="flex" alignItems="center" color="white" fontSize="12px" fontWeight="500">
                        <Warning fontSize='small' />
                        This action cannot be undone.
                    </Typography>}
                    <Box padding="20px" fontSize="14px">{message}</Box>
                    {enableSecondaryConfirmation && <Box marginBottom="20px" padding="0 20px">
                        <TextField fullWidth InputProps={{ sx: { fontSize: "14px", fontStyle: "italic" } }} placeholder={secondaryConfirmationText} value={secondaryConfirmationValue} onChange={e => setSecondaryConfirmationValue( e.target.value )} size="small" />
                        <Typography fontSize="12px">Please type <em> <strong>{secondaryConfirmationText}</strong> </em> to confirm the delete action.</Typography>
                    </Box>}
                </Box>
            </DialogContent>
            <DialogActions sx={{ background: palette.contentBg }}>
                <Button id="delete-confirm-btn" startIcon={waiting && <CircularProgress size={14} />} disabled={waiting || ( enableSecondaryConfirmation && secondaryConfirmationText !== secondaryConfirmationValue )} disableElevation color="errorMessage" variant='contained' onClick={confirmAction} sx={{ textTransform: "capitalize" }}>{actionName}</Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmDialog.propTypes = {
    enableSecondaryConfirmation: PropTypes.bool,
    secondaryConfirmationText: PropTypes.string,
    waiting: PropTypes.bool,
    status: PropTypes.bool,
    isReversible: PropTypes.bool,
    message: PropTypes.node,
    cancelAction: PropTypes.func.isRequired,
    confirmAction: PropTypes.func.isRequired,
    actionName: PropTypes.string
}

export default ConfirmDialog

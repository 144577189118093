import { Box, DialogActions, Button, Dialog, DialogContent, Typography, Collapse, useTheme, IconButton, } from '@mui/material'
import { Icons, attachmentThumbnails, getFileSizeFromBytes } from '../../utils/utilities'
import uploadAssignmentImg from '../../assets/upload-assignment.svg'
import { useAssignmentStore } from '../../store/assignment.store'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import React from 'react'

const SubmitAssignmentDialog = ( { getAssignments, onClose, selectedValue, open } ) => {
    const AssignmentStore = useAssignmentStore()
    const { palette, border } = useTheme()

    const [dropping, setDropping] = React.useState( false )
    const [attachments, setAttachments] = React.useState( [] )
    const [uploading, setUploading] = React.useState( false )

    const handleClose = async () => {
        onClose( selectedValue )
        setAttachments( [] )
        if ( getAssignments )
            await getAssignments()
    }

    const handleFilesInput = ( e ) => {
        let files = []
        let wrongFormats = []
        for ( let i = 0; i < e.target.files.length; i++ ) {
            const file = e.target.files[i]
            if ( ["pptx", "docx", "pdf"].includes( file.name?.split( "." )?.pop()?.toLowerCase() ) )
                files.push( file )
            else
                wrongFormats.push( file.name )
        }
        setAttachments( [...attachments, ...files] )
        if ( wrongFormats.length > 0 )
            toast( `Unsupported file format: ${wrongFormats.join( ", " )}` )
        e.target.value = ""
    }

    const removeFile = ( index ) => {
        const updatedFiles = [...attachments]
        updatedFiles.splice( index, 1 )
        setAttachments( updatedFiles )
    }

    const submitAssignment = async () => {
        //e.preventDefault();
        try {
            setUploading( true )
            if ( attachments.length === 0 ) {
                toast( "Please select the files you want to submit!" )
                setUploading( false )
                return
            }
            const formData = new FormData()
            formData.append( "assignment_id", selectedValue['assignment_id'] )
            for ( let i = 0; i < attachments.length; i++ ) {
                formData.append( "attachments", attachments[i] )
            }

            if ( await AssignmentStore.uploadAssignmentSubmission( formData, selectedValue['assignment_id'] ) ) {
                handleClose()
                setAttachments( [] )
            }
        } catch ( err ) {
            toast( err?.response?.data?.message || "Something went wrong while submitting your assignment." )
        } finally {
            setUploading( false )
        }
    }
    return (
        <Dialog fullWidth={true} maxWidth={'sm'} onClose={handleClose} aria-labelledby="submit-dialog-title" open={open}>
            <Box display="flex" alignItems="center" justifyContent="space-between" padding="20px" borderBottom="1px solid #d3d3d3">
                <Box>
                    <Typography variant='h5'>{selectedValue.assignment_name}</Typography>
                    <Typography variant='subtitle2' color="textSecondary">Submit you answers here.</Typography>
                </Box>
                <IconButton onClick={handleClose}>
                    {Icons.default.CloseIcon}
                </IconButton>
            </Box>
            <DialogContent>
                <form>
                    <Box display="flex" flexDirection="column" sx={{ bgcolor: `${palette.secondary.light}22`, position: "relative", border: `2px dashed ${palette.secondary.dark}`, borderRadius: "5px", padding: "20px", minHeight: "160px" }} >
                        <input onDragLeave={() => setDropping( false )} onDrop={() => setDropping( false )} onDragEnter={() => setDropping( true )} accept='.pdf, .docx, .pptx, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation' type="file" disabled={uploading} style={{ position: "absolute", opacity: 0, top: 0, left: 0, right: 0, bottom: 0 }} onChange={handleFilesInput} multiple />
                        <Box flexGrow={1} display="flex" gap="10px" flexDirection="column" alignItems="center">
                            <img src={uploadAssignmentImg} alt="Upload assignment" width="120px" />
                            {!dropping && <Box display="flex" gap="5px" flexDirection="column" alignItems="center">
                                <Typography variant="body1" fontWeight="600" >Upload assignment</Typography>
                                <Typography variant="subtitle2" color="textSecondary">Drag and drop here or  <span style={{ color: palette.secondary.main }}>Browse</span> </Typography>
                                <Typography variant="body2" >Accepted formats: <strong><em><span style={{ color: palette.secondary.main }}>.PDF,.PPTX and .DOCX</span></em></strong> </Typography>
                            </Box>}
                            {dropping && <Box flexGrow={1} display="flex" gap="5px" flexDirection="column" justifyContent="center" alignItems="center">
                                <Typography sx={{ pointerEvents: "none" }} className='animation-shake' variant="h6" color="grey">Drop the assignment files here!</Typography>
                            </Box>}
                        </Box>
                    </Box>
                    <Collapse in={attachments.length > 0}>
                        <Box display="flex" flexDirection="column" gap="5px" flexWrap="wrap" marginTop="20px">
                            {attachments.map( ( item, index ) => {
                                const ext = item.name.split( "." ).pop()
                                return (
                                    <Box key={index} border={border[1]} display="flex" alignItems="center" padding="2px" borderRadius="5px">
                                        <Box marginTop="5px">
                                            <img style={{ objectFit: "fit" }} width="35px" src={attachmentThumbnails[ext] || attachmentThumbnails["default"]} alt={item.name} />
                                        </Box>
                                        <Box display="flex" flexGrow={1} justifyContent="space-between" gap="10px" alignItems="center">
                                            <Box>
                                                <Typography variant='subtitle2'>{item.name}</Typography>
                                                <Typography variant='body2' fontSize="12px">{getFileSizeFromBytes( item.size )}</Typography>
                                            </Box>
                                            <IconButton onClick={() => removeFile( index )}>
                                                {Icons.default.CloseIcon}
                                            </IconButton>
                                        </Box>
                                    </Box>
                                )
                            } )}
                        </Box>
                    </Collapse>
                </form>
            </DialogContent>
            <DialogActions sx={{ padding: "10px 20px" }}>
                <Button sx={{ textTransform: "capitalize" }} variant='contained' disableElevation startIcon={Icons.default.UploadFileIcon} disabled={uploading} onClick={submitAssignment} color="primary">
                    {!uploading ? 'Upload' : "Uploading..."}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

SubmitAssignmentDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.object.isRequired
}

export default SubmitAssignmentDialog

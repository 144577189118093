import React from 'react'
import { useResumeBuilderStore } from '../../../store/resumebuilder.store'
import { useEffect } from 'react'
import { useState } from 'react'
import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material'
import noFormImg from '../../../assets/no-resume-templates.svg'
import AddResumeTemplateDialog from '../../../components/admin/resume-builder/AddResumeTemplateDialog'
import { useNavigate } from 'react-router-dom'
import { Icons } from '../../../utils/utilities'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import api from '../../../service/api'
import { useUserStore } from '../../../store/user.store'
import { Delete } from '@mui/icons-material'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import BlockMessage from '../../../components/common/BlockMessage'
import TableHeader from '../../../components/common/TableHeader'
import CodeButton from '../../../components/common/CodeButton'

const columns = [
    { name: "Template name", padding: "10px 20px 10px 40px" },
    { name: "Form name", padding: "10px 20px" },
    { name: "Institutes linked", padding: "10px 20px" },
    { name: "Actions", align: "right", padding: "10px 40px 10px 20px" },
]

const ResumeTemplates = observer( () => {

    const [loading, setLoading] = useState( true )
    const [addDialogState, setAddDialogState] = useState( false )
    const [hasPermission, setHasPermission] = useState( true )

    const ResumeBuilderStore = useResumeBuilderStore()
    const UserStore = useUserStore()

    const { table } = useTheme()

    const navigate = useNavigate()

    const deleteTemplate = async ( id ) => {
        try {
            await api.deleteResumeTemplate( id )
            await ResumeBuilderStore.fetchTemplates()
        } catch ( err ) {
            console.log( err )
            toast( ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    useEffect( () => {
        const getData = async () => {
            await ResumeBuilderStore.fetchTemplates()
            setLoading( false )
        }
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.resume_builder ) {
            setHasPermission( false )
        } else
            getData()
    }, [ResumeBuilderStore, UserStore] )

    return (
        hasPermission ? <Box flexGrow={1} padding="20px" display="flex" flexDirection="column" overflow="auto">
            <AddResumeTemplateDialog state={addDialogState} setAddDialogState={setAddDialogState} />
            <Paper sx={{ minHeight: !loading && "350px", display: "flex", flexDirection: "column", flexGrow: !loading && ResumeBuilderStore.getTemplates.length > 0 ? "1" : "initial" }}>
                <Box display="flex" alignItems="center" borderBottom="1px solid #d3d3d3" gap="20px" padding="20px">
                    <Box flexGrow={1}>
                        <Typography variant='h5'>Resume templates</Typography>
                        <Typography color="textSecondary" variant='subtitle2'>Manage resume templates here</Typography>
                    </Box>
                    {!loading && <Button disableElevation sx={{ textTransform: "capitalize" }} onClick={() => setAddDialogState( true )} variant="contained" startIcon={Icons.default.AddIcon}>Create</Button>}
                </Box>

                {!loading && ResumeBuilderStore.getTemplates.length > 0 && <Box flexGrow={1} overflow="hidden">
                    <TableContainer className='custom-scrollbar' sx={{ height: "100%", flexGrow: 1, overflow: "auto" }}>
                        <Table stickyHeader sx={{ overflow: "auto" }}>
                            <TableHeader columns={columns} />
                            <TableBody>
                                {ResumeBuilderStore.getTemplates.map( ( row, rowId ) => (
                                    <TableRow className='table-row' sx={{ padding: "5px", cursor: "pointer", "&:hover": { background: "#eee" } }} key={row.id}>
                                        <TableCell sx={{ padding: columns[0].padding || "10px 20px", color: table.color, fontWeight: "500" }} onClick={() => navigate( `/admin/resume-templates/${row.id}` )}> <Typography fontSize="14px" fontWeight="bold">{row.name}</Typography> </TableCell>
                                        <TableCell sx={{ padding: columns[1].padding || "10px 20px", color: table.color, fontWeight: "500" }} onClick={() => navigate( `/admin/resume-templates/${row.id}` )}>{row.form_name}</TableCell>
                                        <TableCell sx={{ padding: columns[2].padding || "10px 20px", color: table.color, fontWeight: "500" }} onClick={() => navigate( `/admin/resume-templates/${row.id}` )}>
                                            <Box display="flex" flexWrap="wrap" gap="10px">
                                                {row.access_list.map( institute => <CodeButton key={institute}>{institute}</CodeButton> )}
                                            </Box> </TableCell>
                                        <TableCell align='right' sx={{ maxWidth: "100px", padding: columns[3].padding }}>
                                            <IconButton size="small" onClick={() => deleteTemplate( row.id )}>
                                                <Delete sx={{ fontSize: "18px" }} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>}
                {!loading && ResumeBuilderStore.getTemplates.length === 0 && <Box flexGrow={1} justifyContent="center" bgcolor="#eee" padding="20px" display="flex" flexDirection="column" gap="20px" alignItems="center">
                    <img src={noFormImg} height="150px" style={{ marginBottom: "20px" }} alt="No Form" />
                    <Typography color="textSecondary" variant='subtitle2'>Seems like there are no templates to show!</Typography>
                    <Button sx={{ textTransform: "capitalize" }} startIcon={Icons.default.AddIcon} onClick={() => setAddDialogState( true )} variant='outlined'>Add Template</Button>
                </Box>}
                {loading && <DataLoadingSpinner waitingMessage="Loading resume templates..." />}
            </Paper>
        </Box> :
            <Box padding="20px">
                <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
            </Box>
    )
} )

export default ResumeTemplates

import { Box, IconButton, TableCell, TableRow, Tooltip, useTheme } from '@mui/material'
import React, { useState } from 'react'
import Progress from '../common/Progress'
import PendingDataList from './PendingDataList'
import { Icons } from '../../utils/utilities'

const AuditStudentRow = ( { setDetailsDialogState, setSelectedStudent, requiredChecklistItemsCount, item } ) => {

    const [viewMore, setViewMore] = useState( false )

    const { palette, table } = useTheme()

    return (
        <TableRow className='table-row' sx={{ "&:hover": { background: palette.rowHover }, cursor: " pointer" }}>
            <TableCell onClick={() => { setDetailsDialogState( true ); setSelectedStudent( item ) }} sx={{ fontSize: "12px", color: table.color, padding: "10px 20px" }}><strong>{item.auid}</strong></TableCell>
            <TableCell onClick={() => { setDetailsDialogState( true ); setSelectedStudent( item ) }} sx={{ fontSize: "12px", color: table.color, padding: "10px 20px", maxWidth: "200px", textTransform: "capitalize" }}><strong>{item.student_name?.toLowerCase()}</strong></TableCell>
            <TableCell align='center' onClick={() => { setDetailsDialogState( true ); setSelectedStudent( item ) }} sx={{ fontSize: "12px", color: table.color, padding: "10px 20px" }}><strong>{item.totalItems}</strong></TableCell>
            <TableCell align='center' onClick={() => { setDetailsDialogState( true ); setSelectedStudent( item ) }} sx={{ fontSize: "12px", color: table.color, padding: "10px 20px" }}><strong>{requiredChecklistItemsCount}</strong></TableCell>
            <TableCell align='center' onClick={() => { setDetailsDialogState( true ); setSelectedStudent( item ) }} sx={{ fontSize: "12px", color: table.color, padding: "10px 20px" }}>
                <strong> <em>{item.requiredSubmissionPendingCount}</em> </strong>
            </TableCell>
            <TableCell align='center' onClick={() => { setDetailsDialogState( true ); setSelectedStudent( item ) }} sx={{ fontSize: "12px", color: table.color, padding: "10px 20px" }}>
                <strong> <em>{item.requiredApprovalPending}</em> </strong>
            </TableCell>
            <TableCell sx={{ padding: "10px 20px" }}>
                <PendingDataList onClick={() => { setDetailsDialogState( true ); setSelectedStudent( item ) }} list={item.pendingItems} item={item} />
            </TableCell>
            <TableCell align="right" sx={{ padding: "10px 20px" }}>
                <Box display="flex" gap="10px" alignItems="center ">
                    <Box onClick={() => { setDetailsDialogState( true ); setSelectedStudent( item ) }} display="flex" justifyContent="flex-end" gap="10px">
                        <Tooltip sx={{ cursor: "pointer" }} placement="top" title="Required Checklist Completion Percentage">
                            <span>
                                <Progress color={palette.success.main} trackWidth={8} indicatorWidth={6} size={51} progress={item.requiredItemsCompletionPercentage || 0} />
                            </span>
                        </Tooltip>
                        <Tooltip sx={{ cursor: "pointer" }} placement="top" title="Overall Progress">
                            <span>
                                <Progress color={palette.success.main} trackWidth={8} indicatorWidth={6} size={51} progress={item.totalProgress || 0} />
                            </span>
                        </Tooltip>
                        {viewMore && <Tooltip sx={{ cursor: "pointer" }} placement="top" title="Required Checklist Submission Percentage">
                            <span>
                                <Progress color={palette.customThemeColor.main} trackWidth={8} indicatorWidth={6} size={51} progress={item.requiredSubmissionCompletedPercentage || 0} />
                            </span>
                        </Tooltip>}
                        {viewMore && <Tooltip sx={{ cursor: "pointer" }} placement="top" title="Overall Submission Progress">
                            <span>
                                <Progress color={palette.customThemeColor.main} trackWidth={8} indicatorWidth={6} size={51} progress={item.totalSubmissionCompletedPercentage || 0} />
                            </span>
                        </Tooltip>}
                    </Box>
                    <Tooltip title={`${viewMore ? "Hide more" : "More"} progress details`}>
                        <IconButton onClick={() => setViewMore( p => !p )}>
                            {!viewMore ? Icons.default.ChevronRightIcon : Icons.default.ChevronLeftIcon}
                        </IconButton>
                    </Tooltip>
                </Box>
            </TableCell>
        </TableRow>
    )
}

export default AuditStudentRow

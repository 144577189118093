import { Box, Card, TextField, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import api from '../../service/api'
import { auditCountCardColors, getCountString } from '../../utils/utilities'
import { Outlet, useSearchParams } from 'react-router-dom'
import { useUserStore } from '../../store/user.store'
import { useOrganizationStore } from '../../store/organization.store'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import { Error } from '@mui/icons-material'
import InstituteSelect from '../../components/audit/InstituteSelect'
import DepartmentSelect from '../../components/audit/DepartmentSelect'
import TryAgainMessageBlock from '../../components/common/TryAgainMessageBlock'


const AuditQuestionBanks = () => {

    const [searchParams, setSearchParams] = useSearchParams( { searchText: "", month: "", selectedDepartment: "all", selectedInstitute: "all", view: "tabular", sortByName: "1", sortBySubmissionsCount: "1", sortByAssignmentCount: "1" } )
    const searchText = searchParams.get( 'searchText' ) || ""
    const selectedInstitute = searchParams.get( 'selectedInstitute' ) || "all"
    const selectedDepartment = searchParams.get( 'selectedDepartment' ) || "all"

    const [error, setError] = useState( false )
    const [counts, setCounts] = useState( {} )
    const [loading, setLoading] = useState( true )
    const [departments, setDepartments] = useState( [] )
    const [loadingDepartments, setLoadingDepartments] = useState( false )

    const UserStore = useUserStore()
    const OrganizationStore = useOrganizationStore()

    const { palette } = useTheme()


    const setSearchParamValue = ( key, val ) => {
        const newParams = new URLSearchParams( searchParams )
        newParams.set( key, val )
        setSearchParams( newParams, { replace: true } )
    }

    const handleInstituteChange = async val => {
        const newParams = new URLSearchParams( searchParams )
        newParams.set( 'selectedInstitute', val )
        newParams.set( 'selectedDepartment', "all" )
        setSearchParams( newParams, { replace: true } )
    }

    const getData = useCallback( async () => {
        try {
            setLoading( true )
            const department = selectedDepartment !== "all" ? selectedDepartment : ""
            const institute = selectedInstitute !== "all" ? selectedInstitute : ""
            const { data: { data: counts } } = await api.audit.getQuestionBanksCounts( { last30dayscount: true, institute, department } )
            setCounts( counts )
            setError( null )
        } catch ( err ) {
            setError( { code: err?.response?.status, message: err?.response?.data?.message || "Something went wrong while fetching the assignment audit data." } )
        } finally {
            setLoading( false )
        }
    }, [selectedInstitute, selectedDepartment] )


    useEffect( () => {
        getData()
    }, [getData] )

    useEffect( () => {
        const getData = async () => {
            setDepartments( [] )
            try {
                if ( "PRINCIPAL" === UserStore.getUser.user_role || UserStore.getUser.user_auditor || ["ADMIN", "SUBADMIN"].includes( UserStore.getUser?.user_role?.toUpperCase() ) ) {
                    setLoadingDepartments( true )
                    const institute = selectedInstitute !== "all" ? selectedInstitute : ""
                    if ( ( UserStore.getUser.user_auditor || ["ADMIN", "SUBADMIN"].includes( UserStore.getUser?.user_role?.toUpperCase() ) ) && ( !OrganizationStore.getOrganization || OrganizationStore.getOrganization?.length === 0 ) ) {
                        if ( OrganizationStore.getOrganization?.length === 0 )
                            await OrganizationStore.fetchOrganization()
                        setLoadingDepartments( false )
                    }
                    let fetchDepartments = true
                    if ( "PRINCIPAL" !== UserStore.getUser.user_role && !institute )
                        fetchDepartments = false
                    if ( fetchDepartments === true ) {
                        const { data: { data } } = await api.audit.fetchInstituteDepartment( { institute } )
                        setDepartments( data )
                    }
                }
            } catch ( err ) {
                setError( true )
                toast( err?.response?.data?.message || "Something went wrong while fetching the institute's department." )
            } finally {
                setLoadingDepartments( false )
            }
        }
        getData()
    }, [UserStore, selectedInstitute, OrganizationStore] )


    return (
        <Box padding="20px" display="flex" flexDirection="column" gap="20px" overflow="auto" flexGrow={1}>
            <Card sx={{ flexShrink: 0 }}>
                <Box padding="20px" display="flex" flexWrap="wrap" gap="20px" justifyContent="space-between" >
                    <Box display="flex" flexGrow={1} gap="20px" flexDirection="column" justifyContent="space-between">
                        <Box>
                            <Typography variant="h6" fontSize="18px" color="primaryDark.main" >Question banks audit</Typography>
                            <Typography variant="body" fontSize="14px" color="primaryDark.main" >View all question banks activities here.</Typography>
                        </Box>
                        <Box>
                            <Box display="flex" gap="20px" flexWrap="wrap">

                                {( UserStore.getUser.user_auditor || ["ADMIN", "SUBADMIN"].includes( UserStore.getUser?.user_role?.toUpperCase() ) ) && <InstituteSelect selectedInstitute={selectedInstitute} handleInstituteChange={handleInstituteChange} />}

                                {( UserStore.getUser.user_role === 'PRINCIPAL' || UserStore.getUser.user_auditor || ["ADMIN", "SUBADMIN"].includes( UserStore.getUser?.user_role?.toUpperCase() ) ) && <DepartmentSelect disabled={loading || loadingDepartments || ( selectedInstitute === "all" && UserStore.getUser.user_role !== 'PRINCIPAL' )} departments={departments} handleDepartmentChange={val => setSearchParamValue( 'selectedDepartment', val )} selectedDepartment={selectedDepartment} />}

                                <TextField value={searchText} onChange={e => setSearchParamValue( 'searchText', e.target.value )} size='small' color='secondary' fullWidth sx={{ maxWidth: "450px", '& input': { padding: "0 10px", height: "37.17px", '&::placeholder': { fontSize: "14px", fontWeight: "500", color: palette.form.placeholder } } }} placeholder='Search by faculty name, date or subject.' />
                            </Box>
                        </Box>
                    </Box>
                    {!loading && <Box display="flex" flexWrap="wrap" gap="20px">
                        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start" padding="20px" flexShrink={0} borderRadius="10px" bgcolor={auditCountCardColors[0].bg} >
                            <Box width="50px" display="flex" alignItems="center" justifyContent="center" sx={{ aspectRatio: 1 }} borderRadius="50%" bgcolor={auditCountCardColors[0].circleColor} >
                                {!error && <Typography variant="h6" fontSize="14px" color="white">{getCountString( counts.totalQuestionBanks )}</Typography>}
                                {error && <Error />}
                            </Box>
                            <Box marginTop="10px">
                                <Typography color="primaryDark.main" variant="subtitle2">Question banks</Typography>
                                {!error && <Typography variant='subtitle2' fontWeight="normal" fontSize="12px" ><strong>{getCountString( counts.last30daysCount )}</strong> in last 30 days</Typography>}
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start" padding="20px" flexShrink={0} borderRadius="10px" bgcolor={auditCountCardColors[1].bg} >
                            <Box width="50px" display="flex" alignItems="center" justifyContent="center" sx={{ aspectRatio: 1 }} borderRadius="50%" bgcolor={auditCountCardColors[1].circleColor} >
                                {!error && <Typography variant="h6" fontSize="14px" color="white">{getCountString( counts.totalQuestions )}</Typography>}
                                {error && <Error />}
                            </Box>
                            <Box marginTop="10px">
                                <Typography color="primaryDark.main" variant="subtitle2">Questions</Typography>
                                {!error && <Typography variant='subtitle2' fontWeight="normal" fontSize="12px" ><strong>{getCountString( counts.last30daysQuestionsCount )}</strong> in last 30 days</Typography>}
                            </Box>
                        </Box>
                    </Box>}
                </Box>
            </Card>
            {!error && loading && <Card>
                <DataLoadingSpinner waitingMessage="Loading basic reports please wait..." />
            </Card>}
            {!loading && !error && <Outlet />}
            {error && <Card sx={{ padding: "20px" }}>
                <TryAgainMessageBlock getData={getData} err={error.message} code={error.code} loading={loading} />
            </Card>}
        </Box >
    )
}

export default AuditQuestionBanks

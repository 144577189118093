import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import ConfirmDialog from '../../components/common/ConfirmDialog'
import AddEditAdmin from '../../components/admin/AddEditAdmin'
import noAdminsImg from '../../assets/no-subadmins.svg'
import React, { useEffect, useState } from 'react'
import { Icons } from '../../utils/utilities'
import api from '../../service/api'
import TableHeader from '../../components/common/TableHeader'

const columns = [
    { name: "Sl.No.", maxWidth: "100px", padding: "10px 20px 10px 40px", align: "left" },
    { name: "Username", maxWidth: "200px", align: "left" },
    { name: "E-mail", maxWidth: "200px", align: "left" },
    { name: "", maxWidth: "200px", align: "right", padding: "10px 40px 10px 20px", },
]

const ManageAccounts = () => {
    const [deleteConfirmDialogState, setDeleteConfirmDialogState] = useState( false )
    const [deleteSelectedUserId, setDeleteSelectedUserId] = useState( null )
    const [adminUsers, setAdminUsers] = useState( [] )
    const [adminUser, setAdminUser] = useState( null )
    const [isLoading, setIsLoading] = useState( true )
    const [mode, setMode] = useState( "manage" )

    const { palette } = useTheme()

    const deleteAdminUser = async ( id ) => {
        try {
            await api.deleteSubAdminAccount( id )
            const res = await api.getSubAdminAccounts()
            setAdminUsers( res.data.data )
        } catch ( err ) {
            console.log( err )
        }
    }

    useEffect( () => {
        const getAdmins = async () => {
            try {
                const res = await api.getSubAdminAccounts()
                setAdminUsers( res.data.data )
            } catch ( err ) {
                console.log( err )
            } finally {
                setIsLoading( false )
            }
        }
        getAdmins()
    }, [mode] )

    return (
        <Box padding="20px" overflow="auto" display="flex" flexDirection="column" flexGrow={1}>
            <ConfirmDialog status={deleteConfirmDialogState} confirmAction={
                () => {
                    try {
                        deleteAdminUser( deleteSelectedUserId )
                        setDeleteConfirmDialogState( false )
                    } catch ( err ) { }

                }} cancelAction={() => { setDeleteConfirmDialogState( false ) }} actionName="Delete" message="This account can not be retrived" isReversible={false} />
            <Paper sx={{ minHeight: !isLoading && "350px", flexGrow: !isLoading && mode === "manage" && adminUsers.length > 0 ? "1" : "initial", display: "flex", flexDirection: "column" }}>
                <Box padding="20px" borderBottom="1px solid #d3d3d3">
                    <Box display="flex" gap="20px" justifyContent="space-between">
                        <Box>
                            <Typography variant='h5'>Admins</Typography>
                            <Typography variant='subtitle2' color="textSecondary">Manage admins permissions</Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            {mode === "manage" && <Button disableElevation sx={{ textTransform: "capitalize" }} variant="contained" startIcon={Icons.default.AddIcon} color="primary" onClick={() => { if ( mode === "add" || mode === "edit" ) setMode( "manage" ); else setMode( "add" ) }}>
                                Add new user
                            </Button>}
                        </Box>
                    </Box>
                </Box>
                <Box overflow="auto">
                    {( mode === "add" || mode === "edit" ) &&
                        <AddEditAdmin mode={mode} setMode={setMode} user={adminUser} />
                    }
                </Box>
                {!isLoading && mode === "manage" && adminUsers.length > 0 &&
                    <Box flexGrow={1} overflow="hidden">
                        {<TableContainer className='custom-scrollbar' sx={{ height: "100%", overflow: "auto" }}>
                            <Table stickyHeader>
                                <TableHeader columns={columns} />
                                <TableBody>
                                    {adminUsers.map( ( user, index ) => (
                                        <TableRow className='table-row' sx={{ "&:hover": { background: palette.rowHover } }} key={`row-${index}`}>
                                            <TableCell sx={{ padding: columns[0].padding || "10px 20px" }}>{index + 1}</TableCell>
                                            <TableCell sx={{ padding: columns[1].padding || "10px 20px" }}>{user.user_name}</TableCell>
                                            <TableCell sx={{ padding: columns[2].padding || "10px 20px" }}>{user.user_email}</TableCell>
                                            <TableCell sx={{ padding: columns[3].padding || "10px 20px" }}>
                                                <Box display="flex" justifyContent="flex-end" gap="10px" alignItems="center">
                                                    <IconButton size="small" sx={{ padding: "5px" }} color="secondary" onClick={() => { setAdminUser( user ); setMode( "edit" ) }}>
                                                        <ManageAccountsIcon />
                                                    </IconButton>
                                                    <IconButton size="small" onClick={() => { setDeleteSelectedUserId( user.user_id ); setDeleteConfirmDialogState( true ) }} sx={{ padding: "5px" }} color="error" >
                                                        {Icons.default.DeleteIcon}
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ) )}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                        {isLoading && <DataLoadingSpinner waitingMessage="Loading accounts..." />}
                    </Box>
                }
                {adminUsers.length === 0 && !isLoading && mode === "manage" &&
                    <Box padding="20px" flexGrow={1} justifyContent="center" display="flex" alignItems="center" flexDirection="column" gap="10px" bgcolor="#eee">
                        <img src={noAdminsImg} width="200px" alt="No subadmins added" />
                        <Typography variant='subtitle2' color="textSecondary">No subadmin accounts!</Typography>
                        <Button onClick={() => { if ( mode === "add" || mode === "edit" ) setMode( "manage" ); else setMode( "add" ) }} startIcon={Icons.default.AddIcon} variant='outlined' sx={{ textTransform: "capitalize" }}>Add user</Button>
                    </Box>
                }
            </Paper>
        </Box>
    )
}

export default ManageAccounts

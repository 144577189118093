import { Avatar, Box, Button, Tooltip, Typography, useTheme } from '@mui/material'
import { useDiscussionForum } from '../../store/discussionforum.store'
import { Icons, getTimePassedFrom } from '../../utils/utilities'
import { ThumbsUpDownOutlined } from '@mui/icons-material'
import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useUserStore } from '../../store/user.store'
import CommentEditDialog from './CommentEditDialog'

const DiscussionComment = observer( ( { comment, openCommentBox, commentPos } ) => {

    const [votingInProgress, setVotingInProgress] = useState( false )
    const [editMode, setEditMode] = useState( false )
    const [deleting, setDeleting] = useState( false )

    const DiscussionForumStore = useDiscussionForum()
    const UserStore = useUserStore()

    const { palette } = useTheme()

    const getNameWithApostrophe = ( name ) => {
        const n = name.toLowerCase()
        if ( n.substring( n.length - 1 ) === 's' )
            return `${n}'`
        return `${n}'s`
    }

    const handleReply = () => {
        const title = `Replying to ${comment._id} by @${getNameWithApostrophe( comment.commentor_data.name )} comment `
        openCommentBox( title, { id: comment._id, user_name: comment.commentor_data.name } )
    }

    const vote = async ( vote ) => {
        setVotingInProgress( true )
        await DiscussionForumStore.voteComment( comment._id, vote, commentPos )
        setVotingInProgress( false )
    }

    const deleteComment = async () => {
        setDeleting( true )
        await DiscussionForumStore.deleteComment( comment._id, commentPos )
        setDeleting( false )
    }

    return (
        <Box position="relative" borderBottom="2px solid black"  >

            {editMode && <CommentEditDialog commentPos={commentPos} comment={comment} state={editMode} setState={setEditMode} />}

            <Box id={comment._id} sx={{ pointerEvents: "none" }} position="absolute" top="-60px"></Box>
            <Box padding="10px" display="flex" alignItems="flex-start" gap="10px">
                <Box alignItems="center" display="flex" flexDirection="column" gap="10px">
                    <Avatar sx={{ width: { md: "40px", xs: "30px" }, height: { md: "40px", xs: "30px" } }}>{comment.commentor_data.name?.substring( 0, 1 )?.toUpperCase()}</Avatar>
                    <Tooltip title="Votes">
                        <Typography color="textSecondary" display="flex" gap="5px" alignItems="center" variant="subtitle2">{comment.votes.up - comment.votes.down || 0} <ThumbsUpDownOutlined sx={{ fontSize: "15px" }} /></Typography>
                    </Tooltip>
                </Box>
                <Box flexGrow={1} display="flex" alignItems="flex-start" flexDirection="column" gap="10px">
                    <Box>
                        <Typography margin={0} textTransform="capitalize" fontSize="14px" variant="subtitle2">
                            @{comment.commentor_data.name?.toLowerCase()}
                            {comment.parent && <span> to <a className='comment-reference-link' href={`#${comment.parent.id}`}>{getNameWithApostrophe( comment.parent.user_name )}</a> comment</span>}
                        </Typography>
                        <Typography margin={0} marginTop="-3px" fontSize="14px" fontWeight="300">{comment.updated_at ? `Edited ${getTimePassedFrom( comment.updated_at )}` : getTimePassedFrom( comment?.commented_at )}</Typography>
                    </Box>
                    <Box className='wysiwyg-text-container' display="flex" flexDirection="column" flexGrow={1} width="100%" borderRadius="5px" bgcolor={palette.contentBg}>
                        <Box padding="10px 20px" dangerouslySetInnerHTML={{ __html: comment.comment_content }}></Box>
                        <Box display="flex" borderTop="1px solid #d3d3d3" gap="10px" alignItems="center" padding="5px 20px">
                            <Button
                                disabled={votingInProgress}
                                onClick={() => { if ( DiscussionForumStore.getVotes ) DiscussionForumStore.getVotes[comment._id] === 1 ? vote( 0 ) : vote( 1 ) }}
                                sx={{
                                    textTransform: "capitalize",
                                    color: DiscussionForumStore.getVotes ? DiscussionForumStore.getVotes[comment._id] !== 1 ? "GrayText" : "primary" : "GrayText"
                                }}
                                startIcon={DiscussionForumStore.getVotes && DiscussionForumStore.getVotes[comment._id] !== 1 ? Icons.default.LikeOutlined : Icons.default.Like}
                                size="small"
                            >
                                {comment?.votes?.up || 0}
                            </Button>
                            <Button
                                disabled={votingInProgress}
                                onClick={
                                    () => {
                                        if ( DiscussionForumStore.getVotes )
                                            DiscussionForumStore.getVotes[comment._id] === -1 ? vote( 0 ) : vote( -1 )
                                    }}
                                sx={{
                                    textTransform: "capitalize",
                                    color: DiscussionForumStore.getVotes ? DiscussionForumStore.getVotes[comment._id] !== -1 ? "GrayText" : "primary" : "GrayText"
                                }}
                                startIcon={DiscussionForumStore.getVotes && DiscussionForumStore.getVotes[comment._id] !== -1 ? Icons.default.UnlikeOutlined : Icons.default.Unlike}
                                size="small"
                            >
                                {comment?.votes?.down || 0}
                            </Button>
                            <Button onClick={handleReply} sx={{ textTransform: "capitalize", color: "GrayText" }} startIcon={Icons.default.Reply} size="small">Reply</Button>
                            {UserStore.getUser.user_id === comment?.commented_by && <Button onClick={() => setEditMode( true )} sx={{ textTransform: "capitalize" }} startIcon={Icons.default.EditIcon} size="small">Edit</Button>}
                            {UserStore.getUser.user_id === comment?.commented_by && <Button onClick={deleteComment} sx={{ textTransform: "capitalize" }} color="error" startIcon={Icons.default.DeleteIcon} disabled={deleting} size="small">Delete</Button>}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
} )

export default DiscussionComment
import { useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

const CodeButton = ( { children, size = "normal" } ) => {

    const { theme } = useTheme()

    return (
        <code className={`code-button ${size || ""} ${theme}`}>{children}</code>
    )
}

CodeButton.protoType = {
    size: PropTypes.oneOf( ["small", "normal", "large"] )
}

export default CodeButton

import { Avatar, Box, Tooltip, Typography, useTheme } from '@mui/material'
import { getTimePassedFrom } from '../../../utils/utilities'
import { ThumbsUpDownOutlined } from '@mui/icons-material'
import { observer } from 'mobx-react'
import React from 'react'

const AdminViewDiscussionComment = observer( ( { comment } ) => {

    const { theme, palette } = useTheme()

    const getNameWithApostrophe = ( name ) => {
        const n = name.toLowerCase()
        if ( n.substring( n.length - 1 ) === 's' )
            return `${n}'`
        return `${n}'s`
    }

    return (
        <Box position="relative" borderBottom="2px solid black"  >
            <Box id={comment._id} sx={{ pointerEvents: "none" }} position="absolute" top="-60px"></Box>
            <Box padding="10px" display="flex" alignItems="flex-start" gap="10px">
                <Box alignItems="center" display="flex" flexDirection="column" gap="10px">
                    <Avatar sx={{ width: { md: "40px", xs: "30px" }, height: { md: "40px", xs: "30px" } }}>{comment.commentor_data.name?.substring( 0, 1 )?.toUpperCase()}</Avatar>
                    <Tooltip title="Votes">
                        <Typography color="textSecondary" display="flex" gap="5px" alignItems="center" variant="subtitle2">{comment.votes.up - comment.votes.down || 0} <ThumbsUpDownOutlined sx={{ fontSize: "15px" }} /></Typography>
                    </Tooltip>
                </Box>
                <Box flexGrow={1} display="flex" alignItems="flex-start" flexDirection="column" gap="10px">
                    <Box>
                        <Typography margin={0} textTransform="capitalize" fontSize="14px" variant="subtitle2">
                            @{comment.commentor_data.name?.toLowerCase()}
                            {comment.parent && <span> to <a className='comment-reference-link' href={`#${comment.parent.id}`}>{getNameWithApostrophe( comment.parent.user_name )}</a> comment</span>}
                        </Typography>
                        <Typography margin={0} marginTop="-3px" fontSize="14px" fontWeight="300">{getTimePassedFrom( comment.commented_at )}</Typography>
                    </Box>
                    <Box className='wysiwyg-text-container' display="flex" flexDirection="column" flexGrow={1} width="100%" borderRadius="5px" bgcolor={theme === 'dark' ? palette.greyedOut : "whitesmoke"}>
                        <Box padding="10px 20px" dangerouslySetInnerHTML={{ __html: comment.comment_content }}></Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
} )

export default AdminViewDiscussionComment
import { Box, Button, Paper, Table, TableBody, TableContainer, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Icons } from '../../../utils/utilities'
import { useResumeBuilderStore } from '../../../store/resumebuilder.store'
import noTemplatesImg from '../../../assets/no-resume-templates.svg'
import { observer } from 'mobx-react'
import EntitiesTableRow from '../../../components/admin/resume-builder/EntitiesTableRow'
import AddNewEntity from '../../../components/admin/resume-builder/AddNewEntity'
import EditEntity from '../../../components/admin/resume-builder/EditEntity'
import CustomSearchBar from '../../../components/common/CustomSearchBar'
import { useUserStore } from '../../../store/user.store'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import BlockMessage from '../../../components/common/BlockMessage'
import TableHeader from '../../../components/common/TableHeader'

const tableColumns = [
    { label: "Name", padding: "10px 20px 10px 40px", styles: { minWidth: "200px", } },
    { label: "Key", styles: { minWidth: "150px", } },
    { label: "Label", styles: { minWidth: "150px", } },
    { label: "Show Label", styles: { minWidth: "80px", } },
    { label: "Type", styles: { minWidth: "100px", } },
    { label: "", padding: "10px 40px 10px 20px" },
]

const ResumeEntities = observer( () => {

    const [addDialogstate, setAddDialogState] = useState( false )
    const [loading, setLoading] = useState( true )
    const [selectedEntity, setSelectedEntity] = useState( null )
    const [searchText, setSearchText] = useState( '' )
    const [hasPermission, setHasPermission] = useState( true )

    // const navigate = useNavigate()
    const ResumeBuilderStore = useResumeBuilderStore()
    const UserStore = useUserStore()

    useEffect( () => {
        const getData = async () => {
            await ResumeBuilderStore.fetchEntities()
            setLoading( false )
        }
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.resume_builder ) {
            setHasPermission( false )
        } else
            getData()
    }, [ResumeBuilderStore, UserStore] )

    return (
        hasPermission ? <Box flexGrow={1} display="flex" flexDirection="column" padding="20px" overflow="auto">
            <AddNewEntity addDialogstate={addDialogstate} entities={ResumeBuilderStore.getEntities} setAddDialogState={setAddDialogState} />
            {selectedEntity && <EditEntity setSelectedEntity={setSelectedEntity} entity={selectedEntity} />}
            <Paper sx={{ minHeight: !loading && "350px", flexGrow: !loading && ResumeBuilderStore.getEntities.length === 0 ? "initial" : "1", display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <Box borderBottom="1px solid #d3d3d3" padding="20px" flexWrap="wrap" gap="20px" display="flex" flexDirection={{ xs: "column", sm: "row" }} alignitems="center" >
                    <Box flexGrow={1}>
                        <Typography variant='h5'>Resume Entities</Typography>
                        <Typography variant='subtitle2' color="textSecondary">Manage resume entities from here.</Typography>
                    </Box>
                    {ResumeBuilderStore.getEntities.length > 0 && !loading && <Box display="flex" justifyContent={{ xs: "flex-start", sm: "flex-end" }} flexGrow={1} alignItems="center" gap="10px">
                        {/* <TextField value={searchText} onChange={e => setSearchText( e.target.value )} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", "@media(max-width:900px)": { width: "100%" } }, startAdornment: <Search sx={{ marginX: "10px" }} /> }} sx={{ border: "1px solid rgba(0,0,0,0.1)", background: "#eee", flexGrow: 1, marginRight: "10px", outline: "none", borderRadius: "5px", height: "40px", color: "black", "@media(max-width:900px)": { width: "100%" } }} placeholder="Search by name..." variant="standard" type="text" /> */}
                        <CustomSearchBar border="1px solid #d3d3d3" value={searchText} onChange={e => setSearchText( e.target.value )} placeholder="Search by name..." style={{ width: { xs: "100%", sm: "220px" } }} />
                        <Button disableElevation variant='contained' sx={{ textTransform: "capitalize", width: { xs: "100%", sm: "fit-content" } }} startIcon={Icons.default.AddIcon} onClick={() => setAddDialogState( true )}  >Create</Button>
                    </Box>}
                </Box>
                {
                    ResumeBuilderStore.getEntities.length === 0 && !loading && <Box flexGrow={1} justifyContent="center" bgcolor="#eee" padding="20px" display="flex" flexDirection="column" gap="20px" alignItems="center">
                        <img src={noTemplatesImg} height="150px" style={{ marginBottom: "20px" }} alt="No templates" />
                        <Typography color="textSecondary" variant='subtitle2'>Seems like there are no resume entities to show!</Typography>
                        <Button onClick={() => setAddDialogState( true )} startIcon={Icons.default.AddIcon} sx={{ textTransform: "capitalize" }} variant='outlined'>Add Entity</Button>
                    </Box>
                }
                {
                    ResumeBuilderStore.getEntities.length > 0 && !loading &&
                    <Box flexGrow={1} overflow="hidden">
                        <TableContainer className="custom-scrollbar" sx={{ height: "100%", overflow: "auto", position: "relative" }}>
                            <Table stickyHeader>
                                <TableHeader columns={tableColumns} />
                                <TableBody sx={{ overflow: "auto !important" }}>
                                    {ResumeBuilderStore.getEntities.filter( item => item.entity_name.toLowerCase().includes( searchText ) ).map( item => (
                                        <EntitiesTableRow key={item.id} item={item} setSelectedEntity={setSelectedEntity} />
                                    ) )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
                {loading && <DataLoadingSpinner waitingMessage="Loading resume entities..." />}
            </Paper >
        </Box> :
            <Box padding="20px">
                <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
            </Box>
    )
} )

export default ResumeEntities

import { Box, CircularProgress, Paper, Typography } from '@mui/material'
import React from 'react'
import TemplateSectionBox from './TemplateSectionBox'
import { forwardRef } from 'react'
const Template = ( { data, layout, loading, cref } ) => {

    return (
        <Box overflow="auto" flexGrow={1}>
            <Paper id="templateContent"
                sx={{
                    bgcolor: "white", margin: "0 auto", flexGrow: 1, width: "850px", minHeight: "1183px", position: "relative",
                }}
            >
                {loading && <Box height="100%" width="100%">
                    <Typography padding="20px" paddingTop="0" display="flex" alignItems="center" gap="10px" color="textSecondary" variant="h6"> <CircularProgress size={20} /> Loading your template... </Typography>
                </Box>}
                <Box ref={cref} position="relative" sx={{ zIndex: "2", cursor: "default" }} padding={`${layout?.padding}px`} display="flex" flexDirection="column" gap="20px">
                    {!loading && layout.rows.map( ( row, rowIndex ) => (
                        <Box
                            minHeight="100px"
                            position="relative"
                            key={rowIndex}
                            display="grid"
                            alignItems={row.align ? row.align : "initial"}
                            gridTemplateColumns={row.columnRatio}
                            gap={`${row.gap}px`}
                        >
                            {row.columns.map( ( column, columnIndex ) => (
                                <Box
                                    sx={{ pointerEvents: "all", useSelect: "none" }}
                                    display="flex"
                                    flexDirection="column"
                                    key={columnIndex}
                                    borderRadius="5px"
                                >
                                    {column.filter( section => data[section.section_title] ? true : false ).map( ( section, sectionIndex ) => (
                                        <TemplateSectionBox data={data[section.section_title] ? data[section.section_title] : {}} layout={layout} rowIndex={rowIndex} columnIndex={columnIndex} sectionIndex={sectionIndex} section={section} key={sectionIndex} />
                                    ) )}
                                </Box>
                            ) )}
                        </Box>
                    ) )}
                </Box>
            </Paper>
        </Box>
    )
}

const ResumeTemplate = forwardRef( ( props, ref ) => {
    return <Template cref={ref} layout={props.layout} loading={props.loading} data={props.data} />
} )

export default ResumeTemplate
import { Box, Paper, Step, StepButton, Stepper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CreateExamComponent from '../../components/admission-exam/CreateExamComponent'
import AddQuestionsComponent from '../../components/admission-exam/AddQuestionsComponent'
import ExamCreationPreviewComponent from '../../components/admission-exam/ExamCreationPreviewComponent'
import BreadcrumbList from '../../components/common/BreadcrumbList'
import { useUserStore } from '../../store/user.store'
import warningImg from '../../assets/warning.svg'

const steps = [
    'Specify Exam details',
    'Manage Questions',
    'Preview and submit',
]

const CreateAdmissionExam = () => {

    const [activeStep, setActiveStep] = useState( 0 )
    const [stepsStatus, setStepsStatus] = useState( { 0: "pending", 1: "pending", 2: "pending" } )
    const [examCreationData, setExamCreationData] = useState( {} )
    const [hasPermission, setHasPermission] = useState( true )


    const UserStore = useUserStore()


    const handleStepClick = index => {
        if ( stepsStatus[index] === 'completed' ) {
            setActiveStep( index )
        }
    }


    useEffect( () => {
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.admission_exams ) {
            setHasPermission( false )
        }
    }, [UserStore] )

    return (
        <Box padding="20px" overflow="auto" flexGrow={1}>

            {hasPermission && <BreadcrumbList items={[
                { label: "Exams", link: "/admin/assessment" },
                "Create New Exam"
            ]} />}

            {hasPermission ? <Paper>
                <Box padding="20px">
                    <Box>
                        <Stepper activeStep={activeStep}>
                            {steps.map( ( label, index ) => (
                                <Step sx={{ pointerEvents: "none" }} completed={stepsStatus[index] === "completed"} onClick={() => handleStepClick( index )} key={label}>
                                    <StepButton>{label}</StepButton>
                                </Step>
                            ) )}
                        </Stepper>
                    </Box>
                </Box>
                {activeStep === 0 && <CreateExamComponent setStepsStatus={setStepsStatus} setActiveStep={setActiveStep} setExamCreationData={setExamCreationData} examCreationData={examCreationData} />}
                {activeStep === 1 && <AddQuestionsComponent setStepsStatus={setStepsStatus} setActiveStep={setActiveStep} examCreationData={examCreationData} setExamCreationData={setExamCreationData} />}
                {activeStep === 2 && <ExamCreationPreviewComponent setStepsStatus={setStepsStatus} setActiveStep={setActiveStep} examCreationData={examCreationData} setExamCreationData={setExamCreationData} />}
            </Paper> : <Paper sx={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px", alignItems: "center" }}>
                <img src={warningImg} width="40px" alt="No permission" />
                <Typography variant='subtitle2' color="textSecondary">You doesn't have permission to access this page!</Typography>
            </Paper>}
        </Box>
    )
}

export default CreateAdmissionExam

import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material'
import { useStudyMaterialStore } from '../../store/studymaterial.store'
import CustomSearchBar from '../../components/common/CustomSearchBar'
import noMaterialsImg from '../../assets/no-material.svg'
import { useUserStore } from '../../store/user.store'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import TableHeader from '../../components/common/TableHeader'
import { useSubjectStore } from '../../store/subject.store'
import { useNavigate, useParams } from 'react-router-dom'
import { MaterialIcon } from '../../assets/custom-icons'
import { showDateInIndianDateFormat } from '../../utils/utilities'

const columns = [
    { name: "TITLE", align: "left", padding: "10px 20px 10px 40px" },
    { name: "CREATED BY", align: "left" },
    { name: "DOCUMENT COUNT", align: "center" },
    { name: "CREATED AT", align: "center", padding: "10px 40px 10px 20px" },
]


const SubjectStudyMaterial = observer( () => {

    const [searchText, setSearchText] = useState( '' )
    const [docsCount, setDocsCount] = useState( {} )
    const [loading, setLoading] = useState( true )

    const { subject_id } = useParams()

    const { palette, table, border } = useTheme()

    const navigate = useNavigate()
    const UserStore = useUserStore()
    const SubjectStore = useSubjectStore()
    const StudyMaterialStore = useStudyMaterialStore()

    const handleMaterialSelect = ( id ) => {
        navigate( `/${UserStore.getUser.user_role === "STUDENT" ? 'student' : 'faculty'}/subject/${subject_id}/materials/${id}` )
    }

    useEffect( () => {
        const getData = async () => {
            await StudyMaterialStore.fetchMaterialsBySubject( SubjectStore.getSubject.subject_code )
            if ( UserStore.getUser.user_role === 'STUDENT' ) {
                const tempDocsCount = {}
                for ( let i = 0; i < ( StudyMaterialStore.getMaterials?.docsCount || [] ).length; i++ ) {
                    const mat = StudyMaterialStore.getMaterials?.docsCount[i]
                    tempDocsCount[mat.study_material_id] = mat.docs_count
                }
                setDocsCount( tempDocsCount )
            } else {
                console.log( StudyMaterialStore.getMaterials.materials )
                if ( StudyMaterialStore.getMaterials?.materials[0] ) {
                    navigate( `${StudyMaterialStore.getMaterials.materials[0].study_material_id}` )
                }
            }
            setLoading( false )
        }
        getData()
    }, [StudyMaterialStore, UserStore, SubjectStore, navigate] )

    return (
        <Box flexGrow={1} display="flex" flexDirection="column">
            {!loading && ( StudyMaterialStore?.getMaterials?.materials || [] ).length > 0 && <Box display="flex" justifyContent="space-between" gap="20px" alignItems="center" padding="20px 20px 0 20px">
                <Typography variant="h6">Study Materials</Typography>
                <CustomSearchBar border={`1px solid ${palette.borderColor}`} value={searchText} onChange={e => {
                    setSearchText( e.target.value )
                }} placeholder='Search study materials...' />
            </Box>}
            {!loading && ( StudyMaterialStore?.getMaterials?.materials || [] ).length > 0 && <Box padding="20px" display="flex" flexDirection="column" flexGrow={1}>
                <Box flexGrow={1} border={border[1]} borderRadius="5px" bgcolor={palette.contentBg}>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHeader columns={columns} />
                            <TableBody>
                                {( StudyMaterialStore?.getMaterials?.materials || [] )
                                    .filter( s => s.subject_code?.toLowerCase().includes( searchText?.toLowerCase() ) || s.study_material_name?.toLowerCase().includes( searchText?.toLowerCase() ) )
                                    .map( material => (
                                        <TableRow sx={{ cursor: "pointer" }} onClick={() => handleMaterialSelect( material.study_material_id )} key={material.study_material_id} className='table-row'>
                                            <TableCell sx={{ padding: columns[0].padding || "10px 20px" }}>
                                                <Box color={table.color} display="flex" alignItems="center" gap="10px" >
                                                    <img src={MaterialIcon} alt="Study material" />
                                                    <strong>{material.study_material_name?.trim()}</strong>
                                                </Box>
                                            </TableCell>
                                            <TableCell align={columns[1].align || 'left'} sx={{ padding: columns[1].padding || "10px 20px", color: table.color }}>
                                                <strong>{material.study_material_created_by?.trim()}</strong>
                                            </TableCell>
                                            <TableCell align={columns[2].align || 'left'} sx={{ padding: columns[2].padding || "10px 20px", color: table.color }}>
                                                <strong>{docsCount[material.study_material_id] || 0}</strong>
                                            </TableCell>
                                            <TableCell align={columns[3].align || 'left'} sx={{ padding: columns[3].padding || "10px 20px", color: table.color }}>
                                                <strong>{showDateInIndianDateFormat( material.study_material_published_date )}</strong>
                                            </TableCell>
                                        </TableRow>
                                        // <Box display="flex" alignItems="center" gap="10px" padding="10px" borderBottom="1px solid #d3d3d3" key={material.study_material_id}>
                                        //     <Box>
                                        //         <img src={MaterialIcon} alt="Study material" />
                                        //     </Box>
                                        //     <Box flexGrow={1}>
                                        //         <Typography color={palette.common.font} width="80%" noWrap onClick={() => handleMaterialSelect( material.study_material_id )} sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline", fontStyle: "italic" } }} title={material.study_material_name} variant="subtitle2">{material.study_material_name}</Typography>
                                        //         <Box display="flex" alignItems="center" gap="10px" justifyContent="space-between">
                                        //             <Typography variant="subtitle2" textTransform="capitalize" fontSize="12px" color="GrayText">{material.study_material_created_by?.trim()}</Typography>
                                        //             <Typography variant="body2" fontSize="12px">{new Date( material.study_material_published_date ).toLocaleDateString( 'en-IN', { day: "2-digit", month: "2-digit", year: "numeric" } ).replace( /\//g, "-" )}</Typography>
                                        //         </Box>
                                        //     </Box>
                                        // </Box>
                                    ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>}
            {!loading && ( StudyMaterialStore?.getMaterials?.materials || [] ).filter( s => s.subject_code?.toLowerCase().includes( searchText?.toLowerCase() ) || s.study_material_name?.toLowerCase().includes( searchText?.toLowerCase() ) ).length === 0 &&
                <Box display="flex" flexGrow={1} alignItems="center" padding="20px" flexDirection="column" gap="20px" justifyContent="center" >
                    <img src={noMaterialsImg} width="200px" alt="No materials" />
                    <Typography variant="subtitle2" >No material present for {!searchText ? <em> <strong>'{SubjectStore.getSubject.subject_name}'</strong> </em> : <em>search text <strong> '{searchText}'</strong> </em>} </Typography>
                </Box>
            }
            {loading && <DataLoadingSpinner waitingMessage="Loading study materials please wait..." />}
        </Box>
    )
} )

export default SubjectStudyMaterial

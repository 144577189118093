import { Box, IconButton, TableCell, TableRow, Tooltip, Typography, useTheme } from '@mui/material'
import React from 'react'
import { Icons } from '../../../utils/utilities'
import { toast } from 'react-toastify'
import api from '../../../service/api'
import { useResumeBuilderStore } from '../../../store/resumebuilder.store'
import CodeButton from '../../common/CodeButton'

const EntitiesTableRow = ( { item, setSelectedEntity } ) => {

    const ResumeBuilderStore = useResumeBuilderStore()
    const { palette } = useTheme()

    const deleteEntity = async () => {
        try {
            await api.deleteResumeEntity( item.id )
            await ResumeBuilderStore.fetchEntities()
        } catch ( err ) {
            console.log( err )
            toast( ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    return (
        <TableRow className='table-row' sx={{ "&:hover": { background: palette.rowHover } }}>
            <TableCell sx={{ padding: "5px 10px 5px 40px", fontWeight: "600" }}>
                <Box>
                    {item.entity_name}
                    <Typography fontSize="12px"><em>{item.description}</em></Typography>
                </Box>
            </TableCell>
            <TableCell sx={{ padding: "10px 20px" }}> <CodeButton>{item.entity_detail.key}</CodeButton> </TableCell>
            <TableCell sx={{ padding: "10px 20px" }}>{item.entity_detail.label}</TableCell>
            <TableCell sx={{ padding: "10px 20px" }} >
                <Box width="fit-content" padding="5px 10px" borderRadius="5px" bgcolor={`${item.entity_detail.label_show ? palette.success.light + "22" : palette.error.light + "22"}`} border={`1px solid ${item.entity_detail.label_show ? palette.success.dark : palette.errorMessage.dark}`}> <Typography fontSize="12px" sx={{ textTransform: "uppercase", fontStyle: "italic", fontWeight: "700", color: item.entity_detail.label_show ? palette.success.dark : palette.errorMessage.dark }}>{String( item.entity_detail.label_show )}</Typography></Box>
            </TableCell>
            <TableCell sx={{ padding: "10px 20px" }}>{item.entity_detail.data_type}{item.entity_detail.longText !== false && item.entity_detail.longText === true && "(long)"}</TableCell>
            <TableCell sx={{ padding: "5px 40px 5px 10px" }}>
                <Box display="flex" alignItems="center" gap="10px">
                    <Tooltip title="Edit Entity" placement='top-start'>
                        <IconButton onClick={() => setSelectedEntity( item )} size='small' color='secondary'>
                            {Icons.default.EditIcon}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Entity" placement='top-start'>
                        <IconButton onClick={deleteEntity} size='small' color='error'>
                            {Icons.default.DeleteIcon}
                        </IconButton>
                    </Tooltip>
                </Box>
            </TableCell>
        </TableRow>
    )
}

export default EntitiesTableRow

import { Box, Button, CircularProgress, Dialog, IconButton, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useQuizAndQuestionbankStore } from '../../../store/quiz.store'
import { observer } from 'mobx-react'
import quizSubmitSuccessfulImg from '../../../assets/illustrations/quiz-completed.svg'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import { Icons, isMobileDevice, latexDelimiters, showDateInIndianDateFormat, time24to12 } from '../../../utils/utilities'
import { toast } from 'react-toastify'
import api from '../../../service/api'
import { useSearchParams } from 'react-router-dom'
import warning from '../../../assets/warning.svg'
import quizImg from '../../../assets/card-images/quiz-test.png'
import { useSettingsStore } from '../../../store/settings.store'
import { useUserStore } from '../../../store/user.store'
import Latex from 'react-latex-next'

const StudentQuiz = observer( () => {

    const { isMobile, isSmallScreen } = isMobileDevice()
    const SettingsStore = useSettingsStore()

    const [loading, setLoading] = useState( true )
    const [errorCode, setErrorCode] = useState( null )
    const [scoresDetails, setScoresDetails] = useState( {} )
    const [startingQuiz, setStartingQuiz] = useState( false )
    const [viewScoresMode, setViewScoresMode] = useState( false )
    const [smallScreenError, setSmallScreenError] = useState( ( isMobile || isSmallScreen ) && SettingsStore?.getUseSettings['restrict_quiz_attempt_on_mobile_devices'] === true )

    const [searchParams] = useSearchParams()
    const quiz_id = searchParams.get( 'quiz_id' )
    const instance_id = searchParams.get( 'instance_id' )
    const navigate = useNavigate()

    const { palette, border } = useTheme()

    const QuizStore = useQuizAndQuestionbankStore()
    const UserStore = useUserStore()

    const startQuiz = async () => {
        try {
            setStartingQuiz( true )
            const { isMobile, isSmallScreen } = isMobileDevice()
            setSmallScreenError( ( isMobile || isSmallScreen ) && SettingsStore?.getUseSettings['restrict_quiz_attempt_on_mobile_devices'] === true )
            const { data: { data: { attempt_id } } } = await api.quiz.startQuiz( quiz_id, instance_id )
            navigate( `/student/quiz/${quiz_id}/${instance_id}/attempt/${attempt_id}` )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Could't start the quiz! something went wrong." )
        } finally {
            setStartingQuiz( false )
        }
    }

    const fetchScores = async () => {
        try {
            const { data: { data } } = await api.quiz.fetchAttemptScore( quiz_id, instance_id, +QuizStore?.getQuizInstance?.attempt_id )
            setScoresDetails( data )
            console.log( data )
            setViewScoresMode( true )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong whie fetching scores!" )
        }
    }

    useEffect( () => {
        const getData = async () => {
            console.log( window.location.search, quiz_id, instance_id )

            if ( Boolean( quiz_id ) && Boolean( instance_id ) && Number.isInteger( parseInt( quiz_id ) ) && Number.isInteger( parseInt( instance_id ) ) ) {
                const { success, code } = await QuizStore.fetchQuizInstanceForStudent( quiz_id, instance_id )
                if ( code === 401 )
                    UserStore.setERPSessionState( 'ended' )
                if ( !success ) {
                    setErrorCode( code )
                }
            } else {
                toast( `Invalid quiz URL` )
                navigate( `/student/home` )
            }
            setLoading( false )
        }
        getData()

    }, [QuizStore, navigate, UserStore, quiz_id, instance_id] )

    return (
        <Box padding="20px" flexGrow={1} overflow="auto">
            <Dialog PaperProps={{ elevation: 1, sx: { padding: "20px 0px", background: `url('/imgs/quiz-start-dialog.jpg')`, border: `2px solid ${palette.success.light}` } }} open={true}>
                {!loading && +QuizStore?.getQuizInstance?.attempted === 0 && !errorCode && QuizStore.getQuizInstance && <Box display="flex" flexDirection="column" alignItems="center" padding="20px 100px">
                    <img width="200px" src={quizImg} alt="QUIZ TEST" />
                    <Typography color={palette.common.black} variant="h5" textAlign="center" textTransform="capitalize" gutterBottom><strong>{QuizStore.getQuizInstance.title}</strong></Typography>
                    <Typography variant="body2" color={palette.common.black}>By {QuizStore.getQuizInstance?.creator_data?.name}</Typography>
                    <Typography color={palette.common.black} variant="subtitle2" >{showDateInIndianDateFormat( QuizStore.getQuizInstance.start_date )}, {time24to12( QuizStore.getQuizInstance.start_time )} - {time24to12( QuizStore.getQuizInstance.end_time )}</Typography>
                    {QuizStore.getQuizInstance.quiz_duration && <Typography variant="subtitle2" color={palette.common.black} >Duration: {QuizStore.getQuizInstance.quiz_duration || ""}</Typography>}
                    {QuizStore?.getQuizInstance?.description && <Typography variant="body2" color={palette.common.black} >{QuizStore?.getQuizInstance?.description}</Typography>}
                    <Typography gutterBottom marginTop="20px" color="secondary.dark" variant='h6'>
                        Topics for the quiz
                    </Typography>
                    <Box display="flex" alignItems="center" flexDirection="column" gap="10px">
                        {QuizStore?.getQuizInstance?.question_bank_topics.map( topic => (
                            <Box sx={{ bgcolor: palette.contentBg, borderRadius: "5px", padding: "10px 16px" }} key={topic.id}>
                                <Typography variant='subtitle2'>{topic.question_count} questions from {topic.name}</Typography>
                            </Box>
                        ) )}
                    </Box>
                    <Box width="100%" marginTop="20px">
                        <Box display="flex" flexDirection="column" gap="20px" flexWrap="wrap">
                            {<Button startIcon={startingQuiz && <CircularProgress size={14} />} disabled={smallScreenError || startingQuiz} fullWidth disableElevation onClick={startQuiz} sx={{ textTransform: "capitalize" }} variant='contained' >
                                {startingQuiz ? "Starting quiz..." : "Start Quiz"}
                            </Button>}
                            <Button color="errorMessage" onClick={() => navigate( '/student/home' )} sx={{ textTransform: "capitalize" }} disableElevation variant='contained' >
                                Back to home
                            </Button>
                        </Box>
                    </Box>
                    {smallScreenError && <Typography marginTop="20px" variant='subtitle2' fontSize="12px" color="errorMessage.main">Oops! seems like you are using a device with small screen or a mobile device! please use a desktop or laptop to take this quiz.</Typography>}
                </Box>}
                {!loading && errorCode === 404 && <Box maxWidth="200px" padding="20px" display="flex" alignItems="center" flexDirection="column" gap="20px">
                    <img src={warning} alt="Not found" width="70px" />
                    <Typography textAlign="center" variant='subtitle2'>The quiz you are looking for does not exists anymore!</Typography>
                    <Button sx={{ textTransform: "capitalize" }} onClick={() => navigate( '/student/home' )} startIcon={Icons.default.ArrowBack} >Back to home</Button>
                </Box>}
                {!loading && errorCode === 403 && <Box maxWidth="200px" padding="20px" display="flex" alignItems="center" flexDirection="column" gap="20px">
                    <img src={warning} alt="Not found" width="70px" />
                    <Typography textAlign="center" variant='subtitle2'>Oops! you do not have permission to access this quiz!</Typography>
                    <Button sx={{ textTransform: "capitalize" }} onClick={() => navigate( '/student/home' )} startIcon={Icons.default.ArrowBack} >Back to home</Button>
                </Box>}
                {loading && !errorCode && <DataLoadingSpinner waitingMessage="Loading quiz details..." />}
            </Dialog>
            {!loading && !errorCode && <Dialog PaperProps={{ className: 'slim-custom-scrollbar', sx: { width: "60%", maxWidth: "600px" } }} open={+QuizStore?.getQuizInstance?.attempted !== 0}>
                {!viewScoresMode && <Box bgcolor="#FBCF58" justifyContent="center" flexGrow={1} alignItems="center" display="flex" flexDirection="column" gap="20px" padding="50px">
                    <img width="70%" src={quizSubmitSuccessfulImg} alt="Submitted successfully" />
                    <Box alignItems="center" display="flex" flexDirection="column">
                        <Typography color={palette.common.black} gutterBottom variant="h5">Congratulation!</Typography>
                        <Typography color={palette.common.black} variant="body2">You have successfully completed the quiz</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap="20px" flexWrap="wrap">
                        <Button onClick={fetchScores} sx={{ paddingX: "40px", fontSize: "12px", fontWeight: "bolder", marginTop: "20px", background: 'white', borderColor: "white", '&:hover': { background: "white", borderColor: "white" } }} variant='outlined' color='primary' >View Score</Button>
                        <Button onClick={() => navigate( '/student/home' )} sx={{ paddingX: "40px", fontSize: "12px", fontWeight: "bolder", marginTop: "20px", background: 'white', borderColor: "white", '&:hover': { background: "white", borderColor: "white" } }} variant='outlined' color='primary' >Back to home</Button>
                    </Box>
                </Box>}
                {viewScoresMode && <Box bgcolor={palette.background.paper} flexGrow={1} display="flex" flexDirection="column">
                    <Box position="sticky" top="0" display="flex" alignItems="center" justifyContent="space-between" borderBottom={border[1]} padding="10px 20px" bgcolor={palette.background.paper}>
                        <Typography variant="h6">{QuizStore.getQuizInstance.title}</Typography>
                        <IconButton size="small" onClick={() => setViewScoresMode( false )}>
                            {Icons.default.CancelIcon}
                        </IconButton>
                    </Box>
                    <Box >
                        <Box bgcolor={palette.contentBg} position="sticky" top="55px" padding="10px 20px" display="flex" alignItems="center" gap="30px">
                            <Typography variant="h6">OVERALL SCORE</Typography>
                            <Typography variant="h6"> <strong>{scoresDetails?.total_score} / {scoresDetails?.max_score}</strong></Typography>
                        </Box>
                        <Box display="flex" gap="20px" alignItems="center" borderBottom={border[1]} padding="20px">
                            <Box display="flex" gap="10px" alignItems="center">
                                <Box sx={{ width: "15px", borderRadius: "50%", height: "15px", border: `2px solid ${palette.success.dark}` }}></Box>
                                <Typography variant='subtitle2' fontSize="12px" >Correct option</Typography>
                            </Box>
                            <Box display="flex" gap="10px" alignItems="center">
                                <Box sx={{ width: "15px", borderRadius: "50%", bgcolor: palette.success.dark, height: "15px", border: `2px solid ${palette.success.dark}` }}></Box>
                                <Typography variant='subtitle2' fontSize="12px" >Answered, correctly</Typography>
                            </Box>
                            <Box display="flex" gap="10px" alignItems="center">
                                <Box sx={{ width: "15px", borderRadius: "50%", bgcolor: palette.error.light, height: "15px", border: `2px solid ${palette.error.dark}` }}></Box>
                                <Typography variant='subtitle2' fontSize="12px" >Answered, but incorrect</Typography>
                            </Box>
                        </Box>

                        <Box display="flex" padding="0 20px 20px 20px" marginTop="30px" flexDirection="column" gap="20px">
                            {Object.keys( scoresDetails?.scores || {} ).map( ( quesId, index ) => {
                                const ques = scoresDetails?.scores[quesId]
                                return <Box borderBottom={border[1]} paddingBottom="20px" key={index}>
                                    {ques.given < 0 ? <Typography padding="5px 10px" marginBottom="20px" borderRadius="5px" width="fit-content" bgcolor={palette.errorMessage.light + 22} variant='subtitle2' color="errorMessage.main"><em>Not Answered</em></Typography> :
                                        <Typography padding="5px 10px" marginBottom="20px" borderRadius="5px" width="fit-content" bgcolor={palette.primaryDark.light + 22} variant='subtitle2' color="primaryDark.main"><em>Answered</em></Typography>}
                                    <Box color={palette.contrstText} className='questionbank-wysiwyg-container' fontWeight="bold" marginTop="-5px" fontSize="14px">
                                        <Latex delimiters={latexDelimiters}>{ques.question_text}</Latex>
                                    </Box>
                                    <Typography variant='body2' color="textSecondary"><em>{ques.selection_type}</em></Typography>
                                    <Box marginTop="10px" display="flex" flexDirection="column" gap="10px">
                                        {ques.options.map( ( option, optionIndex ) => {
                                            const isCorrect = ques.selection_type === "single" ? +ques.correct_answer === optionIndex : ques.options_status[optionIndex]
                                            const hasAnswered = ques.selection_type === "single" ? +ques.given === optionIndex : ques.given.includes( optionIndex )
                                            return (
                                                <Box
                                                    padding="10px"
                                                    border={border[1]}
                                                    borderRadius="5px"
                                                    bgcolor={hasAnswered ? ( hasAnswered && isCorrect ) ? "success.dark" : "error.light" : "none"}
                                                    borderColor={isCorrect ? "success.dark" : hasAnswered ? "errorMessage.dark" : palette.borderColor}
                                                    sx={{ borderWidth: isCorrect || hasAnswered ? "2px" : "1px" }}
                                                    display="flex"
                                                    marginBottom="2px"
                                                    alignItems="flex-start"
                                                    gap="10px"
                                                    key={optionIndex}
                                                >
                                                    <Typography padding="2px 2px 0px 2px" variant="subtitle2" fontSize="12px" sx={{ aspectRatio: 1 / 1 }} bgcolor={palette.greyedOut} border={`1px solid ${palette.borderColor}`} display="flex" alignItems="center" justifyContent="center" width='20px' borderRadius="50%"
                                                        fontWeight={( isCorrect || hasAnswered ) ? "bolder" : "regular"} color={isCorrect ? "success" : hasAnswered ? "error" : palette.common.font}>{optionIndex + 1}</Typography>
                                                    <Box variant="subtitle2" className='questionbank-wysiwyg-container' color={( hasAnswered ) ? "white" : palette.common.font}>
                                                        <Latex delimiters={latexDelimiters}>{option.option_text}</Latex>
                                                    </Box>
                                                </Box>
                                            )
                                        } )}
                                    </Box>
                                </Box>
                            } )}
                        </Box>
                    </Box>
                </Box>}
            </Dialog>}
        </Box>
    )
} )

export default StudentQuiz

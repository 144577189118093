import { Box, IconButton, Tooltip, Typography, Button, Menu, MenuItem, Dialog, DialogTitle, DialogContent, TextField, useTheme, Drawer, CircularProgress } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DownloadIcon from '@mui/icons-material/Download'
import InfoIcon from '@mui/icons-material/Info'
import { Icons, attachmentThumbnails, convertToCDNLink, isIOS } from '../../utils/utilities'
import { useState } from 'react'
import noAttachmentImg from '../../assets/no-attachments.svg'
import DeleteIcon from '@mui/icons-material/Delete'
import { useUserStore } from '../../store/user.store'
import { Edit, OpenInNewOutlined } from '@mui/icons-material'
import ConfirmDialog from '../common/ConfirmDialog'
import { useDownloadStatusListStore } from '../../store/downloadstatuslistdialog.store'
import DetailsViewer from './DetailsViewer'
import api from '../../service/api'
import DocumentViewer from '../common/DocumentViewer'
import { toast } from 'react-toastify'


const AttachmentBox = ( { setPreviewingFileDetails, setPreviewFile, material, index, markedAttachments, setMarkedAttachments, setAttachmentsSelectedForDetails, attachment, deleteAttachment, renameAttachment } ) => {

    const [renameValue, setRenameValue] = useState( "" )
    const [deleting, setDeleting] = useState( false )
    const [renameDialogState, setRenameDialogState] = useState( false )
    const [selectedAttachment, setSelectedAttachment] = useState( null )
    const [confirmDialogStatus, setConfirmDialogStatus] = useState( false )
    const [anchorEl, setAnchorEl] = useState( null )

    const marked = markedAttachments.find( att => att.id === attachment.study_material_attachment_id )


    const { palette, border } = useTheme()

    const UserStore = useUserStore()

    const DownloadStatusListStore = useDownloadStatusListStore()


    const handleOptionsClick = ( e, index ) => {
        setAnchorEl( e.currentTarget )
    }

    const toggleMarked = () => {
        if ( marked ) {
            let index
            for ( let i = 0; i < markedAttachments.length; i++ ) {
                const att = markedAttachments[i]
                if ( att.id === attachment.study_material_attachment_id ) {
                    index = i
                    break
                }
            }
            const newMarkedList = [...markedAttachments]
            newMarkedList.splice( index, 1 )
            setMarkedAttachments( newMarkedList )
        } else {
            const newMarkedList = [...markedAttachments, { id: attachment.study_material_attachment_id, url: attachment.study_material_attachment_url, name: attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name, ext: attachment.study_material_attachment_name.split( "." ).pop(), ...attachment }]
            setMarkedAttachments( newMarkedList )
        }
    }

    const viewAttachment = async () => {
        try {
            const { data: { data: url } } = await api.downloadAttachment( attachment.study_material_attachment_id, { url: attachment.study_material_attachment_url } )
            setPreviewFile( convertToCDNLink( url ) )
            setPreviewingFileDetails( attachment )
            setAnchorEl( null )
        } catch ( err ) {
            toast( "Couldn't preview the file" )
        }
    }


    return <Box onDoubleClick={() => { setAttachmentsSelectedForDetails( attachment ) }} title={attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name} key={attachment.study_material_attachment_id} borderRadius="5px" overflow="hidden" border={`2px solid ${marked ? palette.primary.dark : palette.borderColor}`} sx={{ userSelect: "none" }} height="250px" width="210px" display="flex" flexDirection="column">


        <ConfirmDialog
            status={confirmDialogStatus}
            confirmAction={async () => {
                setDeleting( true )
                await deleteAttachment( attachment.study_material_attachment_id )
                setDeleting( false )
                setConfirmDialogStatus( false )
            }}
            cancelAction={() => setConfirmDialogStatus( false )}
            waiting={deleting}
            message={
                <Typography variant="subtitle2">
                    Do you really want to delete <em>{attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name}</em>?
                </Typography>}
            actionName="Delete"
        />
        <Dialog PaperProps={{ sx: { width: { md: "60%", xs: "85%" }, background: palette.form.formCardBg } }} open={renameDialogState}>
            <DialogTitle>Rename Attachment</DialogTitle>
            <DialogContent sx={{ padding: "20px" }}>
                <Box sx={{ background: palette.form.formBg, borderRadius: "5px", padding: "20px", border: border[1] }}>
                    <TextField size="small" sx={{ marginTop: "2px" }} value={renameValue} onChange={( e ) => setRenameValue( e.target.value )} variant='outlined' fullWidth />
                    <Box marginTop="20px" display="flex" gap="20px" flexWrap="wrap">
                        <Button color="primary" disableElevation variant='contained' onClick={async () => { await renameAttachment( selectedAttachment, renameValue ); setRenameValue( "" ); setRenameDialogState( false ) }} sx={{ textTransform: "capitalize" }}>Update</Button>
                        <Button color="error" variant='contained' disableElevation onClick={() => { setRenameValue( "" ); setRenameDialogState( false ) }} sx={{ textTransform: "capitalize" }}>Cancel</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>


        <Box onClick={toggleMarked} bgcolor={palette.greyedOut} height="230px" display="flex" alignItems="center" justifyContent="center">
            <img style={{ objectFit: "cover" }} width="120px" src={attachmentThumbnails[attachment.study_material_attachment_type] ? attachmentThumbnails[attachment.study_material_attachment_type] : attachmentThumbnails["default"]} alt={attachment.study_material_attachment_type} />
        </Box>
        <Box display="flex" borderTop={border[1]} padding="10px 20px" alignItems="center" justifyContent="space-between">
            <Box onClick={toggleMarked} maxWidth="calc(100% - 20px)">
                <Typography fontWeight="600" width="150px" noWrap>{attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name}</Typography>
                <Typography fontSize="12px">{new Date( attachment.study_material_attachment_published_date ).toLocaleDateString( 'en-IN', { month: "2-digit", day: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" } )}</Typography>
            </Box>
            <Tooltip title="Options">
                <IconButton onClick={( e ) => handleOptionsClick( e, index )}>
                    <MoreVertIcon />
                </IconButton>
            </Tooltip>
            <Menu anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "center", horizontal: "right" }} anchorEl={anchorEl} open={Boolean( anchorEl )} onClose={() => { setAnchorEl( null ) }} >
                {( attachment?.study_material_attachment_type === 'pdf' || !isIOS() ) && < MenuItem sx={{ padding: "0", width: "200px" }}>
                    <Button size='small' onClick={viewAttachment} startIcon={<OpenInNewOutlined />} color='errorMessage' sx={{ textTransform: "capitalize", width: "100%", margin: 0, padding: "10px 20px", justifyContent: "flex-start" }}>View</Button>
                </MenuItem>}
                <MenuItem sx={{ padding: "0", width: "200px" }}>
                    <Button size='small' color='primaryDark' onClick={( e ) => { DownloadStatusListStore.downloadAttachment( attachment.study_material_attachment_id, attachment.study_material_attachment_url, attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name, attachment.study_material_attachment_name.split( "." ).pop() );; setAnchorEl( null ) }} startIcon={<DownloadIcon />} sx={{ textTransform: "capitalize", width: "100%", margin: 0, padding: "10px 20px", justifyContent: "flex-start" }}>Download</Button>
                </MenuItem>
                <MenuItem sx={{ padding: "0", width: "200px" }}>
                    <Button size='small' onClick={() => { setAttachmentsSelectedForDetails( attachment ); setAnchorEl( null ) }} startIcon={<InfoIcon />} color='secondary' sx={{ textTransform: "capitalize", width: "100%", margin: 0, padding: "10px 20px", justifyContent: "flex-start" }}>View Details</Button>
                </MenuItem>
                {UserStore.getUser.user_id === material.empcode && < MenuItem sx={{ padding: "0", width: "200px" }}>
                    <Button size='small' onClick={e => { setSelectedAttachment( attachment.study_material_attachment_id ); setRenameValue( attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name ); setRenameDialogState( true ); setAnchorEl( null ) }} startIcon={<Edit />} color='primary' sx={{ textTransform: "capitalize", width: "100%", margin: 0, padding: "10px 20px", justifyContent: "flex-start" }}>Rename</Button>
                </MenuItem>}
                {UserStore.getUser.user_id === material.empcode && < MenuItem sx={{ padding: "0", width: "200px" }}>
                    <Button size='small' onClick={() => { setConfirmDialogStatus( true ); setAnchorEl( null ) }} startIcon={<DeleteIcon />} color='error' sx={{ textTransform: "capitalize", width: "100%", margin: 0, padding: "10px 20px", justifyContent: "flex-start" }}>Delete</Button>
                </MenuItem>}
            </Menu>
        </Box>
    </Box>
}

const GridView = ( { setAddMode, attachments, allAttachments, renameAttachment, material, removefilter, deleteAttachment } ) => {

    const [attachmentsSelectedForDetails, setAttachmentsSelectedForDetails] = useState( null )
    const [confirmDialogStatus, setConfirmDialogStatus] = useState( false )
    const [markedAttachments, setMarkedAttachments] = useState( [] )
    const [previewingFileDetails, setPreviewingFileDetails] = useState( null )
    const DownloadStatusListStore = useDownloadStatusListStore()
    const [previewFile, setPreviewFile] = useState( null )
    const [deleting, setDeleting] = useState( false )

    const UserStore = useUserStore()


    const { palette, border } = useTheme()

    const downloadMultipleAttachments = () => {
        DownloadStatusListStore.downloadMultipleAttachments( markedAttachments )
    }

    const deleteMultipleAttachments = async () => {
        setDeleting( true )
        for ( const att of markedAttachments ) {
            await deleteAttachment( att.id, att.name )
        }
        setMarkedAttachments( [] )
        setDeleting( false )
        setConfirmDialogStatus( false )
    }

    const viewSelectedAttachment = async () => {
        try {
            const { data: { data: url } } = await api.downloadAttachment( markedAttachments[0].id, { url: markedAttachments[0].url } )
            setPreviewFile( convertToCDNLink( url ) )
            setPreviewingFileDetails( markedAttachments[0] )
        } catch ( err ) {
            toast( err?.response?.data?.message || "Couldn't preview the file" )
        }
    }

    const downloadViewingAttachment = async () => {
        setDeleting( true )
        await DownloadStatusListStore.downloadAttachment( previewingFileDetails.study_material_attachment_id, previewingFileDetails.study_material_attachment_url, previewingFileDetails.study_material_attachment_title ? previewingFileDetails.study_material_attachment_title : previewingFileDetails.study_material_attachment_name, previewingFileDetails.study_material_attachment_name.split( "." ).pop() )
        setDeleting( false )
    }

    return (
        <Box>

            <Drawer anchor='bottom' PaperProps={{ sx: { height: "calc(100% - 50px)" } }} sx={{ zIndex: 9000000 }} open={Boolean( previewFile )} >
                <Box bgcolor={palette.cardBackground} flexGrow={1} display="flex" overflow="auto" flexDirection="column" borderRadius="20px 20px 0 0">
                    <Box borderBottom={border[1]} alignItems="center" display="flex" justifyContent="space-between" gap="20px" padding="10px 20px">
                        <Typography variant='h6'>{previewingFileDetails?.study_material_attachment_name}</Typography>
                        <Box display="flex" gap="10px" alignItems="center">
                            <IconButton onClick={downloadViewingAttachment}>
                                {deleting ? <CircularProgress size={14} /> : Icons.default.DownloadIcon}
                            </IconButton>
                            <IconButton onClick={() => { setPreviewFile( null ); setPreviewingFileDetails( null ) }}>
                                {Icons.default.CloseIcon}
                            </IconButton>
                        </Box>
                    </Box>
                    <Box padding="20px" display="flex" flexDirection="column" overflow="auto" flexGrow={1} height="300px">
                        <DocumentViewer loadingText="Loading material..." setFile={setPreviewFile} file={previewFile} type={previewingFileDetails?.type || previewingFileDetails?.study_material_attachment_name?.split( "." ).pop()} />
                    </Box>
                </Box>
            </Drawer>

            <ConfirmDialog
                status={confirmDialogStatus}
                confirmAction={deleteMultipleAttachments}
                cancelAction={() => setConfirmDialogStatus( false )}
                waiting={deleting}
                message={
                    <Box>
                        Do you really want to delete these attachments:
                        <ul>{markedAttachments.map( att => (
                            <li key={att.id}> <b>{att.name}</b> </li>
                        ) )}
                        </ul>
                    </Box>}
                actionName="Delete"
            />
            {attachmentsSelectedForDetails && <DetailsViewer attachment={attachmentsSelectedForDetails} setShowDetails={setAttachmentsSelectedForDetails} open={Boolean( attachmentsSelectedForDetails )} />}

            {markedAttachments.length > 0 && <Box bgcolor={palette.greyedOut} marginBottom="20px" padding="5px 10px" display="flex" gap="20px" alignItems="center" borderRadius="25px" border={border[1]}>
                <Box display="flex" gap="10px" alignItems="center" >
                    <IconButton size="small" onClick={() => setMarkedAttachments( [] )}>
                        {Icons.small.CloseIcon}
                    </IconButton>
                    <Typography variant='subtitle2' color="textSecondary" fontSize="16px" display="inline-block">{markedAttachments.length} selected</Typography>
                </Box>
                <Box display="flex" gap="10px" alignItems="center" >
                    <IconButton size="small" onClick={() => downloadMultipleAttachments()}>
                        {Icons.small.DownloadIcon}
                    </IconButton>
                    {UserStore.getUser.user_role === "FACULTY" && <IconButton size="small" onClick={() => setConfirmDialogStatus( true )}>
                        {Icons.small.DeleteIcon}
                    </IconButton>}
                    {markedAttachments.length === 1 && ( markedAttachments[0]?.study_material_attachment_type === 'pdf' || !isIOS() ) && <IconButton size="small" onClick={viewSelectedAttachment}>
                        {Icons.small.VisibilityIcon}
                    </IconButton>}
                    {markedAttachments.length === 1 && <IconButton size="small" onClick={() => setAttachmentsSelectedForDetails( markedAttachments[0] )}>
                        {Icons.small.InfoIcon}
                    </IconButton>}
                </Box>
            </Box>}


            {attachments && attachments.length > 0 && <Box marginTop="10px" display="flex" justifyContent={{ md: "initial", xs: "center" }} flexWrap="wrap" gap="20px">
                {attachments.map( ( attachment, index ) => (
                    <AttachmentBox setPreviewingFileDetails={setPreviewingFileDetails} setPreviewFile={setPreviewFile} material={material} deleteAttachment={deleteAttachment} setMarkedAttachments={setMarkedAttachments} markedAttachments={markedAttachments} renameAttachment={renameAttachment} setAttachmentsSelectedForDetails={setAttachmentsSelectedForDetails} key={index} index={index} attachment={attachment} />
                ) )}
            </Box>
            }
            {
                ( !attachments || attachments.length === 0 ) && <Box textAlign="center" display="flex" flexDirection="column" gap="20px" padding="20px" alignItems="center" justifyContent="center" width="100%">
                    <img width="160px" src={noAttachmentImg} alt="No attachments" />
                    <Typography variant='subtitle2' fontWeight="bold" color="textSecondary" >No attachments!</Typography>
                    <Box display="flex" gap="10px" justifyContent="center" alignItems="center">
                        {UserStore.getUser.user_role !== 'STUDENT' && <Button variant='contained' color='secondary' disableElevation onClick={() => { setAddMode( mode => !mode ) }} sx={{ textTransform: "capitalize", marginTop: "10px" }}>Add Attachment</Button>}
                        {allAttachments.length > 0 && <Button variant='contained' color='errorMessage' disableElevation onClick={removefilter} sx={{ textTransform: "capitalize", marginTop: "10px" }}>Remove filter</Button>}
                    </Box>
                </Box>
            }
        </Box >
    )
}

export default GridView

import { AttachmentOutlined, CheckCircleOutlineOutlined, DownloadOutlined, OpenInNew, PendingOutlined, Sync } from '@mui/icons-material'
import { Box, Button, CircularProgress, Drawer, IconButton, Input, Tooltip, Typography, useTheme } from '@mui/material'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { useUserStore } from '../../../store/user.store'
import { Icons } from '../../../utils/utilities'
import fileDownload from 'js-file-download'
import { TreeItem } from '@mui/x-tree-view'
import React, { useRef, useState } from 'react'
import api from '../../../service/api'
import { toast } from 'react-toastify'
import DocumentViewer from '../../common/DocumentViewer'

const CheckListItem = ( { checklist } ) => {

    const [previewingFileDetails, setPreviewingFileDetails] = useState( null )
    const [downloading, setDownloading] = useState( false )
    const [previewFile, setPreviewFile] = useState( null )
    const [uploading, setUploading] = useState( false )
    const [viewing, setViewing] = useState( false )

    const UserStore = useUserStore()

    const documentInputRef = useRef()

    const { palette, border } = useTheme()

    const downloadAttachment = async ( key ) => {
        if ( !downloading && key ) {
            try {
                setDownloading( true )
                const { data } = await api.downloadAcademicsStudentDataAttachment( key )
                fileDownload( data, key?.split( "/" )?.pop() )
            } catch ( err ) {
                console.log( err )
                const reader = new FileReader()
                reader.onload = function ( event ) {
                    const textDecoder = new TextDecoder()
                    const decodedText =
                        textDecoder.decode( event.target.result )
                    const jsonData = JSON.parse( decodedText )
                    toast( jsonData?.message || "Something went wrong while downloading the attachment!" )
                }
                reader.readAsArrayBuffer( err?.response?.data )
            } finally {
                setDownloading( false )
            }
        }
    }


    const viewAttachment = async ( key ) => {
        if ( !downloading && key ) {
            try {
                setViewing( true )
                const regex = /CID\d+_(?<name>[a-zA-Z0-9%_]+\.(?<type>[a-z]+))/
                const details = key.match( regex ).groups
                const { data } = await api.downloadAcademicsStudentDataAttachment( key )
                const url = URL.createObjectURL( data )
                setPreviewFile( url )
                setPreviewingFileDetails( details )
            } catch ( err ) {
                console.log( err )
                const reader = new FileReader()
                reader.onload = function ( event ) {
                    const textDecoder = new TextDecoder()
                    const decodedText =
                        textDecoder.decode( event.target.result )
                    const jsonData = JSON.parse( decodedText )
                    toast( jsonData?.message || "Something went wrong while downloading the attachment!" )
                }
                reader.readAsArrayBuffer( err?.response?.data )
            } finally {
                setViewing( false )
            }
        }
    }

    const uploadDocument = async ( e ) => {
        try {
            setUploading( true )
            if ( e.target.files && e.target.files[0] ) {
                const formData = new FormData()
                const fileName = `${checklist.input_label?.replace( /[\s]/g, "_" )}.${e.target.files[0].name?.split( "." ).pop()}`
                formData.append( 'rule_id', checklist.rule_id )
                formData.append( 'checklist_id', checklist.checklist_id )
                formData.append( 'student_id', UserStore.getUser.user_id )
                formData.append( 'attachment', e.target.files[0], fileName )
                const { data } = await api.uploadAcademicsDocument( formData )
                checklist.value = data.value
            }
        } catch ( err ) {
            toast( err?.response?.data?.message || "Something went wrong while uploading the document!" )
        } finally {
            e.target.value = ''
            setUploading( false )
        }
    }

    return ( <TreeItem key={checklist.checklist_id} itemId={"semchecklist" + checklist.checklist_id + ""} label={
        <Box padding="10px 0" borderRadius="5px" key={checklist.checklist_id}>
            {previewFile && <Drawer anchor='bottom' PaperProps={{ sx: { height: "calc(100% - 50px)" } }} sx={{ zIndex: 9000000 }} open={Boolean( previewFile )} >
                <Box bgcolor={palette.cardBackground} flexGrow={1} display="flex" overflow="auto" flexDirection="column" borderRadius="20px 20px 0 0">
                    <Box borderBottom={border[1]} alignItems="center" display="flex" justifyContent="space-between" gap="20px" padding="10px 20px">
                        <Typography variant='h6'>{previewingFileDetails?.name}</Typography>
                        <Box display="flex" gap="10px" alignItems="center">
                            <IconButton onClick={() => { setPreviewFile( null ); setPreviewingFileDetails( null ) }}>
                                {Icons.default.CloseIcon}
                            </IconButton>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" overflow="auto" flexGrow={1} padding="20px" height="300px">
                        <DocumentViewer loadingText="Loading material..." setFile={setPreviewFile} type={previewingFileDetails?.type || previewingFileDetails?.name?.split( "." ).pop()} file={previewFile} />
                    </Box>
                </Box>
            </Drawer>}
            <Box display="flex" gap="10px" alignItems="center">
                <Tooltip placement='top' title={checklist.is_completed ? "Completed" : "Pending"}>
                    {checklist.is_completed ? <CheckCircleOutlineOutlined color="success" fontSize='small' /> : <PendingOutlined color="customThemeColor" fontSize='small' />}
                </Tooltip>
                <Typography flexGrow={1} variant="subtitle2">{checklist.input_label}</Typography>
                {UserStore.getUser.user_role === "STUDENT" && checklist.has_attachment && checklist.value?.attachment && <Box display="flex" gap="5px" alignItems="center">
                    <Typography variant='subtitle2' color="success.main" fontSize="10px">DOCUMENT SUBMITTED</Typography>
                    <Tooltip title="replace">
                        <IconButton onClick={() => documentInputRef?.current?.click()} color='secondary' size='small'>
                            {uploading ? <CircularProgress size={14} /> : <Sync fontSize='small' />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="download"><IconButton onClick={() => downloadAttachment( checklist.value.attachment )} color='secondary' size='small'>
                        {downloading ? <CircularProgress size={14} /> : <DownloadOutlined fontSize='small' />}
                    </IconButton></Tooltip>
                </Box>}
                {UserStore.getUser.user_role === "STUDENT" && checklist.has_attachment && <Input onChange={uploadDocument} inputRef={documentInputRef} sx={{ width: 0, height: 0 }} type='file' />}
                {UserStore.getUser.user_role === "STUDENT" && checklist.has_attachment && !checklist.value?.attachment && <Button color='secondary' disabled={uploading} sx={{ textTransform: "capitalize" }} size="small" onClick={() => documentInputRef?.current?.click()} startIcon={uploading ? <CircularProgress size={14} /> : Icons.default.UploadFileIcon}>{uploading ? "Uploading..." : "Upload file"}</Button>}
            </Box>
            {UserStore.getUser.user_role !== "STUDENT" && checklist.value && <Box borderTop="1px dotted #d3d3d3" marginTop="5px">
                {checklist.value?.input && <Typography variant="subtitle1">
                    {checklist.value.input === true && <span> <b>Completed: </b> <i>True</i> </span>}
                    {checklist.value.input}
                </Typography>}
                {checklist.value?.textInput && <Typography variant='body2'>{checklist.value.textInput}</Typography>}
                {checklist.value?.attachment && <Box borderRadius="5px" padding="5px 10px" gap="10px" border="1px solid #aaa" width="fit-content" display="flex" alignItems="center" marginTop="5px">
                    <AttachmentOutlined sx={{ fontSize: "18px" }} />
                    <Typography maxWidth="150px" noWrap variant="subtitle2">{checklist.value.attachment.split( "/" ).pop()}</Typography>
                    <Box display="flex" flexDirection="column" justifyContent="center" sx={{ cursor: "pointer" }} onClick={() => downloadAttachment( checklist.value.attachment )} >
                        <Tooltip title="Download">
                            {downloading ? <CircularProgress size={14} /> : <DownloadOutlined sx={{ fontSize: "18px", color: "secondary.main" }} />}
                        </Tooltip>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" sx={{ cursor: "pointer" }} onClick={() => viewAttachment( checklist.value.attachment )} >
                        <Tooltip title="View">
                            {viewing ? <CircularProgress size={14} /> : <OpenInNew sx={{ fontSize: "18px", color: "secondary.main" }} />}
                        </Tooltip>
                    </Box>
                </Box>}
            </Box>}
        </Box>
    } >

    </TreeItem> )
}

const YearChecklist = ( { year, yearNumber } ) => {

    const { palette } = useTheme()

    return (
        <Box borderRadius="5px" bgcolor={palette.contentBg} border="1px solid #d3d3d3">
            <SimpleTreeView>
                <TreeItem itemId='grid' label={
                    <Box display="flex" alignItems="center" gap="20px" padding="10px 0">
                        <Typography variant='subtitle2'>Year {yearNumber}</Typography>
                        <Box display="flex" gap="10px" alignItems="center">
                            {year.totalRequired > 0 && <Typography variant='subtitle2' color="primary"> {Math.floor( ( ( year.totalCompleted * 100 ) / year.totalRequired ) )}% Completed ({year.totalCompleted}/{year.totalRequired})</Typography>}
                        </Box>
                    </Box>
                }>
                    {year?.checklist?.map( ( checklist ) => {

                        return (
                            <CheckListItem checklist={checklist} key={checklist.checklist_id} />
                        )
                    } )}
                    {year?.semester?.map( ( sem, semNumber ) => (
                        <TreeItem key={semNumber} itemId={"sem" + semNumber} label={
                            <Typography padding="10px 0" color="secondary" variant="subtitle2">Semester {semNumber}</Typography>
                        }>
                            {sem?.map( ( checklist ) => {
                                return (
                                    <CheckListItem key={checklist.checklist_id} checklist={checklist} />
                                )
                            } )}
                        </TreeItem>
                    ) )}
                </TreeItem>

            </SimpleTreeView>
        </Box>
    )
}

export default YearChecklist

import React from 'react'
import { observable, action, toJS, computed, makeObservable, runInAction } from 'mobx'
import api from '../service/api'
import AuthStore from './auth.store'
import NotificationStore from './notification.store'

class UserStore {

    user // Logged In Json Info
    userAccounts
    userDebug
    errorsStatuses

    constructor () {
        this.user = { "user_name": "", "user_id": "", "user_email": "", "user_org_code": "", "user_dept_code": "", "user_role": "", "user_auditor": false }
        this.api = api
        this.userAccounts = []
        this.userDebug = {}
        this.userSubjects = []
        this.userBatchesAndSection = { batches: [], sections: [] }
        this.usersAllBatchesAndSectionList = []
        this.userAcademicYears = []
        this.errorsStatuses = {}

        makeObservable( this, {
            user: observable,
            userAccounts: observable,
            userDebug: observable,
            userSubjects: observable,
            userAcademicYears: observable,
            errorsStatuses: observable,
            userBatchesAndSection: observable,
            getUser: computed,
            getUserSubjects: computed,
            getUserBatchesAndSection: computed,
            getUsersAllBatchesAndSectionList: computed,
            getUserAcademicYears: computed,
            getUserDebug: computed,
            setUser: action,
            setUserDebug: action,
            setERPSessionState: action,
            fetchUserAcademicYears: action,
            fetchUserBatchesAndSection: action,
            fetchUsersAllBatchesAndSectionList: action,
            getUserAccounts: computed,
            getErrorsStatuses: computed,
            setUserAccounts: action,
            fetchUser: action,
            fetchUsers: action,
            capitalizeWords: action,
            fetchUserSubjects: action,
            setUserProfileImg: action
        } )
    }
    capitalizeWords = ( str ) => {
        return str.replace( /\w\S*/g, function ( txt ) { return txt.charAt( 0 ).toUpperCase() + txt.substring( 1 ).toLowerCase() } )
    }
    fetchUser = async () => {
        //console.log(AuthStore.getToken)
        if ( AuthStore.getToken === null || AuthStore.isTokenExpired() ) {
            return { unauth: true, message: "Session does not exists!" }
        } else {
            try {
                const res = await this.api.getUser()
                if ( res["data"] && res["data"]["status"] ) {
                    if ( res["data"]["data"]["user_source"] === "erp" && res["data"]["data"]["session_data"] &&
                        (
                            res["data"]["data"]["session_data"]["lms_role"] === "FACULTY"
                            || res["data"]["data"]["session_data"]["lms_role"] === "HOD"
                            || res["data"]["data"]["session_data"]["lms_role"] === "PRINCIPAL"
                        ) ) {
                        let erp_data = {
                            "user_profile": res["data"]["data"]["session_data"]['user_profile_image_url'],
                            "user_name": this.capitalizeWords( res["data"]["data"]["session_data"]["username"] ),
                            "employee_name": this.capitalizeWords( res["data"]["data"]["session_data"]["employee_name"] ),
                            "user_id": res["data"]["data"]["session_data"]["user_id"],
                            "user_email": "",
                            "user_org_code": res["data"]["data"]["session_data"]["institute_name_short"], //institute_name_short
                            "user_dept_code": res["data"]["data"]["session_data"]["branch_name"],
                            "user_dept_id": res["data"]["data"]["session_data"]["branch_id"],
                            "user_role": res["data"]["data"]["user_role"],
                            "user_status": "active",
                            "user_auditor": res["data"]["data"]["session_data"]["is_auditor"]
                        }
                        this.setUser( res["data"]["data"] ? erp_data : { "user_name": "", "user_email": "", "user_org_code": "", "user_dept_code": "" } )
                    } else if ( res["data"]["data"]["user_source"] === "erp" && res["data"]["data"]["session_data"] && res["data"]["data"]["session_data"]["lms_role"] === "STUDENT" ) {
                        let erp_data = {
                            "user_profile": res["data"]["data"]["session_data"]['user_profile_image_url'],
                            "branch": res["data"]["data"]["session_data"]["course_branch_short_name"],
                            "user_name": this.capitalizeWords( res["data"]["data"]["session_data"]["student_name"] ),
                            "user_id": res["data"]["data"]["session_data"]["auid"],
                            "current_sem": +res["data"]["data"]["session_data"]["current_sem"],
                            "current_year": +res["data"]["data"]["session_data"]["current_year"],
                            "user_email": "",
                            "user_auid": res["data"]["data"]["session_data"]["auid"] ? res["data"]["data"]["session_data"]["auid"] : '',
                            "user_org_code": res["data"]["data"]["session_data"]["institute_name_short"], //institute_name_short
                            "user_dept_code": res["data"]["data"]["session_data"]["branch_name"],
                            "user_dept_code_short": res["data"]["data"]["session_data"]["branch_code"],
                            "user_role": res["data"]["data"]["user_role"],
                            "user_eligible_status": res["data"]["data"]["session_data"]["eligible_status"],
                            "user_due_status": res["data"]["data"]["session_data"]["due_status"],
                            "user_status": "active"
                        }
                        this.setUser( res["data"]["data"] ? erp_data : { "user_name": "", "user_email": "", "user_org_code": "", "user_dept_code": "" } )
                    } else if ( res["data"]["data"]["user_source"] === "standalone" ) {
                        this.setUser( res["data"]["data"] ? res["data"]["data"] : { "user_name": "", "user_email": "", "user_org_code": "", "user_dept_code": "" } )
                    }
                }
                return { success: true, message: "User fetched successfully!" }
            } catch ( err ) {
                console.log( err )
                if ( err.response )
                    return { netError: false, success: false, message: err?.message }
                return { netError: true, success: false, message: "User fetched successfully!" }

            }
        }

    }

    setERPSessionState = ( state ) => {
        AuthStore.setERPSessionState( state )
    }

    async setUserProfileImg( payload ) {
        try {
            const { data: { data } } = await api.updateUserProfileImage( payload )
            runInAction( () => {
                this.user.user_profile = data
            } )
            return true
        } catch ( err ) {
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while updating profile image!" )
            return false
        }
    }

    fetchUserSubjects = async ( refresh = false ) => {
        try {
            const subjects = await this.api.getUserSubjects( { refresh } )
            if ( subjects.data.success ) {
                if ( subjects.data.userRole === "STUDENT" )
                    runInAction( () => {
                        this.userSubjects = subjects.data.data
                    } )
                else {
                    let uniqueSubs = [], uniqueIds = {}
                    for ( let i = subjects.data.data.length - 1; i >= 0; i-- ) {
                        const sub = subjects.data.data[i]
                        if ( !uniqueIds[sub.subject_id] ) {
                            uniqueIds[sub.subject_id] = true
                            uniqueSubs.push( sub )
                        }
                    }
                    runInAction( () => {
                        this.userSubjects = uniqueSubs
                    } )
                }
                runInAction( () => {
                    this.errorsStatuses.subjects = false
                } )
                return true
            } else {
                runInAction( () => {
                    this.userSubjects = this.user?.user_role === "STUDENT" ? {} : []
                    this.errorsStatuses.subjects = true
                } )
                return false
            }
        } catch ( err ) {
            runInAction( () => {
                this.userSubjects = this.user?.user_role === "STUDENT" ? {} : []
                this.errorsStatuses.subjects = true
            } )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth || err?.response?.data?.unauth ) {
                this.setERPSessionState( 'ended' )
            }
            console.log( err )
            return false
        }
    }

    fetchUserAcademicYears = async ( refresh = false ) => {
        try {
            const { data } = await this.api.getAllBatchAcademicYear( { refresh } )
            if ( data.success ) {
                runInAction( () => {
                    this.userAcademicYears = data.data
                    this.errorsStatuses.academicYears = false
                } )
                return true
            } else {
                runInAction( () => {
                    this.userAcademicYears = []
                    this.errorsStatuses.academicYears = true
                } )
                return false
            }
        } catch ( err ) {
            runInAction( () => {
                this.errorsStatuses.academicYears = true
            } )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth || err?.response?.data?.unauth ) {
                this.setERPSessionState( 'ended' )
            }
            console.log( err )
        }
    }

    fetchUserBatchesAndSection = async ( id, refresh = false ) => {
        try {
            const { data } = await this.api.getAllBatch( { ac_year_id: id, refresh } )
            if ( data.success ) {
                runInAction( () => {
                    this.userBatchesAndSection = data.data
                    this.errorsStatuses.academicYearBatch = false
                } )
                return true
            } else {
                runInAction( () => {
                    this.userBatchesAndSection = { batches: [], sections: [] }
                    this.errorsStatuses.academicYearBatch = true
                } )
                return false
            }
        } catch ( err ) {
            runInAction( () => {
                this.errorsStatuses.academicYearBatch = true
            } )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth || err?.response?.data?.unauth ) {
                this.setERPSessionState( 'ended' )
            }
            console.log( err )
        }
    }

    fetchUsersAllBatchesAndSectionList = async ( refresh = false ) => {
        try {
            const { data } = await this.api.getAllBatchAcademicYear( { refresh } )
            let batchesAndSectionslist = []
            for ( const acYear of data.data ) {
                const { data } = await this.api.getAllBatch( { ac_year_id: acYear.ac_year_id, refresh } )
                batchesAndSectionslist.push(
                    ...( data.data.batches || [] ).map( i => {
                        i.ac_year = acYear.ac_year
                        i.ac_year_id = acYear.ac_year_id
                        return i
                    } ),
                    ...( data.data.sections || [] ).map( i => {
                        i.ac_year = acYear.ac_year
                        i.ac_year_id = acYear.ac_year_id
                        return i
                    } ) )
            }
            runInAction( () => {
                this.usersAllBatchesAndSectionList = batchesAndSectionslist
                this.errorsStatuses.academicYearsAndBatches = false
            } )
        } catch ( err ) {
            runInAction( () => {
                this.errorsStatuses.academicYearsAndBatches = true
            } )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth || err?.response?.data?.unauth ) {
                this.setERPSessionState( 'ended' )
            }
            console.log( err )
        }
    }

    fetchUsers = async () => {
        ///console.log(AuthStore.isTokenExpired())
        if ( AuthStore.getToken === null || AuthStore.isTokenExpired() ) {
            console.log( "Session Do not exist!" )
        } else {
            try {
                const res = await this.api.getUsers()
                //console.log(res)
                this.setUserAccounts( res["data"]["data"] )
            } catch ( err ) {
                console.log( err )
            }
        }

    }

    // get compute_func()
    get getUser() {
        return toJS( this.user )
    }

    get getUserAcademicYears() {
        return toJS( this.userAcademicYears )
    }

    get getUserBatchesAndSection() {
        return toJS( this.userBatchesAndSection )
    }

    get getUsersAllBatchesAndSectionList() {
        return toJS( this.usersAllBatchesAndSectionList )
    }

    setUser( user ) {
        this.user = user
    }

    get getUserAccounts() {
        return toJS( this.userAccounts )
    }

    get getUserSubjects() {
        return toJS( this.userSubjects )
    }

    get getErrorsStatuses() {
        return toJS( this.errorsStatuses )
    }

    setUserAccounts( userAccounts ) {
        this.userAccounts = userAccounts
    }

    createUserAccount = async ( payload ) => {
        try {
            const res = await this.api.createUser( payload )
            console.log( res )
        } catch ( error ) {

        }
    }

    updateUserStatus = async ( payload ) => {

        await this.api.updateUserStatus( payload )
        this.fetchUsers()

    }

    updatePassword = async ( payload ) => {
        try {
            const res = await this.api.updatePassword( payload )
            console.log( res["data"]["message"] )
        } catch ( err ) {

        }
    }

    get getUserDebug() {
        return toJS( this.userDebug )
    }

    setUserDebug = ( data ) => {
        this.userDebug = data
    }

    userDebugTimetable = async ( payload ) => {
        return await this.api.findUserDebugTimetable( payload )
        // .then((res)=>{
        //     console.log(`User Debug : `, res["data"])
        // }).catch((err)=>{

        // })
    }

}

// decorate(UserStore, {
//     user:observable,
//     userAccounts:observable,
//     userDebug:observable,
//     getUser:computed,
//     getUserDebug:computed,
//     setUser:action,
//     setUserDebug:action,
//     getUserAccounts:computed,
//     setUserAccounts:action,
//     fetchUser:action,
//     fetchUsers:action,
//     capitalizeWords:action
// })


const UserStoreInstance = new UserStore()

export default UserStoreInstance

const UserStoreContext = React.createContext()

export const UserStoreProvider = ( { children, store } ) => {
    return (
        <UserStoreContext.Provider value={store}>{children}</UserStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useUserStore = () => React.useContext( UserStoreContext )
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    IconButton,
    Popover,
    TextField,
    Typography,
    useTheme,
} from '@mui/material'
import {
    ArrowDropDown,
    CancelOutlined,
    CheckBox,
    CheckBoxOutlineBlank,
} from '@mui/icons-material'
import { useDiscussionForum } from '../../store/discussionforum.store'
import DataLoadingSpinner from '../common/DataLoadingSpinner'
import { Form, Formik, ErrorMessage, Field } from 'formik'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useUserStore } from '../../store/user.store'
import { useSearchParams } from 'react-router-dom'
import { Icons } from '../../utils/utilities'
import 'react-quill/dist/quill.snow.css'
import { toast } from 'react-toastify'
import api from '../../service/api'
import * as Yup from 'yup'
import CustomWysiwyg from '../common/CustomWysiwyg'
import { discussionAttachmentUploader, onDiscussionFilePasteOrDrop } from '../../utils/custom-editor-utilities'


let editorRef, filesList = []

const filePasteOrDropHandler = async ( file, mime ) => {
    const urlDetails = await onDiscussionFilePasteOrDrop( file, mime )
    filesList.push( urlDetails.src )
    return urlDetails
}


const handleFileInput = async ( type ) => {
    const input = document.createElement( 'input' )

    input.setAttribute( 'type', 'file' )
    input.setAttribute( 'accept', type === 'image' ? 'image/*' : "video/*" )
    input.click()

    input.onchange = async () => {
        var file = input.files[0]
        // var fileName = `${Date.now()}_${file.name}`
        if ( type === 'image' && !file.type?.includes( 'image' ) ) {
            toast( "Invalid image format!" )
            return ''
        }
        if ( type === 'video' && !file.type?.includes( 'video' ) ) {
            toast( "Invalid video format!" )
            return ''
        }

        try {
            if ( editorRef.current ) {
                var url = await discussionAttachmentUploader( type, file )
                filesList.push( url )
                console.log( filesList )

                if ( url ) {
                    const range = editorRef.current.getSelection()
                    editorRef.current.insertEmbed( range.index, type, url )
                    editorRef.current.setSelection( range.index + 1 )
                    setTimeout( () => {
                        editorRef.current.focus()
                    }, 10 )
                }
            }

        } catch ( err ) {
            console.log( err )
        }
    }
}

const modules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ size: ['normal', 'small', 'large'] }],
            [{ align: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image', 'video'],
            ['clean'],
            ['code-block'],
        ],
        handlers: {
            image: async () => await handleFileInput( 'image' ),
            video: async () => await handleFileInput( 'video' )
        }
    }
}

const EditDiscussionDialog = ( { state, setState, getData, discussion } ) => {
    const [subjectsDialogAnchorEl, setSubjectsDialogAnchorEl] = useState( null )
    const [updatingDiscussion, setUpdatingDiscussion] = useState( false )
    const [selectedSubjectsIds, setSelectedSubjectsIds] = useState( [] )
    const [existingFilesList, setExistingFilesList] = useState( [] )
    const [loadingSubjects, setLoadingSubjects] = useState( true )
    const [subjects, setSubjects] = useState( {} )

    const DiscussionForumStore = useDiscussionForum()
    const subjectsInputContainerRef = useRef()
    const UserStore = useUserStore()
    const { palette } = useTheme()
    editorRef = useRef()

    const [searchParams, setSearchParams] = useSearchParams( { searchText: '' } )
    const searchText = searchParams.get( 'searchText' )

    const initialValues = {
        title: discussion.title || '',
        content: discussion.content || '',
        access: discussion.access || 'subject_specific',
        access_groups: discussion.access_groups || [],
        categories: discussion.categories || [],
    }

    const validationSchema = Yup.object().shape( {
        title: Yup.string().required( 'Discussion title is required' ),
        content: Yup.string().required( 'Content is required' ).test( "CHECK_CONTENT", "Please enter discussion content", val => {
            if ( !val || val?.trim() === "" || val?.trim()?.replace( /\s/gi, "" ) === `<p></p>` || val?.trim()?.replace( /\s/gi, "" ) === `<p><br></p>` ) return false
            return true
        } ),
        access: Yup.string()
            .required( 'Access type is required' )
            .oneOf( ['global', 'subject_specific'] ),
        access_groups: Yup.array()
            .required( 'Access groups are required' )
            .min( 1, 'Discussion should have at least one access group' ),
        categories: Yup.array()
            .required( 'Categories are required' )
            .min( 1, 'Discussion should have at least one category' ),
    } )


    const setSearchParamValue = ( key, val ) => {
        setSearchParams(
            ( prev ) => {
                prev.set( key, val )
                return prev
            },
            { replace: true }
        )
    }

    const setCategories = ( setFieldValue, val ) => {
        setFieldValue( 'categories', val )
    }

    const handleClose = ( resetForm ) => {
        if ( resetForm ) resetForm()
        setSelectedSubjectsIds( [] )
        setState( false )
    }

    const handleKeyUp = ( e, setFieldValue, categories ) => {
        if ( e.key === 'Enter' && e.target.value.trim() !== '' ) {
            e.preventDefault()
            setCategories( setFieldValue, [...categories, e.target.value] )
            e.target.value = ''
        }
    }

    const removeCategory = ( index, setFieldValue, categories ) => {
        let copy = [...categories]
        copy.splice( index, 1 )
        setCategories( setFieldValue, copy )
    }

    const toggleSubjectInclusion = ( sub, value, setFieldValue ) => {
        if ( selectedSubjectsIds.includes( sub.subject_id ) ) {
            let index = null
            for ( let k = 0; k < value.length; k++ ) {
                const currentSub = value[k]
                if ( sub.subject_id === currentSub.subject_id ) {
                    index = k
                    break
                }
            }
            if ( index > -1 ) {
                removeSubject( index, value, setFieldValue )
            }
        } else {
            setFieldValue( 'access_groups', [...value, sub] )
            setSelectedSubjectsIds( [...value, sub].map( ( s ) => s.subject_id ) )
        }
    }

    const removeSubject = ( index, subjects, setFieldValue ) => {
        let copy = [...subjects]
        copy.splice( index, 1 )
        setFieldValue( 'access_groups', copy )
        setSelectedSubjectsIds( copy.map( ( s ) => s.subject_id ) )
    }

    const getSubjects = useCallback( async () => {
        setLoadingSubjects( true )
        try {
            const { data } = await api.discussionForum.getSubjects()
            if ( UserStore.getUser.user_role === 'STUDENT' ) setSubjects( data.data )
            else {
                let ids = []
                let subs = []
                for ( let i = 0; i < data.data.length; i++ ) {
                    const sub = data.data[i]
                    if ( !ids.includes( sub.subject_id ) ) {
                        ids.push( sub.subject_id )
                        subs.push( sub )
                    }
                }
                setSubjects( subs )
            }
        } catch ( err ) {
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( 'ended' )
            } else toast( err?.response?.data?.message || 'Something went wrong' )
        } finally {
            setLoadingSubjects( false )
        }
    }, [UserStore] )

    const deleteFileFromSpaces = async ( url ) => {
        try {
            await api.discussionForum.deleteFile( { url } )
            const fileUrlIndex = filesList.indexOf( url )
            if ( fileUrlIndex > -1 )
                filesList.splice( fileUrlIndex, 1 )
        } catch ( err ) {

        }
    }

    const handleEditorValueChange = ( val, setFieldValue, previousValue ) => {
        setFieldValue( 'content', val )
        for ( const url of filesList ) {
            if ( !val?.includes( url ) ) {
                console.log( "Deleted file :", url )
                deleteFileFromSpaces( decodeURIComponent( url ) )
            }
        }
    }

    const updateDiscussion = async ( values, { resetForm } ) => {
        setUpdatingDiscussion( true )

        const success = await DiscussionForumStore.updateDiscussion(
            discussion._id,
            values
        )
        if ( success ) {
            handleClose( resetForm )
            console.log( [...filesList, ...existingFilesList] )

            for ( const url of [...filesList, ...existingFilesList] ) {
                if ( !values?.content?.includes( url ) ) {
                    console.log( "Deleting: ", url )
                    deleteFileFromSpaces( decodeURIComponent( url ) )
                }
            }
        }
        setUpdatingDiscussion( false )
    }

    useEffect( () => {
        try {
            const re = /https:\/\/[-a-z]+\.[a-zA-Z0-9]+\.(cdn\.)?digitaloceanspaces\.com\/(temp\/)?discussions\/(images|videos)\/[a-zA-Z0-9@%()_-]+\.\w+\/?/gm
            setExistingFilesList( discussion.content.match( re ) || [] )
        } catch ( err ) { }
    }, [discussion] )

    useEffect( () => {
        const getData = async () => {
            await getSubjects()
        }
        getData()
    }, [getSubjects] )

    return (
        <Dialog
            PaperProps={{ sx: { width: '90%', maxWidth: '850px' } }}
            open={state}
        >
            <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                gap='20px'
                padding='10px 20px'
                borderBottom='1px solid #d3d3d3'
            >
                <Typography variant='h6'>Edit {discussion.title}</Typography>
                <IconButton onClick={() => handleClose()}>
                    {Icons.default.CloseIcon}
                </IconButton>
            </Box>
            <DialogContent sx={{ padding: 0 }}>
                <Box>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={updateDiscussion}
                        validationSchema={validationSchema}
                    >
                        {( { values, setFieldValue, resetForm } ) => {
                            return (
                                <Form>
                                    <Box
                                        padding='20px'
                                        display='flex'
                                        flexDirection='column'
                                        gap='10px'
                                    >
                                        <Box>
                                            <Typography variant='subtitle1' fontWeight='500'>
                                                Discussion title
                                            </Typography>
                                            <Typography
                                                marginTop='-5px'
                                                gutterBottom
                                                variant='body2'
                                                fontSize='12px'
                                                color='textSecondary'
                                            >
                                                Summarize the discussion topic in the short title.
                                            </Typography>
                                            <Field
                                                as={TextField}
                                                placeholder='Eg: State value is not changing in ReactJs'
                                                type='text'
                                                size='small'
                                                name='title'
                                                variant='outlined'
                                                fullWidth
                                            />
                                            <Typography variant='subtitle2' color='error'>
                                                {' '}
                                                <ErrorMessage name='title' />{' '}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant='subtitle1' fontWeight='500'>
                                                Discussion description
                                            </Typography>
                                            <Typography
                                                marginTop='-5px'
                                                gutterBottom
                                                variant='body2'
                                                fontSize='12px'
                                                color='textSecondary'
                                            >
                                                Describe the discussion topic in detail.
                                            </Typography>
                                            <Box>
                                                <CustomWysiwyg filePasteOrDropHandler={filePasteOrDropHandler} height='200px' onChange={e => handleEditorValueChange( e, setFieldValue, values.content )}
                                                    setParentEditorRef={( refVal ) => {
                                                        editorRef.current = refVal?.current
                                                    }}
                                                    value={values.content} modules={modules} placeholder='Share your thoughts...' />
                                                <Typography variant='subtitle2' color='error'>
                                                    <ErrorMessage name='content' />
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Typography variant='subtitle1' fontWeight='500'>
                                                Specify discussion categories
                                            </Typography>
                                            <Box
                                                padding='5px 10px'
                                                flexWrap='wrap'
                                                display='flex'
                                                alignItems='center'
                                                gap='10px'
                                                borderRadius='5px'
                                                border='1px solid #d3d3d3'
                                            >
                                                {values.categories.length > 0 &&
                                                    values.categories.map( ( category, index ) => (
                                                        <Box
                                                            key={index}
                                                            bgcolor={`${palette.primary.light}11`}
                                                            display='flex'
                                                            gap='10px'
                                                            borderRadius='0px'
                                                            width='fit-content'
                                                            alignItems='center'
                                                            padding='0 5px'
                                                            border={`1px solid ${palette.primary.light}55`}
                                                        >
                                                            <Typography variant='subtitle2'>
                                                                {category}
                                                            </Typography>
                                                            <IconButton
                                                                size='small'
                                                                onClick={() =>
                                                                    removeCategory(
                                                                        index,
                                                                        setFieldValue,
                                                                        values.categories
                                                                    )
                                                                }
                                                            >
                                                                <CancelOutlined fontSize='small' />
                                                            </IconButton>
                                                        </Box>
                                                    ) )}
                                                <Box flexGrow={1}>
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        onKeyDown={( e ) =>
                                                            handleKeyUp( e, setFieldValue, values.categories )
                                                        }
                                                        variant='standard'
                                                        InputProps={{ disableUnderline: true }}
                                                        type='text'
                                                        placeholder='Type categoriy and press enter to add.'
                                                    />
                                                </Box>
                                            </Box>
                                            <Typography variant='subtitle2' color='error'>
                                                {' '}
                                                <ErrorMessage name='categories' />{' '}
                                            </Typography>
                                        </Box>
                                        {/* <Box>
                                            <Typography variant="subtitle1" fontWeight="500">Select discussion access type</Typography>
                                            <RadioGroup name='access' value={values.access} onChange={( e ) => handleAccessTypeChange( e, setFieldValue )} sx={{ marginTop: "-5px" }} row>
                                                <FormControlLabel control={<Radio size="small" />} value="global" label="Global access" />
                                                <FormControlLabel control={<Radio size="small" />} value="subject_specific" label="Subject specific access" />
                                            </RadioGroup>
                                            <Typography variant='subtitle2' color="error" > <ErrorMessage name="access" /> </Typography>
                                        </Box> */}
                                        {values.access === 'subject_specific' && (
                                            <Box>
                                                <Typography variant='subtitle1' fontWeight='500'>
                                                    Select access groups(subjects)
                                                </Typography>
                                                <Typography
                                                    marginTop='-5px'
                                                    gutterBottom
                                                    variant='body2'
                                                    fontSize='12px'
                                                    color='textSecondary'
                                                >
                                                    Only selected groups can participate in the
                                                    discussion.
                                                </Typography>
                                                <Box
                                                    padding='5px 10px'
                                                    ref={subjectsInputContainerRef}
                                                    flexWrap='wrap'
                                                    display='flex'
                                                    alignItems='center'
                                                    gap='10px'
                                                    borderRadius='5px'
                                                    border='1px solid #d3d3d3'
                                                >
                                                    {values.access_groups.map( ( selSub, index ) => (
                                                        <Box
                                                            key={selSub.subject_id}
                                                            bgcolor={`${palette.primary.light}11`}
                                                            display='flex'
                                                            gap='10px'
                                                            width='fit-content'
                                                            alignItems='center'
                                                            padding='0 5px'
                                                            border={`1px solid ${palette.primary.light}55`}
                                                        >
                                                            <Box>
                                                                <Typography variant='subtitle2'>
                                                                    {selSub.subject_name}
                                                                </Typography>
                                                                <Typography fontSize='12px'>
                                                                    {selSub.subject_code}
                                                                </Typography>
                                                            </Box>
                                                            <IconButton
                                                                size='small'
                                                                onClick={() =>
                                                                    removeSubject(
                                                                        index,
                                                                        values.access_groups,
                                                                        setFieldValue
                                                                    )
                                                                }
                                                            >
                                                                <CancelOutlined fontSize='small' />
                                                            </IconButton>
                                                        </Box>
                                                    ) )}
                                                    <Button
                                                        onClick={( e ) =>
                                                            setSubjectsDialogAnchorEl(
                                                                subjectsInputContainerRef.current
                                                            )
                                                        }
                                                        variant='outlined'
                                                        sx={{
                                                            textTransform: 'capitalize',
                                                            color: '#666666',
                                                            border: '1px solid #666 !important',
                                                        }}
                                                        endIcon={
                                                            <ArrowDropDown sx={{ fontSize: '15px' }} />
                                                        }
                                                    >
                                                        {loadingSubjects ? (
                                                            <DataLoadingSpinner
                                                                padding='0'
                                                                waitingMessage='Loading subjects...'
                                                                size={14}
                                                            />
                                                        ) : (
                                                            'Select subjects'
                                                        )}
                                                    </Button>
                                                </Box>
                                                <Typography variant='subtitle2' color='error'>
                                                    {' '}
                                                    <ErrorMessage name='access_groups' />{' '}
                                                </Typography>
                                                <Popover
                                                    anchorReference='anchorEl'
                                                    anchorOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'center',
                                                    }}
                                                    onClose={() => setSubjectsDialogAnchorEl( null )}
                                                    open={Boolean( subjectsDialogAnchorEl )}
                                                    anchorEl={subjectsDialogAnchorEl}
                                                >
                                                    <Box
                                                        padding='5px'
                                                        sx={{ zIndex: 5 }}
                                                        position='sticky'
                                                        right='0'
                                                        left='0'
                                                        top='0'
                                                        bgcolor='#fff'
                                                        borderBottom='1px solid #d3d3d3'
                                                    >
                                                        <TextField
                                                            onChange={( e ) =>
                                                                setSearchParamValue(
                                                                    'searchText',
                                                                    e.target.value
                                                                )
                                                            }
                                                            size='small'
                                                            fullWidth
                                                            type='text'
                                                            placeholder='Search subjects...'
                                                        />
                                                    </Box>
                                                    <Box>
                                                        {UserStore.getUser.user_role === 'STUDENT' &&
                                                            Object.keys( subjects ).length > 0 &&
                                                            Object.keys( subjects ).map( ( year ) => {
                                                                const yearSubjects = subjects[year]
                                                                const yearSems = Object.keys( yearSubjects )

                                                                if ( yearSems.length > 0 )
                                                                    return (
                                                                        <Box
                                                                            borderBottom='2px solid #d3d3d3'
                                                                            key={year}
                                                                        >
                                                                            {yearSems.map( ( sem ) => {
                                                                                const subs = yearSubjects[sem]
                                                                                const filtered = subs?.filter(
                                                                                    ( s ) =>
                                                                                        s.subject_name
                                                                                            ?.toLowerCase()
                                                                                            ?.includes(
                                                                                                searchText.toLowerCase()
                                                                                            ) ||
                                                                                        s.subject_code
                                                                                            ?.toLowerCase()
                                                                                            ?.includes(
                                                                                                searchText.toLowerCase()
                                                                                            )
                                                                                )
                                                                                return (
                                                                                    <Box key={sem}>
                                                                                        <Box
                                                                                            padding='10px'
                                                                                            gap='5px'
                                                                                            display='flex'
                                                                                            alignItems='center'
                                                                                            borderBottom='2px solid #d3d3d3'
                                                                                        >
                                                                                            <Typography
                                                                                                variant='subtitle2'
                                                                                                color='secondary'
                                                                                            >
                                                                                                Year - {year}
                                                                                            </Typography>
                                                                                            <Box
                                                                                                bgcolor='GrayText'
                                                                                                height='100%'
                                                                                                borderRadius='50%'
                                                                                                padding='2px'
                                                                                            ></Box>
                                                                                            <Typography
                                                                                                color='GrayText'
                                                                                                variant='subtitle2'
                                                                                            >
                                                                                                Semester - {sem}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                        {filtered &&
                                                                                            filtered.length > 0 && (
                                                                                                <Box
                                                                                                    display='flex'
                                                                                                    flexDirection='column'
                                                                                                >
                                                                                                    {filtered.length > 0 &&
                                                                                                        filtered.map( ( sub ) => {
                                                                                                            return (
                                                                                                                // <FormControlLabel sx={{ "&:hover": { background: palette.rowHover }, borderBottom: "1px solid #d3d3d3", margin: 0, padding: 0 }} size="small" onChange={( e ) => toggleSubjectInclusion( e, sub, values.access_groups, setFieldValue )} control={<Checkbox sx={{ height: "40px", width: "40px", transform: "scale(0.9)" }} />} label={`${sub.subject_name}(${sub.subject_name_short})`} />
                                                                                                                <Box
                                                                                                                    onClick={() =>
                                                                                                                        toggleSubjectInclusion(
                                                                                                                            sub,
                                                                                                                            values.access_groups,
                                                                                                                            setFieldValue
                                                                                                                        )
                                                                                                                    }
                                                                                                                    display='flex'
                                                                                                                    gap='10px'
                                                                                                                    sx={{
                                                                                                                        cursor: 'pointer',
                                                                                                                        '&:hover': {
                                                                                                                            background:
                                                                                                                                palette.rowHover,
                                                                                                                        },
                                                                                                                    }}
                                                                                                                    padding='2px 25px'
                                                                                                                    alignItems='center'
                                                                                                                    key={sub.subject_id}
                                                                                                                    borderBottom='1px solid #d3d3d3'
                                                                                                                >
                                                                                                                    {!selectedSubjectsIds.includes(
                                                                                                                        sub.subject_id
                                                                                                                    ) ? (
                                                                                                                        <CheckBoxOutlineBlank
                                                                                                                            sx={{
                                                                                                                                color:
                                                                                                                                    'darkgrey',
                                                                                                                                '&:hover': {
                                                                                                                                    color:
                                                                                                                                        'GrayText',
                                                                                                                                },
                                                                                                                            }}
                                                                                                                            fontSize='small'
                                                                                                                        />
                                                                                                                    ) : (
                                                                                                                        <CheckBox fontSize='small' />
                                                                                                                    )}
                                                                                                                    <Typography variant='subtitle2'>{`${sub.subject_name}(${sub.subject_name_short})`}</Typography>
                                                                                                                </Box>
                                                                                                            )
                                                                                                        } )}
                                                                                                </Box>
                                                                                            )}
                                                                                        {subs.length > 0 &&
                                                                                            filtered.length === 0 && (
                                                                                                <Typography
                                                                                                    padding='10px'
                                                                                                    variant='subtitle2'
                                                                                                >
                                                                                                    No subjects matchs the search
                                                                                                    text "<em>{searchText}</em>"{' '}
                                                                                                </Typography>
                                                                                            )}
                                                                                        {subs.length === 0 && (
                                                                                            <Typography
                                                                                                padding='10px'
                                                                                                variant='subtitle2'
                                                                                            >
                                                                                                No subjects found for the
                                                                                                semester{' '}
                                                                                            </Typography>
                                                                                        )}
                                                                                    </Box>
                                                                                )
                                                                            } )}
                                                                        </Box>
                                                                    )
                                                                else return ''
                                                            } )}
                                                        {UserStore.getUser.user_role !== 'STUDENT' &&
                                                            subjects.length > 0 &&
                                                            subjects
                                                                .filter(
                                                                    ( s ) =>
                                                                        s.subject_name
                                                                            ?.toLowerCase()
                                                                            ?.includes( searchText.toLowerCase() ) ||
                                                                        s.subject_code
                                                                            ?.toLowerCase()
                                                                            ?.includes( searchText.toLowerCase() )
                                                                )
                                                                .map( ( sub ) => (
                                                                    <Box
                                                                        onClick={() =>
                                                                            toggleSubjectInclusion(
                                                                                sub,
                                                                                values.access_groups,
                                                                                setFieldValue
                                                                            )
                                                                        }
                                                                        display='flex'
                                                                        gap='10px'
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                            '&:hover': {
                                                                                background: palette.rowHover,
                                                                            },
                                                                        }}
                                                                        padding='2px 25px'
                                                                        alignItems='center'
                                                                        key={sub.subject_id}
                                                                        borderBottom='1px solid #d3d3d3'
                                                                    >
                                                                        {!selectedSubjectsIds.includes(
                                                                            sub.subject_id
                                                                        ) ? (
                                                                            <CheckBoxOutlineBlank
                                                                                sx={{
                                                                                    color: 'darkgrey',
                                                                                    '&:hover': { color: 'GrayText' },
                                                                                }}
                                                                                fontSize='small'
                                                                            />
                                                                        ) : (
                                                                            <CheckBox fontSize='small' />
                                                                        )}
                                                                        <Typography variant='subtitle2'>{`${sub.subject_name}(${sub.subject_name_short})`}</Typography>
                                                                    </Box>
                                                                ) )}
                                                    </Box>
                                                </Popover>
                                            </Box>
                                        )}
                                    </Box>

                                    <Box
                                        padding='10px 20px'
                                        bgcolor={palette.rowHover}
                                        borderTop='1px solid #d3d3d3'
                                        display='flex'
                                        justifyContent='flex-end'
                                        gap='10px'
                                    >
                                        <Button
                                            disabled={updatingDiscussion}
                                            type='submit'
                                            sx={{ textTransform: 'capitalize' }}
                                            variant='contained'
                                            disableElevation
                                            startIcon={
                                                updatingDiscussion ? (
                                                    <CircularProgress size={14} />
                                                ) : (
                                                    Icons.default.EditIcon
                                                )
                                            }
                                            color='primary'
                                        >
                                            {updatingDiscussion
                                                ? 'Updating discussion...'
                                                : 'Update Discussion'}
                                        </Button>
                                        <Button
                                            disabled={updatingDiscussion}
                                            startIcon={Icons.default.CloseIcon}
                                            onClick={() => handleClose( resetForm )}
                                            sx={{ textTransform: 'capitalize' }}
                                            variant='outlined'
                                            color='error'
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                </Form>
                            )
                        }}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default EditDiscussionDialog


// const handleAccessTypeChange = async ( e, setFieldValue ) => {
//     const val = e.target.value
//     setFieldValue( 'access', val )
//     if ( val === 'global' ) {
//         setFieldValue( 'access_groups', [
//             {
//                 subject_name: 'all',
//                 subject_code: 'ALL',
//                 subject_name_short: 'all',
//                 subject_id: '-1',
//             },
//         ] )
//     } else {
//         setFieldValue( 'access_groups', [] )
//         if ( Object.keys( subjects ).length === 0 ) await getSubjects()
//     }
//     setSelectedSubjectsIds( [] )
// }
import { extractMimeType, quillDefaultModule } from "../../utils/utilities"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Box, CircularProgress, Typography, useTheme } from "@mui/material"
import PropTypes from 'prop-types'
import 'quill/dist/quill.snow.css'
import Quill from "quill"
import { toast } from "react-toastify"

const CustomWysiwyg = ( { allowBase64Strings = false, filePasteOrDropHandler = null, disableDragAndDrop = false, hideTools = false, borderless = false, onFocusSelectAll = false, autoFocus = false, onBlur, setParentEditorRef, readOnly = false, modules = quillDefaultModule, classList, placeholder, value, onChange = () => { }, height = '50px', styles = {} } ) => {
    const dragAndDropContainerRef = useRef( null )
    const prevValueRef = useRef( value )
    const editorRef = useRef( null )
    const quillRef = useRef( null )

    const { palette } = useTheme()

    const [dropping, setDropping] = useState( false )
    const [uploadingFile, setUploadingFile] = useState( false )

    const handleDragOver = e => {
        e.preventDefault()
        e.stopPropagation()

        if ( !dropping ) {
            setDropping( true )
        }
    }

    const handleDragLeave = e => {
        if ( !dragAndDropContainerRef.current.contains( e.relatedTarget ) ) {
            setDropping( false )
        }
    }

    const handleDrop = async e => {
        e.preventDefault()
        e.stopPropagation()
        setDropping( false )
        if ( !disableDragAndDrop )
            if ( filePasteOrDropHandler ) {
                const quillEditor = quillRef.current
                const file = e.dataTransfer.files[0]
                quillRef.current?.disable()
                setUploadingFile( true )
                if ( file && file instanceof File ) {
                    const { src: url } = await toast.promise(
                        filePasteOrDropHandler( file, file.type ),
                        {
                            pending: 'Uploading file...',
                            error: `Couldn't upload the file! please try again`
                        } )
                    if ( url ) {
                        const range = quillEditor.getSelection( true )

                        if ( file.type.startsWith( "image/" ) ) {
                            quillEditor.insertEmbed( range.index, "image", url )
                        } else if ( file.type.startsWith( "video/" ) ) {
                            quillEditor.insertEmbed( range.index, "video", url )
                        }
                        quillEditor?.setSelection( range.index + 1 )
                        setTimeout( () => {
                            quillEditor?.focus()
                        }, 10 )
                    }
                }
            }
        quillRef.current?.enable()
        setUploadingFile( false )

    }

    const handleKeydown = ( event ) => {
        const isMac = navigator.platform.toUpperCase().indexOf( "MAC" ) >= 0

        if ( ( isMac ? event.metaKey : event.ctrlKey ) && event.key === "z" ) {
            event.preventDefault()
            event.stopPropagation()
        }

        if (
            ( isMac ? event.metaKey && event.shiftKey : event.ctrlKey ) &&
            ( event.key === "y" || event.key === "z" )
        ) {
            event.preventDefault()
            event.stopPropagation()
        }
    }

    const handleTextChange = useCallback( async ( e, a, b, c, d ) => {
        // console.log( "CHANGE:", e, a, b, c, d )

        let content = quillRef.current.root.innerHTML
        if ( content?.includes( "base64" ) ) {
            const regex = /<(img|video)[^>]+(src=["'])(data:image\/[a-zA-Z]*;base64,[^"']+)(["'][^>]*>)/g
            if ( filePasteOrDropHandler ) {
                const matches = [...content.matchAll( regex )]
                setUploadingFile( true )
                quillRef.current?.disable()
                const replacedContent = await toast.promise(
                    Promise.all(
                        matches.map( async ( match ) => {
                            const base64Data = match.at( 3 )
                            const mime = extractMimeType( base64Data )
                            const { tag } = await filePasteOrDropHandler( base64Data, mime )
                            return tag
                        } )
                    ),
                    {
                        pending: 'Uploading file...',
                        error: `Couldn't upload the file! please try again`
                    }
                )

                setUploadingFile( false )
                quillRef.current?.enable()
                matches.forEach( ( match, index ) => {
                    content = content.replace( match[0], replacedContent[index] )
                } )

            } else {
                if ( !allowBase64Strings ) {
                    toast( "Please use toolbar to insert image or video." )
                    content = content.replace( regex, "" )
                }
            }
            if ( content !== prevValueRef.current ) {
                onChange( content, quillRef.current?.getText() )
                prevValueRef.current = content
            } else if ( content === value && content !== prevValueRef.current ) {
                onChange( content, quillRef.current?.getText() )
                prevValueRef.current = content
            }
            quillRef.current.root.innerHTML = content
            setTimeout( () => {
                const length = quillRef.current.getLength()
                quillRef.current.setSelection( length, length )
            }, 10 )
        } else {
            if ( content !== prevValueRef.current ) {
                onChange( content, quillRef.current?.getText() )
                prevValueRef.current = content
            } else if ( content === value && content !== prevValueRef.current ) {
                onChange( content, quillRef.current?.getText() )
                prevValueRef.current = content
            }
        }
    }, [onChange, filePasteOrDropHandler, allowBase64Strings, value] )

    const handleFocus = useCallback( () => {
        if ( onFocusSelectAll ) {
            quillRef.current.focus()
            setTimeout( () => {
                if ( !onFocusSelectAll ) {
                    const length = quillRef.current.getLength()
                    quillRef.current.setSelection( length - 1, length - 1 )
                }
                else {
                    const length = quillRef.current.getLength()
                    quillRef.current.setSelection( 0, length - 1 )
                }
            }, 0 )
        }
    }, [onFocusSelectAll] )

    const handleBlur = useCallback( () => {
        if ( onBlur ) {
            const html = quillRef.current.root.innerHTML
            onBlur( html, quillRef.current?.getText() )
        }
    }, [onBlur] )

    useEffect( () => {

        if ( !quillRef.current ) {
            const quill = new Quill( editorRef.current, {
                readOnly,
                placeholder,
                theme: "snow",
                modules
            } )

            quillRef.current = quill
            if ( quill.keyboard?.bindings?.y[0]?.handler )
                quill.keyboard.bindings.y[0].handler = () => { }


            if ( setParentEditorRef ) {
                setParentEditorRef( quillRef )
            }

            const undoManager = quillRef.current.history

            if ( undoManager ) {
                undoManager.undo = () => console.log( "Undo is disabled." )
                undoManager.redo = () => console.log( "Redo is disabled." )
            }

            quillRef.current.root.innerHTML = value

            quill.on( Quill.events.TEXT_CHANGE, handleTextChange )

            quillRef.current.root.addEventListener( 'blur', handleBlur )

            quillRef.current.root.addEventListener( 'focus', handleFocus )

            if ( autoFocus ) {
                quillRef.current.focus()
            }

            const setCursorToEnd = () => {
                const length = quillRef.current.getLength()
                quillRef.current.setSelection( length, length )
            }

            setCursorToEnd()
            return () => {
                quillRef.current.root.removeEventListener( 'blur', handleBlur )
                quillRef.current.root.removeEventListener( 'focus', handleFocus )
            }
        }
    }, [handleTextChange, handleBlur, handleFocus, modules, placeholder, readOnly, setParentEditorRef, value, autoFocus] )

    useEffect( () => {
        if ( quillRef.current && quillRef.current.root.innerHTML !== value ) {
            quillRef.current.root.innerHTML = value
        }
    }, [value] )

    return (
        <Box ref={dragAndDropContainerRef} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} flexGrow={1} position="relative" className={`${hideTools ? "no-tools-quill" : ""} ${borderless ? "borderless" : ""}`} width="100%">
            <Box className="drop-area" display={( dropping || uploadingFile ) ? "flex" : "none"} alignItems="center" justifyContent="center" bgcolor={palette.contentBg} position="absolute" border={`2px dashed ${palette.borderColor}`} sx={{ opacity: ( ( dropping && !disableDragAndDrop ) || uploadingFile ) ? "1" : "0", pointerEvents: Boolean( filePasteOrDropHandler ) && !disableDragAndDrop ? "all" : "none", inset: 0, zIndex: 100, borderRadius: borderless ? "0px" : "5px" }}>
                {dropping && <Typography variant={Boolean( filePasteOrDropHandler ) ? "h5" : "subtitle1"} color={Boolean( filePasteOrDropHandler ) || allowBase64Strings ? "textSecondary" : "errorMessage.main"} className="animation-shake" >{Boolean( filePasteOrDropHandler ) || allowBase64Strings ? "Drop here!" : "Please insert image from the toolbar!"}</Typography>}
                {uploadingFile && <Typography variant="subtitle2" color="textSecondary" display="flex" alignItems="center" gap="10px"> <CircularProgress size={16} /> Uploading...</Typography>}
            </Box>
            <Box color={palette.common.font} className={classList} onKeyDown={handleKeydown} ref={editorRef} sx={{ minHeight: height, width: "100%", border: '1px solid #ccc', ...styles }} ></Box>
        </Box>
    )
}

CustomWysiwyg.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    height: PropTypes.string,
    styles: PropTypes.object,
    placeholder: PropTypes.string,
    classList: PropTypes.string,
    hideTools: PropTypes.bool,
    onDrop: PropTypes.func
}

export default CustomWysiwyg
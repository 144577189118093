import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, TextField, Typography, useTheme } from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import * as Yup from 'yup'
import api from '../../service/api'
import { toast } from 'react-toastify'
import { Icons } from '../../utils/utilities'
import Switch from '../common/CustomSwitch'

const controls = [
    { label: "Academics (Manage academic rules)", permissionName: "academics" },
    { label: "Admission Exams", permissionName: "admission_exams" },
    { label: "Analytics", permissionName: "analytics" },
    { label: "Announcements", permissionName: "announcements" },
    { label: "API Settings", permissionName: "api_settings" },
    { label: "Attendance Audit", permissionName: "attendance_audit" },
    { label: "Auditing", permissionName: "auditing" },
    { label: "Control Panel", permissionName: "control_panel" },
    { label: "Debug User", permissionName: "debug_user" },
    { label: "Discussions", permissionName: "discussions" },
    { label: "Exam Audit", permissionName: "exam_audit" },
    { label: "Feedback", permissionName: "feedback" },
    { label: "Live Class Audit", permissionName: "live_class_audit" },
    { label: "Online course", permissionName: "online_course" },
    { label: "Recordings", permissionName: "recordings" },
    { label: "Resume Builder", permissionName: "resume_builder" },
    { label: "Study materials", permissionName: "study_material_audit" },
]

const defaultPermissions = {
    academics: false,
    announcements: false,
    admission_exams: false,
    analytics: false,
    api_settings: false,
    attendance_audit: false,
    auditing: false,
    control_panel: false,
    debug_user: false,
    discussions: false,
    exam_audit: false,
    feedback: false,
    live_class_audit: false,
    online_course: false,
    recordings: false,
    resume_builder: false,
    study_material_audit: false,
}
const allPermissions = {
    live_class_audit: true,
    announcements: true,
    study_material_audit: true,
    discussions: true,
    attendance_audit: true,
    academics: true,
    feedback: true,
    api_settings: true,
    debug_user: true,
    analytics: true,
    recordings: true,
    exam_audit: true,
    online_course: true,
    resume_builder: true,
    control_panel: true,
    auditing: true,
    admission_exams: true
}

const AddEditAdmin = ( { setMode, mode, user } ) => {
    const [permissions, setPermissions] = useState( mode === "add" ? defaultPermissions : user.user_permissions || user.permissions )
    const [isIndeterminate, setIsIndeterminate] = useState( false )
    const [isAllChecked, setIsAllChecked] = useState( false )
    const [waiting, setWaiting] = useState( false )

    const { palette } = useTheme()


    const handleAllChange = ( e ) => {
        setIsAllChecked( e.target.checked )
        if ( e.target.checked ) {
            setPermissions( allPermissions )
        } else {
            setPermissions( defaultPermissions )
        }
    }

    const addNewAdmin = async ( values ) => {
        setWaiting( true )
        try {
            if ( values.password.trim() !== "" && values.password ) {
                const res = await api.createSubAdminAccount( { ...values, permissions } )
                toast( res.data.message )
                setMode( "manage" )
            }
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong while adding new subadmin! please try again later" )
        } finally {
            setWaiting( false )
        }
    }

    const updateAdminUser = async ( values ) => {
        try {
            setWaiting( true )
            const res = await api.updateSubAdminAccount( { ...values, permissions }, user.user_id )
            toast( res.data.message )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong while updating new subadmin! please try again later" )
        } finally {
            setWaiting( false )
        }
    }

    const initialValues = {
        email: mode === "edit" ? user.user_email : "",
        username: mode === "edit" ? user.user_name : "",
        password: mode === "edit" ? "" : ""
    }

    const validationSchema = Yup.object().shape( {
        email: Yup.string().email( "Please provide valid email" ).required( "E-mail is required!" ),
        username: Yup.string().required( "Username is required!" ),
        // password: Yup.string().required( "Password is required!" )
        password: Yup.string().test( "TEST FOR PASSWORD", "Password is required!", ( val ) => {
            if ( mode === 'edit' ) return true
            return val?.match( `^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,32}$` )
        } )
    } )

    useEffect( () => {
        let previous = undefined
        let isDiff = false
        for ( const permission in permissions ) {
            if ( previous === true || previous === false ) {
                if ( previous !== permissions[permission] ) {
                    setIsIndeterminate( true )
                    isDiff = true
                    break
                }
            } else
                previous = permissions[permission]
        }
        if ( !isDiff ) {
            setIsIndeterminate( false )
            setIsAllChecked( previous )
        }
    }, [permissions] )

    return (
        <Box borderRadius="5px">
            <Typography padding="10px 20px" variant='h6' gutterBottom>{mode === "add" ? "Add new admin" : "Edit user details"}</Typography>
            <Box padding="20px" paddingTop="0">
                <Formik onSubmit={mode === "add" ? addNewAdmin : updateAdminUser} initialValues={initialValues} validationSchema={validationSchema}>
                    {( { values, errors } ) => {
                        return (
                            <Form autoComplete='off'>
                                <FormControl fullWidth margin="normal">
                                    <Typography variant='subtitle2' color={palette.form.label} >E-mail</Typography>
                                    <Field id="admin-email-input" size="small" as={TextField} name="email" variant='outlined' />
                                    <Typography variant='subtitle2' fontSize="12px" color="error.main" > <ErrorMessage name="email" /> </Typography>
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <Typography variant='subtitle2' color={palette.form.label} >Username</Typography>
                                    <Field id="admin-username-input" size="small" as={TextField} type='text' name="username" variant='outlined' />
                                    <Typography variant='subtitle2' fontSize="12px" color="error.main" > <ErrorMessage name="username" /> </Typography>
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <Typography variant='subtitle2' color={palette.form.label} >{mode !== 'add' ? "Set new password" : "Password"}</Typography>
                                    <Field id="admin-password-input" size="small" as={TextField} type="password" name="password" variant='outlined' />
                                    <Typography variant='subtitle2' fontSize="12px" color="error.main" > <ErrorMessage name="password" /> </Typography>
                                </FormControl>
                                <Box marginTop="20px">
                                    <Typography variant='subtitle2' color="secondary">Grant permissions</Typography>
                                    <Box border="1px solid #d3d3d3" display="flex" flexDirection="column" bgcolor={palette.rowHover}>
                                        <FormControlLabel label="All" labelPlacement='start' sx={{ justifyContent: "space-between", borderBottom: "2px solid black", margin: "0", padding: "1px 5px" }} control={<Checkbox checked={isAllChecked} onChange={handleAllChange} indeterminate={isIndeterminate} />} />
                                        <Box maxHeight="350px" overflow="auto" className="custom-scrollbar" display="flex" flexDirection="column">

                                            {controls.map( ctrl => (
                                                <FormControlLabel key={ctrl.label} sx={{ paddingY: "0", margin: "0", justifyContent: "space-between", padding: "1px 5px", borderBottom: "1px solid #d3d3d3", "& span": { fontSize: "14px", fontWeight: "bold" } }} label={ctrl.label} labelPlacement='start' control={<Switch checked={permissions[ctrl.permissionName]} onChange={() => setPermissions( { ...permissions, [ctrl.permissionName]: !permissions[ctrl.permissionName] } )} />} />
                                            ) )}

                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" marginTop="20px" gap="10px">
                                    <Button disabled={waiting} sx={{ textTransform: "capitalize" }} startIcon={waiting ? <CircularProgress size={14} /> : mode === "add" ? Icons.default.AddIcon : Icons.default.CheckCircleIcon} type='submit' variant='contained' disableElevation>{mode === "edit" ? waiting ? 'Updating...' : "Update" : waiting ? "Adding..." : "Add admin"}</Button>
                                    <Button disabled={waiting} sx={{ textTransform: "capitalize" }} startIcon={Icons.default.CancelIcon} onClick={() => { setMode( "manage" ); setPermissions( defaultPermissions ) }} color="error" variant='outlined' disableElevation>Cancel</Button>
                                </Box>
                            </Form>
                        )
                    }}
                </Formik>
            </Box>
        </Box>
    )
}

export default AddEditAdmin

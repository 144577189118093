import SubmitAssignmentDialog from '../../components/user/SubmitAssignmentDialog'
import { useAssignmentStore } from '../../store/assignment.store'
import { Box, Button, Card, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography, useTheme } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Icons, showDateInIndianDateFormat } from '../../utils/utilities'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import TableHeader from '../../components/common/TableHeader'
import noAssignmentsImg from '../../assets/noassignment.svg'
import AssignmentViewer from '../../components/common/AssignmentViewer'
import { useSubjectStore } from '../../store/subject.store'
import CustomSearchBar from '../../components/common/CustomSearchBar'
import { useUserStore } from '../../store/user.store'
import ConfirmDialog from '../../components/common/ConfirmDialog'
import React, { useCallback, useEffect, useState } from 'react'
import { SubjectIcon } from '../../assets/custom-icons'
import CreateOrUpdateAssignment from '../../components/presenter/CreateOrUpdateAssignment'
import { observer } from 'mobx-react'

const columns = [
    { name: "ASSIGNMENT NAME", align: "left", padding: "10px 20px 10px 40px" },
    { name: "DUE DATE", align: "left" },
    { name: "Assignment for", minWidth: "150px", align: "left" },
    { name: "Submissions", align: "center" },
    { name: "ACTION", align: "right", padding: "10px 40px 10px 20px" },
]

const SubjectAssignments = observer( () => {

    const [assignmentSelectedForDeletion, setAssignmentSelectedForDeletion] = useState( null )
    const [openAssignmentSubmission, setOpenAssignmentSubmission] = useState( false )
    const [selectedAssignmentData, setSelectedAssignmentData] = useState( {} )
    const [confirmDialogStatus, setConfirmDialogStatus] = useState( false )
    const [confirmMessage, setConfirmMessage] = useState( null )
    const [selectedValue, setSelectedValue] = useState( null )
    const [deleting, setDeleting] = useState( false )
    const [loading, setLoading] = useState( true )
    const [view, setView] = useState( 'manage' )

    const AssignmentStore = useAssignmentStore()
    const SubjectStore = useSubjectStore()
    const UserStore = useUserStore()

    const navigate = useNavigate()
    const { palette, shadows, border, table, theme } = useTheme()

    const [searchParams, setSearchParams] = useSearchParams( { searchText: "", assignmentFilter: "due" } )
    const selectedAssignmentId = searchParams.get( 'selectedAssignmentId' )
    const assignmentFilter = searchParams.get( 'assignmentFilter' ) || 'due'
    const searchText = searchParams.get( 'searchText' ) || ''
    const open = searchParams.get( 'open' ) || false

    const setSearchParamValue = useCallback( ( key, val ) => {
        setSearchParams( prev => {
            prev.set( key, val )
            return prev
        }, { replace: true } )
    }, [setSearchParams] )

    const handleClickOpen = ( value ) => {
        setSelectedValue( value )
        setSearchParamValue( 'selectedAssignmentId', value.assignment_id )
        setSearchParamValue( 'open', true )
    }

    const handleClose = ( value ) => {
        setSearchParamValue( 'open', false )
        setSearchParamValue( 'selectedAssignmentId', null )
        setSelectedValue( value )
    }

    const deleteAssignment = async () => {
        setDeleting( true )
        let payload = { "assignment_id": assignmentSelectedForDeletion }
        await AssignmentStore.deleteAssignment( payload )
        await AssignmentStore.fetchSubjectAssignments( SubjectStore.getSubject.subject_id )
        setAssignmentSelectedForDeletion( null )
        setDeleting( false )
        setConfirmDialogStatus( false )
    }

    const openDeleteDialog = ( id, name ) => {
        setAssignmentSelectedForDeletion( id )
        setConfirmMessage(
            <p>
                Assignment <b>{name}</b> will be deleted permanently.
            </p>
        )
        setConfirmDialogStatus( true )
    }

    useEffect( () => {
        if ( "" + selectedAssignmentId !== 'null' ) {
            setSelectedValue( AssignmentStore.getSubjectAssignment.find( assignment => +assignment.assignment_id === +selectedAssignmentId ) )
            setSearchParamValue( 'open', true )
        }
    }, [setSearchParamValue, selectedAssignmentId, AssignmentStore] )

    const handleClickOpenAssignmentSubmission = ( value ) => {
        if ( UserStore.getUser["user_role"] === "STUDENT" ) {
            setSelectedAssignmentData( value )
            setOpenAssignmentSubmission( true )
        } else navigate( `/faculty/assignment/${value.assignment_id}` )
    }

    const handleCloseAssignmentSubmission = ( value ) => {
        setOpenAssignmentSubmission( false )
        setSelectedAssignmentData( value )
    }

    const assignmentFilterFunc = ( item ) => {
        if ( assignmentFilter === "all" ) return true
        if ( assignmentFilter === "due" ) {
            let dueDate = new Date( item.assignment_due_date )
            dueDate = new Date( dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate() + 1 )

            if ( UserStore.getUser.user_role === "STUDENT" )
                return +item.submission_exists === 0 && dueDate > new Date()
            return dueDate > new Date()

        }
        if ( assignmentFilter === "completed" ) {
            let dueDate = new Date( item.assignment_due_date )
            dueDate = new Date( dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate() + 1 )

            return +item.submission_exists > 0 || dueDate < new Date()
        }
        return true
    }

    useEffect( () => {
        const getData = async () => {
            if ( AssignmentStore.getErrors.subjectAssignments )
                await AssignmentStore.fetchSubjectAssignments( SubjectStore.getSubject.subject_id )
            if ( UserStore.getUser.user_role !== "STUDENT" && UserStore.getErrorsStatuses?.academicYearsAndBatches === true )
                await UserStore.fetchUsersAllBatchesAndSectionList()
            setLoading( false )
        }
        if ( view === 'manage' )
            getData()
    }, [AssignmentStore, SubjectStore, UserStore, view] )

    return (
        <Box display="flex" flexDirection="column" flexGrow={1}>
            {/* Dialogs */}
            {!loading && <Box>
                <SubmitAssignmentDialog selectedValue={selectedAssignmentData} open={openAssignmentSubmission} onClose={handleCloseAssignmentSubmission} />
                <AssignmentViewer loaded={!loading} selectedValue={selectedValue} open={open === 'true'} onClose={handleClose} />
                <ConfirmDialog waiting={deleting} status={confirmDialogStatus} confirmAction={deleteAssignment} cancelAction={() => setConfirmDialogStatus( false )} message={confirmMessage} actionName="Yes, Delete it" />
                {UserStore.getUser.user_role !== 'STUDENT' && <CreateOrUpdateAssignment subject={SubjectStore.getSubject} view={view} setView={setView} />}
            </Box>}


            {!loading && ( AssignmentStore?.getSubjectAssignment || [] ).length > 0 && <Box padding="20px" display="flex" alignItems="center" justifyContent="space-between">
                <Box borderRadius="5px" boxShadow={shadows[1]} border={theme === 'dark' && `1px solid ${palette.borderColor}`} display="flex" gap="10px" height="40px" padding="5px">
                    <Typography width="100px" display="flex" alignItems="center" justifyContent="center" textAlign="center" onClick={() => setSearchParamValue( 'assignmentFilter', 'all' )} sx={{ color: assignmentFilter === "all" ? `${palette.primary.contrastText}` : 'inherit', cursor: "pointer", borderRadius: "3px", "&:hover": { background: assignmentFilter !== "all" && palette.rowHover } }} padding="3px" bgcolor={assignmentFilter === "all" ? `${palette.primary.main}` : palette.common.default} variant="subtitle2">All</Typography>
                    <Typography width="100px" display="flex" alignItems="center" justifyContent="center" textAlign="center" onClick={() => setSearchParamValue( 'assignmentFilter', 'due' )} sx={{ color: assignmentFilter === "due" ? `${palette.primary.contrastText}` : 'inherit', cursor: "pointer", borderRadius: "3px", "&:hover": { background: assignmentFilter !== "due" && palette.rowHover } }} padding="3px" bgcolor={assignmentFilter === "due" ? `${palette.primary.main}` : palette.common.default} variant="subtitle2">Due</Typography>
                    <Typography width="100px" display="flex" alignItems="center" justifyContent="center" textAlign="center" onClick={() => setSearchParamValue( 'assignmentFilter', 'completed' )} sx={{ color: assignmentFilter === "completed" ? `${palette.primary.contrastText}` : 'inherit', cursor: "pointer", borderRadius: "3px", "&:hover": { background: assignmentFilter !== "completed" && palette.rowHover } }} padding="3px" bgcolor={assignmentFilter === "completed" ? `${palette.primary.main}` : palette.common.default} variant="subtitle2">Completed</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" gap="20px" padding="10px 20px">
                    <CustomSearchBar border='1px solid #d3d3d3' value={searchText} onChange={e => {
                        setSearchParamValue( 'searchText', e.target.value )
                    }} placeholder='Search assignments...' />
                    {UserStore.getUser.user_role !== 'STUDENT' && <Button onClick={() => setView( 'create' )} startIcon={Icons.default.AddIcon} variant="contained" disableElevation >Create</Button>}
                </Box>
            </Box>}
            {!loading && UserStore.getUser.user_role === 'STUDENT' && ( AssignmentStore?.getSubjectAssignment || [] ).filter( assignmentFilterFunc ).length > 0 && <Box maxHeight="500px" overflow="auto" flexGrow={1} className="custom-scrollbar" display="flex" gap="20px" alignItems="flex-start" flexWrap="wrap" padding="20px">
                {AssignmentStore?.getSubjectAssignment.filter( assignmentFilterFunc ).map( ( assignment, aindex ) => {

                    let dueDate = new Date( assignment.assignment_due_date )
                    dueDate = new Date( dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate() + 1 )
                    const now = new Date()

                    return (
                        <Card key={assignment.assignment_id}>
                            <Box width="300px" borderRadius="5px" sx={{ cursor: "pointer", "&:hover": { boxShadow: `1px 0 5px 1px rgba(0,0,0,0.1)` } }} overflow="hidden">
                                <Box onClick={() => handleClickOpen( assignment )} borderBottom={`1px solid ${palette.borderColor}`} display="flex" alignItems="flex-start" gap="20px" padding="20px">
                                    <img width="20px" src={SubjectIcon} alt="Assignment icons" />
                                    <Box flexGrow={1} overflow="hidden">
                                        <Typography variant='h6' fontWeight="900" width="100%" noWrap fontSize="14px">{assignment.assignment_name}</Typography>
                                        <Typography color="GrayText" variant='subtitle1' fontSize="12px" width="100%" noWrap >{assignment.author_name}</Typography>
                                        {assignment?.assignment_subject && <Typography variant='subtitle1' width="100%" noWrap fontSize="14px">{`${assignment?.assignment_subject?.subject_name} ${assignment?.assignment_subject?.subject_code}`}</Typography>}
                                        <Typography width="100%" variant='subtitle1' noWrap fontSize="12px">Created On: {showDateInIndianDateFormat( assignment.assignment_created_at )}</Typography>
                                    </Box>
                                </Box>
                                <Box padding="10px" paddingLeft="20px" display="flex" justifyContent="space-between" alignItems="center">
                                    {/* <Typography borderRadius="5px" padding="5px" bgcolor={`${palette.error.light}22`} fontSize="12px" sx={{ color: "red" }} >Due date: {showDateInIndianDateFormat( assignment.assignment_due_date, { month: "long" } )}</Typography>
                                    <Button disableElevation variant='contained' sx={{ textTransform: "capitalize" }} color="success" disabled={assignment["submission_exists"] !== "0" || now > dueDate} onClick={() => handleClickOpenAssignmentSubmission( assignment )} size="small">
                                        upload
                                    </Button> */}

                                    {+assignment["submission_exists"] === 0 && <Typography borderRadius="5px" padding="5px" bgcolor={`${palette.error.light}22`} fontSize="12px" sx={{ color: "red" }} >Due date: {showDateInIndianDateFormat( assignment.assignment_due_date, { month: "long" } )}</Typography>}
                                    {+assignment["submission_exists"] !== 0 && <Tooltip title={assignment["assignment_remarks"] ? `Remarks: ${assignment["assignment_remarks"]}` : "No remarks"}>
                                        <Typography variant="subtitle2" borderRadius="5px" padding="5px" bgcolor={`${palette.success.light}22`} fontSize="12px" color="success.main" >Score: {assignment["assignment_marks"] || "Not assigned"}</Typography>
                                    </Tooltip>}
                                    <Box display="flex" gap="10px">
                                        <Tooltip title={+assignment["submission_exists"] !== 0 ? "Already submitted" : now > dueDate ? "Due date passed" : "" ? "Already submitted" : "Upload assignment"}>
                                            <span>
                                                <Button variant="contained" disableElevation color="success" sx={{ textTransform: "capitalize" }} startIcon={Icons.default.UploadFileIcon} disabled={assignment["submission_exists"] !== "0" || now > dueDate} onClick={( e ) => handleClickOpenAssignmentSubmission( assignment )} >
                                                    Upload
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </Box>
                        </Card>
                    )
                } )}
            </Box>}
            {!loading && UserStore.getUser.user_role !== 'STUDENT' && ( AssignmentStore?.getSubjectAssignment || [] ).filter( assignmentFilterFunc ).length > 0 && <Box maxHeight="500px" overflow="auto" flexGrow={1} className="custom-scrollbar" gap="20px" padding="0 20px 20px 20px">
                <Box borderRadius="5px" overflow="hidden" border={border[1]}>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHeader columns={columns} />
                            <TableBody>
                                {AssignmentStore?.getSubjectAssignment.filter( assignmentFilterFunc ).map( ( assignment, aindex ) => {
                                    let accessGroups = []
                                    for ( const groupId of assignment.assignment_batch_id?.split( "," ) ) {
                                        const access = UserStore.getUsersAllBatchesAndSectionList?.find( i => ( i.batch_assignment_id === groupId || `${i.section_assignment_id}-${i.section_short_name}` === groupId ) )
                                        let title = ''
                                        if ( access?.section_assignment_id ) {
                                            title = `${access.course_branch_short_name ? access.course_branch_short_name + "," : ""} ${access?.current_year ? `year: ${access?.current_year},` : ""} ${access.current_sem ? `Sem: ${access.current_sem},` : ""} Sec: ${access.section_short_name}`
                                        } else if ( access?.batch_assignment_id )
                                            title = `${access.course_name_short ? access.course_name_short + "," : ""} ${access?.current_year ? `year: ${access?.current_year},` : ""} ${access.current_sem ? `Sem: ${access.current_sem},` : ""} Batch: ${access.batch_short_name}`
                                        accessGroups.push( title )
                                    }
                                    return (
                                        <TableRow className='table-row' key={assignment.assignment_id}>
                                            <TableCell onClick={() => handleClickOpen( assignment )} sx={{ color: table.color, padding: columns[0].padding || "10px 20px" }}><strong>{assignment.assignment_name}</strong></TableCell>
                                            <TableCell onClick={() => handleClickOpen( assignment )} sx={{ color: table.color, padding: columns[1].padding || "10px 20px" }}><strong>{showDateInIndianDateFormat( assignment.assignment_due_date )}</strong></TableCell>
                                            <TableCell onClick={() => handleClickOpen( assignment )} sx={{ color: table.color, padding: columns[2].padding || "10px 20px" }}>
                                                <Box display="flex" flexDirection="column" gap="5px" >
                                                    {accessGroups?.map( g => ( <strong key={g}>{g}</strong> ) )}
                                                </Box>
                                            </TableCell>
                                            <TableCell onClick={() => handleClickOpen( assignment )} align="center" sx={{ color: table.color, padding: columns[3].padding || "10px 20px" }}><strong>{assignment.submission_exists}</strong></TableCell>
                                            <TableCell sx={{ color: table.color, padding: columns[4].padding || "10px 20px" }}>
                                                <Box display="flex" gap="10px" alignItems="center" justifyContent="flex-end">
                                                    <Button size="small" startIcon={Icons.default.AnalyticsIconOutlined} onClick={() => handleClickOpenAssignmentSubmission( assignment )} color="secondary" variant="outlined">
                                                        Evaluate
                                                    </Button>
                                                    <Box>
                                                        <IconButton size="small" onClick={() => { AssignmentStore.setUpdateStatus( assignment, true ); setView( 'update' ) }} color="secondary">
                                                            {Icons.default.EditIconOutlined}
                                                        </IconButton>
                                                        <IconButton onClick={() => openDeleteDialog( assignment.assignment_id, assignment.assignment_name )} size="small" color="error">
                                                            {Icons.default.DeleteIconOutlined}
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                } )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>}
            {!loading && ( AssignmentStore?.getSubjectAssignment || [] ).filter( assignmentFilterFunc ).length === 0 && <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="center" gap="20px" alignItems="center" padding="20px">
                <img src={noAssignmentsImg} width="200px" alt="No assignments" />
                <Typography variant='subtitle2'>No assignments in <strong> <em>{assignmentFilter}</em> </strong> section!</Typography>
                {UserStore.getUser.user_role !== 'STUDENT' && <Button onClick={() => setView( 'create' )} color='greyed' startIcon={Icons.default.AddIcon} variant="outlined" >Create one</Button>}
            </Box>}
            {loading && <DataLoadingSpinner waitingMessage="Loading subject assignments please wait..." />}
        </Box>
    )
} )

export default SubjectAssignments

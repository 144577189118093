import { Box, Paper, Typography, Tooltip, useTheme, Card, Button } from "@mui/material"
import SubmitAssignmentDialog from "../../components/user/SubmitAssignmentDialog"
import { Icons, showDateInIndianDateFormat } from "../../utils/utilities"
import DataLoadingSpinner from "../../components/common/DataLoadingSpinner"
import { useAssignmentStore } from "../../store/assignment.store"
import noAssignmentImg from '../../assets/noassignment.svg'
import React, { useEffect, useState } from "react"
import CustomSearchBar from "../../components/common/CustomSearchBar"
import { useSearchParams } from "react-router-dom"
import AssignmentViewer from "../../components/common/AssignmentViewer"
import { observer } from "mobx-react"

const Assignment = observer( () => {

    const AssignmentStore = useAssignmentStore()

    const [loaded, setLoaded] = useState( false )

    const [openAssignmentSubmission, setOpenAssignmentSubmission] = useState( false )

    const [selectedValue, setSelectedValue] = useState( {} )
    const [selectedAssignmentData, setSelectedAssignmentData] = useState( {} )

    const [searchParams, setSearchParams] = useSearchParams( { open: false, selectedAssignmentId: "", assignmentFilter: "due", searchText: "" } )
    const assignmentFilter = searchParams.get( 'assignmentFilter' ) || "due"
    const selectedAssignmentId = searchParams.get( 'selectedAssignmentId' ) || ""
    const searchText = searchParams.get( 'searchText' ) || ""
    const open = searchParams.get( 'open' ) === 'true'

    const setSearchParamValue = ( key, val ) => {
        setSearchParams( prev => {
            prev.set( key, val )
            return prev
        }, { replace: true } )
    }

    const { palette, border } = useTheme()

    const getAssignments = async () => {
        if ( AssignmentStore.getErrors.assignments )
            await AssignmentStore.fetchAssignmentByStudent()
        setLoaded( true )
    }

    useEffect( () => {
        const getAssignments = async () => {
            if ( AssignmentStore.getErrors.assignments )
                await AssignmentStore.fetchAssignmentByStudent()

            if ( selectedAssignmentId && Number.isInteger( parseInt( selectedAssignmentId ) ) ) {
                setSelectedValue( AssignmentStore.getAssignment.find( assignment => +assignment.assignment_id === +selectedAssignmentId ) )
                setSearchParams( prev => {
                    const newVals = new URLSearchParams( prev )
                    newVals.set( 'open', true )
                    return newVals
                }, { replace: true } )
            } else {
                setSearchParams( prev => {
                    const newVals = new URLSearchParams( prev )
                    newVals.set( 'open', false )
                    return newVals
                }, { replace: true } )
            }
            setLoaded( true )
        }
        getAssignments()
    }, [AssignmentStore, setSearchParams, selectedAssignmentId] )

    const handleClickOpen = ( value ) => {
        setSelectedValue( value )
        setSearchParams( prev => {
            const newVals = new URLSearchParams( prev )
            newVals.set( 'open', false )
            newVals.set( 'selectedAssignmentId', value.assignment_id )
            return newVals
        }, { replace: true } )
    }

    const handleClose = ( value ) => {
        setSearchParams( prev => {
            const newVals = new URLSearchParams( prev )
            newVals.set( 'open', false )
            newVals.set( 'selectedAssignmentId', '' )
            return newVals
        }, { replace: true } )
        setSelectedValue( value )
    }

    const handleClickOpenAssignmentSubmission = ( value ) => {
        setSelectedAssignmentData( value )
        setOpenAssignmentSubmission( true )
    }

    const handleCloseAssignmentSubmission = ( value ) => {
        setOpenAssignmentSubmission( false )
        setSelectedAssignmentData( value )
    }

    const assignmentFilterFunc = ( item ) => {
        if ( assignmentFilter === "all" ) return true && item["assignment_name"]?.toLowerCase()?.indexOf( searchText?.toLowerCase() ) > -1
        if ( assignmentFilter === "due" ) {
            let dueDate = new Date( item.assignment_due_date )
            dueDate = new Date( dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate() + 1 )
            return +item.submission_exists === 0 && dueDate > new Date() &&
                item["assignment_name"]?.toLowerCase()?.indexOf( searchText?.toLowerCase() ) > -1
        }
        if ( assignmentFilter === "completed" ) {
            let dueDate = new Date( item.assignment_due_date )
            dueDate = new Date( dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate() + 1 )
            return ( item.submission_exists > 0 || dueDate < new Date() )
                && item["assignment_name"]?.toLowerCase()?.indexOf( searchText?.toLowerCase() ) > -1
        }
        return true
    }


    return (
        <Box padding="20px" flexGrow={1} overflow="auto" minWidth="0" className="main">
            <Paper sx={{ overflow: "hidden" }}>
                <Box>
                    <Box sx={{
                        background: `linear-gradient(322.12deg, #F3C400 -21.29%, #E25A26 124.22%)`
                    }} color="white" padding="20px">
                        <Typography variant="h5">Assignments</Typography>
                        <Typography variant="body2">View all your assignments here</Typography>
                    </Box>
                    {/* <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} /> */}
                    <AssignmentViewer selectedValue={selectedValue} open={open} loaded={loaded} onClose={handleClose} />
                    <SubmitAssignmentDialog getAssignments={getAssignments} selectedValue={selectedAssignmentData} open={openAssignmentSubmission} onClose={handleCloseAssignmentSubmission} />
                    {( AssignmentStore.getAssignment.length !== 0 && loaded ) &&
                        <Box padding="20px">
                            {loaded && ( AssignmentStore?.getAssignment || [] ).length > 0 && <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Box borderRadius="5px" border={'dark' && `1px solid ${palette.borderColor}`} display="flex" gap="10px" height="40px" padding="5px">
                                    {/* <Typography width="100px" display="flex" alignItems="center" justifyContent="center" textAlign="center" onClick={() => setSearchParamValue( 'assignmentFilter', 'all' )} sx={{ color: assignmentFilter === "all" ? `${palette.primary.contrastText}` : 'inherit', cursor: "pointer", borderRadius: "3px", "&:hover": { background: assignmentFilter !== "all" && palette.rowHover } }} padding="3px" bgcolor={assignmentFilter === "all" ? `${palette.primary.main}` : palette.common.default} variant="subtitle2">All</Typography> */}
                                    <Typography width="100px" display="flex" alignItems="center" justifyContent="center" textAlign="center" onClick={() => setSearchParamValue( 'assignmentFilter', 'due' )} sx={{ color: assignmentFilter === "due" ? `${palette.primary.contrastText}` : 'inherit', cursor: "pointer", borderRadius: "3px", "&:hover": { background: assignmentFilter !== "due" && palette.rowHover } }} padding="3px" bgcolor={assignmentFilter === "due" ? `${palette.primary.main}` : palette.common.default} variant="subtitle2">Due</Typography>
                                    <Typography width="100px" display="flex" alignItems="center" justifyContent="center" textAlign="center" onClick={() => setSearchParamValue( 'assignmentFilter', 'completed' )} sx={{ color: assignmentFilter === "completed" ? `${palette.primary.contrastText}` : 'inherit', cursor: "pointer", borderRadius: "3px", "&:hover": { background: assignmentFilter !== "completed" && palette.rowHover } }} padding="3px" bgcolor={assignmentFilter === "completed" ? `${palette.primary.main}` : palette.common.default} variant="subtitle2">Completed</Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between" gap="20px" padding="10px 20px">
                                    <CustomSearchBar border='1px solid #d3d3d3' value={searchText} onChange={e => {
                                        setSearchParamValue( 'searchText', e.target.value )
                                    }} placeholder='Search assignments...' />
                                </Box>
                            </Box>}
                            <Box display="flex" marginTop="20px" flexWrap="wrap" gap="20px">
                                {
                                    loaded && AssignmentStore.getAssignment.filter( assignmentFilterFunc ).map( ( assignment, k ) => {
                                        let dueDate = new Date( assignment.assignment_due_date )
                                        dueDate = new Date( dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate() + 1 )
                                        const now = new Date()

                                        return (

                                            <Card sx={{ border: border[1] }} key={assignment.assignment_id}>
                                                <Box width="300px" borderRadius="5px" sx={{ cursor: "pointer", "&:hover": { boxShadow: `1px 0 5px 1px rgba(0,0,0,0.1)` } }} overflow="hidden">
                                                    <Box onClick={() => handleClickOpen( assignment )} borderBottom={`1px solid ${palette.borderColor}`} display="flex" alignItems="flex-start" gap="20px" padding="20px">
                                                        <Typography color={palette.common.bg} sx={{ stroke: "red" }} >{Icons.small.Subject}</Typography>
                                                        <Box flexGrow={1} overflow="hidden">
                                                            <Typography variant='h6' fontWeight="900" width="100%" noWrap fontSize="14px">{assignment.assignment_name}</Typography>
                                                            <Typography color="GrayText" variant='subtitle1' fontSize="12px" width="100%" noWrap >{assignment.author_name}</Typography>
                                                            {assignment?.assignment_subject && <Typography variant='subtitle1' width="100%" noWrap fontSize="14px">{`${assignment?.assignment_subject?.subject_name} ${assignment?.assignment_subject?.subject_code}`}</Typography>}
                                                            <Typography width="100%" variant='subtitle1' noWrap fontSize="12px">Created On: {showDateInIndianDateFormat( assignment.assignment_created_at, { month: "short" } )}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box padding="10px" paddingLeft="20px" display="flex" justifyContent="space-between" alignItems="center">
                                                        {+assignment["submission_exists"] === 0 && <Typography borderRadius="5px" padding="5px" bgcolor={`${palette.error.light}22`} fontSize="12px" sx={{ color: "red" }} >Due date: {showDateInIndianDateFormat( assignment.assignment_due_date, { month: "short" }, 0 )}</Typography>}
                                                        {+assignment["submission_exists"] !== 0 && <Tooltip title={assignment["assignment_remarks"] ? `Remarks: ${assignment["assignment_remarks"]}` : "No remarks"}>
                                                            <Typography variant="subtitle2" borderRadius="5px" padding="5px" bgcolor={`${palette.success.light}22`} fontSize="12px" color="success.main" >Score: {assignment["assignment_marks"] || "Not assigned"}</Typography>
                                                        </Tooltip>}
                                                        <Box display="flex" gap="10px">
                                                            <Tooltip title={+assignment["submission_exists"] !== 0 ? "Already submitted" : now > dueDate ? "Due date passed" : "" ? "Already submitted" : "Upload assignment"}>
                                                                <span>
                                                                    <Button variant="contained" disableElevation color="success" sx={{ textTransform: "capitalize" }} startIcon={Icons.default.UploadFileIcon} disabled={assignment["submission_exists"] !== "0" || now > dueDate} onClick={( e ) => handleClickOpenAssignmentSubmission( assignment )} >
                                                                        Upload
                                                                    </Button>
                                                                </span>
                                                            </Tooltip>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Card>

                                        )
                                    } )
                                }
                                {loaded && AssignmentStore.getAssignment.filter( assignmentFilterFunc ).length === 0 && <Box margin="10px" borderRadius="5px" padding="20px" display="flex" alignItems="center" justifyContent="center" flexGrow={1} flexDirection="column" bgcolor={palette.contentBg}>
                                    <img src={noAssignmentImg} width="200px" className="img-fluid" alt="No Assignments Just Relax" />
                                    <Typography variant="subtitle2" color="textSecondary" marginTop="10px">No Assignment in <strong><em>{assignmentFilter}</em></strong> section
                                    </Typography>
                                </Box>}
                            </Box>
                        </Box>}
                    {
                        !loaded && <DataLoadingSpinner waitingMessage="Loading assignments..." />
                    }
                </Box>
                {
                    ( ( AssignmentStore.getAssignment.length === 0 && loaded ) ? <Box margin="10px" borderRadius="5px" padding="20px" display="flex" alignItems="center" justifyContent="center" flexDirection="column" bgcolor={palette.contentBg}>
                        <img src={noAssignmentImg} width="200px" className="img-fluid" alt="No Assignments Just Relax" />
                        <Typography variant="subtitle2" color="textSecondary" marginTop="10px">No Assignment Just Relax!
                        </Typography>
                    </Box> : '' )
                }
            </Paper>
        </Box>
    )
} )



export default Assignment
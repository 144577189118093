import { Box, Button, CircularProgress, Dialog, DialogContent, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { useDiscussionForum } from '../../store/discussionforum.store'
import { Icons } from '../../utils/utilities'
import { toast } from 'react-toastify'
import { Form, Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import api from '../../service/api'
import *  as Yup from 'yup'
import CustomWysiwyg from '../common/CustomWysiwyg'
import { discussionAttachmentUploader, onDiscussionFilePasteOrDrop } from '../../utils/custom-editor-utilities'
import { convert } from 'html-to-text'

let filesList = [], editorRef

const filePasteOrDropHandler = async ( file, mime ) => {
    const urlDetails = await onDiscussionFilePasteOrDrop( file, mime )
    filesList.push( urlDetails.src )
    return urlDetails
}

const handleFileInput = async ( type ) => {
    const input = document.createElement( 'input' )

    input.setAttribute( 'type', 'file' )
    input.setAttribute( 'accept', type === 'image' ? 'image/*' : "video/*" )
    input.click()

    input.onchange = async () => {
        var file = input.files[0]
        // var fileName = `${Date.now()}_${file.name}`
        if ( type === 'image' && !file.type?.includes( 'image' ) ) {
            toast( "Invalid image format!" )
            return ''
        }
        if ( type === 'video' && !file.type?.includes( 'video' ) ) {
            toast( "Invalid video format!" )
            return ''
        }


        try {
            if ( editorRef.current ) {
                var url = await discussionAttachmentUploader( type, file )
                filesList.push( url )
                if ( url ) {
                    const range = editorRef.current.getSelection()
                    editorRef.current.insertEmbed( range.index, type, url )
                    editorRef.current.setSelection( range.index + 1 )
                    setTimeout( () => {
                        editorRef.current.focus()
                    }, 10 )
                }
            }

        } catch ( err ) {
            console.log( err )
        }
    }
}

const modules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ size: ['normal', 'small', 'large'] }],
            [{ align: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image', 'video'],
            ['clean'],
            ['code-block'],
        ],
        handlers: {
            image: async () => await handleFileInput( 'image' ),
            video: async () => await handleFileInput( 'video' )
        }
    }
}
function CommentEditDialog( { comment, setState, state, currentIndex, commentPos } ) {

    const [updating, setUpdating] = useState( false )
    const [existingFilesList, setExistingFilesList] = useState( [] )

    const DiscussionForumStore = useDiscussionForum()

    const { palette } = useTheme()

    editorRef = useRef()

    const initialValues = {
        comment_content: comment.comment_content || "",
    }

    const validationSchema = Yup.object().shape( {} )


    const handleClose = ( reset ) => {
        reset()
        setState( false )
    }

    const deleteFileFromSpaces = async ( url ) => {
        try {
            await api.discussionForum.deleteFile( { url } )
            const fileUrlIndex = filesList.indexOf( url )
            if ( fileUrlIndex > -1 )
                filesList.splice( fileUrlIndex, 1 )
        } catch ( err ) {

        }
    }

    const handleCommentValueChange = ( val, setFieldValue, previousValue ) => {
        setFieldValue( 'comment_content', val )
        // if ( val.length !== previousValue.length ) {
        console.log( filesList, val )
        for ( let i = 0; i < filesList.length; i++ ) {
            const url = filesList[i]
            if ( !val.includes( url ) ) {
                console.log( "Deleted file :", url )
                deleteFileFromSpaces( decodeURIComponent( url ) )
            }
        }
        // }
    }

    const submitChanges = async ( values, { resetForm } ) => {
        setUpdating( true )
        const val = values.comment_content?.trim()?.replace( /\s+/g, '' )
        if ( val !== "" && convert( val )?.trim() !== "" && val?.trim()?.replace( /\s/gi, "" ) !== `<p></p>` ) {

            const success = await DiscussionForumStore.updateComment( comment._id, values, commentPos )
            if ( success ) {
                try {
                    await Promise.all( [...filesList, ...existingFilesList].map( file => {
                        if ( !val.includes( file ) ) {
                            return api.discussionForum.deleteFile( { url: file } )
                        } else return ""
                    } ) )
                } catch ( fileDeleteErr ) {
                    console.log( fileDeleteErr )
                }
                handleClose( resetForm )
            }
        } else {
            toast( "Please write a comment before submitting" )
        }
        setUpdating( false )
    }

    useEffect( () => {
        try {
            const re = /https:\/\/content-ai\.sgp1\.(cdn\.)?digitaloceanspaces\.com\/(temp\/)?discussions\/(images|videos)\/[a-zA-Z0-9@%()_-]+\.\w+\/?/gm
            setExistingFilesList( comment.comment_content.match( re ) || [] )
            console.log( comment.comment_content.match( re ) || [] )

        } catch ( err ) { }
    }, [comment] )

    return (
        <Dialog open={state} PaperProps={{ sx: { background: palette.form.formCardBg, width: "85%", maxWidth: "750px" } }}>
            <DialogContent sx={{ padding: 0 }} >
                <Box display="flex" height="100%" flexDirection="column">
                    <Formik initialValues={initialValues} onSubmit={submitChanges} validationSchema={validationSchema} >
                        {( { values, setFieldValue, resetForm } ) => ( <Box >
                            <Box borderBottom="1px solid #d3d3d3" padding="10px 20px" display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="subtitle2" fontSize="16px">Edit: <em>{comment._id.substring( 0, 6 )}</em> </Typography>
                                <Tooltip title="Cancel comment">
                                    <IconButton onClick={() => handleClose( resetForm )}>{Icons.default.CloseIcon}</IconButton>
                                </Tooltip>
                            </Box>
                            <Box margin="20px" marginTop="10px" borderRadius="5px" >
                                <Form>
                                    <CustomWysiwyg height='150px' onChange={val => handleCommentValueChange( val, setFieldValue, values.comment_content )} filePasteOrDropHandler={filePasteOrDropHandler} setParentEditorRef={( refVal ) => editorRef.current = refVal?.current} value={values.comment_content} modules={modules} placeholder='Add your comment here...' />
                                    <Box marginTop="20px">
                                        <Button disabled={updating} size="large" type="submit" startIcon={updating ? <CircularProgress size={14} /> : Icons.default.CheckIcon} variant='contained' disableElevation sx={{ textTransform: "capitalize" }}>{updating ? "Updating..." : "Update comment"}</Button>
                                    </Box>
                                </Form>
                            </Box>
                        </Box> )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default CommentEditDialog
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Progress from '../common/Progress'
import { Icons } from '../../utils/utilities'

const BranchAcademicProgressStatsDialog = ( { state, setState, data = [], branchDetails } ) => {

    const { palette } = useTheme()

    const [calculatedData, setCalculatedData] = useState( {} )

    useEffect( () => {
        const allData = {}
        allData.totalLength = data.data.length
        allData.totalOptional = data.data[0].optionalChecklistItemsCount * allData.totalLength
        allData.totalRequired = data.totalRequired * allData.totalLength
        allData.satisfiedRequiredCount = data.data.reduce( ( sum, currentValue ) => sum + currentValue.requiredCompleted, 0 )
        allData.satisfiedOptionalCount = data.data.reduce( ( sum, currentValue ) => sum + currentValue.optionalCompleted, 0 )
        allData.requiredProgress = allData.totalRequired > 0 ? ( ( allData.satisfiedRequiredCount * 100 ) / allData.totalRequired ).toFixed( 1 ) : 0
        allData.totalProgress = ( allData.totalRequired + allData.totalOptional ) > 0 ? ( ( ( allData.satisfiedRequiredCount + allData.satisfiedOptionalCount ) * 100 ) / ( allData.totalRequired + allData.totalOptional ) ).toFixed( 1 ) : 0
        allData.totalNumberOfStudentsSatisfiedRequirements = data.data.reduce( ( sum, currentValue ) => sum + ( currentValue.pendingCount === 0 && allData.totalRequired > 0 ? 1 : 0 ), 0 )
        console.log( allData )

        setCalculatedData( allData )

    }, [data] )

    return (
        <Dialog PaperProps={{ sx: { width: "85%", maxWidth: "650px" } }} open={state}>
            <DialogTitle sx={{ borderBottom: "1px solid #d3d3d3" }}>
                <Box display="flex" gap="20px" alignItems="center" justifyContent="space-between">
                    <Box>
                        <Typography variant='h6'>{branchDetails.branch.institute_name_short} - {branchDetails.branch.course_branch_name}</Typography>
                        <Typography variant='subtitle2'>{branchDetails.academicYear}</Typography>
                    </Box>
                    <IconButton onClick={() => setState( false )}>{Icons.default.CloseIcon}</IconButton>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ padding: "0" }}>
                <Box >
                    <Box flexWrap="wrap" borderBottom="1px solid #d3d3d3" padding="20px" display="flex" justifyContent="center" alignItems="center" gap="20px">
                        <Box display="flex" flexDirection="column" alignItems="center" gap="20px">
                            <Typography variant="subtitle2">Total progress</Typography>
                            <Progress progress={parseFloat( calculatedData.totalProgress ).toFixed( 1 )} size={75} color={palette.secondary.main} />
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="center" gap="20px">
                            <Typography variant="subtitle2">Progress of required</Typography>
                            <Progress progress={parseFloat( calculatedData.requiredProgress ).toFixed( 1 )} size={75} color={palette.secondary.main} />
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="center" gap="20px">
                            <Typography variant="subtitle2">Completed Std. / total Std.</Typography>
                            <Progress progress={( ( calculatedData.totalNumberOfStudentsSatisfiedRequirements * 100 ) / calculatedData.totalLength ).toFixed( 1 )} size={75} color={palette.secondary.main} />
                        </Box>
                    </Box>
                    <Box padding="20px">
                        <Typography variant='subtitle2'>Total No. of students: <b>{calculatedData.totalLength}</b> </Typography>
                        <Typography variant='subtitle2'>Total No. of students satisfied requirements: <b>{calculatedData.totalNumberOfStudentsSatisfiedRequirements}</b> </Typography>
                        <Typography variant='subtitle2'>Total No. of students yet to satisfy requirements: <b>{calculatedData.totalLength - calculatedData.totalNumberOfStudentsSatisfiedRequirements}</b> </Typography>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default BranchAcademicProgressStatsDialog

import { Box, Button, Checkbox, CircularProgress, Dialog, FormControl, FormControlLabel, IconButton, MenuItem, Popover, Radio, RadioGroup, Select, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Icons, showDateInIndianDateFormat } from '../../utils/utilities'
import { InfoOutlined, Percent } from '@mui/icons-material'
import CustomSwitch from '../common/CustomSwitch'
import api from '../../service/api'
import { toast } from 'react-toastify'
import { useAdmissionExamStore } from '../../store/admission-exam.store'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { formatDateTimeForDB } from '../../utils/exam-utilities'
import { useOrganizationStore } from '../../store/organization.store'
import DataLoadingSpinner from '../common/DataLoadingSpinner'

const hours = ["00", "01", "02", "03", "04", "05", "06"]
const minutes = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"]

const EditAdmissionExamsDialog = ( { state, setState, examDetails, currentExamCutoffs, sections } ) => {

    const [saving, setSaving] = useState( false )
    const [academicYears, setAcademicYears] = useState( [] )
    const [randomizeInfo, setRandomizeInfo] = useState( false )
    const [loading, setLoading] = useState( true )
    const [studentBranches, setStudentBranches] = useState( [] )
    const [filteredBranches, setFilteredBranches] = useState( [] )
    const [restrictMobilePopperAnchorEl, setRestrictMobilePopperAnchorEl] = useState( null )
    const [cutoffs, setCutoffs] = useState( examDetails.cutoff_details || { totalCutoff: 0 } )
    const [receiveWorksheetPopperAnchorEl, setReceiveWorksheetPopperAnchorEl] = useState( null )
    const [enableMonitoringPopperAnchorEl, setEnableMonitoringPopperAnchorEl] = useState( null )
    const [sectionsCutoffEnabled, setSectionsCutoffEnabled] = useState( !Boolean( examDetails.cutoff_details?.totalCutoff >= 0 ) )
    const [durationHours, setDurationHours] = useState( examDetails?.exam_duration ? examDetails?.exam_duration?.split( ":" )[0] : "00" )
    const [durationMinutes, setDurationMinutes] = useState( examDetails?.exam_duration ? examDetails?.exam_duration?.split( ":" )[1] : "00" )

    const previousSectionsCutoffValue = useRef( examDetails.cutoff_details || { totalCutoff: 0 } )

    const AdmissionExamStore = useAdmissionExamStore()
    const OrganizationStore = useOrganizationStore()

    const { palette, border, shadows } = useTheme()

    const { id } = useParams()


    const initialValues = {
        title: examDetails.exam_name,
        startDateTime: new Date( `${showDateInIndianDateFormat( examDetails.exam_start_date, { regionCode: "en-US" }, { minutes: 30, hours: 5, seconds: 1 } )} ${examDetails.exam_start_time}` ),
        endDateTime: new Date( `2099-01-01` ),
        institute: examDetails.global_exam_access_courses?.includes( 'all' ) ? 'all' : "-",
        accessCourses: examDetails.global_exam_access_courses,
        duration: examDetails.exam_duration,
        maximumAttempt: examDetails.maximum_attempts,
        randomize: examDetails?.enable_randomize || false,
        receiveWorksheet: [true, false].includes( examDetails?.exam_constraints?.receiveWorksheet ) ? examDetails?.exam_constraints?.receiveWorksheet : false,
        enableMonitoring: [true, false].includes( examDetails?.exam_constraints?.enableMonitoring ) ? examDetails?.exam_constraints?.enableMonitoring : true,
        restrictMobileAttempt: [true, false].includes( examDetails?.exam_constraints?.restrictMobileAttempt ) ? examDetails?.exam_constraints?.restrictMobileAttempt : true,
        showScoresAfterFinishingExam: [true, false].includes( examDetails?.exam_constraints?.showScoresAfterFinishingExam ) ? examDetails?.exam_constraints?.showScoresAfterFinishingExam : false,
        answerAllQuestions: [true, false].includes( examDetails?.exam_constraints?.answerAllQuestions ) ? examDetails?.exam_constraints?.answerAllQuestions : false,
        cutoffs: examDetails.cutoff_details || { totalCutoff: 0 },
        attemptsRule: examDetails?.exam_constraints?.attemptsRule || "ONCE",
        whenToStart: examDetails?.exam_constraints?.whenToStart || 'approval',
        academicYear: examDetails?.exam_constraints?.academicYear || '',
        showDetailedViewOfTheResults: [true, false].includes( examDetails?.exam_constraints?.showDetailedViewOfTheResults ) ? examDetails?.exam_constraints?.showDetailedViewOfTheResults : false
    }

    const validationSchema = Yup.object().shape( {
        title: Yup.string().required( "Exam title is required." ),
        academicYear: Yup.string().required( "Academic year is required." ),
        accessCourses: Yup.array().min( 1, "Please select atleast one course" ),
        duration: Yup.string().nullable().test( "CHECK_DURATION", "Invalid time format hours: HH and minutes: MM", ( value ) => {
            if ( value && value !== '00:00' )
                return true
            return false
        } ).test( "CHECK_FOR_CORRECT_QUIZ_DURATION", "Invalid duration or duration exceeds the set start and end time window", ( val, { parent } ) => {
            if ( val ) {
                const splitted = val?.split( ":" )
                const startDate = new Date( parent.startDateTime )
                const endDate = new Date( parent.endDateTime )
                const selectedDuration = ( endDate - startDate ) / 1000

                if ( val && ( !val.match( /^\d\d:\d\d$/ ) || +splitted[1] > 60 || +splitted[0] > 6 ) ) {
                    return false
                } else {
                    if ( val === "00:00" )
                        return false
                    const timeInSeconds = ( +splitted[0] * 60 * 60 ) + splitted[1] * 60
                    if ( typeof selectedDuration === 'number' && selectedDuration >= timeInSeconds ) {
                        return true
                    }
                    else return false
                }
            } else
                return true
        }
        ),
        startDateTime: Yup.date( 'Please enter a valid start date' ),
        maximumAttempt: Yup.number().required( "This field is required!" ).min( 1, "At least 1 attempt must be given" ),
    } )

    const handleInstituteChange = ( val, setValue, values ) => {
        const filtered = []
        const selected = {}
        for ( const branch of studentBranches ) {
            if ( !selected[branch.course_branch_short_name] ) {
                filtered.push( branch )
                selected[branch.course_branch_short_name] = true
            }
        }
        setFilteredBranches( filtered )
        setValue( 'institute', val )
        if ( val === 'all' )
            setValue( 'accessCourses', ['all'] )
        if ( values.academicYear === '' )
            setValue( 'academicYear', academicYears.at( -1 ).ac_year )
    }

    const handleCourseSelect = ( course, setValue ) => {
        const accessGroups = typeof course === 'string' ? course.split( "," ) : course
        const allBranchesIncluded = accessGroups?.length > 1 && accessGroups?.indexOf( 'all' ) >= 0
        if ( allBranchesIncluded )
            accessGroups?.splice( accessGroups?.indexOf( 'all' ), 1 )
        setValue( 'accessCourses', accessGroups )
    }

    const handleAcademicYearSelect = ( value, setValue ) => {
        setValue( 'academicYear', value )
    }

    const handleTotalCutoffChange = ( e, setFieldValue ) => {
        const newValue = { totalCutoff: e.target.value }
        setCutoffs( newValue )
        setFieldValue( 'cutoffs', newValue )
    }

    const handleSectionCutoffChange = ( val, section, setFieldValue ) => {
        setCutoffs( prev => {
            const newVal = { ...prev }
            newVal[section] = +val
            setFieldValue( 'cutoffs', newVal )
            console.log( newVal )
            return newVal
        } )
    }

    const handleCutoffMethodChange = ( val, setFieldValue ) => {
        if ( val ) {
            setCutoffs( prev => {
                const newSectionsCutOff = {}
                for ( let i = 0; i < sections.length; i++ ) {
                    const section = sections[i]
                    newSectionsCutOff[section] = previousSectionsCutoffValue?.current[section] || 0
                }
                setFieldValue( 'cutoffs', newSectionsCutOff )
                return newSectionsCutOff
            } )
        } else {
            setCutoffs( prev => {
                if ( !prev.totalCutoff ) {
                    previousSectionsCutoffValue.current = prev
                    setFieldValue( 'cutoffs', { totalCutoff: 0 } )
                    return { totalCutoff: 0 }
                }
                return prev
            } )
        }
        setSectionsCutoffEnabled( val )
    }

    const updateAssessment = async ( values ) => {
        setSaving( true )
        try {
            values.sdt = formatDateTimeForDB( values.startDateTime, 'en-IN', '-' )
            values.cutoffs = cutoffs
            await api.admissionExams.updateAdmissionExam( id, values )
            await AdmissionExamStore.fetchExam( id )
            toast( "Exam updated successfully!" )
            setState( false )
        } catch ( err ) {
            toast( err?.response?.data?.message || "Something went wrong while updating the cutoffs!" )
        } finally {
            setSaving( false )
        }
    }

    useEffect( () => {
        const getOrganizationsAndAcademicYears = async () => {
            try {
                if ( OrganizationStore.getOrganization?.length === 0 )
                    await OrganizationStore.fetchOrganization()
                const { data: { data: branches } } = await api.fetchAllBranches()
                const { data: { data: academicYearsList } } = await api.fetchAllAcademicYears()
                setAcademicYears( academicYearsList?.sort( ( a, b ) => a.ac_year_id - b.ac_year_id ) )
                setStudentBranches( branches )
                setLoading( false )
            } catch ( err ) {
                toast( err?.response?.data?.message || "Something went wrong while fatching institutes and departments data!" )
            } finally {
                setLoading( false )
            }

        }
        getOrganizationsAndAcademicYears()
    }, [OrganizationStore] )


    return (
        <Dialog PaperProps={{ className: "custom-scrollbar", sx: { position: "relative", width: "90%", maxWidth: "1024px" } }} open={state}>
            <Box position="sticky" top="0" sx={{ zIndex: "10" }} bgcolor={palette.form.formCardBg} borderBottom={border[1]} padding="10px 20px" display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant='h6'>Edit {examDetails.exam_name}</Typography>
                <IconButton onClick={() => setState( false )}>
                    {Icons.default.CloseIcon}
                </IconButton>
            </Box>
            <Box padding="20px" margin="20px" borderRadius="10px" border={border[1]} bgcolor={palette.contentBg}>

                {!loading && <Formik onSubmit={updateAssessment} initialValues={initialValues} validationSchema={validationSchema}>
                    {( { values, setFieldValue } ) => {
                        return (
                            <Form>
                                <FormControl margin="normal" fullWidth>
                                    <label htmlFor="title"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Exam title * </Typography></label>
                                    <Field placeholder="Eg: Kannada language internal test - 1" as={TextField} id="title" size="small" InputLabelProps={{ sx: { fontSize: "14px !important" } }} fullWidth name='title' />
                                    <Typography fontSize="12px" color="errorMessage.main"><ErrorMessage name='title' /></Typography>
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <label htmlFor="institute"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Select institute</Typography></label>
                                    <Field
                                        sx={{ color: values.institute === "-" && palette.form.placeholder, textTransform: "capitalize", fontSize: "14px" }}
                                        placeholder="Eg: Kannada language internal test - 1" as={Select}
                                        id="institute" size="small" onChange={e => handleInstituteChange( e.target.value, setFieldValue, values )} fullWidth name='institute' >
                                        <MenuItem disabled value="-" sx={{ fontSize: "14px" }}>{loading ? <DataLoadingSpinner padding='0' size={12} waitingMessage="Loading institutes..." /> : "Eg: Acharya institute of Technology (AIT)"}</MenuItem>
                                        <MenuItem sx={{ fontSize: "12px", textTransform: "capitalize" }} value={'all'} key={'key-all'}>All institutes</MenuItem>
                                        {OrganizationStore.getOrganization.map( ( obj, i ) => {
                                            return <MenuItem sx={{ fontSize: "12px", textTransform: "capitalize" }} value={obj.institute_name_short} key={'key-' + i}>{obj.institute_name_short} - {obj.institute_name?.toLowerCase()} </MenuItem>
                                        } )}
                                    </Field>
                                </FormControl>
                                {values.institute !== 'all' && <FormControl margin="normal" fullWidth>
                                    <label htmlFor="courses"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Select exam branches * </Typography></label>
                                    <Field
                                        displayEmpty
                                        renderValue={( selected ) => {
                                            if ( selected.length === 0 ) {
                                                return <Typography fontSize="14px" color={palette.form.placeholder}>Please select the branches here, Eg: CS - computer science</Typography>
                                            }
                                            const selectedBranches = []
                                            for ( const branch of selected ) {
                                                if ( branch !== 'all' ) {
                                                    const branchDetails = studentBranches.find( b => b?.course_branch_short_name === branch )
                                                    selectedBranches.push( `${branchDetails?.course_branch_short_name} - ${branchDetails?.course_branch_name?.toLowerCase()}` )
                                                }
                                            }
                                            return selectedBranches.join( ', ' )
                                        }}
                                        onChange={e => handleCourseSelect( e.target.value, setFieldValue, values.accessCourses )} sx={{ color: values.accessCourses?.length === 0 && palette.form.placeholder, textTransform: "capitalize", fontSize: "14px" }} multiple as={Select} id="courses" size="small" value={values.accessCourses} fullWidth name='access_courses' >
                                        {filteredBranches.filter( b => b.institute_name_short === values.institute ).map( ( branch, i ) => {
                                            return <MenuItem sx={{ fontSize: "12px", textTransform: "capitalize" }} value={branch.course_branch_short_name} key={'key-' + i}> {branch.course_branch_short_name} - {branch.course_branch_name}</MenuItem>
                                        } )}
                                    </Field>
                                    <Typography fontSize="12px" color="errorMessage.main"><ErrorMessage name='accessCourses' /></Typography>
                                </FormControl>}
                                <FormControl margin="normal" fullWidth>
                                    <label htmlFor="academicYear"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Select academic year * </Typography></label>
                                    <Field
                                        displayEmpty
                                        onChange={e => handleAcademicYearSelect( e.target.value, setFieldValue )} sx={{ color: values.academicYear?.length === 0 && palette.form.placeholder, textTransform: "capitalize", fontSize: "14px" }} as={Select} id="academicYear" size="small" value={values.academicYear} fullWidth name='academicYear' >
                                        {academicYears.map( ( academicYear, i ) => {
                                            return <MenuItem sx={{ fontSize: "12px", textTransform: "capitalize" }} value={academicYear.ac_year} key={academicYear.ac_year_id}>{academicYear.ac_year}</MenuItem>
                                        } )}
                                    </Field>
                                    <Typography fontSize="12px" color="errorMessage.main"><ErrorMessage name='academicYear' /></Typography>
                                </FormControl>
                                <FormControl variant='outlined' sx={{ display: "flex" }}>
                                    <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Exam duration</Typography>
                                    <Box display="flex" flexWrap="wrap" gap="10px" alignItems="center">
                                        <FormControl sx={{ maxWidth: "250px" }} fullWidth>
                                            <Select endAdornment={<Typography fontSize="12px" marginRight="20px" sx={{ pointerEvents: "none" }} color="textSecondary">HOURS</Typography>} size="small" onChange={e => { setDurationHours( e.target.value ); setFieldValue( 'duration', `${e.target.value}:${durationMinutes}` ) }} value={durationHours}>
                                                {hours.map( hour => (
                                                    <MenuItem dense sx={{ fontSize: "14px" }} key={hour} value={hour}>{hour}</MenuItem>
                                                ) )}
                                            </Select>
                                        </FormControl >
                                        <FormControl sx={{ maxWidth: "250px" }} fullWidth>
                                            <Select endAdornment={<Typography fontSize="12px" marginRight="20px" sx={{ pointerEvents: "none" }} color="textSecondary">MIN</Typography>} size="small" onChange={e => { setDurationMinutes( e.target.value ); setFieldValue( 'duration', `${durationHours}:${e.target.value}` ) }} value={durationMinutes}>
                                                {minutes.map( min => (
                                                    <MenuItem dense sx={{ fontSize: "14px" }} key={min} value={min}>{min}</MenuItem>
                                                ) )}
                                            </Select>
                                        </FormControl>
                                    </Box>

                                    <Typography variant="subtitle2" >Exam duration: {`${durationHours ? durationHours : 0} hours ${durationMinutes ? durationMinutes : 0} minutes`}</Typography>
                                    <Typography fontSize="12px" color="errorMessage.main" ><ErrorMessage name='duration' /></Typography>
                                </FormControl>

                                {<Box marginTop="15px" position="relative">
                                    <Popover open={Boolean( randomizeInfo )} onClose={e => setRandomizeInfo( null )} anchorEl={randomizeInfo} slotProps={{ paper: { sx: { border: border[1], maxWidth: "300px" } } }}>
                                        <Typography padding="10px" borderBottom="1px solid #d3d3d3" variant="subtitle2" color="secondary">Randomize questions and options?</Typography>
                                        <Typography textAlign="justify" padding="10px" variant='body2'>If selected the questions and their options will be in random order for each individual taking the exam.</Typography>
                                    </Popover>
                                    <Box display="flex" alignItems="center">
                                        <FormControlLabel control={<Checkbox color='primaryDark' checked={values.randomize} onChange={e => { setFieldValue( 'randomize', e.target.checked ) }} />} label="Randamize questions and options?" />
                                        <InfoOutlined sx={{ cursor: "pointer" }} onClick={e => setRandomizeInfo( e.currentTarget )} />
                                    </Box>
                                </Box>}

                                {<Box marginTop="15px" position="relative">
                                    <Popover open={Boolean( receiveWorksheetPopperAnchorEl )} onClose={e => setReceiveWorksheetPopperAnchorEl( null )} anchorEl={receiveWorksheetPopperAnchorEl} slotProps={{ paper: { sx: { border: border[1], maxWidth: "300px" } } }}>
                                        <Typography padding="10px" borderBottom="1px solid #d3d3d3" variant="subtitle2" color="secondary">Receive worksheet after the completion of exam?</Typography>
                                        <Typography textAlign="justify" padding="10px" variant='body2'>If selected the candidates wil be asked to upload a worksheet in PDF format to show how thry have worked the solution for the problems.</Typography>
                                    </Popover>
                                    <Box display="flex" alignItems="center">
                                        <FormControlLabel control={<Checkbox color='primaryDark' checked={values.receiveWorksheet} onChange={e => { setFieldValue( 'receiveWorksheet', e.target.checked ) }} />} label="Receive worksheet after the completion of exam?" />
                                        <InfoOutlined sx={{ cursor: "pointer" }} onClick={e => setReceiveWorksheetPopperAnchorEl( e.currentTarget )} />
                                    </Box>
                                </Box>}

                                {<Box marginTop="15px" position="relative">
                                    <Popover open={Boolean( restrictMobilePopperAnchorEl )} onClose={e => setRestrictMobilePopperAnchorEl( null )} anchorEl={restrictMobilePopperAnchorEl} slotProps={{ paper: { sx: { border: border[1], maxWidth: "300px" } } }}>
                                        <Typography padding="10px" borderBottom="1px solid #d3d3d3" variant="subtitle2" color="secondary">Restrict candidate from taking exam from mobile devices?</Typography>
                                        <Typography textAlign="justify" padding="10px" variant='body2'>If selected the candidates will not be able to attempt this exam on mobiles.</Typography>
                                    </Popover>
                                    <Box display="flex" alignItems="center">
                                        <FormControlLabel control={<Checkbox color='primaryDark' checked={values.restrictMobileAttempt} onChange={e => { setFieldValue( 'restrictMobileAttempt', e.target.checked ) }} />} label="Restrict candidate from taking exams from mobile devices?" />
                                        <InfoOutlined sx={{ cursor: "pointer" }} onClick={e => setRestrictMobilePopperAnchorEl( e.currentTarget )} />
                                    </Box>
                                </Box>}

                                <Box marginTop="15px" display="flex" alignItems="center">
                                    <FormControlLabel control={<Checkbox color='primaryDark' checked={values.showScoresAfterFinishingExam} onChange={e => { setFieldValue( 'showScoresAfterFinishingExam', e.target.checked ) }} />} label="Show scores to candidates after finishing exams?" />
                                </Box>

                                {( values.showScoresAfterFinishingExam === true || values.showScoresAfterFinishingExam === 'true' ) && <Box marginTop="15px" display="flex" alignItems="center">
                                    <FormControlLabel control={<Checkbox color='primaryDark' checked={values.showDetailedViewOfTheResults} onChange={e => { setFieldValue( 'showDetailedViewOfTheResults', e.target.checked ) }} />} label="Show detailed results report after finishing exams?" />
                                </Box>}

                                <Box marginTop="15px" display="flex" alignItems="center">
                                    <FormControlLabel control={<Checkbox color='primaryDark' checked={values.answerAllQuestions} onChange={e => { setFieldValue( 'answerAllQuestions', e.target.checked ) }} />} label="Restrict candidate from finishing the assessment before answering all the questions?" />
                                </Box>

                                {<Box marginTop="15px" position="relative">
                                    <Popover open={Boolean( enableMonitoringPopperAnchorEl )} onClose={e => setEnableMonitoringPopperAnchorEl( null )} anchorEl={enableMonitoringPopperAnchorEl} slotProps={{ paper: { sx: { border: border[1], maxWidth: "300px" } } }}>
                                        <Typography padding="10px" borderBottom="1px solid #d3d3d3" variant="subtitle2" color="secondary">Restrict candidate from moving away from the exam?</Typography>
                                        <Typography textAlign="justify" padding="10px" variant='body2'>Restricts the candidate from moving away from the exam tab, the exam will be finished if candidate tries to move away from the tab after fixed number of times.</Typography>
                                    </Popover>
                                    <Box display="flex" alignItems="center">
                                        <FormControlLabel control={<Checkbox color='primaryDark' checked={values.enableMonitoring} onChange={e => { setFieldValue( 'enableMonitoring', e.target.checked ) }} />} label="Restrict candidate from moving away from the exam?" />
                                        <InfoOutlined sx={{ cursor: "pointer" }} onClick={e => setEnableMonitoringPopperAnchorEl( e.currentTarget )} />
                                    </Box>
                                </Box>}

                                <FormControl margin='normal' fullWidth sx={{ maxWidth: "900px" }} >
                                    <Typography gutterBottom color={palette.labelColor} variant='subtitle2'>When to start exam?</Typography>
                                    <RadioGroup sx={{ gap: "10px" }} row name='whenToStart' value={values.whenToStart} color='primaryDark' onChange={e => { setFieldValue( 'whenToStart', e.target.value ); setFieldValue( 'startDateTime', e.target.value === 'approval' ? new Date() : values.startDateTime ) }} >
                                        <FormControlLabel sx={{ alignItems: "flex-start", bgcolor: values.whenToStart === "approval" ? palette.secondary.light + "22" : palette.form.inputBg, width: "100%", padding: "10px 20px 10px 10px", border: values.whenToStart === "approval" ? `2px dotted ${palette.secondary.main}` : border[1], borderRadius: "5px", '&:hover': { boxShadow: shadows[2] } }} value="approval" control={<Radio size="small" color="primaryDark" />} label={
                                            <Box marginLeft="20px">
                                                <Typography variant='h6' fontSize="16px">Immediately after exam approval</Typography>
                                                <Typography sx={{ fontStyle: "italic" }} variant='body2'>The exam will be available to candidates as soon as the exam is approved by the admin.</Typography>
                                            </Box>
                                        } />
                                        <FormControlLabel sx={{ alignItems: "flex-start", bgcolor: values.whenToStart === "schedule" ? palette.secondary.light + "22" : palette.form.inputBg, width: "100%", padding: "10px 20px 10px 10px", border: values.whenToStart === "schedule" ? `2px dotted ${palette.secondary.main}` : border[1], borderRadius: "5px", '&:hover': { boxShadow: shadows[2] } }} value="schedule" control={<Radio size="small" color="primaryDark" />} label={
                                            <Box marginLeft="20px">
                                                <Typography variant='h6' fontSize="16px">Schedule start date and time.</Typography>
                                                <Typography sx={{ fontStyle: "italic" }} variant='body2'>Exam will start at the specified date and time.</Typography>
                                                {values.whenToStart === 'schedule' && <Box marginTop="10px">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            id="startdatetime"
                                                            sx={{ width: "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                                            minDateTime={dayjs( new Date() )}
                                                            value={dayjs( values.startDateTime )}
                                                            format="DD-MM-YYYY hh:mm A"
                                                            onChange={( e ) => setFieldValue( 'startDateTime', e )}
                                                        />
                                                    </LocalizationProvider>
                                                    <Typography fontSize="12px" color="errorMessage.main"><ErrorMessage name='startDateTime' /></Typography>
                                                </Box>}
                                            </Box>
                                        } />
                                    </RadioGroup>
                                </FormControl>

                                <FormControl margin='normal' fullWidth sx={{ maxWidth: "900px" }} >
                                    <Typography gutterBottom color={palette.labelColor} variant='subtitle2'>Attemp Rule</Typography>
                                    <RadioGroup sx={{ gap: "10px" }} row name='attemptsRule' value={values.attemptsRule} color='primaryDark' onChange={e => setFieldValue( 'attemptsRule', e.target.value )} >
                                        <FormControlLabel sx={{ bgcolor: values.attemptsRule === "ONCE" ? palette.secondary.light + "22" : palette.form.inputBg, alignItems: "flex-start", width: "100%", padding: "10px 20px 10px 10px", border: values.attemptsRule === "ONCE" ? `2px dotted ${palette.secondary.main}` : border[1], borderRadius: "5px", '&:hover': { boxShadow: shadows[2] } }} value="ONCE" control={<Radio size="small" color="primaryDark" />} label={
                                            <Box marginLeft="20px">
                                                <Typography variant='h6' fontSize="16px">Only once</Typography>
                                                <Typography sx={{ fontStyle: "italic" }} variant='body2'>Candidate is only allowed to take exam once.</Typography>
                                            </Box>
                                        } />
                                        <FormControlLabel sx={{ bgcolor: values.attemptsRule === "UNTILLQUALIFY" ? palette.secondary.light + "22" : palette.form.inputBg, alignItems: "flex-start", width: "100%", padding: "10px 20px 10px 10px", border: values.attemptsRule === "UNTILLQUALIFY" ? `2px dotted ${palette.secondary.main}` : border[1], borderRadius: "5px", '&:hover': { boxShadow: shadows[2] } }} value="UNTILLQUALIFY" control={<Radio size="small" color="primaryDark" />} label={
                                            <Box marginLeft="20px">
                                                <Typography variant='h6' fontSize="16px">Untill qualify</Typography>
                                                <Typography sx={{ fontStyle: "italic" }} variant='body2'>The candidate can take exam repeateadly until he/she qualifies.</Typography>
                                            </Box>
                                        } />
                                        <FormControlLabel sx={{ bgcolor: values.attemptsRule === "MAXATTEMPTS" ? palette.secondary.light + "22" : palette.form.inputBg, alignItems: "flex-start", width: "100%", padding: "10px 20px 10px 10px", border: values.attemptsRule === "MAXATTEMPTS" ? `2px dotted ${palette.secondary.main}` : border[1], borderRadius: "5px", '&:hover': { boxShadow: shadows[2] } }} value="MAXATTEMPTS" control={<Radio size="small" color="primaryDark" />} label={
                                            <Box marginLeft="20px">
                                                <Typography variant='h6' fontSize="16px">Maximum Attempts</Typography>
                                                <Typography sx={{ fontStyle: "italic" }} variant='body2'>Candidate can only take exam specified number of times.</Typography>
                                                {values.attemptsRule === 'MAXATTEMPTS' && <Box marginTop="10px">
                                                    <Field as={TextField} name="maximumAttempt" type="number" size="small" inputProps={{ min: 1 }} />
                                                </Box>}
                                            </Box>
                                        } />
                                    </RadioGroup>
                                </FormControl>

                                <Box display="flex" flexDirection="column">
                                    {!sectionsCutoffEnabled && <FormControl margin="normal" sx={{ maxWidth: "450px" }} fullWidth>
                                        <label htmlFor="title"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}>Exam cut-off %</Typography></label>
                                        <TextField onWheel={e => e.target?.blur()} onChange={e => handleTotalCutoffChange( e, setFieldValue )} error={values.cutoffs?.totalCutoff <= 0 || values.cutoffs?.totalCutoff > 100} value={values.cutoffs?.totalCutoff >= 0 ? values.cutoffs?.totalCutoff : ""} disabled={sectionsCutoffEnabled} type="number" InputProps={{ endAdornment: <Percent sx={{ paddingX: "10px" }} fontSize='small' /> }} inputProps={{ min: 0, step: 0.001, max: 100 }} id="cutoff" size="small" InputLabelProps={{ sx: { fontSize: "14px !important" } }} fullWidth name='cutoff' />
                                        <Typography variant='subtitle2' fontSize="12px" >Candidate will only be qualified if cut-off has been reached!</Typography>
                                    </FormControl>}
                                    {sections?.length >= 2 && <FormControlLabel control={<CustomSwitch checked={sectionsCutoffEnabled} onChange={e => handleCutoffMethodChange( e.target.checked, setFieldValue )} />} label="Use section-wise cut-off." />}
                                    {sectionsCutoffEnabled && sections.map( ( section, index ) => (
                                        <FormControl margin="normal" key={index} sx={{ maxWidth: "450px" }} fullWidth>
                                            <label htmlFor="title"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}>{section} cut-off %</Typography></label>
                                            <TextField onWheel={e => e.target?.blur()} onChange={e => handleSectionCutoffChange( e.target.value, section, setFieldValue )} error={!values.cutoffs[section] || values.cutoffs[section] > 100 || values.cutoffs[section] < 0} value={values.cutoffs[section]} type="number" InputProps={{ endAdornment: <Percent sx={{ paddingX: "10px" }} fontSize='small' /> }} inputProps={{ min: 0, step: 0.001, max: 100 }} id="cutoff" size="small" InputLabelProps={{ sx: { fontSize: "14px !important" } }} fullWidth name='cutoff' />
                                            <Typography variant='subtitle2' fontSize="12px" >Candidate will only be qualified if cut-off has been reached!</Typography>
                                        </FormControl>
                                    ) )}
                                </Box>
                                <Box marginTop="40px" display="flex" gap="10px" alignItems="center" flexWrap="wrap">
                                    <Button disabled={saving} sx={{ textTransform: "capitalize" }} variant='contained' type='submit' color="success" disableElevation startIcon={saving ? <CircularProgress size={14} /> : Icons.default.CheckIcon}>Save</Button>
                                    <Button disabled={saving} onClick={() => setState( false )} sx={{ textTransform: "capitalize" }} variant='contained' color="error" disableElevation startIcon={Icons.default.CloseIcon}>Cancel</Button>
                                </Box>
                            </Form>
                        )
                    }}
                </Formik>}
                {loading && <DataLoadingSpinner waitingMessage="Loading assessmenet details..." />}
            </Box>
        </Dialog>
    )
}

export default EditAdmissionExamsDialog

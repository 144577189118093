import { Paper, Typography, Box, useTheme } from '@mui/material'

const AnalyticsCountCard = ( { icon, entity, count } ) => {

    const { border } = useTheme()

    return (
        <Paper sx={{ border: border[1] }}>
            <Box gap="10px" display="flex" flexDirection="column" padding="10px 20px" justifyContent="center" spacing={0} >
                <Box gap="10px" display="flex" alignItems="center">
                    {icon}
                    <Typography variant="subtitle2">
                        {entity}
                    </Typography></Box>
                <Box>
                    <Typography fontWeight="bold" variant="h4">
                        {count}
                    </Typography>
                </Box>
            </Box>
        </Paper>
    )
}

export default AnalyticsCountCard

import React, { useCallback, useEffect, useState } from 'react'
import { Avatar, Box, Card, Tooltip, Typography, useTheme } from '@mui/material'
import CustomSearchBar from '../../components/common/CustomSearchBar'
import noDiscussions from '../../assets/no-discussions.svg'
import { useDiscussionForum } from '../../store/discussionforum.store'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import { useSubjectStore } from '../../store/subject.store'
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom'
import { useUserStore } from '../../store/user.store'
import { flatColorsAndBackgrounds } from '../../utils/utilities'
import { CommentOutlined, ThumbsUpDownOutlined, VisibilityOutlined } from '@mui/icons-material'

const SubjectDiscussions = () => {

    const [loading, setLoading] = useState( true )

    const { palette } = useTheme()
    const UserStore = useUserStore()
    const SubjectStore = useSubjectStore()
    const DiscussionStore = useDiscussionForum()

    const navigate = useNavigate()

    const { id } = useParams()

    const getSubjectDiscussions = useCallback( async () => {
        await DiscussionStore.fetchDiscussions( { search: SubjectStore.getSubject.subject_name } )
        if ( DiscussionStore?.getDiscussions.length > 0 && !id ) {
            const userType = UserStore.getUser.user_role === "STUDENT" ? "student" : "faculty"
            navigate( `/${userType}/subject/${SubjectStore.getSubject.subject_id}/discussions/${DiscussionStore?.getDiscussions[0]?._id}` )
        }
        setLoading( false )
    }, [DiscussionStore, SubjectStore, UserStore, navigate, id] )

    const getNavigateToDiscussionLink = ( id, query = "" ) => {
        const userType = UserStore.getUser.user_role === "STUDENT" ? "student" : "faculty"
        return `/${userType}/discussion/${id}${query}`
    }


    useEffect( () => {
        const getData = () => {
            getSubjectDiscussions()
        }
        getData()
    }, [getSubjectDiscussions] )

    return (
        <Box display="flex" overflow="auto" bgcolor={palette.greyedOut} flexDirection="column" flexGrow={1}>
            {!loading && <Box display="flex" justifyContent="space-between" gap="20px" alignItems="center" padding="10px 20px">
                {( DiscussionStore.getDiscussions || [] ).length > 0 && <Typography variant="subtitle2" color="textSecondary">{( DiscussionStore.getDiscussions || [] ).length} Discussions found</Typography>}
                {( DiscussionStore.getDiscussions || [] ).length > 0 && <CustomSearchBar border='1px solid #d3d3d3' placeholder='Search discussions...' />}
            </Box>}
            {!loading && ( DiscussionStore.getDiscussions || [] ).length > 0 && <Box padding="20px" height="700px" flexGrow={1} paddingTop="0" overflow="auto" gap="20px" display="grid" gridTemplateColumns={{ md: "300px auto", xs: "1fr" }} >
                <Box overflow="auto">
                    {<Box display="flex" flexDirection="column" gap="20px" maxHeight="500px" overflow="auto" className="custom-scrollbar">
                        {DiscussionStore.getDiscussions.map( ( discussion, k ) => (
                            <Card sx={{ flexShrink: 0 }} key={k}  >
                                <Box padding="20px" border={`1px solid ${palette.borerColor}`} display="flex" flexDirection="column" gap="10px">
                                    <Box display="flex" gap="10px" alignItems="center">
                                        <Avatar sx={{ width: "30px", height: "30px" }}>{discussion.creator_data.name.substring( 0, 1 )}</Avatar>
                                        <Box>
                                            <Typography marginTop="-5px" fontSize="14px" variant='subtitle2' textTransform="capitalize" color="GrayText" >
                                                {discussion.access_groups[0].subject_name} {discussion.access_groups?.length > 1 && ` and ${discussion.access_groups.length - 1} more`}
                                            </Typography>
                                            <Typography fontSize="10px" variant='subtitle2' textTransform="capitalize" color="GrayText" >@<em>{discussion.creator_data.name}</em> </Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Link className='default-link' to={window.innerWidth > 900 ? `${discussion._id}` : UserStore.getUser.user_role === 'STUDENT' ? `/student/discussion/${discussion._id}` : `/faculty/discussion/${discussion._id}`}>
                                            <Typography title={discussion.title} marginTop="-5px" display="inline-block" fontSize="18px" sx={{ "&:hover": { color: "#333", textDecoration: "underline" } }} fontWeight="500">{discussion.title}</Typography>
                                        </Link>
                                        <Box display="flex" flexWrap="wrap" marginTop="5px" gap="10px" alignItems="center">
                                            {discussion.categories.map( ( category, index ) => {
                                                const link = getNavigateToDiscussionLink( discussion._id, `?category=${category}` )
                                                const { color } = flatColorsAndBackgrounds[index % flatColorsAndBackgrounds.length]
                                                return (
                                                    <Box key={category} fontSize="12px" padding="5px" borderRadius="5px" sx={{ color: "#fff", cursor: "pointer", background: color }}>
                                                        <Link target={'_blank'} to={link} sx={{ color }} className='default-link'>{category}</Link>
                                                    </Box>
                                                )
                                            } )}
                                        </Box>
                                    </Box>
                                    <Box display="flex" gap="20px" alignItems="center">
                                        <Tooltip title="Views">
                                            <Typography color="textSecondary" display="flex" gap="5px" alignItems="center" variant="subtitle2">{discussion.views} <VisibilityOutlined sx={{ fontSize: "15px" }} /></Typography>
                                        </Tooltip>
                                        <Tooltip title="Votes">
                                            <Typography color="textSecondary" display="flex" gap="5px" alignItems="center" variant="subtitle2">{discussion.votes.up - discussion.votes.down} <ThumbsUpDownOutlined sx={{ fontSize: "15px" }} /></Typography>
                                        </Tooltip>
                                        <Tooltip title="Comments" >
                                            <Typography color="textSecondary" display="flex" gap="5px" alignItems="center" variant="subtitle2">{discussion.comment_count} <CommentOutlined sx={{ fontSize: "15px" }} /> </Typography>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </Card>
                        ) )}
                    </Box>}

                </Box>
                {<Box display={{ md: "flex", xs: "none" }} flexDirection="column" overflow="auto" flexGrow={1}>
                    <Outlet />
                </Box>}

            </Box>}

            {!loading && ( DiscussionStore?.getDiscussions || [] ).length === 0 &&
                <Box display="flex" flexGrow={1} alignItems="center" padding="20px" flexDirection="column" gap="20px" justifyContent="center" >
                    <img src={noDiscussions} width="200px" alt="No Discussions" />
                    <Typography variant="subtitle2" >No discussions created for <em> <strong>'{SubjectStore.getSubject.subject_name}'</strong> </em> </Typography>
                </Box>
            }
            {loading && <DataLoadingSpinner waitingMessage="Loading discussions please wait..." />}
        </Box>
    )
}

export default SubjectDiscussions

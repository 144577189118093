import { SwapVertOutlined } from '@mui/icons-material'
import { Box, Icon, IconButton, Popover } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { Icons } from '../../utils/utilities'

const CustomTableColumnLabel = ( { info = null, align = "flex-start", data, setData, label, sortByProperty, setSearchParamValue, sortKey, sortName, sortFlag, sortValueType, minWidth } ) => {

    const [openInfoDialog, setOpenInfoDialog] = useState( null )
    const columnContainer = useRef()

    return <Box display="flex" justifyContent={align} minWidth={minWidth} alignItems="center" gap="5px">
        {info && <Popover anchorEl={columnContainer.current} onClose={() => setOpenInfoDialog( false )} open={openInfoDialog}>
            {info}
        </Popover>}
        {info && <Icon onClick={() => setOpenInfoDialog( true )} sx={{ cursor: "pointer" }} ref={columnContainer}>{Icons.small.InfoIcon}</Icon>}
        {label}
        <IconButton onClick={
            () => {
                sortByProperty( data, setData, sortKey, sortValueType, sortFlag === "1" ? "-1" : "1" )
                setSearchParamValue( sortName, sortFlag === "1" ? "-1" : "1" )
            }
        } size="small" sx={{ cursor: "pointer", padding: "5px", "&:hover": { background: "rgba(0,0,0,0.4)", color: "white", } }}>
            <SwapVertOutlined sx={{ fontSize: "16px" }} />
        </IconButton>
    </Box>
}

CustomTableColumnLabel.propTypes = {
    info: PropTypes.node,

}

export default CustomTableColumnLabel

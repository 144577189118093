import { Box, Dialog, Drawer, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { useState } from 'react'
import api from '../../../service/api'
import { Icons, attachmentThumbnails, convertToCDNLink, getFileSizeFromBytes } from '../../../utils/utilities'
import TableHeader from '../../common/TableHeader'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import { useDownloadStatusListStore } from '../../../store/downloadstatuslistdialog.store'
import TryAgainMessageBlock from '../../common/TryAgainMessageBlock'
import { toast } from 'react-toastify'
import DocumentViewer from '../../common/DocumentViewer'

const columns = [
    { name: "Name", padding: "10px 20px 10px 40px" },
    { name: "Downloads", align: "center" },
    { name: "Size", align: "center", padding: "10px 40px 10px 20px" },
    { name: "Actions", align: "right", padding: "10px 40px 10px 20px" },
]

const StudyMaterialViewer = ( { handleClose, openState, studyMaterial } ) => {

    const [error, setError] = useState( null )
    const [loading, setLoading] = useState( true )
    const [attachments, setAttachments] = useState( [] )
    const [previewFile, setPreviewFile] = useState( null )
    const [previewingFileDetails, setPreviewingFileDetails] = useState( null )

    const { table, palette, border } = useTheme()

    const DownloadStatusListStore = useDownloadStatusListStore()

    const viewAttachment = async ( attachment ) => {
        try {
            console.log( attachment )
            const { data: { data: url } } = await api.downloadAttachment( attachment.study_material_attachment_id, { url: attachment.study_material_attachment_url } )
            setPreviewFile( convertToCDNLink( url ) )
            setPreviewingFileDetails( { study_material_attachment_name: `${attachment.study_material_attachment_title}.${attachment.study_material_attachment_type}`, type: attachment.study_material_attachment_type } )
        } catch ( err ) {
            toast( err?.response?.data?.message || "Couldn't preview the file" )
        }
    }

    const fetchAttachments = useCallback( async () => {
        try {
            setLoading( true )
            const { data: { data: attachments } } = await api.getAttachments( studyMaterial.study_material_id )
            setAttachments( attachments )
            setError( null )
        } catch ( err ) {
            console.log( err )
            setError( err?.response?.data?.message || "Opps! An error occured while trying to fetch attchments, please try again." )
        } finally {
            setLoading( false )
        }
    }, [studyMaterial] )

    useEffect( () => {
        fetchAttachments()
    }, [fetchAttachments] )


    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "1200px" } }} open={openState}>
            <Drawer anchor='bottom' PaperProps={{ sx: { height: "calc(100% - 50px)" } }} sx={{ zIndex: 9000000 }} open={Boolean( previewFile )} >
                <Box bgcolor={palette.cardBackground} flexGrow={1} display="flex" overflow="auto" flexDirection="column" borderRadius="20px 20px 0 0">
                    <Box borderBottom={border[1]} alignItems="center" display="flex" justifyContent="space-between" gap="20px" padding="10px 20px">
                        <Typography variant='h6'>{previewingFileDetails?.study_material_attachment_name}</Typography>
                        <Box display="flex" gap="10px" alignItems="center">
                            <IconButton onClick={() => { setPreviewFile( null ); setPreviewingFileDetails( null ) }}>
                                {Icons.default.CloseIcon}
                            </IconButton>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" overflow="auto" flexGrow={1} padding="20px" height="300px">
                        <DocumentViewer loadingText="Loading material..." setFile={setPreviewFile} type={previewingFileDetails?.type || previewingFileDetails?.study_material_attachment_name?.split( "." ).pop()} file={previewFile} />
                    </Box>
                </Box>
            </Drawer>
            <Box sx={{
                background: `linear-gradient(322.12deg, #F3C400 -21.29%, #E25A26 124.22%)`
            }} display="flex" alignItems="center" justifyContent="space-between" padding="20px" >
                <Box>
                    <Typography color="white" variant="h6">{studyMaterial.study_material_name}</Typography>
                    <Typography variant='body2' color='white' >{studyMaterial.study_material_tags}</Typography>
                </Box>
                <IconButton onClick={handleClose}>{Icons.default.CloseIcon}</IconButton>
            </Box>
            <Box padding="20px" overflow="hidden" display="flex" flexDirection="column">
                {!loading && !error && attachments.length > 0 && <TableContainer className='custom-scrollbar' sx={{ height: "100%", bgcolor: palette.contentBg, borderRadius: "10px", flexGrow: "1", overflow: "auto" }}>
                    <Table stickyHeader>
                        <TableHeader columns={columns} />
                        <TableBody>
                            {attachments.map( att => {
                                return <TableRow className='table-row' key={att.study_material_attachment_id} >
                                    <TableCell align={columns[0].align || "left"} sx={{ fontSize: "12px", padding: columns[0].padding || "10px 20px", color: table.color, textTransform: "capitalize" }}>
                                        <Typography display="flex" gap="5px" alignItems="center" fontSize="12px" maxWidth="200px">
                                            <img style={{ objectFit: "cover" }} width="30px" src={attachmentThumbnails[att.study_material_attachment_type] ? attachmentThumbnails[att.study_material_attachment_type] : attachmentThumbnails["default"]} alt={att.study_material_attachment_type} />
                                            <strong>{att.study_material_attachment_title || att.study_material_attachment_name}</strong>
                                        </Typography>
                                    </TableCell>
                                    <TableCell align={columns[1].align || "left"} sx={{ fontSize: "12px", padding: columns[1].padding || "10px 20px", color: table.color, textTransform: "capitalize" }}><strong>{att.attachment_download_count}</strong></TableCell>
                                    <TableCell align={columns[2].align || "left"} sx={{ fontSize: "12px", padding: columns[2].padding || "10px 20px", color: table.color, textTransform: "capitalize" }}><strong>{getFileSizeFromBytes( att.study_material_attachment_meta?.size )}</strong></TableCell>
                                    <TableCell align={columns[3].align || "left"} sx={{ fontSize: "12px", padding: columns[3].padding || "10px 20px", color: table.color, textTransform: "capitalize" }}>
                                        <Box>
                                            <IconButton onClick={() => viewAttachment( att )} size='small' color="secondary">
                                                {Icons.default.VisibilityIcon}
                                            </IconButton>
                                            <IconButton onClick={() => { DownloadStatusListStore.downloadAttachment( att.study_material_attachment_id, att.study_material_attachment_url, att.study_material_attachment_title ? att.study_material_attachment_title : att.study_material_attachment_name, att.study_material_attachment_name.split( "." ).pop() ) }} size='small' color="secondary">
                                                {Icons.default.DownloadIconOutlined}
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            } )}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </Box>
            {loading && !error && <DataLoadingSpinner waitingMessage="Please wait while we load the attachments..." />}
            {error && <TryAgainMessageBlock err={error} loading={loading} getData={fetchAttachments} />}
        </Dialog>
    )
}

export default StudyMaterialViewer

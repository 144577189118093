import { Box, Button, CircularProgress, Paper, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useAdmissionExamStore } from '../../store/admission-exam.store'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import { Icons, isMobileDevice } from '../../utils/utilities'
import { formatDateTimeForDisplay } from '../../utils/exam-utilities'
import api from '../../service/api'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import TryAgainMessageBlock from '../../components/common/TryAgainMessageBlock'

const AssessmentCard = ( { assessment, activeExam } ) => {

    const { border, palette, shadows } = useTheme()

    const [checkingEligibility, setCheckingEligibility] = useState( false )
    const [attempting, setAttempting] = useState( false )

    const navigate = useNavigate()

    const attempt = async ( id ) => {
        try {
            setCheckingEligibility( true )
            const { data: eligibilityData } = await api.admissionExams.verifyCandidate( { exam_id: id } )
            if ( eligibilityData.exceedsMaximumNumberOfAttempts )
                return toast( `The maximum number of attempts exceeded` )
            if ( eligibilityData.restrictMobileAttempt ) {
                const { isMobile } = isMobileDevice()
                if ( isMobile )
                    return toast( `This exam cannot be taken on mobile device! please use laptop or desktop with stable internet connection.` )
            }
            setCheckingEligibility( true )

            setAttempting( true )
            const { status, data } = await api.admissionExams.attempt( id )
            if ( status === 200 ) {
                if ( !data.isNewParticipation )
                    toast( "Proceeding with the previously unfinished exam!", { containerId: "main" } )
                const url = `/assessment/${id}/${data.participation_id}/attempt/${data.attempt}`

                navigate( url )
            }
        } catch ( e ) {
            console.log( e )
            toast( e?.response?.data?.message || e?.response?.data || "Something went wrong", { containerId: "main" } )
        } finally {
            setAttempting( false )
            setCheckingEligibility( false )
        }
    }

    return ( <Box flexGrow={{ sm: "initial", xs: "1" }} sx={{ ":hover": { boxShadow: shadows[1] } }} border={border[1]} padding="10px" borderRadius="10px" width="250px">
        <Typography variant='subtitle2' color={assessment.closed ? "error" : activeExam ? "success.main" : palette.grey[500]} fontSize="12px" noWrap>{assessment.closed ? "CLOSED" : activeExam ? "ACTIVE" : "COMPLETED"}</Typography>
        <Typography variant='h6' fontSize="16px" noWrap>{assessment.exam_name}</Typography>
        <Typography variant='subtitle2' fontSize="12px" noWrap>{`${formatDateTimeForDisplay( assessment.exam_start_date, assessment.exam_start_time )}`}</Typography>
        {assessment.exam_duration !== "null" && assessment.exam_duration !== null && <Typography variant='subtitle2' fontSize="12px" noWrap > {`Duration: ${assessment.exam_duration.split( ":" )[0]} Hours ${assessment.exam_duration.split( ":" )[1]} Minutes`}</Typography>}
        <Button disabled={!activeExam || assessment.closed || checkingEligibility || attempting} onClick={() => attempt( assessment.exam_id )} endIcon={( checkingEligibility || attempting ) ? <CircularProgress size={14} /> : Icons.default.KeyBoardArrowRight} variant='outlined' sx={{ marginTop: "10px", textTransform: "capitalize" }} >{attempting ? "Attempting assessment" : checkingEligibility ? "Checking eligibilty..." : "Attempt assessment"}</Button>
    </Box> )
}

const CandidateExams = () => {
    const [loading, setLoading] = useState( false )
    const [error, setError] = useState( null )

    const AdmissionExamStore = useAdmissionExamStore()

    const { border } = useTheme()

    const getAssessments = useCallback( async () => {
        setLoading( true )
        const { success, error } = await AdmissionExamStore.fetchExams( { exam_type: "global" } )
        if ( !success ) {
            console.log( error )
            setError( error )
        } else {
            setError( null )
        }
        setLoading( false )
    }, [AdmissionExamStore] )

    useEffect( () => {
        getAssessments()
    }, [getAssessments] )

    return (
        <Box sx={{ background: '' }} padding="20px" flexGrow={1} overflow="auto">
            <Paper >
                <Box padding="20px" borderBottom={border[1]} >
                    <Typography variant='h6'>Your Assessments</Typography>
                    <Typography color="" variant='subtitle2'>Select the assessment to attempt!</Typography>
                </Box>
                {loading && !error && <DataLoadingSpinner waitingMessage="Loading your assessments..." />}
                {!loading && !error && AdmissionExamStore.getExams?.length > 0 && <Box display="flex" gap="20px" justifyContent={{ sm: "flex-start", xs: "center" }} flexWrap="wrap" padding="20px">
                    {AdmissionExamStore.getExams?.map( ( assessment, index ) => (
                        <AssessmentCard activeExam={index === 0} assessment={assessment} key={assessment.exam_id} />
                    ) )}
                </Box>}
                {error && <TryAgainMessageBlock code={error.code} err={error.message} getData={getAssessments} loading={loading} />}
            </Paper>
        </Box>
    )
}

export default CandidateExams

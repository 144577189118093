import { Box, Button, MenuItem, FormControl, Select, TextField, Typography, CircularProgress, Collapse, Chip, Tooltip, IconButton, useTheme, Dialog } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { useAssignmentStore } from '../../store/assignment.store'
import uploadAssignmentsImg from '../../assets/upload-assignment.svg'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import DataLoadingSpinner from '../common/DataLoadingSpinner'
import { chipColors, Icons } from '../../utils/utilities'
import { useUserStore } from '../../store/user.store'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { observer } from 'mobx-react'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import { RefreshOutlined } from '@mui/icons-material'

const CreateOrUpdateAssignment = observer( ( { assignment = undefined, subject = undefined, setView, view } ) => {

    const AssignmentStore = useAssignmentStore()
    const UserStore = useUserStore()

    const navigate = useNavigate()
    const { palette, border } = useTheme()

    const [academicYearId, setAcademicYearId] = useState( "-" )
    const [loaded, setLoaded] = useState( false )
    const [uploadStatus, setUploadStatus] = useState( false )
    const [isLoadingBatches, setIsLoadingBatches] = useState( false )


    const initialValues = {
        assignment_name: "",
        assignment_details: "",
        assignment_subject: subject || "-",
        assignment_batch_id: [],
        assignment_due_date: view === 'update' ? dayjs( AssignmentStore.getSelectedAssignment.assignment_due_date ) : "",
        attachments: []
    }

    const validationSchema = Yup.object().shape( {
        assignment_name: Yup.string( "" ).required( "Assignment name is required!" ),
        assignment_details: Yup.string().required( "Assignment details is required" ),
        assignment_batch_id: Yup.array().required( "Specify the assignment batch" ).test( "TEST_FOR_BATCHES", "Specify the assignment atleast one batch", ( val ) => {
            if ( val.length > 0 )
                return true
            return false
        } ),
        assignment_due_date: Yup.date().required( "Specify the assignment's due date" ),
        assignment_subject: Yup.mixed().required( "Specify the subject" ).notOneOf( ["-"], "Specify the assignment subject" ),
        attachments: Yup.array().required( "Please select assignment attachment" ).test( "CHECK_ATTACHMENT", "Please select assignment attachment", ( val ) => {
            if ( val?.length > 0 )
                return true
            return false
        } )
    } )

    const setAssignmentSubmissionDate = ( val, setFieldValue ) => {
        setFieldValue( 'assignment_due_date', val )
    }


    useEffect( () => {
        const fetchAcademicYearAndSubjects = async () => {
            try {
                if ( UserStore.getErrorsStatuses?.academicYearsAndBatches === true )
                    await UserStore.fetchUsersAllBatchesAndSectionList()
                if ( UserStore.getErrorsStatuses?.academicYears === true )
                    await UserStore.fetchUserAcademicYears()
                if ( UserStore.getErrorsStatuses?.subjects === true )
                    await UserStore.fetchUserSubjects()

            } catch ( err ) {
                console.log( err )
                if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                    UserStore.setERPSessionState( 'ended' )
                } else toast( err?.response?.data?.message || 'Something went wrong while fetching required details!' )
            } finally {
                setLoaded( true )
            }
        }
        fetchAcademicYearAndSubjects()
    }, [AssignmentStore, navigate, view, UserStore] )


    const uploadAssignment = async ( values, { resetForm } ) => {
        setUploadStatus( true )
        const formData = new FormData()

        for ( let i = 0; i < values.attachments.length; i++ ) {
            formData.append( "attachments", values.attachments[i] )
        }

        formData.append( "assignment_name", values.assignment_name )
        formData.append( "assignment_details", values.assignment_details )
        formData.append( "assignment_batch_id", values.assignment_batch_id?.join( "," ) )
        formData.append( "assignment_due_date", values.assignment_due_date )
        formData.append( "assignment_subject", JSON.stringify( values.assignment_subject ) )
        try {
            if ( await AssignmentStore.uploadAssignment( formData, subject?.subject_id || null ) ) {
                await AssignmentStore.fetchAssignmentByAuthor()
                resetForm()
                setView( "manage" )
            }
        } catch ( err ) {
            toast( err?.response?.data?.message || "Something went wrong while creating the assignment" )
        } finally {
            setUploadStatus( false )
        }
    }

    const handleFileInputChange = ( e, value, setFieldValue ) => {
        const files = e.target.files
        let selectedFileList = []
        for ( let i = 0; i < files.length; i++ ) {
            selectedFileList.push( files[i] )
        }
        setFieldValue( 'attachments', [...value, ...selectedFileList] )
        e.target.value = ''
    }

    const handleAcademicYearChange = async ( e ) => {
        setIsLoadingBatches( true )
        setAcademicYearId( e.target.value )
        // await UserStore.fetchUserBatchesAndSection( e.target.value )
        setIsLoadingBatches( false )
    }

    const refreshYears = async () => {
        await UserStore.fetchUserAcademicYears( true )
    }

    const refreshBatches = async () => {
        if ( academicYearId !== "-" )
            await UserStore.fetchUserBatchesAndSection( academicYearId, true )
    }

    const removeAttachment = ( values, index, setFieldValue ) => {
        const updatedAttachments = [...values.attachments]
        updatedAttachments.splice( index, 1 )
        setFieldValue( 'attachments', updatedAttachments )
    }

    const updateAssignment = async ( values ) => {
        setUploadStatus( true )
        let payload = { "assignment_due_date": values.assignment_due_date }
        await AssignmentStore.updateAssignmentDetail( payload )
        AssignmentStore.setUpdateStatus( AssignmentStore.selectedAssignment, false )
        setUploadStatus( false )
        setView( "manage" )
    }

    return (

        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "900px", bgcolor: palette.form.formCardBg } }} open={['update', 'create'].includes( view )} sx={{ padding: "0" }} >
            <Box sx={{ padding: 0 }}>
                <Box position="sticky" sx={{ zIndex: "10" }} top="0" borderBottom={`1px solid ${palette.borderColor}`} bgcolor={palette.form.formCardBg} padding="20px" gap="20px" display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        <Typography variant='h5'>{view === 'update' ? "Update assignment" : "Create new assignment"}</Typography>
                        <Typography variant="subtitle2" color="textSecondary">Create assignment by providing all required details</Typography>
                    </Box>
                    <Tooltip placement='top' title="Back to assignments">
                        <IconButton onClick={() => setView( "manage" )} size='small'>
                            {Icons.default.CloseIcon}
                        </IconButton>
                    </Tooltip>
                </Box>
                {loaded && <Box margin="20px" bgcolor={palette.form.formBg} borderRadius="5px" border={border[1]} padding="20px">
                    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={view === 'update' ? updateAssignment : uploadAssignment}>
                        {( { values, setFieldValue } ) => (
                            <Form>
                                <Box>
                                    <FormControl fullWidth>
                                        <label htmlFor="name">
                                            <Typography gutterBottom variant='subtitle2' color={palette.labelColor}>Enter a clear and concise assignment title *</Typography>
                                        </label>
                                        <Field size="small" as={TextField} type="text"
                                            placeholder="Eg: DS assignment"
                                            InputLabelProps={{ sx: { fontSize: "14px" } }}
                                            value={values.assignment_name}
                                            id="name"
                                            name="assignment_name"
                                            disabled={view === 'update'}
                                        />
                                        <Typography variant='subtitle2' color="errorMessage.main" fontSize="12px"> <ErrorMessage name="assignment_name" /> </Typography>
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl margin='normal' fullWidth>
                                        <label htmlFor="outlined-multiline-static">
                                            <Typography gutterBottom variant='subtitle2' color={palette.labelColor}>
                                                Describe the assignment details here *
                                            </Typography>
                                        </label>
                                        <Field size="small" as={TextField}
                                            placeholder="Eg: Complete the assignment and upload before due date!"
                                            InputLabelProps={{ sx: { fontSize: "14px" } }}
                                            id="outlined-multiline-static"
                                            multiline
                                            rows={4}
                                            name="assignment_details"
                                            value={values.assignment_details}
                                            disabled={view === 'update'}
                                        />
                                        <Typography variant='subtitle2' color="errorMessage.main" fontSize="12px"> <ErrorMessage name="assignment_details" /> </Typography>
                                    </FormControl>
                                </Box>

                                {view !== 'update' && <Box>
                                    <FormControl margin='normal' fullWidth>
                                        <label htmlFor="subject">
                                            <Typography gutterBottom variant='subtitle2' color={palette.labelColor}>
                                                Select the subject relevant to the assignment *
                                            </Typography>
                                        </label>
                                        <Field size="small"
                                            disabled={Boolean( subject )}
                                            as={Select}
                                            sx={{ color: values.assignment_subject === "-" && palette.form.placeholder }}
                                            id="subject"
                                            value={values.assignment_subject?.subject_id || "-"}
                                            onChange={e => setFieldValue( 'assignment_subject', UserStore.getUserSubjects.find( s => s.subject_id === e.target.value ) )}
                                            name="assignment_subject"
                                        >
                                            <MenuItem sx={{ fontSize: "14px" }} key={"default"} disabled value={"-"}>
                                                Eg: Data structures
                                            </MenuItem>
                                            {
                                                UserStore.getUserSubjects.map( ( sub ) => {

                                                    return ( <MenuItem sx={{ fontSize: "14px" }} key={sub.subject_id} value={sub.subject_id}>
                                                        {sub.subject_name}
                                                    </MenuItem> )
                                                } )
                                            }
                                        </Field>
                                        <Typography variant='subtitle2' color="errorMessage.main" fontSize="12px"> <ErrorMessage name="assignment_subject" /> </Typography>

                                    </FormControl>
                                </Box>}

                                {view !== 'update' && <Box>
                                    <FormControl margin='normal' fullWidth>
                                        <Box display="flex" alignItems="center" gap="5px">
                                            <label htmlFor="ac-year-id">
                                                <Typography gutterBottom variant='subtitle2' color={palette.labelColor}>
                                                    Select academic year of admission
                                                </Typography>
                                            </label>
                                            {<Typography onClick={refreshYears} sx={{ cursor: "pointer", '&:hover': { textDecoration: "underline" } }} display="flex" gap="5px" alignItems="center" fontSize="14px" color="secondary" fontWeight="500"><RefreshOutlined fontSize='16px' />Refresh</Typography>}
                                        </Box>
                                        <Select
                                            sx={{ color: academicYearId === '-' && palette.form.placeholder }}
                                            size="small"
                                            id="ac-year-id"
                                            label=""
                                            value={academicYearId}
                                            onChange={handleAcademicYearChange}
                                        >
                                            <MenuItem disabled sx={{ fontSize: "14px" }} key={"default"} value={"-"}>
                                                Select academic year to fetch batched and sections
                                            </MenuItem>
                                            {
                                                UserStore.getUserAcademicYears.map( ( batchYear, k ) => {

                                                    return ( <MenuItem sx={{ fontSize: "14px" }} key={batchYear.ac_year_id} value={batchYear.ac_year_id}>
                                                        {batchYear.ac_year}
                                                    </MenuItem> )
                                                } )
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>}
                                {view !== 'update' && <Box>
                                    <FormControl margin='normal' fullWidth>
                                        <Box display="flex" alignItems="center" gap="5px">
                                            <Typography gutterBottom variant='subtitle2' color={palette.labelColor}>
                                                Select the student batches or sections for this assignment *
                                            </Typography>
                                            {academicYearId !== "-" && <Typography onClick={refreshBatches} sx={{ cursor: "pointer", '&:hover': { textDecoration: "underline" } }} display="flex" gap="5px" alignItems="center" fontSize="14px" color={"secondary"} fontWeight="500"> <RefreshOutlined fontSize='16px' />Refresh batches</Typography>}
                                        </Box>
                                        <Field size="small"
                                            as={Select}
                                            sx={{ color: values.assignment_batch_id === '-' && palette.form.placeholder }}
                                            disabled={isLoadingBatches}
                                            labelId="batch-id"
                                            id="batch-id-selector"
                                            name="assignment_batch_id"
                                            multiple
                                            displayEmpty
                                            renderValue={( selected ) => {
                                                return (
                                                    selected.length === 0 ?
                                                        <Typography fontSize="16px" color={palette.form.placeholder} noWrap >
                                                            Select batch or section
                                                        </Typography> : <Box display="flex" gap="10px" flexWrap="wrap">
                                                            {values.assignment_batch_id?.map( ( val, index ) => {
                                                                const access = UserStore.getUsersAllBatchesAndSectionList?.find( i => ( i.batch_assignment_id === val || `${i.section_assignment_id}-${i.section_short_name}` === val ) )
                                                                let title = ''
                                                                if ( access?.section_assignment_id ) {
                                                                    title = `${access.course_branch_short_name ? access.course_branch_short_name + "," : ""} ${access?.current_year ? `year: ${access?.current_year},` : ""} ${access.current_sem ? `Sem: ${access.current_sem},` : ""} Sec: ${access.section_short_name}`
                                                                } else if ( access?.batch_assignment_id )
                                                                    title = `${access.course_name_short ? access.course_name_short + "," : ""} ${access?.current_year ? `year: ${access?.current_year},` : ""} ${access.current_sem ? `Sem: ${access.current_sem},` : ""} Batch: ${access.batch_short_name}`

                                                                return (
                                                                    <Chip
                                                                        sx={{ height: "50px", border: border[1], borderRadius: "40px" }}
                                                                        key={val}
                                                                        label={
                                                                            <Box padding="10px 0" display="inline-flex" flexDirection="column">
                                                                                <Typography variant='subtitle2' fontSize="12px"><em><strong>{title}</strong></em></Typography>
                                                                                <Typography variant='body2' fontSize="10px"><em>{access.ac_year}</em></Typography>
                                                                            </Box>
                                                                        } />
                                                                )
                                                            } )}
                                                        </Box>
                                                )
                                            }}
                                            value={values.assignment_batch_id}>
                                            <MenuItem value="-" key="key" disabled sx={{ fontSize: "14px" }} color='textSecondary'> {academicYearId === "-" ? "Select academic year to select bacthes or sections" : "Select batch"} </MenuItem>

                                            {UserStore.getUsersAllBatchesAndSectionList?.filter( item => item.batch_assignment_id && item.ac_year_id === academicYearId ).length > 0 && <Typography sx={{ pointerEvents: "none" }} paddingLeft="18px" variant='subtitle2' color="secondary">Batches ({UserStore.getUsersAllBatchesAndSectionList?.filter( item => item.batch_assignment_id && item.ac_year_id === academicYearId ).length})</Typography>}
                                            {UserStore.getUsersAllBatchesAndSectionList?.filter( item => item.batch_assignment_id && item.ac_year_id === academicYearId )?.map( ( batch, k ) => {
                                                return ( <MenuItem sx={{ display: "flex", gap: "10px", alignItems: "center", fontSize: "14px", fontWeight: "500" }} key={batch.batch_assignment_id} value={batch.batch_assignment_id}>
                                                    {batch.batch_name + "-" + batch.course_name_short + " Y" + batch.current_year + " S" + batch.current_sem + " " + batch.batch_short_name}
                                                </MenuItem> )
                                            } )}
                                            {UserStore.getUsersAllBatchesAndSectionList?.filter( item => item.section_assignment_id && item.ac_year_id === academicYearId ).length > 0 && <Typography sx={{ pointerEvents: "none" }} paddingLeft="18px" variant='subtitle2' color="secondary">Sections ({UserStore.getUsersAllBatchesAndSectionList?.filter( item => item.section_assignment_id && item.ac_year_id === academicYearId ).length})</Typography>}
                                            {UserStore.getUsersAllBatchesAndSectionList?.filter( item => item.section_assignment_id && item.ac_year_id === academicYearId )?.map( ( batch, k ) => {
                                                return ( <MenuItem sx={{ display: "flex", gap: "10px", alignItems: "center", fontSize: "14px", fontWeight: "500" }} key={batch.section_assignment_id} value={batch.section_assignment_id + "-" + batch.section_short_name}>
                                                    {batch.course_branch_short_name + "-" + batch.course_short_name + " Y" + batch.current_year + " S" + batch.current_sem + " (" + batch.section_short_name + ")"}
                                                </MenuItem> )
                                            } )}
                                            {UserStore.getUsersAllBatchesAndSectionList?.filter( item => item.section_assignment_id && item.ac_year_id === academicYearId ).length === 0 && UserStore.getUsersAllBatchesAndSectionList?.filter( item => item.batch_assignment_id && item.ac_year_id === academicYearId ).length === 0 && <Typography sx={{ pointerEvents: "none" }} paddingLeft="18px" variant='subtitle2' color="textSecondary">No sections or batches for selected academic year!</Typography>}
                                        </Field>
                                        <Typography variant='subtitle2' color="errorMessage.main" fontSize="12px"> <ErrorMessage name="assignment_batch_id" /> </Typography>
                                    </FormControl>
                                </Box>}
                                <Box>
                                    <FormControl fullWidth margin='normal'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Typography gutterBottom variant='subtitle2' color={palette.labelColor}>
                                                {view === 'update' ? "Update the submission deadline *" : "Set the submission deadline *"}
                                            </Typography>
                                            <DatePicker
                                                sx={{ "width": "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                                minDate={dayjs( new Date() )}
                                                disableToolbar
                                                variant="inline"
                                                format="DD-MM-YYYY"
                                                id="date-picker-inline"
                                                value={dayjs( values.assignment_due_date )}
                                                onChange={( val ) => setAssignmentSubmissionDate( val, setFieldValue )}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                    <Typography variant='subtitle2' color="errorMessage.main" fontSize="12px"> <ErrorMessage name="assignment_due_date" /> </Typography>

                                </Box>
                                {view !== 'update' && <Box marginTop='20px'>
                                    <Box sx={{ bgcolor: `${palette.primary.light}11`, position: "relative", border: `2px dashed ${palette.primary.dark}`, borderRadius: "5px", padding: "20px" }} >
                                        <input type="file" style={{ position: "absolute", opacity: 0, top: 0, left: 0, right: 0, bottom: 0 }} onChange={( e ) => handleFileInputChange( e, values.attachments, setFieldValue )} multiple />
                                        <Box display="flex" gap="10px" flexDirection="column" alignItems="center">
                                            <img src={uploadAssignmentsImg} alt="Upload assignment" width="120px" />
                                            <Box display="flex" gap="5px" flexDirection="column" alignItems="center">
                                                <Typography variant="body1" fontWeight="600" >Upload assignment problem file</Typography>
                                                <Typography variant="subtitle2" color="textSecondary">Drag and drop here or  <span style={{ color: palette.customThemeColor.dark }}>Browse</span> </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Collapse in={values.attachments.length > 0}>
                                        <Box display="flex" gap="10px" paddingTop="20px" flexWrap="wrap">
                                            {values.attachments.length > 0 && values.attachments.map( ( file, index ) => (
                                                <Chip key={index} deleteIcon={<RemoveCircleIcon sx={{ color: "white !important" }} />} onDelete={() => removeAttachment( values, index, setFieldValue )} sx={{ color: "white", background: chipColors[index % chipColors.length] }} label={file.name} />
                                            ) )}
                                        </Box>
                                    </Collapse>
                                    <Typography variant='subtitle2' color="errorMessage.main" fontSize="12px"> <ErrorMessage name="attachments" /> </Typography>
                                </Box>}

                                <Box marginTop="20px">
                                    {
                                        view !== 'update' ?
                                            <Box display="flex" marginTop="40px" gap="10px" alignItems="center" flexWrap="wrap">
                                                <Button startIcon={uploadStatus ? <CircularProgress size={14} /> : Icons.default.AddIcon} disabled={uploadStatus} disableElevation sx={{ textTransform: "capitalize" }} type="submit" variant="contained" color="primary">
                                                    {uploadStatus === false ? 'Create' : "Creating..."}
                                                </Button>
                                                <Button disableElevation disabled={uploadStatus} onClick={() => setView( "manage" )} sx={{ textTransform: "capitalize" }} variant="contained" color="error">
                                                    Cancel
                                                </Button>
                                            </Box> :
                                            <Box display="flex" marginTop="40px" gap="10px" alignItems="center" flexWrap="wrap">
                                                <Button onClick={() => updateAssignment( values )} startIcon={uploadStatus ? <CircularProgress size={14} /> : Icons.default.EditIcon} disabled={uploadStatus} disableElevation sx={{ textTransform: "capitalize" }} variant="contained" color="primary">
                                                    {uploadStatus === false ? 'Update' : "Updating..."}
                                                </Button>
                                                <Button disabled={uploadStatus} onClick={() => setView( "manage" )} sx={{ textTransform: "capitalize" }} variant="contained" disableElevation color="error">
                                                    Cancel
                                                </Button>
                                            </Box>
                                    }

                                </Box>
                            </Form> )}
                    </Formik>
                </Box>}
                {!loaded && <DataLoadingSpinner waitingMessage="Fetching data..." />}
            </Box>
        </Dialog>
    )
} )

export default CreateOrUpdateAssignment

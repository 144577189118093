import { Box, Button, CircularProgress, Tooltip, Typography, useTheme } from '@mui/material'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useRoomStore } from '../../store/room.store'
import DataLoadingSpinner from '../common/DataLoadingSpinner'
import { calendarColors } from '../../utils/utilities'
import { useUserStore } from '../../store/user.store'

const timelineHeight = 300

const CalendarDateColumn = observer( ( { day, quizzesData, dateDetails, setClassDetailsAnchor, setSelectedRoom, isToday, isFuture } ) => {

    const [loading, setLoading] = useState( true )

    const RoomStore = useRoomStore()
    const UserStore = useUserStore()

    const { palette, theme, shadows } = useTheme()

    const dateBoxDesigns = {
        bg: isToday ? calendarColors.todayCalendarDateColor : dateDetails.notSameMonth === true ? `${palette.common.font}4` : "none",
        border: `2px solid ${isToday ? calendarColors.todayCalendarDateColor : "#ADADAD"}`,
        color: isToday ? "#fff" : isFuture ? palette.common.font : theme === 'dark' ? "whitesmoke" : "GrayText"
    }

    const getClassBoxDesigns = c => {
        const designs = {}

        designs.border = isFuture ? c.online === '0' ? `1px solid ${calendarColors.offlineDark}` : `1px solid ${calendarColors.onlineDark}` : "none"
        designs.opacity = ( isFuture || isToday ) ? 1 : 0.4
        designs.boxShadow = ( isFuture || isToday ) ? shadows[3] : shadows[0]
        designs.boxBorderRadius = isFuture ? "0" : "0 0 10px 10px"
        designs.bg = isFuture ? palette.common.bg : isToday ? c.online === '0' ? calendarColors.offlineDark : calendarColors.onlineDark : "grey"
        designs.color = isFuture ? c.online === '0' ? calendarColors.offlineDark : calendarColors.onlineDark : isToday ? "#fff" : "#333"
        designs.timeBg = ( isFuture || isToday ) ? c.online === '0' ? calendarColors.offlineLight : calendarColors.onlineLight : "#ccc"

        return designs
    }

    const getData = useCallback( async () => {
        setLoading( true )
        if ( RoomStore.getErrors[dateDetails.erp_date] === undefined || RoomStore.getErrors[dateDetails.erp_date] === true )
            await RoomStore.fetchRooms( dateDetails.erp_date, quizzesData )
        setLoading( false )
    }, [RoomStore, dateDetails, quizzesData] )

    useEffect( () => {
        getData()
    }, [getData] )

    return (
        <Box overflow="auto" width="calc((100% - 60px) / 7)" display="flex" flexDirection="column" >
            <Box
                padding="5px 10px"
                borderRadius="10px 10px 0 0"
                bgcolor={dateBoxDesigns.bg}
                border={dateBoxDesigns.border}
                sx={{ opacity: dateDetails.notSameMonth === true ? 0.8 : 1 }}
            >
                <Typography fontSize="22px" fontWeight="bold" color={dateBoxDesigns.color}>{dateDetails?.date}</Typography>
                <Typography fontSize="12px" color={dateBoxDesigns.color} fontWeight="500">{day}</Typography>
                <Typography color={dateBoxDesigns.color} fontSize="12px" noWrap fontWeight="400">{( RoomStore.getRoom[dateDetails.erp_date]?.length || 0 )} classes</Typography>
            </Box>
            <Box className="slim-custom-scrollbar" paddingTop="10px" paddingX="4px" height={timelineHeight + 100} width="calc(100% - 10px)" borderRight="1px solid #E0E0E080" gap="10px" overflow="auto" borderLeft="1px solid #E0E0E080">
                {!loading && !RoomStore.getErrors[dateDetails.erp_date] === true && RoomStore.getRoom[dateDetails.erp_date] && RoomStore.getRoom[dateDetails.erp_date]?.sort( ( c1, c2 ) => c1.sTime - c2.sTime ).map( ( c, tindex ) => {

                    const classBoxDesigns = getClassBoxDesigns( c )
                    return <Box
                        marginBottom="10px"
                        onClick={e => {
                            let does_exists = RoomStore.getActiveRoom.filter( ( item, k ) => {
                                return item.room_session_id === c.time_table_id
                            } ).length

                            setSelectedRoom( { ...c, date: dateDetails?.tense, does_exists } )
                            setClassDetailsAnchor( e.currentTarget )
                        }}
                        position="relative"
                        sx={{
                            cursor: "pointer",
                            opacity: classBoxDesigns.opacity,
                            "&:hover": {
                                boxShadow: classBoxDesigns.boxShadow
                            }
                        }}
                        borderRadius="10px"
                        bgcolor={classBoxDesigns.timeBg}
                        border={classBoxDesigns.border}
                        key={tindex}
                        overflow="hidden"
                    >
                        <Box padding="10px" borderRadius={classBoxDesigns.boxBorderRadius} bgcolor={classBoxDesigns.bg}>
                            {UserStore?.getUser?.user_role !== 'STUDENT' && <Typography fontWeight="400" fontSize="12px" sx={{ color: classBoxDesigns.color }}>{`${c.course_short_name}-${c.course_branch_short_name}`}</Typography>}
                            <Typography fontWeight="600" fontSize="14px" sx={{ color: classBoxDesigns.color }}>{c.subject_name_short}</Typography>
                            {UserStore?.getUser?.user_role === 'STUDENT' && ( c?.presenter_name?.toLowerCase() || c?.empID ) && <Typography fontWeight="600" maxWidth="60px" noWrap fontSize="12px" sx={{ color: classBoxDesigns.color }} textTransform="capitalize">By <em>{c?.presenter_name?.toLowerCase() || c?.empID}</em></Typography>}
                            {UserStore?.getUser?.user_role !== 'STUDENT' && <Tooltip title={`Y:${c.current_year}, S:${c.current_sem}, ${c.batch_short_name !== 'NA' ? `Ba: ${c.batch_short_name}` : `Sec-${c.section_short_name} `}`} ><Typography fontWeight="600" maxWidth="60px" noWrap fontSize="12px" sx={{ color: classBoxDesigns.color }} textTransform="capitalize"> <strong>Y:{c.current_year}, S:{c.current_sem}, {c.batch_short_name !== 'NA' ? `Ba: ${c.batch_short_name}` : `Sec-${c.section_short_name} `}</strong> </Typography></Tooltip>}
                        </Box>
                        <Box padding="5px" bgcolor={classBoxDesigns.timeBg}>
                            <Typography textAlign="center" fontSize="10px" fontWeight="bold" color="black">{c.sTime?.split( /:\d{2}\./ )[0]} - {c.eTime?.split( /:\d{2}\./ )[0]}</Typography>
                        </Box>
                    </Box>
                } )}
                {loading && !RoomStore.getErrors[dateDetails.erp_date] === true && <Box display="flex" justifyContent="center"> <DataLoadingSpinner size={14} /></Box>}
                {!loading && !RoomStore.getErrors[dateDetails.erp_date] === true && RoomStore.getRoom[dateDetails.erp_date]?.length < 1 && <Typography variant="body2" fontSize="10px" textAlign="center">No classes</Typography>}
                {RoomStore.getErrors[dateDetails.erp_date] === true && <Box marginTop="10px" padding="5px" borderRadius="5px" bgcolor={palette.error.light + 44} display="flex" flexDirection="column" gap="2px" alignItems="center">
                    <Typography fontSize="12px" textAlign="center" variant='subtitle2'>Failed to fetch classes for the day!</Typography>
                    <Button color="errorMessage" startIcon={loading && <CircularProgress size={14} />} disabled={loading} onClick={getData} disableElevation={loading} sx={{ textTransform: "capitalize" }} >Retry</Button>
                </Box>}
            </Box>
        </Box>
    )
} )

export default CalendarDateColumn

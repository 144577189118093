import { Box, Typography, Dialog, DialogTitle, List, ListItem, ListItemText, Button, Paper, Divider } from '@mui/material'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { Icons, exportAttendanceReport } from '../../utils/utilities'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import noAttendenceImg from '../../assets/noattendence.svg'
import SelectImg from '../../assets/selectinstitute.svg'
import { useUserStore } from '../../store/user.store'
import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import api from '../../service/api'
import dayjs from 'dayjs'
import BlockMessage from '../../components/common/BlockMessage'

const columns = [
    { field: 'selected_date', headerName: 'Date', width: 110 },
    { field: 'subject_name_short', headerName: 'Subject', width: 160 },
    { field: 'institute', headerName: 'Institute', width: 160 },
    { field: 'branch_name', headerName: 'Branch', width: 160 },
    { field: 'presenter', headerName: 'Presenter', width: 160 },
    { field: 'duration', headerName: 'Duration', width: 190 }, //subject_name_short
    { field: 'attendance', headerName: 'Attendance', width: 100 }, //selected_date
]

export default function AttendanceAudit() {
    const [loading, setLoading] = React.useState( false )
    const [allAttendance, setAllAttendance] = React.useState( [] )
    const [hasPermission, setHasPermission] = useState( true )
    const [fromDate, setFromDate] = React.useState( new Date().toISOString() )
    const [toDate, setToDate] = React.useState( new Date().toISOString() )
    const [openDialog, setOpenDialog] = React.useState( false )
    const [auidList, setAuidList] = React.useState( [] )
    const [isExporting, setIsExporting] = useState( false )
    const [isAttendanceFetched, setIsAttendanceFetched] = useState( false )

    const UserStore = useUserStore()

    const submit = async () => {
        setIsAttendanceFetched( true )
        let payload = {
            "date_from": fromDate.toString().split( "T" )[0],
            "date_to": toDate.toString().split( "T" )[0]
        }
        setLoading( true )
        try {
            const res = await api.attendenceAudit( payload )
            console.log( res.data )
            setAllAttendance( res["data"]["data"] ? res["data"]["data"] : [] )
        } catch ( error ) {
            console.log( error )
        } finally {
            setLoading( false )
        }

    }

    const exportReport = async () => {
        setIsExporting( true )
        await exportAttendanceReport( allAttendance, new Date( fromDate ).toLocaleDateString( 'en-IN' ).replace( /\//g, "-" ), new Date( toDate ).toLocaleDateString( 'en-IN' ).replace( /\//g, "-" ) )
        setIsExporting( false )
    }

    const showAuid = ( e ) => {
        //console.log(e.row.student_attendence)
        setAuidList( e.row.student_attendence )
        setOpenDialog( true )
    }

    const handleClose = () => {
        setOpenDialog( false )
        setAuidList( [] )
    }

    useEffect( () => {
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.attendance_audit ) {
            setHasPermission( false )
        }
    }, [UserStore] )

    return (
        hasPermission ? <Box padding="20px" flexGrow={1} display="flex" flexDirection="column" gap="20px">
            <Box>
                <Paper sx={{ display: "flex", flexDirection: "column" }}>
                    <Box borderBottom="1px solid #d3d3d3" padding="20px">
                        <Typography variant='h5'>Attendance Audit</Typography>
                        <Typography variant='subtitle2' color="textSecondary">View all the online classes attendance details</Typography>
                        <Typography marginTop="20px" gutterBottom variant="subtitle2" component="h2">
                            Select Date Range
                        </Typography>
                        <Box sx={{ display: "flex", flexWrap: 'wrap', gap: "10px", marginTop: "5px" }}>
                            <Box sx={{ alignItems: "center", display: "flex", gap: "10px" }} noValidate autoComplete="off">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="DD-MM-YYYY"
                                        sx={{ fontSize: "12px", '& input': { padding: "10px" } }}
                                        margin="normal"
                                        id="fromdate-picker-inline"
                                        // label="From Date"
                                        value={dayjs( fromDate )}
                                        // onAccept={( e ) => setFromDate( e.toISOString() )}
                                        onChange={( e ) => setFromDate( e.toISOString() )}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}

                                    />
                                </LocalizationProvider>
                                <Typography color="textSecondary" width="fit-content" variant='subtitle2'>-</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        disableToolbar
                                        maxDate={dayjs( new Date() ).add( 1, 'day' )}
                                        sx={{ fontSize: "12px", '& input': { padding: "10px" } }}
                                        variant="inline"
                                        format="DD-MM-YYYY"
                                        margin="normal"
                                        id="todate-picker-inline"
                                        // label="To Date"
                                        value={dayjs( toDate )}
                                        // onAccept={( e ) => setFromDate( e.toISOString() )}
                                        onChange={( e ) => setToDate( e.toISOString() )}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}


                                    />
                                </LocalizationProvider>
                            </Box>
                            <Button variant='contained' disableElevation sx={{ width: { xs: "100%", md: "fit-content" } }} color="primary" onClick={submit}>
                                Search
                            </Button>
                        </Box>
                    </Box>
                    {allAttendance.length > 0 && !loading && <Box flexGrow={1}>
                        <Dialog fullWidth={true} maxWidth={'sm'} onClose={handleClose} aria-labelledby="simple-dialog-title" open={openDialog}>
                            <DialogTitle id="simple-dialog-title">Attendance List</DialogTitle>
                            <Divider />
                            <List>
                                {auidList.map( ( list ) => (
                                    <ListItem key={list}>
                                        <ListItemText primary={list} />
                                    </ListItem>
                                ) )}
                            </List>
                        </Dialog>
                        <Box sx={{ padding: "20px" }}>
                            <Box display="flex" gap="10px" marginBottom="20px" alignItems="center" justifyContent="space-between" flexDirection={{ md: "row", xs: "column" }}>
                                <Typography variant='h6'>Attendance details from {new Date( fromDate ).toLocaleDateString( 'en-IN' ).replace( /\//g, "-" )} to {new Date( toDate ).toLocaleDateString( 'en-IN' ).replace( /\//g, "-" )}</Typography>
                                <Button onClick={exportReport} startIcon={isExporting ? Icons.default.DownloadForOfflineIcon : Icons.default.DownloadIcon} disabled={isExporting} variant='contained' sx={{ textTransform: "capitalize" }}>Export as excel</Button>
                            </Box>
                            <DataGrid autoHeight={false} sx={{ borderRadius: "0 0 5px 5px", height: "500px", overflow: "auto", "&::-webkit-scrollbar": { width: "10px !important", height: "10px !important" }, "&::-webkit-scrollbar-track": { background: "#f1f1f1" }, "&::-webkit-scrollbar-thumb": { background: "#c1c1c1" } }} rows={allAttendance} onRowClick={showAuid} columns={columns} pageSize={( allAttendance.length > 10 ) ? 10 : 0} />
                        </Box>
                    </Box>}
                    {allAttendance.length === 0 && !loading && isAttendanceFetched &&
                        <Box sx={{ flexGrow: 1, padding: "20px", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                            <img src={noAttendenceImg} width="150px" alt="No attendence details for selected date range!" />
                            <Typography color="textSecondary" variant="subtitle2" marginY="20px">No attendence details for selected date range!</Typography>
                        </Box>
                    }
                    {allAttendance.length === 0 && !loading && !isAttendanceFetched &&
                        <Box sx={{ flexGrow: 1, padding: "20px", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                            <img src={SelectImg} width="150px" alt="Select a date range to get attendance report!" />
                            <Typography variant='subtitle2' color="textSecondary" marginY="20px">Select a date range to get attendance report!</Typography>
                        </Box>
                    }
                    {loading && <Box>
                        <DataLoadingSpinner waitingMessage="Loading attendance data..." />
                    </Box>}
                </Paper>
            </Box>
        </Box> :
            <Box padding="20px">
                <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
            </Box>
    )
}

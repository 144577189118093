import { observable, action, toJS, computed, makeObservable, runInAction } from 'mobx'
import api from '../service/api'
import React from 'react'

import NotificationStore from "./notification.store"
import axios from 'axios'
import { convertToCDNLink } from '../utils/utilities'

class LiveSessionStore {

    minimizedUploadProgressDialogExpand
    resourcesUploadProgressList
    showMinimizedDialog
    downloadingCount
    downloadStatuses
    livesessions
    livesession
    resources

    constructor () {
        this.minimizedUploadProgressDialogExpand = false
        this.showMinimizedDialog = false
        this.resourcesUploadProgressList = {}
        this.downloadStatuses = {}
        this.downloadingCount = 0
        this.livesession = null
        this.livesessions = []
        this.resources = []
        this.api = api
        makeObservable( this, {
            resourcesUploadProgressList: observable,
            resources: observable,
            livesession: observable,
            livesessions: observable,
            downloadStatuses: observable,
            downloadingCount: observable,
            showMinimizedDialog: observable,
            minimizedUploadProgressDialogExpand: observable,

            addResource: action,
            createSession: action,
            setUploadsList: action,
            unlinkResource: action,
            fetchResources: action,
            deleteResource: action,
            enableResource: action,
            disableResource: action,
            fetchLiveSession: action,
            fetchLiveSessions: action,
            closeMinimizedResourceDialog: action,
            minimizeResourceUploadDialog: action,

            getLiveSessions: computed,
            getLiveSession: computed,
            getDownloadStatuses: computed,
            getDownloadingCount: computed,
            getUploadProgressList: computed,
            getMinimizedUploadProgressDialogExpand: computed,
            getMinimizedDialogState: computed,
        } )
    }

    get getLiveSessions() {
        return toJS( this.livesessions )
    }

    get getLiveSession() {
        return toJS( this.livesession )
    }

    get getResources() {
        return toJS( this.resources )
    }

    get getDownloadStatuses() {
        return toJS( this.downloadStatuses )
    }

    get getDownloadingCount() {
        return toJS( this.downloadStatuses )
    }

    get getUploadProgressList() {
        return toJS( this.resourcesUploadProgressList )
    }

    get getMinimizedUploadProgressDialogExpand() {
        return toJS( this.minimizedUploadProgressDialogExpand )
    }

    get getMinimizedDialogState() {
        return toJS( this.showMinimizedDialog )
    }

    minimizeResourceUploadDialog() {
        runInAction( () => {
            this.showMinimizedDialog = true
        } )
    }

    closeMinimizedResourceDialog() {
        if ( this.minimizedUploadProgressDialogExpand ) {
            const resources = Object.keys( this.resourcesUploadProgressList )
            const newUploadProgressList = {}
            let inProgressCount = 0
            for ( const id of resources ) {
                if ( this.resourcesUploadProgressList[id].progress === 1 || ['completed', 'failed', 'cancelled'].includes( this.resourcesUploadProgressList[id].status ) )
                    continue
                newUploadProgressList[id] = this.resourcesUploadProgressList[id]
                inProgressCount++
            }
            runInAction( () => {
                this.resourcesUploadProgressList = newUploadProgressList
                this.minimizedUploadProgressDialogExpand = false
                this.showMinimizedDialog = inProgressCount !== 0
            } )
        } else {
            this.minimizedUploadProgressDialogExpand = true
        }

    }

    async createSession( payload ) {
        try {
            await this.api.liveSessions.createLiveSession( payload )
            this.fetchLiveSessions( payload.subject.subject_id )
            return true
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while creating your live session." )
        }
    }

    async fetchLiveSessions( subject_id ) {
        try {
            const { data: { success, data } } = await this.api.liveSessions.fetchLiveSessions( { subject_id } )
            runInAction( () => {
                this.livesessions = success ? data : []
            } )
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while fetching your live sessions." )
        }
    }


    async fetchLiveSession( id ) {
        try {
            const { data: { success, data } } = await this.api.liveSessions.fetchLiveSession( id )
            runInAction( () => {
                this.livesession = success ? data : null
            } )
            return { accessDenied: false }
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while fetching your live sessions." )
            return { accessDenied: err?.response?.status === 403 }
        }
    }

    async updateSession( id, payload ) {
        try {
            const { data: { success, data } } = await this.api.liveSessions.updateLiveSession( id, payload )
            if ( success )
                runInAction( () => {
                    this.livesession.details = data
                } )
            return true
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while creating your live session." )
        }
    }

    handleUploadProgress( e, resource, controller ) {
        runInAction( () => {
            this.resourcesUploadProgressList[resource.id] = { controller, progress: e.progress, name: resource.name, type: resource.type, status: e.progress === 1 ? "processing" : "inprogress", ext: resource?.resource?.name?.split( "." ).pop() }
        } )
    }

    handleDownloadProgress( e, resource, controller ) {
        if ( e.progress === 1 || e.event.returnValue || e.loaded === e.bytes )
            runInAction( () => {
                this.resourcesUploadProgressList[resource.id] = { controller, progress: 1, name: resource.name, type: resource.type, status: "completed", ext: resource?.resource?.name?.split( "." ).pop() }
            } )
    }

    setUploadsList( resources ) {
        const list = {}
        for ( let i = 0; i < resources.length; i++ ) {
            const resource = resources[i]

            const controller = new AbortController()

            list[resource.id] = { controller, progress: 0, name: resource.name, type: resource.type, status: "queued", ext: resource?.resource?.name?.split( "." ).pop() }
        }
        runInAction( () => {
            this.resourcesUploadProgressList = { ...this.resourcesUploadProgressList, ...list }
            this.minimizedUploadProgressDialogExpand = true
        } )

    }

    async addResource( payload, sessionId, resource ) {
        try {
            await this.api.liveSessions.addResource( payload, e => this.handleUploadProgress( e, resource, this.resourcesUploadProgressList[resource.id].controller ), ( e ) => this.handleDownloadProgress( e, resource, this.resourcesUploadProgressList[resource.id].controller ), this.resourcesUploadProgressList[resource.id].controller.signal )
            if ( sessionId )
                this.fetchLiveSession( sessionId )
            return true
        } catch ( err ) {
            runInAction( () => {
                this.resourcesUploadProgressList[resource.id].status = err.code === "ERR_CANCELED" ? "cancelled" : "failed"
            } )
            if ( err.code !== "ERR_CANCELED" ) {
                console.log( err )
                NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while uploading resource." )
            }
        }
    }


    async linkResource( sessionId, resourceIds ) {
        try {
            await this.api.liveSessions.linkResource( sessionId, { resources: resourceIds } )
            this.fetchLiveSession( sessionId )
            return true
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while linking resource to live session." )
        }
    }

    async unlinkResource( sessionId, id ) {
        try {
            await this.api.liveSessions.unlinkResource( sessionId, id )
            this.fetchLiveSession( sessionId )
            return true
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while unlinking resource from live session." )
        }
    }

    async enableResource( sessionId, relationId ) {
        try {
            await this.api.liveSessions.enableResource( sessionId, relationId )
            this.fetchLiveSession( sessionId )
            return true
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while enabling the resource." )
        }
    }

    async disableResource( sessionId, relationId ) {
        try {
            await this.api.liveSessions.disableResource( sessionId, relationId )
            this.fetchLiveSession( sessionId )
            return true
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while enabling the resource." )
        }
    }


    async fetchResources( params = {} ) {
        try {
            const { data: { data } } = await this.api.liveSessions.fetchResources( params )
            runInAction( () => {
                this.resources = data
            } )
            return true
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while creating your live session." )
        }
    }

    async deleteResource( id ) {
        try {
            await this.api.liveSessions.deleteResource( id )
            return true
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while creating deleting resource." )
        }
    }

    downloadProgress( e, id, details ) {
        runInAction( () => {
            const downloadProgressValue = Math.ceil( ( e.progress || ( e.loaded / e.bytes ) ) * 100 )
            this.downloadStatuses[id] = { name: details.name, progress: downloadProgressValue, type: details.resource_type, inProgress: downloadProgressValue !== 100 }
            this.downloadingCount = Object.keys( this.downloadStatuses ).filter( progress => progress.progress < 100 ).length
        } )
    }

    async downloadResource( key, session_id, relation_id, details ) {
        try {
            const { data: { data } } = await this.api.liveSessions.fetchLiveSessionResourceLink( session_id, relation_id, { key } )
            const { data: blob } = await axios.get( convertToCDNLink( data ), {
                onDownloadProgress: e => this.downloadProgress( e, relation_id, details ), responseType: 'blob'
            } )
            const blobURL = URL.createObjectURL( blob )
            const a = window.document.createElement( 'a' )
            a.href = blobURL
            a.download = `${details.name}.${details.resource_key?.split( "." ).pop()}`
            a.click()
            return true
        } catch ( err ) {
            console.log( err )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while fetching the session resource." )
        }
    }

    clearDownloaded() {
        for ( const key in this.downloadStatuses ) {
            if ( Object.hasOwnProperty.call( this.downloadStatuses, key ) ) {
                const progress = this.downloadStatuses[key]
                if ( progress.progress === 100 || isNaN( progress.progress ) )
                    delete this.downloadStatuses[key]
            }
        }
        this.downloadingCount = Object.keys( this.downloadStatuses ).filter( progress => progress.progress < 100 ).length
    }

}


const LiveSessionStoreInstance = new LiveSessionStore()

export default LiveSessionStoreInstance

const LiveSessionStoreContext = React.createContext()

export const LiveSessionStoreProvider = ( { children, store } ) => {
    return (
        <LiveSessionStoreContext.Provider value={store}>{children}</LiveSessionStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useLiveSessionStore = () => React.useContext( LiveSessionStoreContext )
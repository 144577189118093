import { Box, IconButton, Paper, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import { useUserStore } from '../../store/user.store'
import { observer } from 'mobx-react'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import SubjectStore, { SubjectStoreProvider, useSubjectStore } from '../../store/subject.store'
import BreadcrumbList from '../../components/common/BreadcrumbList'
import { Icons } from '../../utils/utilities'
import TryAgainMessageBlock from '../../components/common/TryAgainMessageBlock'


const Subject = observer( () => {

    const { pathname } = useLocation()

    const [error, setError] = useState( false )
    const [loading, setLoading] = useState( true )
    const [tabValue, setTabValue] = useState( "home" )

    // Scroll stats
    const [scrollX, setScrollX] = useState( 0 )
    const [scrollEnd, setScrollEnd] = useState( false )

    const { palette, theme } = useTheme()

    const { subject_id } = useParams()

    const tabListRef = useRef()

    const SubjectStore = useSubjectStore()
    const UserStore = useUserStore()


    const tabs = [
        { permission: true, label: "Home", link: 'home', matchers: [/home/], color: "#139CFF" },
        { permission: true, label: "AI tutor", link: 'aimentor', matchers: [/aimentor/], color: "#E9573F" },
        { permission: true, label: "Class Sessions", link: 'class-sessions', matchers: [/class-sessions/], color: "#49982E" },
        { permission: true, label: "Classes", link: 'classes', matchers: [/classes/], color: "#FF9053" },
        { permission: true, label: "Study material", link: 'materials', matchers: [/material\/?/, /material\/\d+\/?/], color: "#009B9B" },
        { permission: true, label: "Assignments", link: 'assignments', matchers: [/assignment\/?/], color: "#F49C20" },
        { permission: true, label: "Exams", link: 'exams', matchers: [/exams\/?/, /exams\/\d+\/?/], color: "#ff4d4d" },
        { permission: UserStore.getUser?.user_role !== "STUDENT", label: "Question banks", link: 'questionbank', matchers: [/questionbank\/?/, /questionbank\/\d+\/?/], color: "#227093" },
        { permission: UserStore.getUser?.user_role !== "STUDENT", label: "Quizzes", link: 'quiz', matchers: [/quiz\/?/, /quiz\/\d+\/?/], color: "#967ADC" },
        { permission: true, label: "Discussions", link: 'discussions', matchers: [/discussions\/?/], color: "#3ae374" },
    ]
    const slide = ( shift ) => {
        tabListRef.current.scrollLeft += shift
        setScrollX( scrollX + shift )

        if (
            Math.floor( tabListRef.current.scrollWidth - tabListRef.current.scrollLeft ) <=
            tabListRef.current.offsetWidth
        ) {
            setScrollEnd( true )
        } else {
            setScrollEnd( false )
        }
    }

    const handleTabsScroll = () => {
        setScrollX( tabListRef.current.scrollLeft )
        if (
            Math.floor( tabListRef.current.scrollWidth - tabListRef.current.scrollLeft ) <=
            tabListRef.current.offsetWidth
        ) {
            setScrollEnd( true )
        } else {
            setScrollEnd( false )
        }
    }

    const getData = useCallback( async () => {
        setLoading( true )
        try {
            // const { data } = await api.getUserSubjects()
            if ( UserStore.getErrorsStatuses?.subjects === true ) {
                await UserStore.fetchUserSubjects()
            }
            if ( UserStore.getErrorsStatuses?.subjects === false ) {
                SubjectStore.getSubjectData( UserStore.getUserSubjects, UserStore.getUser.user_role, subject_id )
                setError( false )
            } else
                setError( { error: true } )
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( 'ended' )
            } else setError( { code: err?.response?.status, message: err?.response?.data?.message || 'Something went wrong' } )
        } finally {
            setLoading( false )
        }
    }, [subject_id, UserStore, SubjectStore] )

    useEffect( () => {
        getData()
        if ( !tabListRef.current )
            setScrollEnd( true )
        else {
            if ( tabListRef.current && Math.floor( tabListRef.current.scrollWidth - tabListRef.current.scrollLeft ) <= tabListRef.current.offsetWidth ) {
                setScrollEnd( true )
            } else {
                setScrollEnd( false )
            }
        }
    }, [getData] )

    return (
        <Box minHeight="0" flexGrow={1} overflow="auto" display="flex" flexDirection="column" padding="20px">
            {/* Breadcrumb */}
            <BreadcrumbList items={["Course", { label: "All subjects", link: `/${UserStore.getUser.user_role === "STUDENT" ? 'student' : 'faculty'}/subjects` }, SubjectStore.getSubject ? SubjectStore.getSubject.subject_name : "", tabValue]} />

            <Paper sx={{ minHeight: "700px", flexGrow: 1, display: "flex", flexDirection: "column", overflow: "hidden" }}>
                {SubjectStore.getSubject &&
                    <Box flexShrink={0} padding="20px" borderBottom={`1px solid ${palette.borderColor}`} gap="20px" overflow="auto" display="flex" flexDirection="column">
                        <Box>
                            <Typography variant="h5" textTransform="capitalize">{SubjectStore.getSubject.subject_name}</Typography>
                            {UserStore.getUser.user_role === 'STUDENT' && <Typography display="flex" alignItems="center" gap="5px" variant='subtitle2'>Year {SubjectStore.getSubject.current_year || SubjectStore.getSubject.year} <Box width="5px" height="5px" borderRadius="50%" bgcolor="grey"></Box> Semester {SubjectStore.getSubject.current_sem || SubjectStore.getSubject.sem} </Typography>}
                        </Box>

                        <Box position="relative">
                            {scrollX !== 0 && <Box display="flex" alignItems="center" sx={{ position: "absolute", left: 0, bottom: 0, top: 0, width: "80px", background: `linear-gradient(to right, ${theme === 'dark' ? palette.common.bg : '#ffffff'} 40%, ${theme === 'dark' ? palette.common.bg : '#ffffff'}aa) 120%` }}>
                                <IconButton onClick={() => slide( -200 )} sx={{ color: palette.common.font }} size='small'>
                                    {Icons.default.KeyBoardArrowLeft}
                                </IconButton>
                            </Box>}
                            {!scrollEnd && <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ position: "absolute", right: 0, bottom: 0, top: 0, width: "80px", background: `linear-gradient(to left, ${theme === 'dark' ? palette.common.bg : '#ffffff'} 40%, ${theme === 'dark' ? palette.common.bg : '#ffffff'}aa) 120%` }}>
                                <IconButton onClick={() => slide( 200 )} sx={{ color: palette.common.font }} size='small'>
                                    {Icons.default.KeyBoardArrowRight}
                                </IconButton>
                            </Box>}
                            <Box overflow="auto" onScroll={handleTabsScroll} ref={tabListRef} className="no-scrollbar" display="flex" gap="20px" alignItems="center" >
                                {tabs.map( tab => {
                                    const isActive = tab.matchers.some( matcher => pathname.match( matcher ) )
                                    return tab.permission ? (
                                        <Link onClick={() => setTabValue( tab.label )} to={`${tab.link}`} className="default-link" key={tab.label}>
                                            <Box bgcolor={isActive ? tab.color : "transparent"} borderRadius="5px" border={`1px solid ${tab.color}`} padding="5px 15px">
                                                <Typography textAlign="center" width="max-content" color={isActive ? "white" : tab.color} variant="subtitle2">{tab.label}</Typography>
                                            </Box>
                                        </Link>
                                    ) : ""
                                } )}
                            </Box>
                        </Box>
                    </Box>}
                {!loading && !error && <Outlet />}
                {loading && !error && <DataLoadingSpinner waitingMessage="Loading subject data please wait..." />}
                {error && <TryAgainMessageBlock styles={{ flexGrow: 1 }} showErrorIcon iconSize="40px" err={error?.message} code={error.code} getData={getData} />}
            </Paper>
            {/* <Paper sx={{ flexGrow: 1, marginTop: "20px", display: "flex", flexDirection: "column" }}>
            </Paper> */}
        </Box >
    )
} )



const SubjectWrapper = () => {
    return <SubjectStoreProvider store={SubjectStore}>
        <Subject />
    </SubjectStoreProvider>
}

export default SubjectWrapper

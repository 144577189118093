import React from 'react'
import { TextField, Button, Popover, Typography, Box, useTheme, FormControl, CircularProgress, IconButton } from '@mui/material'
import { useAssignmentStore } from '../../store/assignment.store'
import { Icons, flatColorsAndBackgrounds, isIOS } from '../../utils/utilities'

const AssignmentSubmissionDownloadDialog = ( { viewSubmission, onClose, selectedValue, open, dialogType } ) => {

    const [score, setScore] = React.useState( 0 )
    const [remarks, setRemarks] = React.useState( '' )
    const [waiting, setWaiting] = React.useState( false )
    const [downloading, setDownloading] = React.useState( null )

    const AssignmentStore = useAssignmentStore()

    const { palette, border } = useTheme()

    const handleClose = () => {
        onClose( selectedValue )
    }

    const downloadSubmission = async ( value ) => {
        setDownloading( value )
        await AssignmentStore.downloadAssignment( value )
        setDownloading( null )
    }

    const submitRemarks = async () => {
        setWaiting( true )
        let payload = { "assignment_submission_id": selectedValue.assignment_submission_id, "assignment_remarks": remarks }
        await AssignmentStore.setAssignmentRemarks( payload )
        setWaiting( false )
    }

    const submit = async () => {
        setWaiting( true )
        let payload = { "assignment_submission_id": selectedValue.assignment_submission_id, "assignment_marks": score }
        await AssignmentStore.setAssignmentScore( payload )
        setWaiting( false )
    }

    return (
        <Popover anchorEl={open} onClose={handleClose} aria-labelledby="simple-dialog-title" open={Boolean( open )}>

            <Typography borderBottom={border[1]} variant='h6' fontSize="16px" padding="10px 20px" id="simple-dialog-title"> {( dialogType === 'download' ) ? 'Submissions' : dialogType === 'setremarks' ? "Set remarks" : 'Set Score'} </Typography>

            {
                ( dialogType === 'download' ) ?
                    <Box display="flex" flexDirection="column" gap="10px" borderRadius="5px">
                        {selectedValue?.assignment_submission_file_paths?.map( ( item, index ) => (
                            <Box justifyContent="space-between" display="flex" alignItems="center" padding="10px 20px" borderBottom={border[1]} color={flatColorsAndBackgrounds[2].color} gap="20px" key={item}>
                                <Typography sx={{ cursor: "pointer" }} display="flex" gap="10px" alignItems="center" variant='subtitle2' >
                                    {index + 1}. {item.split( "." )[item.split( "." ).length - 2]?.replace( /%\d+/g, " " )}
                                </Typography>
                                <Box>
                                    <IconButton size='small' onClick={() => downloadSubmission( item )}>
                                        {downloading === item ? <CircularProgress size={14} /> : Icons.small.DownloadIcon}
                                    </IconButton>
                                    {( item.endsWith( "pdf" ) || !isIOS() ) && <IconButton size='small' onClick={() => {
                                        viewSubmission( item )
                                        handleClose()
                                    }}>
                                        {Icons.small.VisibilityIcon}
                                    </IconButton>}
                                </Box>
                            </Box>
                        ) )}

                    </Box> : ''
            }
            {( dialogType === 'setmarks' ) && <Box display="flex" alignItems="flex-start" flexDirection="column" gap="10px" padding="10px" margin="20px" borderRadius="5px" border={border[1]} bgcolor={palette.form.formBg}>
                <FormControl >
                    <Typography gutterBottom variant='subtitle2' color={palette.labelColor}>Score *</Typography>
                    <TextField size="small" placeholder='Eg: 10' id="standard-basic" onChange={( e ) => setScore( e.target.value )} style={{ width: '100%' }} />
                </FormControl>
                <Button startIcon={waiting && <CircularProgress size={14} />} disabled={waiting} variant="contained" disableElevation color="secondary" onClick={submit}>
                    {waiting ? "Submitting..." : "Submit"}
                </Button>
            </Box>}
            {( dialogType === 'setremarks' ) && <Box display="flex" alignItems="flex-start" flexDirection="column" gap="10px" padding="10px" margin="20px" borderRadius="5px" border={border[1]} bgcolor={palette.form.formBg}>
                <FormControl >
                    <Typography gutterBottom variant='subtitle2' color={palette.labelColor}>Remarks *</Typography>
                    <TextField size="small" placeholder='Good job keep it up.' onChange={( e ) => setRemarks( e.target.value )} style={{ width: '100%' }} />
                </FormControl>
                <Button startIcon={waiting && <CircularProgress size={14} />} disabled={waiting} variant="contained" disableElevation color="secondary" onClick={submitRemarks}>
                    {waiting ? "Submitting..." : "Submit"}
                </Button>
            </Box>}

        </Popover>
    )
}

export default AssignmentSubmissionDownloadDialog

import { Box, Button, CircularProgress, Dialog, DialogContent, FormControl, IconButton, MenuItem, Popover, Select, TextField, Typography, useTheme } from '@mui/material'
import { ErrorMessage, Form, Field, Formik } from 'formik'
import * as Yup from 'yup'
import React, { useEffect, useState } from 'react'
import { Icons } from '../../../utils/utilities'
import { useUserStore } from '../../../store/user.store'
import { ArrowDropDown, CheckBox, CheckBoxOutlineBlankOutlined, RefreshOutlined, RemoveCircle } from '@mui/icons-material'
import { observer } from 'mobx-react'
import { useSubjectStore } from '../../../store/subject.store'
import { useLiveSessionStore } from '../../../store/livesessions.store'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'

const CreateClassSessionDialog = observer( ( { state, setState, mode = 'create', session } ) => {

    const [adding, setAdding] = useState( false )
    const [loading, setLoading] = useState( true )
    const [academicYearId, setAcademicYearId] = useState( "-" )
    const [isLoadingBatches, setIsLoadingBatches] = useState( false )
    const [accessGroupsPopoverAnchor, setAccessGroupsPopoverAnchor] = useState( false )

    const UserStore = useUserStore()
    const LiveSessionStore = useLiveSessionStore()
    const SubjectStore = useSubjectStore()

    const { palette, border, theme } = useTheme()

    const initialValues = {
        name: mode === 'edit' ? session.name : '',
        access_groups: mode === 'edit' ? session.access_groups : [],
    }

    const validationSchema = Yup.object().shape( {
        name: Yup.string().required( "Name is required" ).max( 200 ),
        access_groups: Yup.mixed().required( "Provide atleast one access group" ).test( "TEXT_ACCESS_GROUPS", "Please provide atleast one access group", val => {
            if ( !val || val?.length === 0 ) return false
            return true
        } )
    } )

    const openAccessGroupsPopover = ( e, setError ) => {
        if ( academicYearId !== "-" )
            setAccessGroupsPopoverAnchor( e.currentTarget )
        else {
            setError( 'access_groups', 'Please select academic year to select batch or section' )
        }
    }

    const handleAcademicYearChange = async ( e, setValue ) => {
        setIsLoadingBatches( true )
        setAcademicYearId( e.target.value )
        if ( UserStore.getErrorsStatuses?.academicYearsAndBatches === true )
            await UserStore.fetchUserBatchesAndSection( e.target.value )
        setIsLoadingBatches( false )
    }

    const refreshYears = async () => {
        await UserStore.fetchUserAcademicYears( true )
    }

    const refreshBatches = async () => {
        if ( academicYearId !== "-" )
            await UserStore.fetchUserBatchesAndSection( academicYearId, true )
    }

    const toggleToAccessGroup = ( values, val, setValue, id, selected ) => {
        if ( !selected )
            setValue( 'access_groups', [...values, { ...val, id: id }] )
        else {
            let index
            for ( let i = 0; i < values.length; i++ ) {
                const ag = values[i]
                if ( ag.id === id ) {
                    index = i
                    break
                }

            }
            if ( index >= 0 ) {
                let newValues = [...values]
                newValues.splice( index, 1 )
                setValue( 'access_groups', newValues )
            }
        }
    }

    const createSession = async ( values, { resetForm } ) => {
        setAdding( true )
        const subject = {
            subject_id: SubjectStore?.getSubject?.subject_id,
            subject_name: SubjectStore?.getSubject?.subject_name,
            subject_assignment_id: SubjectStore?.getSubject?.subject_assignment_id,
            subject_code: SubjectStore?.getSubject?.subject_code,
            subject_name_short: SubjectStore?.getSubject?.subject_name_short,
        }
        if ( await LiveSessionStore.createSession( { ...values, subject } ) )
            handleClose( resetForm )
        setAdding( false )
    }

    const updateSession = async ( values, { resetForm } ) => {
        setAdding( true )
        if ( await LiveSessionStore.updateSession( session.id, values ) )
            handleClose( resetForm )
        setAdding( false )
    }

    const handleClose = ( reset ) => {
        reset()
        setState( false )
    }

    useEffect( () => {
        const getData = async () => {
            if ( mode === 'edit' && !session ) {
                return setState( false )
            }
            if ( UserStore.getErrorsStatuses?.subjects === true )
                await UserStore.fetchUserSubjects()
            if ( UserStore.getErrorsStatuses?.academicYears )
                await UserStore.fetchUserAcademicYears()
            setLoading( false )
        }
        getData()
    }, [UserStore, mode, session, setState] )

    return (
        <Dialog PaperProps={{ sx: { width: "85%", maxWidth: "750px", background: palette.form.formCardBg } }} open={state}>
            <DialogContent>
                <Typography variant="h6" flexGrow={1}>Create new live session</Typography>
                <Typography variant="subtitle2" marginTop="-5px" fontStyle="italic" flexGrow={1}>For {SubjectStore.getSubject?.subject_name}</Typography>
                <Box borderRadius="5px" border={border[1]} marginTop="15px" padding="20px" bgcolor={palette.form.formBg}>
                    <Formik onSubmit={mode === 'create' ? createSession : updateSession} initialValues={initialValues} validationSchema={validationSchema}>
                        {( { values, setFieldValue, resetForm, setFieldError } ) => ( <Form>
                            <FormControl fullWidth>
                                <Typography color={palette.labelColor} variant='subtitle2' gutterBottom>Live session name *</Typography>
                                <Field size="small" type="text" name="name" as={TextField} placeholder="Eg: CSE, 2019 SECTION-A" />
                                <Typography fontSize="12px" color="error"> <ErrorMessage name='name' /> </Typography>
                            </FormControl>

                            <FormControl margin='normal' fullWidth>
                                <Typography variant="subtitle2" color={palette.labelColor} gutterBottom >Select academic year of admission</Typography>
                                <Select sx={{ color: academicYearId === "-" && palette.form.placeholder }} size="small" placeholder='Select an academic year' name="academicYear" value={academicYearId} onChange={handleAcademicYearChange}>
                                    <MenuItem sx={{ fontSize: "14px" }} key={"default"} value={'-'}>
                                        Select an academic year
                                    </MenuItem>
                                    {UserStore.getUserAcademicYears.map( ( batchYear, k ) => {
                                        return ( <MenuItem sx={{ fontSize: "14px" }} key={batchYear.ac_year_id} value={batchYear.ac_year_id}>
                                            {batchYear.ac_year}
                                        </MenuItem> )
                                    } )}
                                </Select>
                                {<Typography onClick={refreshYears} sx={{ cursor: "pointer", '&:hover': { textDecoration: "underline" } }} display="flex" gap="5px" alignItems="center" fontSize="14px" color="secondary" fontWeight="500"><RefreshOutlined fontSize='16px' />Refresh academic years</Typography>}
                            </FormControl>
                            <FormControl margin='normal' fullWidth>
                                <Typography variant="subtitle2" color={palette.labelColor} gutterBottom >Select Batch / Section *</Typography>
                                {values.access_groups.length === 0 && <Box
                                    onClick={e => openAccessGroupsPopover( e, setFieldError )}
                                    justifyContent="space-between"
                                    display="flex"
                                    alignItems="center"
                                    padding="0 15px"
                                    minHeight="40px"
                                    borderRadius="5px"
                                    border={theme === 'dark' ? `0.8px solid #505050` : `0.8px solid #d3d3d3`}
                                    color={values.access_groups?.length === 0 ? "GrayText" : palette.common.font}
                                    bgcolor={palette.form.inputBg}
                                    sx={{
                                        cursor: "pointer",
                                        '&:hover': {
                                            borderColor: theme === 'dark' ? '#fff' : "#000"
                                        }
                                    }}
                                >
                                    <Typography sx={{ color: palette.form.placeholder }}>{loading ? <DataLoadingSpinner size={14} padding="0" /> : "Eg: CSE-I, SECTION-A"}</Typography>
                                    <ArrowDropDown />
                                </Box>}
                                {academicYearId !== "-" && <Typography onClick={refreshBatches} sx={{ cursor: "pointer", '&:hover': { textDecoration: "underline" } }} display="flex" gap="5px" alignItems="center" fontSize="14px" color={"secondary"} fontWeight="500"> <RefreshOutlined fontSize='16px' />Refresh batches</Typography>}
                                {values.access_groups.length > 0 && <Box
                                    justifyContent="space-between"
                                    display="flex"
                                    alignItems="center"
                                    padding="0 15px"
                                    minHeight="40px"
                                    borderRadius="5px"
                                    border={theme === 'dark' ? `0.8px solid #505050` : `0.8px solid #d3d3d3`}
                                    color={values.access_groups?.length === 0 ? "GrayText" : palette.common.font}
                                >
                                    <Box padding="10px 0" display="flex" gap="10px" flexWrap="wrap">
                                        {values.access_groups.map( ag => (
                                            <Box padding="5px" alignItems="center" border="1px solid #d3d3d3" display="flex" gap="10px" borderRadius="5px" key={ag.id}>
                                                <Typography variant="subtitle2">{ag.batch_assignment_id ? `${ag.batch_name} - ${ag.batch_short_name}` : `${ag.course_short_name} - ${ag.section_short_name}`}</Typography>
                                                <IconButton onClick={() => toggleToAccessGroup( values.access_groups, ag, setFieldValue, ag.id, true )} size="small">
                                                    <RemoveCircle sx={{ fontSize: "18px" }} />
                                                </IconButton>
                                            </Box>
                                        ) )}
                                        <Button color="greyed" onClick={( e ) => openAccessGroupsPopover( e, setFieldError )} sx={{ textTransform: "capitalize" }} startIcon={Icons.default.AddIcon} >Add more</Button>
                                    </Box>
                                </Box>}
                                <Popover anchorReference='anchorPosition' anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2 }} transformOrigin={{ horizontal: "center", vertical: "center" }} anchorOrigin={{ horizontal: "center", vertical: "center" }} open={Boolean( accessGroupsPopoverAnchor )} onClose={() => setAccessGroupsPopoverAnchor( false )}>
                                    {academicYearId === "-" && <Typography padding="20px" variant="subtitle2">Please select academic year to access batches or sections</Typography>}
                                    {UserStore.getUsersAllBatchesAndSectionList?.filter( item => item.batch_assignment_id && item.ac_year_id === academicYearId ).length > 0 && <Typography sx={{ pointerEvents: "none" }} paddingLeft="18px" variant='subtitle2' color="secondary">Batches ({UserStore.getUsersAllBatchesAndSectionList?.filter( item => item.batch_assignment_id && item.ac_year_id === academicYearId ).length})</Typography>}
                                    {/* {UserStore.getUserBatchesAndSection["batches"]?.length !== 0 && <Typography borderBottom={`1px solid ${palette.borderColor}`} padding="10px" color="secondary">Batches</Typography>} */}
                                    <Box padding="10px">
                                        {UserStore.getUsersAllBatchesAndSectionList?.filter( item => item.batch_assignment_id && item.ac_year_id === academicYearId )?.map( ( batch, k ) => {

                                            const selected = values.access_groups.find( ag => ag.id === batch.batch_assignment_id )

                                            return ( <Box display="flex" alignItems="center" gap="5px" padding="2px 0" sx={{ fontSize: "14px", cursor: "pointer", '&:hover': { background: palette.rowHover } }} onClick={() => toggleToAccessGroup( values.access_groups, batch, setFieldValue, batch.batch_assignment_id, selected )} key={batch.batch_assignment_id} value={batch.batch_assignment_id}>
                                                {selected ? <CheckBox fontSize='small' /> : <CheckBoxOutlineBlankOutlined fontSize='small' />}
                                                <Typography variant="body2">{batch.batch_name + "-" + batch.course_name_short + " Y" + batch.current_year + " S" + batch.current_sem + " " + batch.batch_short_name}</Typography>
                                            </Box> )
                                        } )}
                                    </Box>
                                    {UserStore.getUsersAllBatchesAndSectionList?.filter( item => item.section_assignment_id && item.ac_year_id === academicYearId ).length > 0 && <Typography sx={{ pointerEvents: "none" }} paddingLeft="18px" variant='subtitle2' color="secondary">Sections ({UserStore.getUsersAllBatchesAndSectionList?.filter( item => item.section_assignment_id && item.ac_year_id === academicYearId ).length})</Typography>}
                                    {/* {UserStore.getUserBatchesAndSection["sections"]?.length !== 0 && <Typography paddingLeft="10px" color="secondary">Sections</Typography>} */}
                                    <Box padding="10px">
                                        {UserStore.getUsersAllBatchesAndSectionList?.filter( item => item.section_assignment_id && item.ac_year_id === academicYearId )?.map( ( batch, k ) => {
                                            const selected = values.access_groups.find( ag => ag.id === batch.section_assignment_id + "-" + batch.section_short_name )
                                            return ( <Box display="flex" alignItems="center" gap="5px" padding="2px 0" sx={{ fontSize: "14px", cursor: "pointer", '&:hover': { background: palette.rowHover } }} onClick={() => toggleToAccessGroup( values.access_groups, batch, setFieldValue, batch.section_assignment_id + "-" + batch.section_short_name, selected )} key={batch.section_assignment_id} value={batch.section_assignment_id + "-" + batch.section_short_name}>
                                                {selected ? <CheckBox fontSize='small' /> : <CheckBoxOutlineBlankOutlined fontSize='small' />}
                                                <Typography variant='body2'>{batch.course_branch_short_name + "-" + batch.course_short_name + " Y" + batch.current_year + " S" + batch.current_sem + " (" + batch.section_short_name + ")"}</Typography>
                                            </Box> )
                                        } )
                                        }
                                    </Box>
                                </Popover>
                                {isLoadingBatches && <Typography display="flex" alignItems="center" gap="10px" variant='subtitle2' color="textSecondary"> <CircularProgress size={14} /> Loading batches...</Typography>}
                                {academicYearId === "-" && <Typography fontSize="12px" color="GrayText">Select Academic year to access the batches and section</Typography>}
                                <Typography fontSize="12px" color="error"> <ErrorMessage name='access_groups' /> </Typography>
                            </FormControl>

                            <Box marginTop="30px" display="flex" alignItems="center" gap="20px">
                                <Button type="submit" disabled={adding} startIcon={adding ? <CircularProgress size={14} /> : Icons.default.AddIcon} variant="contained" disableElevation >{mode === 'edit' ? "Update live session" : "Create Live session"}</Button>
                                <Button onClick={() => handleClose( resetForm )} variant="contained" disableElevation color="error" >Cancel</Button>
                            </Box>
                        </Form> )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
} )

export default CreateClassSessionDialog

import { Box, Card, MenuItem, Select, Typography, useTheme } from '@mui/material'
import { useUserStore } from '../../store/user.store'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { SubjectIcon } from '../../assets/custom-icons'
import { Icons } from '../../utils/utilities'
import { observer } from 'mobx-react'
import DataLoadingSpinner from '../common/DataLoadingSpinner'
import TryAgainMessageBlock from '../common/TryAgainMessageBlock'

const SubjectsCard = observer( ( { shadow = false } ) => {

    const [selectedYear, setSelectedYear] = useState( 1 )
    const [selectedSem, setSelectedSem] = useState( 1 )
    const [semesters, setSemesters] = useState( [] )
    const [userRole, setUserRole] = useState( null )
    const [loading, setLoading] = useState( true )

    const UserStore = useUserStore()

    const { palette, theme } = useTheme()

    const handleSemesterChange = e => {
        let year = Math.ceil( e.target.value / 2 )
        year = year === 0 ? 1 : year
        setSelectedYear( year )
        setSelectedSem( e.target.value )
    }

    const getData = useCallback( async () => {
        try {
            setLoading( true )
            if ( UserStore.getErrorsStatuses?.subjects === true )
                await UserStore.fetchUserSubjects()

            setUserRole( UserStore.getUser.user_role )
            if ( UserStore.getUser.user_role === "STUDENT" ) {
                const years = Object.keys( UserStore.getUserSubjects )
                setSelectedSem( UserStore.getUser.current_sem )
                setSelectedYear( UserStore.getUser.current_year )
                let sems = []
                for ( let i = 0; i < years.length; i++ ) {
                    const year = years[i]
                    sems.push( ...Object.keys( UserStore.getUserSubjects[year] ) )
                }
                setSemesters( sems )
            } else {
                let years = {}
                let yearsList = []
                for ( let i = UserStore.getUserSubjects.length - 1; i >= 0; i-- ) {
                    const sub = UserStore.getUserSubjects[i]
                    if ( years[sub.ac_year] )
                        continue
                    yearsList.push( sub.ac_year )
                    years[sub.ac_year] = true
                }
                setSemesters( yearsList )
                setSelectedSem( yearsList.at( -1 ) )
            }
        } catch ( err ) {
            console.log( err )
        } finally {
            setLoading( false )
        }
    }, [UserStore] )

    useEffect( () => {
        getData()
    }, [getData] )

    return (
        <Card elevation={1}>
            <Box boxShadow={shadow && !['dark'].includes( theme ) && "0px 2px 5px 0px #DBDBDBAB"} flexShrink={0} minWidth="200px" overflow="hidden" padding="20px" borderRadius="10px">
                <Box display="flex" gap="20px" justifyContent="space-between" marginBottom={userRole !== "STUDENT" && "10px"} alignItems="center">
                    <Typography display="flex" alignItems="center" gap="10px" fontSize="20px" gutterBottom fontWeight="700">
                        Subjects {Icons.default.Books}
                    </Typography>
                    {UserStore.getErrorsStatuses?.subjects === false && semesters.length > 0 && !loading && <Select size='small' variant='outlined' sx={{ fontSize: "14px", padding: "0", height: "30px" }} onChange={handleSemesterChange} value={selectedSem}>
                        {semesters.map( ( sem, i ) => (
                            <MenuItem dense sx={{ fontSize: "14px" }} value={sem} key={sem}>{userRole === 'STUDENT' ? "Semester" : "Year"} {sem}</MenuItem>
                        ) )}
                    </Select>}
                </Box>
                {!loading && UserStore.getErrorsStatuses?.subjects === false && <Box overflow="auto">
                    {userRole && userRole === "STUDENT" && UserStore.getUserSubjects &&
                        <Box>
                            <Box
                                padding='0 0 10px 0'
                                gap='5px'
                                display='flex'
                                alignItems='center'
                                // borderBottom='2px solid #d3d3d3'
                                bgcolor={palette.background.paper}
                                position="sticky"
                                top="0"
                            >
                                <Typography
                                    variant='subtitle2'
                                    color='secondary'
                                >
                                    Year - {selectedYear}
                                </Typography>
                                <Box
                                    bgcolor='GrayText'
                                    height='100%'
                                    borderRadius='50%'
                                    padding='2px'
                                ></Box>
                                <Typography
                                    color='GrayText'
                                    variant='subtitle2'
                                >
                                    Semester - {selectedSem}
                                </Typography>
                            </Box>
                            {UserStore.getUserSubjects[selectedYear] && UserStore.getUserSubjects[selectedYear][selectedSem] && UserStore.getUserSubjects[selectedYear][selectedSem].length &&
                                <Box
                                    display='flex'
                                    flexDirection='column'
                                    overflow="auto"
                                    maxHeight="300px"
                                    className="slim-custom-scrollbar"
                                >
                                    {UserStore.getUserSubjects[selectedYear][selectedSem].map( ( sub ) => {
                                        return (
                                            // <FormControlLabel sx={{ "&:hover": { background: palette.rowHover }, borderBottom: "1px solid #d3d3d3", margin: 0, padding: 0 }} size="small" onChange={( e ) => toggleSubjectInclusion( e, sub, values.access_groups, setFieldValue )} control={<Checkbox sx={{ height: "40px", width: "40px", transform: "scale(0.9)" }} />} label={`${sub.subject_name}(${sub.subject_name_short})`} />
                                            <Link key={sub.subject_id} className='default-link' to={`/student/subject/${sub.subject_id}/home`}>
                                                <Box
                                                    maxWidth="300px"
                                                    display='flex'
                                                    gap='10px'
                                                    sx={{
                                                        cursor: 'pointer',
                                                        borderRadius: "5px",
                                                        '&:hover': {
                                                            background:
                                                                palette.rowHover,
                                                        },
                                                    }}
                                                    padding='5px 10px'
                                                    alignItems='center'
                                                // borderBottom='1px solid #d3d3d3'
                                                >
                                                    <img width="20px" src={SubjectIcon} alt="Icon" />
                                                    <Typography variant='subtitle2' textTransform="capitalize" fontWeight="normal">{`${sub.subject_name}(${sub.subject_name_short})`}</Typography>
                                                </Box>
                                            </Link>
                                        )
                                    } )}
                                </Box>
                            }
                        </Box>
                    }
                    {userRole && userRole !== "STUDENT" && UserStore.getUserSubjects.length > 0 &&
                        UserStore.getUserSubjects
                            .filter( ( s ) => s.ac_year === selectedSem )
                            .map( ( sub ) => {
                                return (
                                    <Link className='default-link' key={sub.subject_id} to={`/faculty/subject/${sub.subject_id}/home`}>
                                        <Box
                                            maxWidth="300px"
                                            display='flex'
                                            gap='10px'
                                            sx={{
                                                cursor: 'pointer',
                                                borderRadius: "5px",
                                                '&:hover': {
                                                    background:
                                                        palette.rowHover,
                                                },
                                            }}
                                            padding='5px 10px'
                                            alignItems='center'

                                        >
                                            <img width="20px" src={SubjectIcon} alt="Icon" />
                                            <Typography variant='subtitle2' textTransform="capitalize" fontWeight="normal">{`${sub.subject_name}(${sub.subject_name_short})`}</Typography>
                                        </Box>
                                    </Link>
                                )
                            } )}
                </Box>}
                {UserStore.getErrorsStatuses?.subjects === true && <TryAgainMessageBlock loading={loading} parent='component' getData={getData} />}
                {loading && UserStore.getErrorsStatuses?.subjects === false && <Box padding="5px" borderRadius="5px" bgcolor={palette.error.light + 44} display="flex" flexDirection="column" gap="2px" alignItems="center">
                    <DataLoadingSpinner size={14} />
                </Box>}
            </Box>
        </Card>
    )
} )

export default SubjectsCard

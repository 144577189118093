import { Box, Button, Dialog, Typography, useTheme } from '@mui/material'
import { convert } from 'html-to-text'
import React, { useCallback, useEffect, useState } from 'react'
import api from '../../service/api'
import { toast } from 'react-toastify'
import TryAgainMessageBlock from '../common/TryAgainMessageBlock'
import DataLoadingSpinner from '../common/DataLoadingSpinner'
import { Icons } from '../../utils/utilities'
import CustomWysiwyg from '../common/CustomWysiwyg'

const ExamInstructionsDialog = ( { state, setState } ) => {

    const [instructions, setInstrctions] = useState( '' )
    const [loading, setLoading] = useState( true )
    const [error, setError] = useState( null )
    const [saving, setSaving] = useState( false )

    const { border } = useTheme()

    const saveInstrctions = async () => {
        try {
            setSaving( true )
            const value = convert( instructions )?.trim()
            if ( value ) {
                await api.updateExamInstructions( { value: instructions?.trim(), exam_type: "admission_exam" } )
                setState( false )
            }

        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong while updating exam instructions" )
        } finally {
            setSaving( false )
        }
    }

    const getData = useCallback( async () => {
        try {
            setLoading( true )
            const { data } = await api.fetchExamInstructions( { exam_type: 'admission_exam' } )
            setInstrctions( data?.instructions?.instructions )
            setError( null )
        } catch ( err ) {
            console.log( err )

            setError( { code: err?.response?.status, message: err.response?.data?.message || "Something went worng while fetching the existing instructions!" } )
        } finally {
            setLoading( false )
        }
    }, [] )

    useEffect( () => {
        getData()
    }, [getData] )

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "650px" } }} open={state} onClose={() => setState( false )} >
            <Box border={border[1]} padding="10px 20px">
                <Typography variant='h6'>Set exam instructions</Typography>
            </Box>
            {!loading && !error && <Box padding="20px">
                <Box height="200px" overflow="hidden">
                    <CustomWysiwyg
                        disableDragAndDrop
                        placeholder={'Type here...'}
                        value={instructions}
                        onChange={val =>
                            setInstrctions( val )
                        }
                    />
                </Box>
                <Button startIcon={Icons.default.CheckCircleIcon} disableElevation disabled={saving} variant='contained' color="success" onClick={saveInstrctions} sx={{ marginY: "20px", textTransform: "capitalize" }}>
                    Save
                </Button>
            </Box>}
            {loading && !error && <DataLoadingSpinner waitingMessage="Loading insttructions...." />}
            {error && <TryAgainMessageBlock getData={getData} loading={loading} err={error?.message} code={error.code} />}
        </Dialog>
    )
}

export default ExamInstructionsDialog

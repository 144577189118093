import { makeObservable, observable, runInAction, action, toJS, computed } from "mobx"
import React from "react"
import api from '../service/api'
import NotificationStore from "./notification.store"
import UserStore from './user.store'

const notification = NotificationStore

class SettingsStore {
    settings
    modules
    useSettings

    constructor () {
        this.settings = []
        this.modules = []
        this.useSettings = []
        makeObservable( this, {
            settings: observable,
            modules: observable,
            setSettings: action,
            setModules: action,
            fetchSettings: action,
            getUseSettings: computed,
            getSettings: computed,
            getModules: computed
        } )
    }

    setSettings( settings ) {
        runInAction( () => {
            this.settings = settings
        } )
    }

    setModules( data ) {
        runInAction( () => {
            this.modules = data
        } )
    }

    get getUseSettings() {
        return toJS( this.useSettings )
    }

    async fetchSettings( filters ) {
        try {
            const { data } = await api.getSettings( filters )
            if ( filters.filter !== 'foruse' )
                this.setSettings( data.data )
            else {
                const allSettings = {}
                for ( let i = 0; i < data?.data?.length; i++ ) {
                    const setting = data.data[i]
                    if ( setting.setting_data_type === 'boolean' ) {
                        allSettings[setting.setting_key] = setting.value === 'true'
                    } else if ( setting.setting_data_type === 'number' ) {
                        allSettings[setting.setting_key] = parseInt( setting.value )
                    } else if ( setting.setting_data_type === 'multiple' ) {
                        allSettings[setting.setting_key] = setting.value.split( "," ).map( i => i.trim() )
                    } else allSettings[setting.setting_key] = setting.value
                }
                runInAction( () => {
                    this.useSettings = allSettings
                } )
            }
        } catch ( err ) {
            if ( err?.response?.status === 401 || err?.response?.data?.unauth || err?.response?.unauth ) {
                UserStore.setERPSessionState( 'ended' )
            } else {
                console.log( err )
                notification.setNotification( true, err?.response?.data?.message || "Error occured while fetching settings!" )
            }
        }
    }

    async fetchModules() {
        try {
            const { data } = await api.getSettingsModules()
            this.setModules( data.data )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, "Error occured while fetching settings!" )
        }
    }

    get getSettings() {
        return toJS( this.settings )
    }

    get getModules() {
        return toJS( this.modules )
    }


}
const SettingsStoreInstance = new SettingsStore()

export default SettingsStoreInstance

const SettingsStoreContext = React.createContext()

export const SettingsStoreProvider = ( { children, store } ) => {
    return (
        <SettingsStoreContext.Provider value={store}>{children}</SettingsStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useSettingsStore = () => React.useContext( SettingsStoreContext )

import StudentResumeFormStore, { StudentResumeFormStoreProvider } from '../../../store/studentresumeform.store'
import DownloadStatusListDialog from '../../study-material-components/DownloadStatusListDialog'
import { Box, Button, Dialog, DialogContent, Typography, useTheme } from '@mui/material'
import { useLayoutContext } from '../../../contexts/layout.context'
import sessionEndedImg from '../../../assets/waiting.svg'
import { useAuthStore } from '../../../store/auth.store'
import { Outlet, useNavigate } from 'react-router-dom'
import { LoginOutlined } from '@mui/icons-material'
import SideDrawer from './UserSideDrawer'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useRef } from 'react'
import Navbar from './UserNavbar'
import React from 'react'
import ResourceDownloadStatusDialog from '../../live-session/ResourceDownloadStatusDialog'
import api from '../../../service/api'


const getModuleByPathname = ( pathname ) => {
    if ( [/^(\/student\/material)\/?$/, /^(\/student\/material\/\d+)\/?$/].some( regexp => pathname.match( regexp ) ) )
        return "materials"
    else if ( [/^(\/student\/assignment)\/?$/, /^(\/student\/assignment\/\d+)\/?$/].some( regexp => pathname.match( regexp ) ) )
        return "assignment"
    else if ( [/^(\/student\/exams)\/?$/, /^(\/student\/exams\/\d+)\/?$/, /^(\/student\/scores)\/?$/, /^(\/student\/scores\/\d+\/\d+)\/?$/].some( regexp => pathname.match( regexp ) ) )
        return "exam"
    else if ( [/^(\/student\/discussions)\/?$/, /^(\/student\/discussions\/\w+)\/?$/, /^(\/student\/my-discussions)\/?$/, /^(\/student\/my-discussions\/\w+)\/?$/].some( regexp => pathname.match( regexp ) ) )
        return "discussion"
    else if ( [/^(\/student\/resume-builder)\/?$/, /^(\/student\/resume-builder\/preview)\/?$/].some( regexp => pathname.match( regexp ) ) )
        return "resume_builder"
    else if ( [/^(\/student\/academics)\/?$/].some( regexp => pathname.match( regexp ) ) )
        return "academics"
    else if ( [/^(\/student\/subject\/\d+\/class-sessions)\/?$/].some( regexp => pathname.match( regexp ) ) )
        return "class_session"
    else if ( [/^(\/student\/subject\/\d+\/aimentor)\/?$/].some( regexp => pathname.match( regexp ) ) )
        return "aimentor"
    else if ( [/^(\/student\/subject\/\d+\/materials)\/?$/].some( regexp => pathname.match( regexp ) ) )
        return "materials"
    else if ( [/^(\/student\/subject\/\d+\/assignments)\/?$/].some( regexp => pathname.match( regexp ) ) )
        return "assignment"
    else if ( [/^(\/student\/subject\/\d+\/exams)\/?$/].some( regexp => pathname.match( regexp ) ) )
        return "exam"
    else if ( [/^(\/student\/stream)\/?$/].some( regexp => pathname.match( regexp ) ) )
        return "online_class"
    else if ( [/^(\/student\/subject\/\d+\/discussion)\/?$/].some( regexp => pathname.match( regexp ) ) )
        return "discussion"
    else if ( [/^(\/student\/subjects)\/?$/, /^(\/student\/subject\/\d+)\/?$/].some( regexp => pathname.match( regexp ) ) )
        return "subjects"
    else if ( [/^(\/student\/quiz)\/?$/, /^(\/student\/quiz\/\d+\/\d+\/attempt\/\d+)\/?$/].some( regexp => pathname.match( regexp ) ) )
        return "quiz"
    else if ( [/^(\/student\/home)\/?$/].some( regexp => pathname.match( regexp ) ) )
        return "home"
    else if ( [/^(\/student\/faq)\/?$/].some( regexp => pathname.match( regexp ) ) )
        return "faq"
    else return null
}

const UserLayout = observer( ( { navHeadText } ) => {

    const { active, setMenuState, navHeight, minSidebarCollapseWidth } = useLayoutContext()

    const AuthStore = useAuthStore()
    const navigate = useNavigate()
    const { palette, theme } = useTheme()

    const previousModule = useRef( null )
    const previousModuleChangedTime = useRef( Date.now() )
    const activities = useRef( {} )

    const handleResizeOrLoad = useCallback( () => {
        if ( window.innerWidth < minSidebarCollapseWidth ) {
            setMenuState( false )
        }
        else {
            setMenuState( true )
        }
    }, [minSidebarCollapseWidth, setMenuState] )



    const registerActivities = useCallback( async ( calculateAgain = false ) => {
        try {
            const module = getModuleByPathname( active )
            let activitiesToSend = activities.current

            if ( module && calculateAgain ) {
                const now = Date.now()
                const timeSpentOnLastModule = Math.round( ( now - previousModuleChangedTime.current ) / 1000 )
                const previousModuleValue = module

                const newValues = { ...activitiesToSend }
                if ( !["subjects", "home", "faq"].includes( previousModule.current ) ) {
                    if ( !newValues[previousModuleValue] )
                        newValues[previousModuleValue] = timeSpentOnLastModule
                    else
                        newValues[previousModuleValue] = newValues[previousModuleValue] + timeSpentOnLastModule
                }
                newValues.total = newValues.total ? newValues.total + timeSpentOnLastModule : timeSpentOnLastModule
                activitiesToSend = newValues

                previousModule.current = module
                previousModuleChangedTime.current = now
            }
            await api.registerSessionTrackDetails( { activities_details: activitiesToSend, updateValue: "timeSpent" } )
            activities.current = {}
        } catch ( err ) {
            console.log( err )
        } finally {
        }
    }, [active] )

    useEffect( () => {
        if ( active !== "/" ) {
            const module = getModuleByPathname( active )

            if ( module && module !== previousModule.current ) {
                const now = Date.now()
                const timeSpentOnLastModule = Math.round( ( now - previousModuleChangedTime.current ) / 1000 )
                const previousModuleValue = previousModule.current

                if ( previousModule.current ) {
                    const newValues = { ...activities.current }
                    if ( !["subjects", "home", "faq"].includes( previousModule.current ) ) {
                        if ( !newValues[previousModuleValue] )
                            newValues[previousModuleValue] = timeSpentOnLastModule
                        else
                            newValues[previousModuleValue] = newValues[previousModuleValue] + timeSpentOnLastModule
                    }
                    newValues.total = newValues.total ? newValues.total + timeSpentOnLastModule : timeSpentOnLastModule
                    activities.current = newValues
                }

                previousModule.current = module
                previousModuleChangedTime.current = now
            }
        }
    }, [active] )

    useEffect( () => {
        window.addEventListener( 'resize', handleResizeOrLoad )
        window.addEventListener( 'load', handleResizeOrLoad )
        return () => {
            window.removeEventListener( 'resize', handleResizeOrLoad )
            window.removeEventListener( 'resize', handleResizeOrLoad )
        }
    }, [handleResizeOrLoad] )


    // useEffect( () => {
    //     const handleBeforeUnload = () => {
    //         if ( 'serviceWorker' in navigator && 'SyncManager' in window ) {
    //             navigator.serviceWorker.ready.then( registration => {
    //                 const module = getModuleByPathname( window.location.pathname )
    //                 let activitiesToSend = activities.current

    //                 if ( module ) {
    //                     const now = Date.now()
    //                     const timeSpentOnLastModule = Math.round( ( now - previousModuleChangedTime.current ) / 1000 )
    //                     const previousModuleValue = module
    //                     const newValues = { ...activitiesToSend }
    //                     if ( !["subjects", "home", "faq"].includes( previousModule.current ) ) {
    //                         if ( !newValues[previousModuleValue] )
    //                             newValues[previousModuleValue] = timeSpentOnLastModule
    //                         else
    //                             newValues[previousModuleValue] = newValues[previousModuleValue] + timeSpentOnLastModule
    //                     }
    //                     newValues.total = newValues.total ? newValues.total + timeSpentOnLastModule : timeSpentOnLastModule
    //                     activitiesToSend = newValues

    //                     previousModule.current = module
    //                     previousModuleChangedTime.current = now
    //                 }
    //                 if ( localStorage.getItem( 'id_token' ) )
    //                     registration.active?.postMessage( { timespent: activitiesToSend, token: localStorage.getItem( 'id_token' ) } )
    //             } ).catch( error => {
    //                 console.error( 'Error registering sync:', error )
    //             } )
    //         }
    //     }

    //     window.addEventListener( 'beforeunload', handleBeforeUnload )

    //     return () => {
    //         window.removeEventListener( 'beforeunload', handleBeforeUnload )
    //     }
    // }, [] )

    return (
        <Box display="flex" height="100%" bgcolor={palette.layoutBackground} overflow="auto">
            <StudentResumeFormStoreProvider store={StudentResumeFormStore}>
                <SideDrawer closeSideMenu={() => setMenuState( false )} />
                <Box
                    flexGrow={1}
                    display="flex"
                    minWidth="0"
                    position="relative"
                >
                    <Navbar onLogout={registerActivities} navHeadText={navHeadText} closeSideMenu={() => setMenuState( false )} openSideMenu={() => setMenuState( true )} />
                    {AuthStore.getErpSessionState !== "ended" &&
                        <Box
                            flexGrow={1}
                            display="flex"
                            maxHeight="100%"
                            id="page-content"
                            className={theme}
                            paddingTop={`${navHeight}px`}
                            minWidth="0"
                        >
                            <Outlet />
                        </Box>
                    }
                </Box>
                <Dialog open={AuthStore.getErpSessionState === "ended"}>
                    <DialogContent >
                        <Box display="flex" alignItems="center" gap="20px" flexDirection="column">
                            <img width="200px" src={sessionEndedImg} alt="Session ended!" />
                            <Typography textAlign="center" variant='subtitle2'>Your session seems to have ended please login again.</Typography>
                            <Button onClick={() => navigate( `/?redirect=${window.location.pathname}${window.location.search}` )} startIcon={<LoginOutlined fontSize='small' />} variant='outlined' sx={{ textTransform: "capitalize" }}>Sign In</Button>
                        </Box>
                    </DialogContent>
                </Dialog>
                <DownloadStatusListDialog />
                <ResourceDownloadStatusDialog />
            </StudentResumeFormStoreProvider>
        </Box>
    )
} )

export default UserLayout

import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, Paper, TextField, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useAdmissionExamStore } from '../../store/admission-exam.store'
import QuestionsPreviewDialog from '../../components/admission-exam/QuestionsPreviewDialog'
import { validateQuestionsImportFormatForQuestionBank } from '../../utils/exam-utilities'
import { Icons, showDateInIndianDateFormat, time24to12 } from '../../utils/utilities'
import { toast } from 'react-toastify'
import api from '../../service/api'
import SectionBlock from '../../components/admission-exam/SectionBlock'
import QuestionBlock from '../../components/admission-exam/QuestionBlock'
import { useUserStore } from '../../store/user.store'
import BreadcrumbList from '../../components/common/BreadcrumbList'
import { observer } from 'mobx-react'
import TryAgainMessageBlock from '../../components/common/TryAgainMessageBlock'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import warningImg from '../../assets/warning.svg'
import EditAdmissionExamsDialog from '../../components/admission-exam/EditAdmissionExamsDialog'
import { Lock, LockOpen, Warning } from '@mui/icons-material'
import CodeButton from '../../components/common/CodeButton'

const ConstraintsCard = ( { flag, text } ) => {

    const { palette } = useTheme()

    return (
        <Box display="flex" alignItems="center" gap="10px" border={`2px solid ${flag ? palette.success.dark : palette.errorMessage.main}`} padding="5px 15px" borderRadius="40px" >
            <Box color={flag ? palette.success.dark : palette.errorMessage.main}>{flag ? Icons.default.CheckCircleIcon : Icons.default.DoNotDisturb}</Box>
            <Box>
                <Typography variant='body2' fontSize="12px"> <strong> <em>{text} </em> </strong> </Typography>
                <Typography variant='body2' fontSize="10px">{flag ? "Enabled" : "Disabled"} </Typography>
            </Box>
        </Box>
    )
}

const ConfirmDialogAdmissionExam = ( { waiting, actionName, confirmForceAction, confirmAction, message, status, cancelAction, isReversible, examStatus } ) => {

    const { palette } = useTheme()

    const UserStore = useUserStore()

    const [confirmValue, setConfirmValue] = useState( '' )

    const handleForceDelete = async () => {
        if ( confirmValue === 'Confirm' ) {
            await confirmForceAction()
            setConfirmValue( '' )
        }
    }

    return (
        <Dialog PaperProps={{ sx: { width: "400px", minWidth: "200px", maxWidth: "500px" } }} open={status}>
            <DialogTitle sx={{ justifyContent: "space-between", display: "flex", alignItems: "center", gap: "20px" }}>
                Are you sure?
                <IconButton onClick={cancelAction}>
                    {Icons.default.CloseIcon}
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: 0 }}>
                <Box>
                    {!isReversible && <Typography padding="10px 20px" bgcolor={palette.errorMessage.main} gap="10px" display="flex" alignItems="center" color="white" fontSize="12px" fontWeight="500">
                        <Warning fontSize='small' />
                        This action cannot be undone.
                    </Typography>}
                    <Box padding="20px">
                        {examStatus !== 'Published' && <Typography variant='subtitle2'>All the questions and details of the exam will be deleted.</Typography>}
                        {examStatus === 'Published' && <Typography variant='subtitle2'>{message}</Typography>}
                        {examStatus === 'Published' && <Typography color="GrayText" fontSize="12px" variant='subtitle2'>Type <strong> <em>Confirm</em></strong> below to force delete.</Typography>}
                        {examStatus === 'Published' && <TextField color='secondary' fullWidth value={confirmValue} placeholder='Confirm' error={confirmValue !== 'Confirm'} onChange={e => setConfirmValue( e.target.value )} size='small' />}
                        {examStatus === 'Published' && <Typography color="GrayText" fontSize="10px" variant='subtitle2'>Force delete will delete an exam even if it contains participation(s). Force-deleting the exam will results in loss of all the participations data and questions.</Typography>}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions sx={{ background: palette.contentBg }}>
                {UserStore?.getUser?.user_role === 'admin' && examStatus === 'Published' && <Button startIcon={waiting && <CircularProgress size={14} />} disabled={waiting || confirmValue !== 'Confirm'} disableElevation color="errorMessage" variant='contained' onClick={handleForceDelete} sx={{ textTransform: "capitalize" }}>Force delete</Button>}
                <Button startIcon={waiting && <CircularProgress size={14} />} disabled={waiting} disableElevation color="errorMessage" variant='contained' onClick={confirmAction} sx={{ textTransform: "capitalize" }}>{actionName}</Button>
            </DialogActions>
        </Dialog>
    )
}


const AdmissionExam = observer( () => {

    const { id } = useParams()

    const [editCutoffDialogState, setEditCutoffDialogState] = useState( false )
    const [confirmDialogState, setConfirmDialogState] = useState( false )
    const [questionsPreview, setQuestionsPreview] = useState( false )
    const [hasPermission, setHasPermission] = useState( true )
    const [closingExam, setClosingExam] = useState( false )
    const [publishing, setPublishing] = useState( false )
    const [deleting, setDeleting] = useState( false )
    const [loading, setLoading] = useState( true )
    const [viewing, setViewing] = useState( null )
    const [error, setError] = useState( null )

    const AdmissionExamStore = useAdmissionExamStore()
    const UserStore = useUserStore()
    const { palette, border, shadows } = useTheme()

    const navigate = useNavigate()

    const extractQuestionFromFile = ( file, section ) => {
        return new Promise( ( resolve, reject ) => {
            const fileReader = new FileReader()
            fileReader.onload = function ( e ) {
                const { questions } = validateQuestionsImportFormatForQuestionBank( fileReader.result, "txt", undefined, section )
                resolve( questions )
            }
            fileReader.onerror = function ( e ) {
                reject( new Error( "Error reading file" ) )
            }
            fileReader.readAsText( file )
        } )
    }

    const onImportChange = ( file ) => {
        if ( file.name.includes( ".txt" ) ) {
            setQuestionsPreview( { status: "loading" } )
            const fileReader = new FileReader()
            fileReader.onload = () => {
                const { questions, errors, preview, errorLines } = validateQuestionsImportFormatForQuestionBank( fileReader.result, "txt", undefined, undefined )
                setQuestionsPreview( { status: "loaded", data: preview, file: file, type: "txt", plainData: fileReader.result, errors, questions, errorLines } )
            }
            fileReader.readAsText( file )
        } else {
            try {
                const fileReader = new FileReader()
                fileReader.onload = async () => {
                    setQuestionsPreview( { status: "loading" } )
                    const formData = new FormData()
                    formData.append( 'file', file )
                    try {
                        const res = await api.admissionExams.convertDocxToHtml( formData )
                        const { questions, errors, preview, errorLines } = validateQuestionsImportFormatForQuestionBank( res.data, "docx", undefined, undefined )
                        setQuestionsPreview( { status: "loaded", data: preview, file: file, type: "doc", errors, questions, errorLines } )
                    } catch ( err ) {
                        console.log( err )
                        toast( "Something went wrong! try again later", { containerId: "main" } )
                    }
                }
                fileReader.readAsText( file )
            } catch ( err ) {
                console.log( err )
            }
        }
    }

    const viewQuestionFile = async ( fileId, name ) => {
        setViewing( fileId )
        try {
            const { data } = await api.admissionExams.getQuestionFile( id, fileId, name?.endsWith( "txt" ) ? 'blob' : undefined )
            const txtFile = new File( [data], name, { type: "application/octet-stream" } )
            if ( name?.endsWith( "txt" ) ) {
                onImportChange( txtFile )
            } else {
                const { questions, errors, preview, errorLines } = validateQuestionsImportFormatForQuestionBank( data.file, "docx", undefined, undefined )
                setQuestionsPreview( { status: "loaded", data: preview, type: "doc", errors, questions, errorLines } )
            }
        } catch ( err ) {
            console.log( err )
        } finally {
            setViewing( null )
        }
    }

    const getExamDurationString = duration => {
        const hours = duration?.split( ":" )[0]
        const minutes = duration?.split( ":" )[1]
        return `${hours > 0 ? `${hours} Hour${hours > 1 ? "s" : ""}` : ""} ${minutes > 0 ? `${minutes} Minute${minutes > 1 ? "s" : ""}` : ""}`
    }

    const deleteExam = async () => {
        setDeleting( true )
        try {
            await api.admissionExams.deleteExam( id )
            navigate( '/admin/assessment' )
        } catch ( err ) {
            toast( err?.response?.data?.message || err?.response?.data || "Something went wrong while deleting the exam" )
        }
        setDeleting( false )
        setConfirmDialogState( false )
    }

    const forceDeleteExam = async () => {
        setDeleting( true )
        try {
            await api.admissionExams.forceDeleteExam( id )
            navigate( '/admin/assessment' )
        } catch ( err ) {
            toast( err?.response?.data?.message || err?.response?.data || "Something went wrong while deleting the exam" )
        }
        setDeleting( false )
        setConfirmDialogState( false )
    }

    const extractQuestionAndApprove = async ( files ) => {
        try {
            setPublishing( true )
            let allQuestions = []
            for ( const fileDetails of files ) {
                const { data } = await api.admissionExams.getQuestionFile( id, fileDetails.id, fileDetails.name?.endsWith( "txt" ) ? 'blob' : undefined )
                const file = new File( [data], fileDetails?.name, { type: "application/octet-stream" } )
                if ( fileDetails?.name?.endsWith( "txt" ) ) {
                    const questions = await extractQuestionFromFile( file, fileDetails.section )
                    allQuestions.push( ...questions )
                } else {
                    const { questions } = validateQuestionsImportFormatForQuestionBank( data.file, "docx", undefined, fileDetails.section )
                    allQuestions.push( ...questions )
                }
            }
            await api.admissionExams.approveExam( id, { questions: allQuestions, exam_id: id } )
            const { success, error } = await AdmissionExamStore.fetchExam( id )
            if ( success ) {
                setError( null )
            } else {
                setError( error )
            }
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data || err?.response?.data?.message || "Something went wrong while publishing the exam!", { containerId: "main" } )
        } finally {
            setPublishing( false )
        }
    }

    const toggleExaminationClosure = async () => {
        setClosingExam( true )
        try {
            await api.admissionExams.toggleClose( id, { closed: !AdmissionExamStore.getExam?.exam?.closed } )
            const { success, error } = await AdmissionExamStore.fetchExam( id )
            if ( success ) {
                setError( null )
            } else {
                setError( error )
            }
        } catch ( err ) {
            toast( err?.response?.data?.message || "Something went wrong while " )
        } finally {
            setClosingExam( false )
        }
    }

    const getExam = useCallback( async () => {
        setLoading( true )
        const { success, error } = await AdmissionExamStore.fetchExam( id )
        if ( success ) {
            setError( null )
        } else {
            setError( error )
        }
        setLoading( false )
    }, [AdmissionExamStore, id] )


    useEffect( () => {
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.admission_exams ) {
            setHasPermission( false )
        }
    }, [UserStore] )

    useEffect( () => {
        getExam()
    }, [getExam] )

    return (
        <Box padding="20px" flexGrow={1} overflow="auto">

            {hasPermission && <BreadcrumbList items={[
                { label: "Pre-Post assessments", link: "/admin/assessment" },
                !loading && AdmissionExamStore?.getExam?.exam ? AdmissionExamStore.getExam?.exam?.exam_name : <CircularProgress size={12} />
            ]} />}

            <ConfirmDialogAdmissionExam examStatus={AdmissionExamStore.getExam?.exam?.exam_status} confirmForceAction={forceDeleteExam} status={confirmDialogState} actionName="Delete" cancelAction={() => setConfirmDialogState( false )} waiting={deleting} confirmAction={deleteExam} message={<p>All the question and participation data will be deleted!</p>} />
            {editCutoffDialogState && <EditAdmissionExamsDialog examDetails={AdmissionExamStore.getExam?.exam} state={editCutoffDialogState} setState={setEditCutoffDialogState} sections={AdmissionExamStore.getExam?.sections} />}

            {!loading && hasPermission && AdmissionExamStore?.getExam?.exam && AdmissionExamStore.getExam?.exam?.exam_status === 'Draft' && <Box color="error.main" display="flex" gap="10px" alignItems="center" marginBottom="20px" padding="10px 20px" bgcolor={palette.error.light + "22"} border={`2px solid ${palette.error.main}`} borderRadius="10px" >
                <Icon color='inherit'>{Icons.default.Warning}</Icon>
                <Typography color="error.main" variant='subtitle2'>{UserStore?.getUser?.user_role?.toUpperCase() !== 'ADMIN' ? "This exam is not approved yet by the admin" : "This exam is yet to be approved! approve now to let students take this exam!"}</Typography>
            </Box>}

            {hasPermission ? <Paper sx={{ overflow: "hidden" }}>
                <QuestionsPreviewDialog readOnly setIsImporting={() => { }} getQuestions={() => { }} setQuestionsPreview={setQuestionsPreview} questionsPreview={questionsPreview} />

                {!loading && !error && <Box>
                    <Box padding="20px" bgcolor={palette.contentBg} borderBottom={border[1]} display="flex" justifyContent="space-between" gap="20px" flexWrap="wrap" alignItems="flex-end">
                        <Box>
                            <Typography variant='h6'><em>{AdmissionExamStore.getExam?.exam?.exam_name}</em></Typography>
                            {+AdmissionExamStore.getExam.exam?.cutoff_details?.totalCutoff > 0 && <Typography variant='subtitle2'> <strong> <em>Cut-off: </em> </strong> {+AdmissionExamStore.getExam.exam?.cutoff_details?.totalCutoff}%</Typography>}
                            {AdmissionExamStore.getExam.exam?.exam_constraints?.whenToStart === "approval" ? <Typography variant='body2'> <strong> <em>Exam Starts At: </em> </strong> Immediately after approval</Typography> : <Typography variant='body2'> <strong> <em>Exam Starts At: </em> </strong> {showDateInIndianDateFormat( AdmissionExamStore.getExam.exam?.exam_start_date, { month: "short" } )}, {time24to12( AdmissionExamStore.getExam?.exam?.exam_start_time )}</Typography>}
                            <Typography variant='body2'> <strong> <em>Assessment Duration: </em> </strong> {getExamDurationString( AdmissionExamStore.getExam.exam?.exam_duration )}</Typography>
                            <Typography variant='body2' display="flex" gap="5px" flexWrap="wrap"> <strong> <em>Assessment for: </em> </strong> {AdmissionExamStore.getExam.exam?.global_exam_access_courses?.map( course => (
                                <CodeButton size='small' key={course}>{course}</CodeButton>
                            ) )}  </Typography>
                        </Box>
                        {!loading && !error && <Box display="flex" gap="20px" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                            <Box display="flex" gap="10px" flexWrap="wrap" alignItems="center">
                                {AdmissionExamStore.getExam?.exam?.exam_status === 'Draft' && UserStore.getUser?.user_role?.toLowerCase() === "admin" && <Button color='errorMessage' onClick={() => setConfirmDialogState( true )} startIcon={Icons.default.DeleteForever} sx={{ textTransform: "capitalize" }} variant='contained' disableElevation >Unapprove and Delete</Button>}
                                {AdmissionExamStore.getExam?.exam?.exam_status === 'Draft' && UserStore.getUser?.user_role?.toLowerCase() === "admin" && <Button onClick={() => extractQuestionAndApprove( AdmissionExamStore.getExam?.questionFiles )} disabled={publishing} startIcon={publishing ? <CircularProgress size={14} /> : Icons.default.CheckIcon} color='success' sx={{ textTransform: "capitalize" }} variant='contained' disableElevation >Approve and publish</Button>}
                                {!AdmissionExamStore.getExam?.exam?.closed && <Button color='secondary' startIcon={Icons.default.EditIcon} sx={{ textTransform: "capitalize" }} onClick={() => setEditCutoffDialogState( true )} variant='contained' disableElevation >Edit details</Button>}
                                {AdmissionExamStore.getExam?.exam?.exam_status === 'Published' && <Button color='success' disableFocusRipple disabled disableRipple disableTouchRipple startIcon={Icons.default.CheckCircleIcon} sx={{ textTransform: "capitalize" }} variant='contained' disableElevation >Published</Button>}
                                {AdmissionExamStore.getExam?.exam?.exam_status === 'Published' && <Button color='errorMessage' onClick={() => setConfirmDialogState( true )} startIcon={Icons.default.DeleteForever} sx={{ textTransform: "capitalize" }} variant='contained' disableElevation >Delete</Button>}
                                {AdmissionExamStore.getExam?.exam?.exam_status === 'Published' && <Button disabled={closingExam} color={AdmissionExamStore.getExam?.exam?.closed ? "warning" : 'errorMessage'} onClick={toggleExaminationClosure} startIcon={closingExam ? <CircularProgress size={14} /> : AdmissionExamStore.getExam?.exam?.closed ? <LockOpen /> : <Lock />} sx={{ textTransform: "capitalize" }} variant='contained' disableElevation >{AdmissionExamStore.getExam?.exam?.closed ? closingExam ? "opening exam.." : "Open Exam" : closingExam ? "Closing exam" : "Close Exam"}</Button>}
                                {AdmissionExamStore.getExam?.exam?.exam_status !== 'Draft' && <Link className='default-link' to={`/admin/assessment/${id}/participation`}> <Button color='secondary' startIcon={Icons.default.AnalyticsIcon} sx={{ textTransform: "capitalize" }} variant='contained' disableElevation >View Participants</Button></Link>}
                            </Box>
                        </Box>}
                    </Box>

                    {AdmissionExamStore.getExam?.exam?.closed && <Typography margin="20px 20px 0 20px" padding="10px 20px" borderRadius="10px" bgcolor={palette.error.light + "44"} border={border[1]} borderColor={palette.error.dark} variant='subtitle2' color="errorMessage.main">The exam has been closed! this exam cannot be accessed by students</Typography>}

                    {/* <Typography variant='body2'> <strong> <em>Attempts Rule: </em> </strong> {AdmissionExamStore.getExam.exam?.exam_constraints?.attemptsRule?.toLowerCase()} </Typography>
                    {AdmissionExamStore.getExam.exam?.exam_constraints?.attemptsRule === 'MAXATTEMPTS' && <Typography variant='body2'> <strong> <em>Maximum attempts: </em> </strong> {AdmissionExamStore.getExam.exam?.maximum_attempts} </Typography>} */}

                    <Box padding="20px" gap="10px" display="flex" flexWrap="wrap">
                        <Box display="flex" alignItems="center" gap="10px" border={`2px solid ${palette.success.dark}`} padding="5px 15px" borderRadius="40px" >
                            <Box color={palette.success.dark}>{Icons.default.CheckCircleIcon}</Box>
                            <Box>
                                <Typography variant='body2' fontSize="12px"> <strong> <em>Attempts Rule: </em> </strong> {AdmissionExamStore.getExam.exam?.exam_constraints?.attemptsRule?.toLowerCase()} </Typography>
                                <Typography variant='body2' fontSize="10px"> Max attempts: {AdmissionExamStore.getExam.exam?.exam_constraints?.attemptsRule === 'MAXATTEMPTS' ? AdmissionExamStore.getExam.exam?.maximum_attempts : "NA"} </Typography>
                            </Box>
                        </Box>
                        <ConstraintsCard flag={AdmissionExamStore.getExam.exam?.enable_randomize} text="Randomize questions and options" />
                        <ConstraintsCard flag={AdmissionExamStore.getExam.exam?.exam_constraints?.showScoresAfterFinishingExam} text="Show Scores After Completing an Attempt" />
                        <ConstraintsCard flag={AdmissionExamStore.getExam.exam?.exam_constraints?.showDetailedViewOfTheResults} text="Show Detailed Scores Report" />
                        <ConstraintsCard flag={AdmissionExamStore.getExam.exam?.exam_constraints?.restrictMobileAttempt} text="Mobile Restriction" />
                        <ConstraintsCard flag={AdmissionExamStore.getExam.exam?.exam_constraints?.enableMonitoring} text="Window Monitoring" />
                        <ConstraintsCard flag={AdmissionExamStore.getExam.exam?.exam_constraints?.answerAllQuestions} text="Restrict finishing before answering all questions" />
                        <ConstraintsCard flag={AdmissionExamStore.getExam.exam?.exam_constraints?.receiveWorksheet} text="Ask for worksheet after candidate completes the assessment" />
                    </Box>


                    <Box padding="20px" display="flex" flexDirection="column" gap="20px">
                        <Box display="flex" alignItems="center" flexWrap="wrap" gap="10px">
                            <Typography variant='h6' fontSize="18px" color="secondary">Questions</Typography>
                            {AdmissionExamStore.getExam?.sections?.length < 2 && AdmissionExamStore.getExam?.exam?.section_question_count_map?.maxQuestionsForExam && AdmissionExamStore.getExam?.exam?.section_question_count_map?.maxQuestionsForExam[AdmissionExamStore.getExam?.sections[0]] && <Typography variant='subtitle2' color="textSecondary" >{AdmissionExamStore.getExam?.exam?.section_question_count_map?.maxQuestionsForExam[AdmissionExamStore.getExam?.sections[0]]} of {AdmissionExamStore.getExam?.exam?.section_question_count_map?.totalQuestions[AdmissionExamStore.getExam?.sections[0]]} questions</Typography>}
                        </Box>
                        {AdmissionExamStore.getExam?.sections?.length > 1 && <Box display="flex" flexDirection="column" gap="10px">
                            {AdmissionExamStore.getExam.sections.map( ( section, index ) => {
                                return (
                                    <SectionBlock viewQuestionFile={viewQuestionFile} readOnly files={AdmissionExamStore.getExam.questionFiles?.filter( q => q?.section === section )} index={index} section={section} questions={AdmissionExamStore.getExam.questions?.filter( q => q?.question_section === section )} key={index} />
                                )
                            }
                            )}
                        </Box>}
                        {AdmissionExamStore.getExam?.sections?.length < 2 && AdmissionExamStore.getExam?.exam?.exam_status === 'Draft' && AdmissionExamStore.getExam.questionFiles.length > 0 && <Box display="flex" gap="10px" >
                            {AdmissionExamStore.getExam?.questionFiles?.map( ( fileDetails, index ) => {
                                return <Box border={border[1]} boxShadow={shadows[1]} display="flex" gap="10px" alignItems="center" borderRadius="10px" padding="10px" key={index}>
                                    <Typography variant='subtitle2'>{fileDetails.name}</Typography>
                                    <IconButton size='small' disabled={viewing === index} sx={{ padding: "2px" }} onClick={() => viewQuestionFile( fileDetails.id, fileDetails.name )} >{viewing === index ? <CircularProgress size={14} /> : Icons.default.OpenInNewIcon}</IconButton>
                                </Box>
                            } )}
                        </Box>}
                        {AdmissionExamStore.getExam?.sections?.length < 2 && AdmissionExamStore.getExam.questions.length > 0 && <Box display="flex" flexDirection="column" gap="10px" >
                            {AdmissionExamStore.getExam?.questions?.map( ( question, index ) => {
                                return <QuestionBlock parent='exam' readOnly defaultCollapseState={false} question={question} index={index} key={index} />
                            } )}
                        </Box>}
                    </Box>
                </Box>}
                {loading && !error && <DataLoadingSpinner waitingMessage="Loading Exam please wait..." />}
                {error && <TryAgainMessageBlock err={error?.message} code={error?.code} getData={getExam} loading={loading} />}
            </Paper> : <Paper sx={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px", alignItems: "center" }}>
                <img src={warningImg} width="40px" alt="No permission" />
                <Typography variant='subtitle2' color="textSecondary">You doesn't have permission to access this page!</Typography>
            </Paper>}

        </Box >
    )
} )

export default AdmissionExam

import { Box, Card, Collapse, IconButton, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import { studyMaterialFormats, Icons, getFileSizeFromBytes } from '../../utils/utilities'
import materialUploadImg from '../../assets/add-file-img.svg'
import CancelIcon from '@mui/icons-material/Cancel'
import Progress from '../common/Progress'
import { useState } from 'react'
import { toast } from 'react-toastify'

const FileInput = ( { setFieldValue, size, selectedAttachments, setSelectedAttachments, isUploading, setAttachmentsNames, attachmentsNames, setAttachmentsSizes, attachmentsSizes, progress, acceptableFormats } ) => {

    const [dragOverStatus, setDragOverStatus] = useState( false )
    const { palette, border, theme } = useTheme()

    const handleFilesInsert = ( e ) => {
        const files = e.target.files
        let selectedFileList = []
        let selectedFileNameList = []
        let selectedFileSizeList = []
        let unsupportedFiles = []
        for ( let i = 0; i < files.length; i++ ) {
            const file = files[i]
            if ( !studyMaterialFormats.includes( `.${file.name?.split( "." ).pop()}` ) ) {
                unsupportedFiles.push( file.name )
                continue
            }
            selectedFileList.push( file )
            selectedFileNameList.push( file.name.split( "." ).slice( 0, -1 ).join( "" ) )
            selectedFileSizeList.push( file.size )
        }
        if ( selectedFileList.length > 0 ) {
            setSelectedAttachments( [...selectedAttachments, ...selectedFileList] )
            setAttachmentsNames( [...attachmentsNames, ...selectedFileNameList] )
            setAttachmentsSizes( [...attachmentsSizes, ...selectedFileSizeList] )
            setFieldValue( 'attachments', [...selectedAttachments, ...selectedFileList] )
        }
        if ( unsupportedFiles.length > 0 )
            toast( <p>Found unsupported file(s): <strong><em>{unsupportedFiles.join( "," )}</em></strong></p>, )
        e.target.value = ''
    }

    const handleFileNameChange = ( index, value ) => {
        let values = [...attachmentsNames]
        values[index] = value
        setAttachmentsNames( values )
    }

    const removeAttachment = ( index ) => {
        const files = [...selectedAttachments]
        const filesNames = [...attachmentsNames]
        const sizes = [...attachmentsSizes]
        files.splice( index, 1 )
        filesNames.splice( index, 1 )
        sizes.splice( index, 1 )
        setSelectedAttachments( files )
        setAttachmentsNames( filesNames )
        setAttachmentsSizes( sizes )
        setFieldValue( 'attachments', [...files] )
    }

    return (
        <Box>
            <Box
                onDragLeave={() => setDragOverStatus( false )}
                onDrop={() => setDragOverStatus( false )}
                onDragEnter={() => setDragOverStatus( true )}
                onDragOver={() => setDragOverStatus( true )}
                position="relative"
                borderRadius="5px"
                gap="10px"
                display="flex"
                padding="20px"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                minHeight="150px"
                border={`${dragOverStatus ? '4px' : '3px'} dashed ${dragOverStatus ? palette.primary.dark : palette.primary.main}`}
                bgcolor={`${palette.primary.light}11`}
            >
                <input accept={acceptableFormats} disabled={isUploading} style={{ position: "absolute", cursor: isUploading ? "not-allowed" : "pointer", opacity: 0, top: 0, left: 0, width: "100%", height: "100%" }} title="" type="file" multiple name="attachments" id="attachments" onInput={( e ) => { handleFilesInsert( e ) }} />
                {isUploading && <Progress progress={progress} size={size} />}
                {isUploading && <Typography variant="body2" fontWeight="bold" color="textSecondary">Uploading selected attachments. This could take a moment...</Typography>}
                {!dragOverStatus && <Box display="flex" flexDirection="column" alignItems="center">
                    <img src={materialUploadImg} width="90px" alt="Upload materials" />
                    {!isUploading && <Box color="textSecondary" fontSize="14px">{selectedAttachments.length > 0 ? "Drag and drop more files" : "Drag and drop to upload attachments"} <Typography variant='subtitle2' display="inline-block" sx={{ textDecoration: "underline" }} fontWeight="bold" color="customThemeColor.main">
                        Or browse
                    </Typography>
                    </Box>}
                    {!isUploading && <Typography variant="body2" color="textSecondary" textAlign="center">Accepted formats: {studyMaterialFormats.join( ", " )} </Typography>}
                </Box>}
                {dragOverStatus && <Box>
                    <Typography sx={{ pointerEvents: "none" }} className='animation-shake' color="grey" variant='h5'>Drop your materials here!</Typography>
                </Box>}
            </Box>
            <Collapse in={selectedAttachments.length > 0} sx={{ marginTop: "10px" }} >
                <Box >
                    <Typography variant='subtitle2'>Selected Attachments</Typography>
                    <Box gap="10px" marginTop="10px" display="flex" alignItems="center" flexWrap="wrap">
                        {selectedAttachments.length > 0 && selectedAttachments.map( ( attachment, index ) => (
                            <Card key={index} sx={{ position: "relative", padding: "0", border: theme === 'dark' && border[1] }}>
                                <Box display="flex" gap="10px" alignItems="center" padding="10px" >
                                    <Box width="60px" sx={{ aspectRatio: 1 }} borderRadius="5px" bgcolor={palette.primary.light + "22"} display="flex" alignItems="center" justifyContent="center">
                                        {Icons.default.FileIcon}
                                    </Box>
                                    <Box display="flex" gap="5px" flexDirection="column">
                                        <TextField onFocus={e => e.target.select()} size="small" placeholder='Enter file name here...' onChange={( e ) => handleFileNameChange( index, e.target.value )} value={attachmentsNames[index]} sx={{ width: "calc(100% - 30px)" }} inputProps={{ sx: { fontSize: "14px" } }} />
                                        <Box display="flex" justifyContent="space-between" gap="20px" alignItems="center">
                                            <Typography color="GrayText" fontSize="10px"> {attachment.name}</Typography>
                                            <Typography color="GrayText" fontSize="10px">{getFileSizeFromBytes( attachment.size )}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Tooltip title={`Remove ${attachment.name}`} sx={{ zIndex: "10", position: "absolute", top: "0", right: "0" }} placement="top">
                                    <IconButton disabled={isUploading} onClick={() => { removeAttachment( index ) }} size='small'>
                                        <CancelIcon sx={{ color: palette.primary.light + "55", "&:hover": { color: palette.primary.light + "88" } }} />
                                    </IconButton>
                                </Tooltip>
                            </Card>
                        ) )}
                    </Box>
                </Box>
            </Collapse >
        </Box >
    )
}

export default FileInput

import { Box, Button, Paper, Typography } from '@mui/material'
import thankYouImg from '../../assets/thank-you-exam-completion.svg'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const ThankYouPage = () => {

    const navigate = useNavigate()

    return (
        <Box sx={{ background: `url('/imgs/bg-pattern1.png')` }} display="flex" height="100%" flexGrow={1} alignItems="center" justifyContent="center">
            <Paper sx={{ minWidth: "220px", padding: "20px", display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}>
                <img width="250px" src={thankYouImg} alt="Alive" />
                <Typography variant="subtitle2" display="flex" alignItems="center" gap="10px">Exam completed successfully!</Typography>
                <Button variant='contained' disableElevation sx={{ textTransform: "capitalize" }} onClick={e => navigate( `/assessment`, { replace: true } )} color='primaryDark'>Back to Assessments</Button>
            </Paper>
        </Box>
    )
}

export default ThankYouPage

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import AnalyticsCountCard from '../../components/admin/AnalyticsCountCard'
import SelectInstitute from '../../components/admin/SelectInstitute'
import selectInstituteImg from '../../assets/selectinstitute.svg'
import { Divider, Typography, Paper, Box } from '@mui/material'
import BlockMessage from '../../components/common/BlockMessage'
import DescriptionIcon from '@mui/icons-material/Description'
import AssignmentIcon from '@mui/icons-material/Assignment'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import DeviceHubIcon from '@mui/icons-material/DeviceHub'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import { useUserStore } from '../../store/user.store'
import WcIcon from '@mui/icons-material/Wc'
import { observer } from 'mobx-react'
import api from '../../service/api'
import { useEffect } from 'react'
import React from 'react'

const COLORS = ['#E31A4B', '#F26922', '#F7B217', '#B6D433', '#209D48', '#3FBED5', '#745BA7', '#E31A4B', '#F26922']

const Analytics = observer( () => {
    const [instituteCode, setInstituteCode] = React.useState( '0' )
    const [isLoading, setIsLoading] = React.useState( false )
    const [facultyCount, setFacultyCount] = React.useState( 0 )
    const [studentCount, setStudentCount] = React.useState( 0 )
    const [branchCount, setBranchCount] = React.useState( 0 )
    const [branchUserCount, setBranchUserCount] = React.useState( [] )
    const [noteCount, setNoteCount] = React.useState( 0 )
    const [assignmentCount, setAssignmentCount] = React.useState( 0 )
    const [examCount, setExamCount] = React.useState( 0 )
    const [hasPermission, setHasPermission] = React.useState( true )

    const UserStore = useUserStore()

    const handleChange = async ( val ) => {
        setIsLoading( true )
        setInstituteCode( val )
        loadAllStat( val )
        setIsLoading( false )
    }

    const loadAllStat = async ( val ) => {
        setBranchUserCount( [] )
        try {
            const res = await api.getInstituteFacultyCount( val )
            setFacultyCount( res["data"]["count"] ? res["data"]["count"] : 0 )
        } catch ( err ) {
            console.log( err )
        }

        try {
            const res = await api.getInstituteStudentCount( val )
            setStudentCount( res["data"]["count"] ? res["data"]["count"] : 0 )
            // setBranchUserCount( [{ "branch": "ALL", "count": parseInt( facultyCount ) + parseInt( studentCount ) }] )
        } catch ( err ) {
            console.log( err )
        }

        try {
            const res = await api.getInstituteBranchCount( val )
            setBranchCount( res["data"]["count"] ? res["data"]["count"] : 0 )
        } catch ( err ) {
            console.log( err )
        }

        try {
            const res = await api.getInstituteStudyMaterialCount( val )
            console.log( res.data )
            setNoteCount( res["data"]["data"]["count"] ? res["data"]["data"]["count"] : 0 )
        } catch ( err ) {
            console.log( err )
        }

        try {
            const res = await api.getInstituteAssignmentCount( val )
            setAssignmentCount( res["data"]["count"] ? res["data"]["count"] : 0 )
        } catch ( err ) {
            console.log( err )
        }

        try {
            const res = await api.getInstituteExamCount( val )
            setExamCount( res["data"] ? res["data"] : 0 )
        } catch ( err ) {
            console.log( err )
        }

        try {
            const res = await api.getInstituteBranchUserCount( val )
            console.log( res["data"]["data"] )
            setBranchUserCount( res["data"]["data"] ? res["data"]["data"] : [] )
        } catch ( err ) {
            console.log( err )
        }
    }

    useEffect( () => {
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.analytics ) {
            setHasPermission( false )
        }
    }, [UserStore] )

    return (
        hasPermission ? <Box padding="20px" flexGrow={1}>
            <Paper >
                <Box borderBottom="1px solid #d3d3d3" display="flex" flexDirection={{ xs: "column", sm: "row" }} alignItems={{ sm: "center", xs: "stretch" }} gap="20px" flexWrap="wrap" justifyContent="space-between" padding="20px">
                    <Box flexGrow={1}>
                        <Typography variant='h5'>Analytics</Typography>
                        <Typography color="textSecondary" variant='subtitle2'>View all the data in one place</Typography>
                    </Box>
                    <SelectInstitute padding="0" variant="outlined" onSelect={handleChange} />
                </Box>
                {instituteCode === "0" && <Box gap="20px" padding="20px" height="70%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <img src={selectInstituteImg} width="150px" alt="Select Institute" />
                    <Typography color="textSecondary" variant='subtitle2'>Select institute to view analytics</Typography>
                </Box>}
                {instituteCode !== "0" && !isLoading && <Box sx={{ marginTop: "20px", display: "grid", gridTemplateColumns: { lg: "33% auto", xs: "1fr" }, gap: "20px" }}>
                    <Box sx={{ padding: "20px" }} >
                        <Box display="grid" gap="10px" gridTemplateColumns="1fr 1fr">
                            <Box>
                                <AnalyticsCountCard count={facultyCount} entity="Faculties" icon={<PeopleAltIcon />} />
                            </Box>
                            <Box>
                                <AnalyticsCountCard count={studentCount} entity="Students" icon={<WcIcon />} />
                            </Box>
                            <Box>
                                <AnalyticsCountCard count={branchCount} entity="Branches" icon={<DeviceHubIcon />} />
                            </Box>
                            <Box>
                                <AnalyticsCountCard count={noteCount} entity="Notes" icon={<DescriptionIcon />} />
                            </Box>
                            <Box>
                                <AnalyticsCountCard count={assignmentCount} entity="Assignments" icon={<AssignmentIcon />} />
                            </Box>
                            <Box>
                                <AnalyticsCountCard count={examCount} entity="Exams" icon={<MenuBookIcon />} />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ flexGrow: 1, height: "100%" }} >
                            <Typography padding="20px 20px 10px 20px" variant="h6" component="h6" gutterBottom>
                                User(s) across all Branches {( instituteCode !== '' ) ? ( 'of ' + instituteCode ) : ''}
                            </Typography>
                            <Divider sx={{ marginBottom: "20px" }} />
                            {branchUserCount.length > 0 && <ResponsiveContainer height="70%" width="99%">
                                <BarChart
                                    data={branchUserCount}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis fontSize="12px" dataKey="branch" angle={-90} dy={12} allowDataOverflow={false} />
                                    <YAxis fontSize="12px" />
                                    <Tooltip />
                                    {/* <Legend /> */}

                                    <Bar
                                        dataKey="count"
                                        strokeWidth={1}>
                                        {
                                            branchUserCount.map( ( entry, index ) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ) )
                                        }
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>}
                            {branchUserCount.length === 0 && <Box gap="20px" height="70%" display="flex" alignItems="center" justifyContent="center">
                                <DataLoadingSpinner waitingMessage={`Loading ${instituteCode} data...`} />
                            </Box>}
                        </Box>
                    </Box>
                </Box>}
            </Paper>
        </Box> :
            <Box padding="20px">
                <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
            </Box>
    )
} )

export default Analytics

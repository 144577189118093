import { Box, Button, Popover, TextField, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import api from '../../service/api'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

const SubmitOrEditRemarkDialog = ( { getData, attempt, anchor, mode = "submit", setAnchor } ) => {

    const [remarkValue, setRemarkValue] = useState( mode === 'edit' ? attempt.exam_participation_remark : "" )
    const [submitting, setSubmitting] = useState( false )

    const { palette } = useTheme()

    const { id } = useParams()

    const handleClose = () => {
        setAnchor( null )
    }

    const submitRemark = async () => {
        setSubmitting( true )
        try {
            if ( remarkValue && remarkValue?.trim().length > 0 ) {
                const { data } = await api.submitRemark( id, attempt.participation_id, { remarks: remarkValue } )
                getData()
                toast( data.message )
                handleClose()
            }
        } catch ( e ) {
            toast.error( e?.response?.data?.message || "Something went wrong while submitting the remark! try again" )
        } finally {
            setSubmitting( false )
        }
    }

    const open = Boolean( attempt )

    return (
        <Popover slotProps={{ paper: { sx: { width: "90%", maxWidth: "400px" } } }} open={open}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            anchorEl={anchor}
            onClose={handleClose}
        >
            <Box bgcolor={palette.greyedOut} padding="10px">
                <Box>
                    <Typography variant='subtitle2' textTransform="capitalize" color="primaryDark.main">{mode} remark</Typography>
                    <Typography fontSize="12px">For <strong>{attempt.student_id}</strong>'s  attempt: {attempt.exam_participation_attempt}</Typography>
                </Box>
            </Box>
            <TextField variant='standard' InputProps={{ disableUnderline: true, sx: { padding: "5px", borderRadius: "5px" } }} sx={{ padding: "10px", width: "calc(100% - 20px)" }} multiline rows={4} placeholder='Remark goes here...' value={remarkValue} onChange={e => setRemarkValue( e.target.value )} />
            <Box bgcolor={palette.contentBg} padding="5px 10px" display="flex" justifyContent="center">
                <Button variant="contained" disableElevation disabled={submitting} onClick={submitRemark}>{mode === 'edit' ? "Update" : mode}</Button>
            </Box>
        </Popover>
    )
}

export default SubmitOrEditRemarkDialog

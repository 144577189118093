import { createTheme } from "@mui/material"
import { grey } from "@mui/material/colors"

const darkTheme = createTheme( {
    theme: "dark",
    border: [
        "none",
        '1px solid #3E3E3E',
    ],
    table: {
        headerBg: "#404040",
        headerColor: '#A7A7A7',
        color: '#FFFFFF'
    },
    palette: {
        mode: 'dark',
        borderColor: "#3E3E3E",
        contentBg: "#232323",
        labelColor: '#797979',
        form: {
            formBg: "#121212",
            formCardBg: "#000",
            placeholder: "#7f7f7f",
            inputBg: "#232323"
        },
        shadedText: "#eee",

        nav: {
            sidebar: "#121212",
            sidebarHover: "#FFFFFF1F",
            activeSidebarItem: "#EEBBC3",
            selected: "#151D48"
        },

        defaultColor: "#fff",
        greyedOut: "#FFFFFF1F",
        common: {
            black: "#000",
            white: "#fff",
            font: "#fff",
            bg: "#121212"
        },
        layoutBackground: "#000",
        background: { paper: "#121212", default: "#121212" },
        cardBackground: "#121212",

        // Main colors
        primary: {
            main: '#139CFF',
            light: '#2ea7ff',
            dark: '#1183d6',
            contrastText: '#fff'
        },
        primaryDark: {
            main: '#eee',
            light: '#eee',
            dark: '#eee',
            contrastText: '#000'
        },
        error: {
            main: "#FF6A7F",
            light: "#FF8D9D",
            dark: "#de5f71",
            contrastText: "#fff"
        },
        errorMessage: {
            main: "#F44336",
            light: "#FF8D9D",
            dark: "#de5f71",
            contrastText: "#fff"
        },
        customThemeColor: {
            main: "#E25A26",
            light: "#d4653b",
            dark: "#d94c16",
            contrastText: "#fff"
        },
        warning: {
            main: "#ffd600",
            dark: "#E2BC00",
            light: "#ffe800",
            contrastText: "#000"
        },
        greyed: {
            main: grey[500],
            light: grey[300],
            dark: grey[700],
            contrastText: "#fff"
        },
        rowHover: "#404040"
    },
    typography: {
        fontFamily: "'Roboto', sans-serif",
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        color: "#fff",
        h6: { color: "#fff" },
        h5: { color: "#fff" },
        h4: { color: "#fff" },
        h3: { color: "#fff" },
        h2: { color: "#fff" },
        h1: { color: "#fff" },
        subtitle2: { color: "#e0e0e0" },
        subtitle1: { color: "#fff" },
        body1: { color: "#fff" },
        body2: { color: "#fff" },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: "10px",
                    backgroundImage: "none"
                }
            }
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    boxShadow: `0 10px 24px rgba(0, 0, 0, 0.8)`,
                    border: '1px solid #3E3E3E'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    background: "#232323"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    border: "none",
                    color: "#fff"
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    padding: "0",
                    borderRadius: "5px"
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 0,
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    background: "#fff8"
                }
            }
        }
    }
} )

export default darkTheme
import React, { useState } from "react"
import { Typography, Box, TextField, useTheme, FormControl, Button, FormHelperText, IconButton, Tooltip, CircularProgress, } from "@mui/material"
import KeyIcon from "@mui/icons-material/Key"
import { Formik, Field, ErrorMessage, Form } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Latex from "react-latex-next"
import { latexDelimiters } from "../../utils/utilities"
import api from "../../service/api"
import { useParams } from "react-router-dom"

export default function Submission( {
    question,
    answer,
    index,
    keyAnswer,
    options,
    refresh
} ) {


    const [isAssigned, setIsAssigned] = useState(
        answer && answer[0]?.submission_score ? true : false
    )
    const [showScheme, setShowScheme] = useState( false )
    const [submittingScore, setSubmittingScore] = useState( false )

    const { palette, border } = useTheme()

    const { id, participation_id } = useParams()

    const validationSchema = Yup.object().shape( {
        marks: Yup.number()
            .required( "Marks required" )
            .min( 0, "Minimum marks 0" )
            .max( question.question_point, `Maximum marks ${question.question_point}` ),
    } )

    const initialValues = {
        marks: ( answer && answer[0]?.submission_score ) ? answer[0]?.submission_score : "",
        question_id: question.question_id,
    }


    const getAnswerText = ( id, options ) => {
        for ( let i = 0; i < options.length; i++ ) {
            if ( id === options[i].answer_id )
                return options[i].answer_text.includes( "LATEX-" ) ? (
                    <Latex delimiters={latexDelimiters}>{options[i].answer_text.replace( "LATEX-", "" )}</Latex>
                ) : (
                    <Latex delimiters={latexDelimiters}>{options[i].answer_text}</Latex>
                )
        }
    }

    const assignMarks = async ( values, props ) => {
        try {
            setSubmittingScore( true )
            const res = await api.admissionExams.assignMarksToDescriptiveAnswer( id, participation_id, { marks: values.marks, question_id: values.question_id } )
            await refresh()
            toast( res.data )
            setIsAssigned( true )
        } catch ( e ) {
            toast.error( e.response.data, { containerId: "main" } )
        } finally {
            setSubmittingScore( false )
        }
    }

    const answeredCorrectly =
        answer && answer.some( ( ans ) => ans.submission_score > 0 )

    return (
        <Box maxWidth="600px">
            <Box
                gap={1}
                sx={{
                    "& p": { margin: 0, display: "inline-block" },
                    flexDirection: { sm: "row", md: "row", lg: "row", xs: "column" },
                    alignItems: {
                        sm: "center",
                        md: "center",
                        lg: "center",
                        xs: "flex-start",
                    },
                }}
                alignItems="center"
                color="textSecondary"
            >
                <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-between"
                    gap="20px"
                >
                    <Typography variant="subtitle2" color="textSecondary" fontSize="12px">
                        <em>Question {index + 1}</em>
                    </Typography>
                    <Box
                        display="flex"
                        fontSize="12px"
                        gap="5px"
                        color={answeredCorrectly ? "#22DD3B" : "#E4586C"}
                        fontWeight="600"
                        alignItems="center"
                    >
                        <Typography
                            bgcolor={answeredCorrectly ? "#22DD3B" : "#E4586C"}
                            textAlign="center"
                            fontSize="12px"
                            noWrap
                            sx={{
                                width: "20px",
                                display: "flex",
                                alignItems: "center",
                                color: "white",
                                justifyContent: "center",
                                borderRadius: "50%",
                                margin: 0,
                                padding: 0,
                                aspectRatio: 1 / 1,
                            }}
                        >
                            {answer && answer[0].submission_score
                                ? answer[0].submission_score
                                : 0}
                        </Typography>{" "}
                        OUT OF {question.question_point}
                    </Box>
                </Box>

                <Box color={palette.common.font} textAlign="justify">
                    <Latex delimiters={latexDelimiters} >{question.question_text?.trim()?.replace( /LATEX-/gi, "" )}</Latex>
                    {/* {question.question_text.trim().includes( 'LATEX-' ) ? <EditableMathField latex={question.question_text.trim().split( "LATEX-" )[1]} id='static-latex'> </EditableMathField> : parse( question.question_text.trim() )} */}

                    {question.question_type !== 'MCQ' && <Tooltip title="Show key answer">
                        <IconButton onClick={e => { setShowScheme( !showScheme ) }} sx={{ fontSize: "10px" }}> <KeyIcon sx={{ fontSize: "20px" }} /> </IconButton>
                    </Tooltip>}
                </Box>

            </Box>
            {showScheme &&
                <Box sx={{ fontSize: "12px", padding: "20px", border: "#eee 2px solid", borderRadius: "5px", marginBottom: "5px", "& p": { margin: 0 } }}>
                    <Typography variant='h6' color="secondary" textAlign="justify">Key answer</Typography>
                    <Latex delimiters={latexDelimiters} >{keyAnswer.answer_text}</Latex>
                </Box>
            }
            <Box display="flex" marginTop="10px" padding="10px" borderRadius="5px" flexDirection={question?.question_type === 'MCQ' ? "row" : "column"} gap="10px" flexWrap="wrap" border={border[1]} borderColor={answeredCorrectly ? "#22DD3B" : "#E4586C"} alignItems={question?.question_type === 'MCQ' ? "center" : "flex-start"}>
                <Typography variant='body2' >Answer: </Typography>
                {answer && <Box flexGrow="1" sx={{ background: palette.contentBg, borderRadius: "5px", fontSize: "14px", "& p": { margin: "0" } }}>
                    <Box display="block" className='wysiwyg-text-container'>
                        {answer.map( ( ans, index ) => {
                            return ans.answer_id ? (
                                <Box className='wysiwyg-text-container' key={ans.answer_id ? ans.answer_id : index} textAlign="justify" fontSize="14px" borderRadius="5px"  >{getAnswerText( ans.answer_id, options )}</Box>
                            ) : ( < Latex delimiters={latexDelimiters} > {ans.answer_descriptive} </Latex> )
                        } )}
                    </Box>
                </Box>}
                {!answer && <Typography variant='subtitle2' flexGrow="1" fontSize="14px" sx={{ background: palette.contentBg, borderRadius: "5px", fontSize: "14px", "& p": { margin: "0" } }} color="error">Not answered</Typography>}
            </Box>

            {answer && question.question_type !== "MCQ" && (
                <Formik
                    onSubmit={assignMarks}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {( { values, setFieldValue } ) => (
                        <>
                            <Form autoComplete="off" method="POST">
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginTop: "10px",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "10px",
                                        }}
                                    >
                                        <FormControl>
                                            <Field
                                                size="small"
                                                as={TextField}
                                                disabled={isAssigned || submittingScore}
                                                onWheel={( e ) => e.target.blur()}
                                                type="number"
                                                name="marks"
                                                inputProps={{ step: 0.5 }}
                                                variant="outlined"
                                                placeholder={`Assign marks, max marks: ${question.question_point}`}
                                            />
                                            <input
                                                type="hidden"
                                                name="question_id"
                                                value={question.question_id}
                                            />
                                        </FormControl>
                                        <Button
                                            size="small"
                                            type="submit"
                                            disableElevation
                                            startIcon={submittingScore && <CircularProgress size={14} />}
                                            variant="contained"
                                            disabled={isAssigned || submittingScore}
                                            sx={{
                                                textTransform: "capitalize",
                                                width: "fit-content",
                                            }}
                                        >
                                            {submittingScore ? "submitting..." : "Submit"}
                                        </Button>
                                        {isAssigned && (
                                            <Button
                                                disableElevation
                                                onClick={( e ) => setIsAssigned( false )}
                                                variant="contained"
                                                sx={{
                                                    textTransform: "capitalize",
                                                    width: "fit-content",
                                                }}
                                            >
                                                Edit
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                            </Form>
                            <FormHelperText sx={{ color: palette.error.main }}>
                                <ErrorMessage name="marks" />
                            </FormHelperText>
                        </>
                    )}
                </Formik>
            )}
        </Box>
    )
}

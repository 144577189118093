import { createTheme } from "@mui/material"
import defaultTheme from "./default"
import { grey } from "@mui/material/colors"

const lightTheme = createTheme( {
    theme: 'light',
    border: [
        "none",
        '1px solid #D7D7D7',
    ],
    table: {
        headerBg: "#F7F7F7",
        headerColor: '#A3A3A3',
        color: '#464E5B'
    },
    palette: {
        common: { white: "#fff", black: "#000", font: "#000", bg: "#fff" },
        mode: 'light',
        borderColor: "#D7D7D7",
        contentBg: "#FCFCFC",
        labelColor: '#797979',
        form: {
            formCardBg: "#fff",
            formBg: "#FCFCFC",
            placeholder: "#b3b3b3",
            inputBg: "#fff"
        },
        defaultColor: "#000",
        shadedText: "#373737",

        nav: {
            sidebar: "linear-gradient(90deg, #030034 25.54%, #0A009A 205.66%)",
            sidebarHover: "#FFFFFF1F",
            activeSidebarItem: "#EEBBC3",
            selected: "#151D48"
        },

        greyedOut: "#EFF6FF",
        layoutBackground: "#f7f7fa",
        background: { paper: "#fff", default: "#fff" },
        cardBackground: "#fff",
        contrastText: "#000",

        primary: {
            main: '#139CFF',
            light: '#2ea7ff',
            dark: '#1183d6',
            contrastText: '#fff'
        },
        primaryDark: {
            main: '#05004E',
            light: '#24327d',
            dark: '#101636',
            contrastText: '#fff'
        },
        error: {
            main: "#FF6A7F",
            light: "#FF8D9D",
            dark: "#de5f71",
            contrastText: "#fff"
        },
        errorMessage: {
            main: "#F44336",
            light: "#FF8D9D",
            dark: "#de5f71",
            contrastText: "#fff"
        },
        customThemeColor: {
            main: "#E25A26",
            light: "#d4653b",
            dark: "#d94c16",
            contrastText: "#fff"
        },
        warning: {
            main: "#ffd600",
            dark: "#E2BC00",
            light: "#ffe800",
            contrastText: "#000"
        },
        greyed: {
            main: grey[500],
            light: grey[300],
            dark: grey[700],
            contrastText: "#fff"
        },
        rowHover: "#f7f7f7"
    },
    shadows: [
        "none",
        "0px 2px 5px 0px #DBDBDBAB",
        ...defaultTheme.shadows.slice( 2 )
    ],
    typography: {
        fontFamily: "'Roboto', sans-serif",
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: "10px"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    border: "none"
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    background: "#fff"
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    padding: "0",
                    borderRadius: "5px"
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 0,
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        background: defaultTheme.palette.secondary.dark,
                        color: "#fff"
                    },
                    '&.Mui-selected:hover': {
                        background: defaultTheme.palette.secondary.dark + "dd",
                        color: "#fff"
                    }
                }
            }
        }
    }
} )

export default lightTheme
import { Box, Button, Card, Typography } from '@mui/material'
import { Icons } from '../../utils/utilities'
import sessionEnded from '../../assets/noclasses.svg'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useUserStore } from '../../store/user.store'

const StreamSessionEnded = () => {

    const [loading, setLoading] = useState( true )
    const [link, setLink] = useState( null )

    const UserStore = useUserStore()

    useEffect( () => {
        const getData = async () => {
            setLink( ['admin', 'subadmin'].includes( UserStore.getUser.user_role?.toLowerCase() ) ? "/admin/home" : UserStore.getUser.user_role?.toLowerCase() === 'student' ? "/student/home" : "/faculty/home" )
            setLoading( false )
        }
        getData()
    }, [UserStore] )

    return (
        !loading ? <Box display="flex" alignItems="center" justifyContent="center" overflow="hidden" height="100%">
            <Card sx={{ width: "90%", maxWidth: "350px" }}>
                <Box display="flex" gap="20px" flexDirection="column" alignItems="center" justifyContent="center" padding="20px">
                    <img src={sessionEnded} width="150px" alt="Class ended" />
                    <Typography textAlign="center" variant="subtitle2">The session ended</Typography>
                    <Link to={link} className="default-link" target='_top' >
                        <Button sx={{ textTransform: "capitalize" }} startIcon={Icons.default.ArrowBack} >
                            Back to home
                        </Button>
                    </Link>
                </Box>
            </Card>
        </Box> : ""
    )
}

export default StreamSessionEnded

import { Box, Button, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import noQuestionBanksImg from '../../../../assets/illustrations/no-questions_bank_questions.svg'
import { useQuizAndQuestionbankStore } from '../../../../store/quiz.store'
import React, { useEffect, useState } from 'react'
import { Icons } from '../../../../utils/utilities'
import QuestionsPreviewDialog from '../../../../components/exams-components/QuestionsPreviewDialog'
import DataLoadingSpinner from '../../../../components/common/DataLoadingSpinner'
import CreateTopicDialog from '../../../../components/exams-components/quiz-questionbank/CreateTopicDialog'
import { observer } from 'mobx-react'
import TopicBlock from '../../../../components/exams-components/quiz-questionbank/TopicBlock'
import ConfirmDialog from '../../../../components/common/ConfirmDialog'
import api from '../../../../service/api'
import { toast } from 'react-toastify'
import { useSubjectStore } from '../../../../store/subject.store'
import { validateQuestionsImportFormatForQuestionBank } from '../../../../utils/exam-utilities'
import { convert } from 'html-to-text'


const ManageNewQuestionBank = observer( ( { setHasNewQuestionBankSaved, bank_id, setQuestionBankMode } ) => {

    const [loading, setLoading] = useState( true )
    const [deleting, setDeleting] = useState( false )
    const [isImporting, setIsImporting] = useState( false )
    const [selectedTopicId, setSelectedTopicId] = useState( null )
    const [questionsPreview, setQuestionsPreview] = useState( false )
    const [addTopicDialogState, setAddTopicDialogState] = useState( false )
    const [deleteConfirmDialogState, setDeleteConfirmDialogState] = useState( false )

    const { palette, table, border } = useTheme()

    const QuizStore = useQuizAndQuestionbankStore()
    const SubjectStore = useSubjectStore()

    const deleteQuestionBank = async () => {
        setDeleting( true )
        if ( await QuizStore.deleteQuestionBank( bank_id ) ) {
            setDeleteConfirmDialogState( false )
            setHasNewQuestionBankSaved( false )
            setQuestionBankMode( 'select' )
        }
        setDeleting( false )
    }

    const editQuestionbankName = async ( e ) => {
        try {
            if ( e.type === 'keydown' && e.key === "Enter" ) {
                e.preventDefault()
            }
            const val = e.target.innerText
            if ( e.type === "blur" && val?.length > 0 && val !== QuizStore.getQuestionBank?.name ) {
                await api.quiz.editQuestionbankName( bank_id, { name: val } )
                QuizStore.fetchQuestionBank( bank_id )
                toast( "Update successful" )
            }
        } catch ( err ) {
            toast( "Something went wrong!" )
        }
    }

    const fetchQuestionBanks = async () => {
        setHasNewQuestionBankSaved( true )
        await QuizStore.fetchQuestionBanks()
        setQuestionBankMode( 'select' )
    }

    const onImportChange = async ( e, topicId ) => {
        if ( e.target.files[0].name.includes( ".txt" ) ) {
            setQuestionsPreview( { status: "loading" } )
            const fileReader = new FileReader()
            fileReader.onload = () => {
                const { questions, errors, preview, errorLines } = validateQuestionsImportFormatForQuestionBank( fileReader.result, "txt", bank_id, topicId )
                setQuestionsPreview( { status: "loaded", data: preview, file: e.target.files[0], type: "txt", plainData: fileReader.result, errors, questions, errorLines } )
                e.target.value = ""
            }
            fileReader.readAsText( e.target.files[0] )
        } else {
            try {
                const fileReader = new FileReader()
                fileReader.onload = async () => {
                    setQuestionsPreview( { status: "loading" } )
                    const formData = new FormData()
                    formData.append( 'file', e.target.files[0] )
                    try {
                        const res = await api.convertDocxToHtml( formData )
                        const { questions, errors, preview, errorLines } = validateQuestionsImportFormatForQuestionBank( res.data, "docx", bank_id, topicId )
                        setQuestionsPreview( { status: "loaded", data: preview, file: e.target.files[0], type: "doc", plainData: convert( fileReader.result ), errors, questions, errorLines } )
                        e.target.value = ''
                    } catch ( err ) {
                        console.log( err )
                        toast( "Something went wrong! try again later" )
                    }
                }
                fileReader.readAsText( e.target.files[0] )
            } catch ( err ) {
                console.log( err )
            }
        }
    }

    useEffect( () => {
        const getData = async () => {
            console.log( "New Bank: ", bank_id )
            const errorCode = await QuizStore.fetchQuestionBank( bank_id )
            if ( errorCode )
                console.log( errorCode )
            console.log( QuizStore.getQuestionBank )
            setLoading( false )
        }
        getData()
    }, [QuizStore, bank_id, SubjectStore] )

    return (
        <Box display="flex" flexDirection="column" flexGrow={1} overflow="auto">

            <QuestionsPreviewDialog isImporting={isImporting} bankId={bank_id} topicId={selectedTopicId} fromComponent="questionbank" setIsImporting={setIsImporting} getQuestions={() => { }} parent="questionbank" setQuestionsPreview={setQuestionsPreview} questionsPreview={questionsPreview} />
            <CreateTopicDialog bankId={bank_id} topicId={selectedTopicId} fromComponent="questionbank" state={addTopicDialogState} setState={setAddTopicDialogState} />
            <ConfirmDialog waiting={deleting} message="All the associated topics and questions will be deleted, are you sure?" actionName="Yes, delete it!" status={deleteConfirmDialogState} confirmAction={deleteQuestionBank} cancelAction={() => setDeleteConfirmDialogState( false )} />

            <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }} elevation={0}>
                <Box padding="0 20px 15px 20px">
                    <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap="10px" >
                        <Box display="flex" gap="10px" alignItems="center">
                            <Typography border="1px solid #fff" borderRadius="5px" variant="h6">
                                <div onBlur={editQuestionbankName} onKeyDown={editQuestionbankName} className='editable-name-element slim-custom-scrollbar' contentEditable suppressContentEditableWarning>
                                    {QuizStore.getQuestionBank?.name}
                                    <IconButton disabled size="small">
                                        {Icons.default.EditIconOutlined}
                                    </IconButton>
                                </div>
                            </Typography>
                        </Box>
                        <Box display="flex" flexWrap="wrap" gap="12px" alignItems="center">
                            <Tooltip title={( !QuizStore.getQuestionBank?.questions || QuizStore.getQuestionBank?.questions.length === 0 ) ? "Please add questions to save the question bank" : "Save to use this question bank"}>
                                <span><Button variant='contained' disableElevation startIcon={Icons.default.CheckIcon} disabled={( !QuizStore.getQuestionBank?.questions || QuizStore.getQuestionBank?.questions.length === 0 )} onClick={fetchQuestionBanks} color="success" sx={{ textTransform: "capitalize" }}>Save</Button></span>
                            </Tooltip>
                            <Button variant='contained' disableElevation startIcon={Icons.default.AddIcon} onClick={() => setAddTopicDialogState( true )} color="primary" sx={{ textTransform: "capitalize" }}>Add Topic</Button>
                            <Button variant='contained' disableElevation startIcon={Icons.default.CancelIcon} onClick={() => setDeleteConfirmDialogState( true )} color="error" sx={{ textTransform: "capitalize" }}>Cancel</Button>
                        </Box>
                    </Box>
                </Box>
                <Box flexGrow={1} display="flex" flexDirection="column" padding="0 20px 20px 20px">
                    <Box border={border[1]} flexGrow={1} borderRadius="5px" overflow="hidden" bgcolor={palette.contentBg}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" bgcolor={table.headerBg} padding="10px 20px">
                            <Typography display="flex" gap="5px" alignItems="center" > {Icons.default.ViewListIcon} Questions List</Typography>
                        </Box>
                        {
                            QuizStore.getQuestionBank?.topics?.map( topic => (
                                <TopicBlock onImportChange={onImportChange} newBankId={bank_id} fromComponent="newQuestionBank" setSelectedTopicId={setSelectedTopicId} key={topic.id} topic={topic} questions={QuizStore.getQuestionBank?.formattedQuestions[topic.id] || []} />
                            ) )
                        }
                        {
                            !loading && ( ( !QuizStore.getQuestionBank?.questions || QuizStore.getQuestionBank?.questions.length === 0 ) && ( !QuizStore.getQuestionBank?.topics || QuizStore.getQuestionBank?.topics.length === 0 ) ) && <Box display="flex" padding="20px" alignItems="center" flexDirection="column" gap="20px" bgcolor={palette.contentBg}>
                                <img src={noQuestionBanksImg} width="200px" alt="No questions" />
                                <Typography variant="subtitle2" >No questions or topics in this question bank</Typography>
                                <Box display="flex" gap="10px" alignItems="center">
                                    <Button startIcon={Icons.default.AddIcon} onClick={() => setAddTopicDialogState( true )} color="primaryDark" variant="outlined" >Add new Topic</Button>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
                {loading && <DataLoadingSpinner waitingMessage="Loading the question bank..." />}
            </Box >
        </Box >
    )
} )

export default ManageNewQuestionBank

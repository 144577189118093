import { observable, action, toJS, computed, makeObservable, runInAction } from 'mobx'
import React from 'react'
import StudyMaterialStore from './studymaterial.store'
import axios from 'axios'
import NotificationStore from './notification.store'
import { downloadZip } from 'client-zip'
import { convertToCDNLink } from '../utils/utilities'

class DownloadStatusList {
    dialogStatus
    progressStatusList
    statusList
    tempProgressValues
    downloadingCount

    constructor () {
        this.dialogStatus = false
        this.progressStatusList = {}
        this.tempProgressValues = {}
        this.downloadingCount = 0

        makeObservable( this, {
            downloadingCount: observable,
            dialogStatus: observable,
            progressStatusList: observable,
            setProgressStatusList: action,
            downloadMultipleAttachments: action,
            downloadAttachment: action,
            downloadMaterial: action,
            closeDialog: action,
            getProgressStatusList: computed,
            getDialogStatus: computed,
            openDialog: action
        } )
    }

    get getProgressStatusList() {
        return toJS( this.progressStatusList )
    }

    get getDialogStatus() {
        return this.dialogStatus
    }

    get getDownloadingCount() {
        return toJS( this.downloadingCount )
    }

    setDialogStatus( status ) {
        runInAction( () => {
            this.dialogStatus = status
        } )
    }

    setProgressStatusList( list ) {
        runInAction( () => {
            this.progressStatusList = list
        } )
    }

    async downloadMaterial( material_id, data ) {
        runInAction( () => {
            this.progressStatusList[`material-${material_id}`] = { status: "queued", name: data.study_material_name, type: "material", progress: 0, inProgress: true }
        } )
        this.setDialogStatus( true )
        const attachmentsUrl = await StudyMaterialStore.downloadMaterial( material_id )

        runInAction( () => {
            this.progressStatusList[`material-${material_id}`]["progress"] = 10
        } )
        let filesUrl = []

        for ( const [i, url] of attachmentsUrl?.entries() ) {
            const urlBlob = await fetch( convertToCDNLink( url ) )
            filesUrl.push( urlBlob )

            runInAction( () => {
                this.progressStatusList[`material-${material_id}`]["progress"] = Math.ceil( 10 + ( ( i + 1 ) * 80 / attachmentsUrl.length ) )
                this.progressStatusList[`material-${material_id}`]["status"] = "inprogress"
            } )
            if ( i === attachmentsUrl.length - 1 ) {
                try {
                    const blob = await downloadZip( filesUrl ).blob()
                    const link = URL.createObjectURL( blob )
                    const a = document.createElement( "a" )
                    a.href = link
                    a.download = data.study_material_name
                    a.click()

                    runInAction( () => {
                        this.progressStatusList[`material-${material_id}`]["progress"] = 100
                        this.progressStatusList[`material-${material_id}`]["inProgress"] = false
                        this.progressStatusList[`material-${material_id}`]["status"] = "completed"
                    } )
                } catch ( err1 ) {
                    runInAction( () => {
                        this.progressStatusList[`material-${material_id}`]["progress"] = -1
                        this.progressStatusList[`material-${material_id}`]["inProgress"] = false
                        this.progressStatusList[`material-${material_id}`]["status"] = "failed"
                    } )
                }
            }
        }
    }

    async downloadAttachment( id, url, name, ext ) {
        try {
            runInAction( () => {
                this.progressStatusList[id] = { name, type: "attachment", progress: 0, inProgress: true, status: "queued" }
            } )
            this.setDialogStatus( true )
            const DownloadLink = await StudyMaterialStore.downloadAttachment( id, { url } )
            const a = document.createElement( "a" )
            const res = await axios( convertToCDNLink( DownloadLink ), {
                onDownloadProgress: e => this.handleDownloadProgress( e, id, { id, name, url, ext, type: "attachment" } ), responseType: 'blob'
            } )
            const link = URL.createObjectURL( res.data )
            a.href = link
            a.download = `${name}.${ext}`
            a.click()
            a.remove()
        }
        catch ( err ) {
            runInAction( () => {
                this.progressStatusList[id] = { name, type: "attachment", progress: -1, inProgress: false, status: "failed" }
            } )
        }
    }

    handleDownloadProgress( e, id, details ) {
        runInAction( () => {
            this.progressStatusList[id] = { name: details.name, type: details.type, progress: ( e.progress || 0 ) * 100, inProgress: e.progress > 0 ? e.progress === 1 ? false : true : false, status: e.progress === 1 ? "completed" : "inprogress" }
        } )
    }

    async downloadMultipleAttachments( attachments = [] ) {


        for ( const { id, name } of attachments ) {
            this.progressStatusList[id] = { name, type: "attachment", progress: 0, inProgress: true, status: "queued" }
        }
        // this.setProgressStatusList( this.tempProgressValues )

        this.setDialogStatus( true )

        let urls = await Promise.all( attachments.map( async att => {

            const { id, name, url, ext } = att
            try {
                const DownloadLink = await StudyMaterialStore.downloadAttachment( id, { url } )

                const { data: downloadLinkStream } = await axios( convertToCDNLink( DownloadLink ), {
                    onDownloadProgress: e => this.handleDownloadProgress( e, id, { id, name, url, ext, type: "attachment" } )
                    , responseType: 'blob'
                } )
                const fileObj = new File( [downloadLinkStream], `${name}.${ext}` )

                return fileObj
            } catch ( err ) {
                runInAction( () => {
                    this.progressStatusList[id] = { name, type: "attachment", progress: -1, inProgress: false, status: "failed" }
                } )
            }
        } ) )
        try {
            const blob = await downloadZip( urls ).blob()
            const link = URL.createObjectURL( blob )
            const a = document.createElement( "a" )
            a.href = link
            a.download = `alive-download-${new Date().getTime()}.zip`
            a.click()
            a.remove()
        } catch ( err ) {
            const newData = {}
            attachments.forEach( att => {
                const { id, name } = att
                newData[id] = { name, type: "attachment", progress: -1, inProgress: false, status: "failed" }
            } )
            runInAction( () => {
                this.progressStatusList = { ...this.progressStatusList, ...newData }
            } )
            console.log( err )
            NotificationStore.setNotification( true, "Failed to zip the files! try again later" )
        }
    }

    openDialog() {
        this.setDialogStatus( true )
    }

    closeDialog() {
        if ( this.dialogStatus ) {
            const newStatuses = { ...this.progressStatusList }
            const keys = Object.keys( this.progressStatusList )
            for ( let i = 0; i < keys.length; i++ ) {
                const key = keys[i]
                if ( ["completed", "failed"].includes( newStatuses[key].status ) || ( newStatuses[key].progress >= 100 && !newStatuses[key].inprogress ) ) {
                    delete newStatuses[key]
                    delete this.tempProgressValues[key]
                }
            }
            this.setProgressStatusList( newStatuses )
            this.setDialogStatus( false )
        } else {
            this.setDialogStatus( true )
        }
    }
}


const DownloadStatusListInstance = new DownloadStatusList()

export default DownloadStatusListInstance

const DownloadStatusListContext = React.createContext()

export const DownloadStatusListProvider = ( { children, store } ) => {
    return (
        <DownloadStatusListContext.Provider value={store}>{children}</DownloadStatusListContext.Provider>
    )
}

export const useDownloadStatusListStore = () => React.useContext( DownloadStatusListContext )
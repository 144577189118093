import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material'
import requestErrorImg from '../../assets/request-error.svg'
import notFoundImg from '../../assets/not-found.svg'
import accessDeniedErrorImg from '../../assets/access-denied.svg'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUserStore } from '../../store/user.store'
import { ErrorOutline } from '@mui/icons-material'
import PropTypes from 'prop-types'

const TryAgainMessageBlock = ( { iconSize = "24px", showErrorIcon = false, parent = "page", styles = {}, actions = [], code = 500, getData, imageHeight = "120px", err = "Something went wrong! try again.", loading, btnColor = "error" } ) => {

    const navigate = useNavigate()
    const UserStore = useUserStore()
    const { pathname } = useLocation()

    const { palette } = useTheme()

    const navigateToSinginPage = () => {
        if ( !pathname?.includes( "admin" ) )
            navigate( `/?redirect=${pathname}${window.location.search}` )
        else
            navigate( `/admin/?redirect=${pathname}${window.location.search}` )
    }

    const navigateToHomePage = () => {
        const user = UserStore?.getUser?.user_role === 'STUDENT' ? 'student' : ["admin", "subadmin"].includes( UserStore?.getUser?.user_role?.toLowerCase() ) ? 'admin' : 'faculty'
        navigate( `/${user}/home` )
    }

    return parent === 'page' ? (
        <Box flexDirection="column" padding="20px" display="flex" alignItems="center" justifyContent="center" gap="20px" sx={{ ...styles }}>
            {showErrorIcon ? <ErrorOutline sx={{ marginBottom: "10px", fontSize: iconSize, color: "errorMessage.main" }} /> :
                <img src={code === 403 ? accessDeniedErrorImg : code === 404 ? notFoundImg : requestErrorImg} height={imageHeight} alt="Something went wrong!" />}
            <Typography textAlign="center" variant='subtitle2'>{err}</Typography>
            <Box display="flex" gap="20px" alignItems="center">
                {code === 401 && <Button disabled={loading} onClick={navigateToSinginPage} sx={{ textTransform: "capitalize" }} variant="contained" disableElevation>Singin</Button>}
                {code !== 403 && <Button disabled={loading} startIcon={loading && <CircularProgress size={14} />} onClick={getData} sx={{ textTransform: "capitalize" }} color={btnColor} variant="contained" disableElevation>Retry</Button>}
                {code === 403 && <Button disabled={loading} onClick={navigateToHomePage} sx={{ textTransform: "capitalize" }} color="success" variant="contained" disableElevation>Back to home</Button>}
                {code === 404 && <Button disabled={loading} onClick={navigateToHomePage} sx={{ textTransform: "capitalize" }} color="success" variant="contained" disableElevation>Back to home</Button>}
                {actions?.length > 0 && actions.map( action => (
                    <Button key={action.label} onClick={action.action} sx={{ textTransform: "capitalize" }} color={action.color} variant="contained" disableElevation>{action.label}</Button>
                ) )}
            </Box>
        </Box>
    ) : (
        <Box justifyContent="center" minHeight="120px" padding="5px" borderRadius="5px" bgcolor={palette.error.light + 44} display="flex" flexDirection="column" gap="2px" alignItems="center" sx={{ ...styles }}>
            <ErrorOutline sx={{ marginBottom: "10px", fontSize: "24px", color: "errorMessage.main" }} />
            <Typography fontSize="12px" textAlign="center" variant='subtitle2'>{err}</Typography>
            <Button color="errorMessage" startIcon={loading && <CircularProgress size={14} />} onClick={getData} disabled={loading} sx={{ textTransform: "capitalize" }} >Retry</Button>
        </Box>
    )
}

TryAgainMessageBlock.propTypes = {
    showErrorIcon: PropTypes.bool,
    err: PropTypes.string,
    code: PropTypes.number,
    action: PropTypes.array,
    iconSize: PropTypes.string,
    imageHeight: PropTypes.string,
    parent: PropTypes.oneOf( ["component", "page"] )
}

export default TryAgainMessageBlock

import { Cancel, KeyboardArrowDown, KeyboardArrowUp, Settings } from '@mui/icons-material'
import { Box, Chip, IconButton, Paper, Tooltip, Typography, useTheme } from '@mui/material'
import React from 'react'
import { displayPresets, templateColorThemes } from '../../../utils/resume-builder-utilities'
import { useResumeTemplateEditStore } from '../../../store/resumetemplateEdit.store'

const TemplateSectionBox = ( { section, sectionIndex, isDragging, setTab, rowIndex, columnIndex, setNewSectionPadding, setNewSectionGap, setNewSectionDataGap, columnLength, moveSectionUp, moveSectionDown } ) => {

    const { palette } = useTheme()

    const getGroupStyles = ( grpIndex ) => {
        if ( section.groupStyles ) {
            if ( section.groupStyles[grpIndex] ) {
                return section.groupStyles[grpIndex]
            }
        }
        return {}
    }

    const TemplateStore = useResumeTemplateEditStore()
    return (
        <Box sx={{ "&:hover .on-hover-show": { display: !TemplateStore.getPreviewMode ? "flex" : "none" }, pointerEvents: isDragging ? "none" : "all" }} position="relative"
            border={!TemplateStore.getPreviewMode ? ( TemplateStore.getSelectedIndexes?.column === columnIndex && TemplateStore.getSelectedIndexes?.row === rowIndex && TemplateStore.getSelectedIndexes?.index === sectionIndex ) ? "1px solid #0007ff" : "1px solid #aaa" : "none"}
            display="flex" flexDirection="column" alignItems={section.align ? section.align : "flex-start"} padding={`${section.padding.y}px ${section.padding.x}px`} gap={`${section.gap}px`}>
            {/* ACTIONS */}
            <Paper elevation={1} className="on-hover-show" sx={{ zIndex: "4", position: "absolute", alignItems: "center", top: "10px", borderRight: "10px", right: "10px", padding: "5px", background: palette.greyedOut, borderRadius: "5px", display: "none", flexDirection: "column" }} >
                <Tooltip title="Configure and style" placement='left'>
                    <IconButton color='secondary' onClick={() => {
                        setTab( "Settings" )
                        TemplateStore.setSelectedSection( { row: rowIndex, column: columnIndex, index: sectionIndex } )
                        setNewSectionPadding( section.padding )
                        setNewSectionGap( section.gap )
                        setNewSectionDataGap( section.dataSetGap ? section.dataSetGap : 10 )
                    }}>
                        <Settings sx={{ fontSize: "18px" }} />
                    </IconButton>
                </Tooltip>
                {sectionIndex !== 0 && <Tooltip title="Move this section up" placement='left'>
                    <IconButton onClick={() => moveSectionUp( rowIndex, columnIndex, sectionIndex )} color="primary">
                        <KeyboardArrowUp sx={{ fontSize: "18px" }} />
                    </IconButton>
                </Tooltip>}
                {sectionIndex !== columnLength - 1 && <Tooltip title="Move this section down" placement='left'>
                    <IconButton onClick={() => moveSectionDown( rowIndex, columnIndex, sectionIndex )} color="primary">
                        <KeyboardArrowDown sx={{ fontSize: "18px" }} />
                    </IconButton>
                </Tooltip>}
                <Tooltip title="Remove this section from column" placement='left'>
                    <IconButton onClick={() => TemplateStore.removeSectionFromColumn( { row: rowIndex, column: columnIndex, index: sectionIndex } )} color="error">
                        <Cancel sx={{ fontSize: "18px" }} />
                    </IconButton>
                </Tooltip>
            </Paper>
            {section.show_section_title && <Typography variant="h6"
                sx={{ color: templateColorThemes[TemplateStore.getTemplate.colorTheme ? TemplateStore.getTemplate.colorTheme : "default"]?.primary }}
                fontSize={`${displayPresets[section.title_preset ? section.title_preset : "header1"].fontSize * TemplateStore.getTemplate.fontSize}px`}
                fontWeight={displayPresets[section.title_preset ? section.title_preset : "header1"].fontWeight}
            >{section.section_title}</Typography>}
            {section.attributes.map( ( grp, grpIndex ) => {
                const { displayDirection, spacing, separator } = getGroupStyles( grpIndex )
                return (
                    <Box key={grpIndex} display="flex" flexDirection={displayDirection} justifyContent={spacing === "space-between" ? "space-between" : "flex-start"} gap={( !spacing || spacing === "gap" ) && !separator ? "10px" : "0"} alignItems={displayDirection === "column" ? "flex-start" : "center"}>
                        {grp.map( ( entity, entityIndex ) => {

                            return (
                                <Box sx={{ pointerEvents: "none" }} display={( entity.visibility === undefined || entity.visibility ) ? "block" : "none"} alignItems="center" key={entityIndex}>
                                    {entity.entity_detail.label_show && <Typography variant="subtitle2" textAlign={entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left"} fontSize={`${displayPresets[entity.labelFontSize ? entity.labelFontSize : "label1"].fontSize * TemplateStore.getTemplate.fontSize}px`} sx={{ pointerEvents: "none", color: entity.labelColor ? templateColorThemes[TemplateStore.getTemplate.colorTheme][entity.labelColor] || "black" : "black" }}>{entity.entity_detail.label}</Typography>}
                                    {entity.entity_detail.only_for_proctors !== true && <Box>
                                        {entity.entity_detail.data_type !== "file" && entity.entity_detail.data_type !== "document" && entity.entity_detail.data_type !== "array" && <Typography textAlign={entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left"} variant="body1"
                                            margin="0"
                                            fontStyle={entity.italic ? "italic" : "none"}
                                            fontSize={entity.fontSize ? `${entity.fontSize}px` : `${displayPresets[entity.displayAs ? entity.displayAs : "custom"]?.fontSize * TemplateStore.getTemplate.fontSize}px`}
                                            fontWeight={entity.fontWeight ? entity.fontWeight : displayPresets[entity.displayAs ? entity.displayAs : "custom"]?.fontWeight}
                                            sx={{ pointerEvents: "none", textDecoration: entity.underline ? "underline" : "none", textTransform: entity.textTransform, color: TemplateStore.getTemplate.colorTheme ? templateColorThemes[TemplateStore.getTemplate.colorTheme][entity.color] || "black" : "black" }} >
                                            {entityIndex !== 0 && separator && displayDirection !== "column" && <span style={{ padding: "0 5px" }}>{separator}</span>}
                                            {entity.entity_name}
                                        </Typography>
                                        }
                                        {entity.entity_detail.data_type === "array" && <Box display="flex" justifyContent={section.align ? section.align : "flex-start"} flexWrap="wrap" gap="5px">
                                            {entity.displayItemAs !== "list" && <Chip
                                                sx={{
                                                    pointerEvents: "none",
                                                    fontSize: entity.fontSize ? `${entity.fontSize}px` : `16px`,
                                                    fontWeight: entity.fontWeight ? entity.fontWeight : "400",
                                                    textDecoration: entity.underline ? "underline" : "none", textTransform: entity.textTransform, color: TemplateStore.getTemplate.colorTheme ? templateColorThemes[TemplateStore.getTemplate.colorTheme][entity.color] || "black" : "black",
                                                    borderRadius: ( entity.displayItemAs && entity.displayItemAs !== "roundedRectangleChip" ) ? "0" : "20px"
                                                }}
                                                label={entity.entity_name}
                                            />}
                                            {entity.displayItemAs === "list" && <ul style={{ margin: "0", paddingLeft: "15px" }}>
                                                <li><Typography
                                                    sx={{
                                                        pointerEvents: "none",
                                                        fontSize: entity.fontSize ? `${entity.fontSize}px` : `16px`,
                                                        fontWeight: entity.fontWeight ? entity.fontWeight : "400",
                                                        textDecoration: entity.underline ? "underline" : "none", textTransform: entity.textTransform, color: TemplateStore.getTemplate.colorTheme ? templateColorThemes[TemplateStore.getTemplate.colorTheme][entity.color] || "black" : "black",
                                                        borderRadius: entity.displayItemAs !== "roundedRectangleChip" && "0"
                                                    }}
                                                > {entity.entity_name}</Typography></li>
                                            </ul>
                                            }
                                        </Box>}
                                        {entity.entity_detail.data_type === "file" && <Box bgcolor="#eee" borderRadius="50%" display="flex" alignItems="center" justifyContent="center" width={entity.size ? `${entity.size}px` : "150px"} height={entity.size ? `${entity.size}px` : "150px"}>
                                            <Typography color="GrayText" textAlign="center" variant="subtitle2">File goes here</Typography>
                                        </Box>}
                                    </Box>}
                                </Box>
                            )
                        } )}
                    </Box>
                )
            } )}
        </Box>
    )
}

export default TemplateSectionBox

import React, { useCallback, useEffect, useState } from 'react'
import api from '../../service/api'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { convertToCDNLink, flatGradients, Icons, showDateInIndianDateFormat } from '../../utils/utilities'
import { AnnouncementOutlined } from '@mui/icons-material'
import AnnouncementDialog from '../../components/common/AnnouncementDialog'

const AnnouncementsCard = () => {

    const [announcements, setAnnouncements] = useState( [] )
    const [selectedAnnouncement, setSelectedAnnouncement] = useState( null )

    const { palette } = useTheme()

    const fetchAnnouncements = useCallback( async () => {
        try {
            const { data: { data } } = await api.announcements.fetchAnnouncements( { purpose: "announcement" } )
            setAnnouncements( data )
        } catch ( err ) {
            console.log( err )
        }
    }, [] )

    useEffect( () => {
        fetchAnnouncements()

    }, [fetchAnnouncements] )

    return announcements.length > 0 ? (
        <Box>

            {Boolean( selectedAnnouncement ) && <AnnouncementDialog state={Boolean( selectedAnnouncement )} onClose={() => setSelectedAnnouncement( null )} announcement_id={selectedAnnouncement} />}

            <Box display="flex" flexDirection="column" gap="10px">
                {announcements.map( ( announcement, announcementIndex ) => {
                    const lastUpdated = new Date( announcement.announcement_updated_at ).toLocaleDateString()
                    const today = new Date().toLocaleDateString()
                    let yesterday = new Date()
                    yesterday.setDate( new Date().getDate() - 1 )
                    yesterday = yesterday.toLocaleDateString()
                    let lastUpdatedString
                    if ( lastUpdated === today )
                        lastUpdatedString = "Today"
                    else if ( lastUpdated === yesterday )
                        lastUpdatedString = "Yesterday"
                    else {

                        let llformatDate = showDateInIndianDateFormat( lastUpdated, { format: "ll" } )
                        const [dateAndMonth, year] = llformatDate?.split( ", " )

                        if ( +year === new Date().getFullYear() )
                            lastUpdatedString = dateAndMonth
                        else
                            lastUpdatedString = `${dateAndMonth}\n${year}`
                    }

                    const { background: bg, color } = flatGradients[( announcementIndex % flatGradients.length )]

                    return (
                        <Box overflow="clip" position="relative" className="animation-shake-twice" sx={{ zIndex: 10, background: bg }} borderRadius="5px" display="flex" gap="10px" padding="20px" key={announcement.id}>
                            <Box top="-150px" right="-150px" sx={{ opacity: !announcement.cover_image ? 0.7 : 0.4, background: bg, zIndex: 0, position: "absolute", width: "500px", height: "500px", borderRadius: "50%", overflow: "hidden" }} >
                                {announcement.cover_image && <img src={convertToCDNLink( announcement.cover_image )} alt={`${announcement.id}-cover`} />}
                            </Box>
                            <Box display="flex" gap="10px" sx={{ zIndex: "10", position: "relative" }}>
                                <Typography minWidth="45px" color={color} display="flex" flexDirection="column" alignItems="center" gap="5px" fontSize="14  px" gutterBottom fontWeight="500"><AnnouncementOutlined />{lastUpdatedString}</Typography>
                                <Box width="1px" bgcolor={palette.greyedOut}></Box>
                                <Box>
                                    <Typography color={color} variant='h6' fontSize="18px">{announcement.announcement_title}</Typography>
                                    <Button onClick={() => setSelectedAnnouncement( announcement.id )} variant='outlined' size="small" sx={{ marginTop: "5px", border: `1px solid ${color}`, color: color, bgcolor: "none", textTransform: "capitalize", "&:hover": { border: `1px solid ${color}`, color: color, bgcolor: "none" } }} endIcon={Icons.default.KeyBoardArrowRight}>Read more</Button>
                                </Box>
                            </Box>
                        </Box>
                    )
                } )}
            </Box>
        </Box >
    ) : ""
}

export default AnnouncementsCard

import { Box, Button, CircularProgress, Collapse, IconButton, TextField, Tooltip, Typography, useTheme } from '@mui/material'
// import { useQuizAndQuestionbankStore } from '../../../store/quiz.store'
import ConfirmDialog from '../../components/common/ConfirmDialog'
import { Icons } from '../../utils/utilities'
import QuestionBlock from './QuestionBlock'
import React, { useRef, useState } from 'react'
// import { useParams } from 'react-router-dom'
// import QuestionsPreviewDialog from '../common/QuestionsPreviewDialog'
import { useAdmissionExamStore } from '../../store/admission-exam.store'
import { observer } from 'mobx-react'
import { InfoOutlined } from '@mui/icons-material'

const SectionBlock = observer( ( { cutoffInput, setSectionMaxQuestionCounts = () => { }, setReadOnlyModeForFileView = () => { }, sectionsCutoff = {}, setSectionsCutoff = () => { }, sectionMaxQuestionCount, readOnly = false, viewQuestionFile, parent = 'approve', index, files, deleteQuestion, removeQuestionFile, deleteSection, onImportChange, newBankId = undefined, questions, section, setSelectedSection, setAddQuestionDialogState } ) => {

    const [open, setOpen] = useState( section === 'Primary' )
    const [viewing, setViewing] = useState( null )
    const [deleting, setDeleting] = useState( false )
    const [deleteConfirmDialogState, setDeleteConfirmDialogState] = useState( false )


    const AdmissionExamStore = useAdmissionExamStore()

    const importFileInputRef = useRef()

    const { palette, border, shadows } = useTheme()

    const openImportWindow = () => {
        setSelectedSection( index !== -1 ? section : '' )
        importFileInputRef.current.click()
    }

    const handleClickOnViewQuestionFile = async ( fileDetails, index ) => {
        setViewing( index )
        if ( parent === 'create' ) {
            await onImportChange( { target: { files: [fileDetails.file] } } )
            setReadOnlyModeForFileView( true )
        } else {
            await viewQuestionFile( fileDetails.id, fileDetails.name )
        }
        setViewing( null )
    }

    const handleSectionMaxQuestionChange = ( e, index ) => {
        const newValue = { ...sectionMaxQuestionCount }
        newValue.maxQuestionsForExam[section] = parseInt( e.target.value )
        setSectionMaxQuestionCounts( newValue )
    }

    const handleSectionCutOffChange = ( e, index ) => {
        const newValue = { ...sectionsCutoff }
        newValue[section] = +e.target.value
        setSectionsCutoff( newValue )
    }

    const handleDeleteSection = () => {
        setDeleting( true )
        deleteSection( index )
        setDeleteConfirmDialogState( false )
        setDeleting( false )
    }


    return (
        <Box sx={{
            "&:hover .display-on-hover": {
                display: { md: "flex", xs: "none" }
            },
            borderRadius: "5px",
            border: border[1],
            borderColor: open ? palette.primary.main : palette.borderColor,
            borderWidth: open ? "2px" : "1px",
            boxShadow: open && shadows[5],
            bgcolor: palette.common.bg
        }} >

            <ConfirmDialog confirmAction={() => handleDeleteSection()} actionName="Yes, Delete it!" status={deleteConfirmDialogState} cancelAction={() => setDeleteConfirmDialogState( false )} message={<p>All the associated questions will be removed from this action!</p>} />

            <Box sx={{
                position: "relative",
            }} padding="10px 20px" gap="10px" display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center" >
                <Box sx={{ cursor: "pointer" }} onClick={() => setOpen( section !== 'Primary' ? !open : true )} flexGrow={1} display="flex" gap="20px" alignItems="center" >
                    <Box display="flex" gap="20px" alignItems="center">
                        <Box>
                            <Typography fontWeight={open ? "700" : "500"} color={open && "primary"} variant="subtitle1">
                                {section}
                            </Typography>
                            {section === 'Primary' && <Typography color="textSecondary" marginTop="-10px" variant='subtitle2' fontSize="12px" >Default section</Typography>}
                        </Box>
                        {readOnly && parent !== 'create' && AdmissionExamStore.getExam?.exam?.section_question_count_map?.maxQuestionsForExam && AdmissionExamStore.getExam?.exam?.section_question_count_map?.maxQuestionsForExam[section] && <Typography variant='subtitle2' color="textSecondary" >{AdmissionExamStore.getExam?.exam?.section_question_count_map?.maxQuestionsForExam[section]} of {AdmissionExamStore.getExam?.exam?.section_question_count_map?.totalQuestions[section]} questions</Typography>}
                        {readOnly && parent !== 'create' && +( AdmissionExamStore.getExam?.exam?.cutoff_details[section] || 0 ) > 0 && <Typography variant='subtitle2' color="textSecondary" >Cut-off %: {+AdmissionExamStore.getExam?.exam?.cutoff_details[section]}</Typography>}
                        {readOnly && parent === 'create' && sectionMaxQuestionCount?.maxQuestionsForExam && <Typography variant='subtitle2' color="textSecondary" >{( sectionMaxQuestionCount?.maxQuestionsForExam[section] || 0 )} of {( sectionMaxQuestionCount?.totalQuestions[section] || 0 )} questions</Typography>}
                        {readOnly && parent === 'create' && sectionsCutoff[section] > 0 && <Typography variant='subtitle2' color="textSecondary" >Cut-off %: {sectionsCutoff[section]}</Typography>}
                    </Box>
                    <IconButton size='small'>{open ? Icons.default.KeyboardArrowUp : Icons.default.KeyboardArrowDownIcon}</IconButton>
                </Box>
                <input accept='text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document' onChange={e => onImportChange( e, section )} style={{ width: 0, height: 0, opacity: 0 }} type="file" ref={importFileInputRef} />
                {!readOnly && <Box flexWrap="wrap" display={{ md: "flex", xs: "none" }} gap="10px" alignItems="center">
                    <Box display="flex" gap='10px'>
                        {cutoffInput && <TextField
                            sx={{ paddingRight: 0 }}
                            error={( sectionsCutoff[section] || 0 ) > 100 || sectionsCutoff[section] < 0}
                            type="number"
                            size='small'
                            value={( sectionsCutoff[section] >= 0 ? sectionsCutoff[section] : "" )}
                            onChange={handleSectionCutOffChange}
                            inputProps={{ min: 0, step: 0.01, max: 100 }} InputProps={{
                                startAdornment: <Typography width="100px" variant='subtitle2' fontSize="12px" color="textSecondary" >Cut-off %: </Typography>,
                                endAdornment: <Tooltip title="Specify section cut-off %. Candidate will get qualified, only if all the section cut-off is reached.">
                                    <IconButton color='secondary' sx={{ paddingRight: 0 }} disableFocusRipple disableRipple disableTouchRipple >
                                        <InfoOutlined />
                                    </IconButton>
                                </Tooltip>
                            }} />}
                        <TextField
                            sx={{ paddingRight: 0 }}
                            error={( sectionMaxQuestionCount?.maxQuestionsForExam[section] || 0 ) > ( sectionMaxQuestionCount?.totalQuestions[section] || 0 )}
                            type="number"
                            size='small'
                            onWheel={e => e.target.blur()}
                            value={( sectionMaxQuestionCount?.maxQuestionsForExam[section] )}
                            onChange={handleSectionMaxQuestionChange}
                            inputProps={{ min: 0, max: ( sectionMaxQuestionCount?.totalQuestions[section] || 0 ) }} InputProps={{
                                startAdornment: <Typography width="100px" variant='subtitle2' fontSize="12px" color="textSecondary" >Max: {( sectionMaxQuestionCount?.totalQuestions[section] || 0 )}</Typography>,
                                endAdornment: <Tooltip title="Specify number of question to take from this section for exam">
                                    <IconButton color='secondary' sx={{ paddingRight: 0 }} disableFocusRipple disableRipple disableTouchRipple >
                                        <InfoOutlined />
                                    </IconButton>
                                </Tooltip>
                            }} />
                    </Box>
                    <Box display="flex" gap='10px'>
                        <Button disabled={deleting} startIcon={Icons.default.AddIcon} onClick={() => { setSelectedSection( index !== -1 ? section : '' ); setAddQuestionDialogState( true ) }} color="secondary" size="small" sx={{ textTransform: "capitalize" }} > Add new questions </Button>
                        <Button disabled={deleting} startIcon={Icons.default.DownloadForOfflineIcon} onClick={openImportWindow} color="success" size="small" sx={{ textTransform: "capitalize" }} > Import questions </Button>
                        {index !== 0 && <Button disabled={deleting} startIcon={Icons.default.DeleteIconOutlined} onClick={() => { setDeleteConfirmDialogState( true ) }} color="error" size="small" sx={{ textTransform: "capitalize" }} > Delete </Button>}
                    </Box>
                </Box>}
                {!readOnly && <Box display={{ md: "none", xs: "flex" }} flexWrap="wrap" gap="10px" alignItems="center">
                    <Box display="flex" gap="10px" flexWrap="wrap">
                        {cutoffInput && <TextField
                            fullWidth
                            sx={{ paddingRight: 0 }}
                            error={( sectionsCutoff[section] || 0 ) > 100 || sectionsCutoff[section] < 0}
                            type="number"
                            size='small'
                            value={( sectionsCutoff[section] )}
                            onChange={handleSectionCutOffChange}
                            inputProps={{ min: 0, step: 0.01, max: 100 }} InputProps={{
                                startAdornment: <Typography width="120px" variant='subtitle2' fontSize="12px" color="textSecondary" >Cut-off %: </Typography>,
                                endAdornment: <Tooltip title="Specify section cut-off %. Candidate will get qualified, only if all the section cut-off is reached.">
                                    <IconButton color='secondary' sx={{ paddingRight: 0 }} disableFocusRipple disableRipple disableTouchRipple >
                                        <InfoOutlined />
                                    </IconButton>
                                </Tooltip>
                            }} />}
                        <TextField
                            fullWidth
                            sx={{ paddingRight: 0 }}
                            error={( sectionMaxQuestionCount?.maxQuestionsForExam[section] || 0 ) > ( sectionMaxQuestionCount?.totalQuestions[section] || 0 )}
                            type="number"
                            size='small'
                            onWheel={e => e.target.blur()}
                            value={( sectionMaxQuestionCount?.maxQuestionsForExam[section] )}
                            onChange={handleSectionMaxQuestionChange}
                            inputProps={{ min: 0, max: ( sectionMaxQuestionCount?.totalQuestions[section] || 0 ) }} InputProps={{
                                startAdornment: <Typography width="100px" variant='subtitle2' fontSize="12px" color="textSecondary" >Max: {( sectionMaxQuestionCount?.totalQuestions[section] || 0 )}</Typography>,
                                endAdornment: <Tooltip title="Specify number of question to take from this section for exam">
                                    <IconButton color='secondary' sx={{ paddingRight: 0 }} disableFocusRipple disableRipple disableTouchRipple >
                                        <InfoOutlined />
                                    </IconButton>
                                </Tooltip>
                            }} />
                    </Box>
                    <Box display="flex" gap='10px'>
                        <IconButton disabled={deleting} onClick={() => { setSelectedSection( index !== -1 ? section : '' ); setAddQuestionDialogState( true ) }} color="secondary" size="small" >{Icons.default.AddIcon}</IconButton>
                        <IconButton disabled={deleting} onClick={openImportWindow} color="success" size="small" >{Icons.default.DownloadForOfflineIcon}</IconButton>
                        {index !== 0 && <IconButton disabled={deleting} onClick={() => { setDeleteConfirmDialogState( true ) }} color="error" size="small" >{Icons.default.DeleteIconOutlined}</IconButton>}
                    </Box>
                </Box>}
            </Box>
            <Collapse in={open}>
                {files && ( parent === 'create' || ( parent !== 'create' && AdmissionExamStore.getExam?.exam?.exam_status === 'Draft' ) ) && files.length > 0 && <Box borderTop={border[1]} display="flex" gap="10px" padding="10px 20px">
                    {files.map( ( fileDetails, index ) => {
                        return <Box borderRadius="10px" border={border[1]} boxShadow={shadows[1]} display="flex" gap="10px" alignItems="center" padding="10px" key={index}>
                            <Typography variant='subtitle2'>{fileDetails?.file?.name || fileDetails.name}</Typography>
                            <IconButton size='small' disabled={viewing === index} sx={{ padding: "2px" }} onClick={() => handleClickOnViewQuestionFile( fileDetails, index )} >{viewing === index ? <CircularProgress size={14} /> : Icons.default.OpenInNewIcon}</IconButton>
                            {!readOnly && <IconButton size='small' sx={{ padding: "2px" }} onClick={() => removeQuestionFile( fileDetails.id )} >{Icons.default.CloseIcon}</IconButton>}
                        </Box>
                    } )}
                </Box>}
                {questions && questions.length > 0 && <Box borderTop={border[1]} display="flex" flexDirection="column" gap="15px" padding="10px 20px">
                    {questions.map( ( question, index ) => {
                        return <QuestionBlock parent={parent} readOnly={readOnly} deleteQuestion={deleteQuestion} question={question} index={index} key={index} />
                    } )}
                </Box>}
                {questions && questions.length === 0 && files?.length === 0 && <Box borderTop={border[1]} display="flex" flexDirection="column" gap="5px" padding="10px 20px">
                    <Typography color="error" display="flex" gap="10px" alignItems="center" variant="body2">
                        {Icons.default.Warning}
                        This section does not contains any questions.
                    </Typography>
                </Box>}
            </Collapse>
        </Box >
    )
} )

export default SectionBlock

import { Avatar, Box, Button, Dialog, DialogContent, Divider, Paper, Typography, useTheme } from '@mui/material'
import EditDiscussionDialog from '../../components/discussion-forum/EditDiscussionDialog'
import DiscussionComment from '../../components/discussion-forum/DiscussionComment'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import CommentDialog from '../../components/discussion-forum/CommentDialog'
import { useDiscussionForum } from '../../store/discussionforum.store'
import NoCommentsImg from '../../assets/discussion-no-comments.svg'
import DiscussionNotFoundImg from '../../assets/no-discussions.svg'
import { Icons, replaceSpacesesLocationsToCDNLink, convertToCDNLink, flatColorsAndBackgrounds, getTimePassedFrom } from '../../utils/utilities'
import BlockMessage from '../../components/common/BlockMessage'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useUserStore } from '../../store/user.store'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react'
import React from 'react'
import ConfirmDialog from '../../components/common/ConfirmDialog'
import BreadcrumbList from '../../components/common/BreadcrumbList'
import { Delete, ThumbDown, ThumbDownAltOutlined, ThumbUp, ThumbUpOutlined } from '@mui/icons-material'

const Discussion = observer( ( { fromComponent = false } ) => {

    const [showStickyTopTitleBar, setShowStickyTopTitleBar] = useState( false )
    const [deleteDialogState, setDeleteDialogState] = useState( false )
    const [votingInProgress, setVotingInProgress] = useState( false )
    const [commentBoxState, setCommentBoxState] = useState( false )
    const [commentingTo, setCommentingTo] = useState( null )
    const [errorCode, setErrorCode] = useState( null )
    const [deleting, setDeleting] = useState( false )
    const [editMode, setEditMode] = useState( false )
    const [loading, setLoading] = useState( true )

    const DiscussionForumStore = useDiscussionForum()
    const UserStore = useUserStore()
    const navigate = useNavigate()

    const { pathname } = useLocation()

    const { id } = useParams()

    const { palette } = useTheme()

    const handlePaperScroll = ( e ) => {
        const scrolledVal = e.target.scrollTop
        if ( scrolledVal > 250 && !showStickyTopTitleBar ) setShowStickyTopTitleBar( true )
        else if ( scrolledVal <= 250 && showStickyTopTitleBar ) setShowStickyTopTitleBar( false )
    }

    const openCommentBox = ( commentingTo = null, parentId = null ) => {
        if ( commentingTo ) {
            setCommentingTo( { name: commentingTo, parent: parentId } )
        }
        setCommentBoxState( true )
    }

    const filterByCategory = ( category ) => {
        const userType = UserStore.getUser.user_role === "STUDENT" ? 'student' : 'faculty'
        navigate( `/${userType}/discussions?category=${category.toLowerCase()}` )
    }

    const vote = async ( vote ) => {
        setVotingInProgress( true )
        await DiscussionForumStore.voteDiscussion( id, vote )
        setVotingInProgress( false )
    }

    const navigateToDiscussions = ( path = 'discussions' ) => {
        const userType = UserStore.getUser.user_role === "STUDENT" ? 'student' : 'faculty'
        navigate( `/${userType}/${path}` )
    }

    const deleteDiscussion = async () => {
        setDeleting( true )
        const { forbidden, notFound } = await DiscussionForumStore.deleteDiscussion( id )
        console.log( { forbidden, notFound } )

        if ( notFound ) {
            setErrorCode( "notfound" )
        } else if ( forbidden ) {
            toast( "You do not have permission to perform this action" )
        }
        else {
            if ( pathname?.includes( 'my-discussions' ) ) {
                navigateToDiscussions( "my-discussions" )
            } else
                navigateToDiscussions()
        }
        setDeleteDialogState( false )
        setDeleting( false )
    }

    const getData = useCallback( async () => {
        setLoading( true )
        const { forbidden, notFound } = await DiscussionForumStore.fetchDiscussion( id )
        if ( notFound ) {
            setErrorCode( "notfound" )
        } else if ( forbidden ) {
            setErrorCode( "forbidden" )
        }
        setLoading( false )
    }, [DiscussionForumStore, id] )

    useEffect( () => {
        getData()
    }, [getData] )

    return (
        <Box padding={fromComponent ? "0" : "20px"} position="relative" flexGrow={1} className="slim-custom-scrollbar" overflow="auto" display="flex" flexDirection="column">

            {/* Edit and confirm Dialog */}
            {editMode && <EditDiscussionDialog state={editMode && Boolean( DiscussionForumStore?.getDiscussion?.details )} setState={setEditMode} discussion={DiscussionForumStore.getDiscussion?.details} getData={getData} />}
            <ConfirmDialog status={deleteDialogState} cancelAction={() => setDeleteDialogState( false )} actionName="Delete" waiting={deleting} confirmAction={deleteDiscussion} message="All the details including comments will be lost after this action." />

            {!fromComponent && <BreadcrumbList items={[
                { label: "Discussions", link: UserStore.getUser.user_role === "STUDENT" ? "/student/discussions" : "/faculty/discussions" },
                loading ? <DataLoadingSpinner size={14} /> : id
            ]} />}

            {!errorCode && <Paper onScroll={handlePaperScroll} sx={{ height: "100%", position: "relative", flexGrow: 1, display: "flex", flexDirection: "column", overflow: "auto" }}>
                <span id={id}></span>
                <Box position="sticky" sx={{ zIndex: 30 }} top="0" right="0" left="0">
                    {showStickyTopTitleBar && <Box>
                        {!loading && DiscussionForumStore.getDiscussion?.details && <Box flexWrap="wrap" boxShadow="0px 1px 5px 0px rgba(0,0,0,0.1)" sx={{ zIndex: 20 }} borderBottom="1px solid #d3d3d3" padding="10px 20px" display="flex" gap="10px" alignItems="center" justifyContent="space-between" bgcolor={palette.common.bg}>
                            <Box>
                                <Typography noWrap margin={0} variant="subtitle2" fontSize="16px"> <a className='sticky-header-link' href={`#${id}`}>{DiscussionForumStore.getDiscussion?.details?.title}</a> </Typography>
                                <Typography marginTop="-5px" fontSize="12px" variant="subtitle2" textTransform="capitalize">@{DiscussionForumStore.getDiscussion?.details?.creator_data?.name?.toLowerCase()}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center" gap="10px">
                                <Button
                                    disabled={votingInProgress}
                                    variant="outlined"
                                    onClick={() => {
                                        if ( DiscussionForumStore.getVotes ) DiscussionForumStore.getVotes[id] === 1 ? vote( 0 ) : vote( 1 )
                                    }}
                                    sx={{
                                        textTransform: "capitalize",
                                        color: DiscussionForumStore.getVotes ? DiscussionForumStore.getVotes[id] !== 1 ? "GrayText" : "primary" : "GrayText"
                                    }}
                                    startIcon={DiscussionForumStore.getVotes && DiscussionForumStore.getVotes[id] !== 1 ? Icons.default.LikeOutlined : Icons.default.Like}
                                    size="small"
                                >
                                    {DiscussionForumStore.getDiscussion?.details?.votes?.up || 0} Upvotes
                                </Button>
                                <Button
                                    disabled={votingInProgress}
                                    variant="outlined"
                                    onClick={
                                        () => {
                                            if ( DiscussionForumStore.getVotes )
                                                DiscussionForumStore.getVotes[id] === -1 ? vote( 0 ) : vote( -1 )
                                        }}
                                    sx={{
                                        textTransform: "capitalize",
                                        color: DiscussionForumStore.getVotes ? DiscussionForumStore.getVotes[id] !== -1 ? "GrayText" : "primary" : "GrayText"
                                    }}
                                    startIcon={DiscussionForumStore.getVotes && DiscussionForumStore.getVotes[id] !== -1 ? Icons.default.UnlikeOutlined : Icons.default.Unlike}
                                    size="small"
                                >
                                    {DiscussionForumStore.getDiscussion?.details?.votes?.down || 0} Downvotes
                                </Button>
                            </Box>
                        </Box>}
                    </Box>}
                </Box>
                {!loading &&
                    <Box flexGrow={1} display="flex" flexDirection="column" position="relative">
                        {DiscussionForumStore.getDiscussion?.details &&
                            <Box>
                                <Box padding="10px 20px" borderBottom="1px solid #d3d3d3" display="flex" gap="10px" alignItems="center">
                                    <Avatar sx={{ width: "30px", height: "30px" }} src={convertToCDNLink( DiscussionForumStore.getDiscussion?.details?.creator_data?.user_profile_image_url )}>{DiscussionForumStore.getDiscussion?.details?.creator_data?.name[0]}</Avatar>
                                    <Box>
                                        <Typography variant='subtitle1' color="textSecondary">@{DiscussionForumStore.getDiscussion?.details?.creator_data?.name}</Typography>
                                        <Typography marginTop="-5px" fontSize="12px">{DiscussionForumStore.getDiscussion?.details?.updated_at ? `Edited ${getTimePassedFrom( DiscussionForumStore.getDiscussion?.details?.updated_at )}` : getTimePassedFrom( DiscussionForumStore.getDiscussion?.details?.created_at )}</Typography>
                                    </Box>
                                </Box>
                                <Box padding="10px 20px">
                                    <Box display="flex" flexWrap="wrap" marginBottom="5px" flexDirection="column" gap="10px">
                                        {/* <Typography fontSize="12px"> Categories</Typography> */}
                                        <Box display="flex" gap="10px" flexWrap="wrap">
                                            {DiscussionForumStore.getDiscussion?.details?.categories.map( ( category, index ) => {
                                                const { color, bg } = flatColorsAndBackgrounds[index % flatColorsAndBackgrounds.length]
                                                return (
                                                    <Typography display="inline" key={category} color={color} onClick={() => filterByCategory( category )} sx={{ cursor: "pointer", "&:hover": { fontWeight: "bold" } }} textTransform="capitalize" fontSize="10px" fontWeight="500" padding="5px 10px" borderRadius="5px" bgcolor={bg}>{category}</Typography>
                                                )
                                            } )}
                                        </Box>
                                    </Box>
                                    <Box marginTop="20px" display="flex" flexWrap="wrap" alignItems="flex-start" >
                                        <Box flexGrow={1}>
                                            <Typography variant="h6" fontWeight="bolder">{DiscussionForumStore.getDiscussion?.details?.title}</Typography>
                                            <Box display="flex" flexWrap="wrap" marginTop="-5px" marginBottom="10px" gap="10px" alignItems="center">
                                                {DiscussionForumStore.getDiscussion?.details?.access_groups.map( group => (
                                                    <Box key={group.subject_id} display="flex" alignItems="center" >
                                                        <Box width="5px" height="5px" bgcolor="darkgrey" borderRadius="50%"></Box>
                                                        <Typography fontSize="12px" fontWeight="300" padding="5px 10px" borderRadius="5px">{group.subject_name === "all" ? "Global" : group.subject_name}</Typography>
                                                    </Box>
                                                ) )}
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center" gap="5px">
                                            <Button
                                                disabled={votingInProgress}
                                                onClick={() => {
                                                    if ( DiscussionForumStore.getVotes )
                                                        DiscussionForumStore.getVotes[id] === 1 ? vote( 0 ) : vote( 1 )
                                                }}
                                                sx={{
                                                    pointerEvents: votingInProgress ? "none" : "all",
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    gap: "5px",
                                                    alignItems: "center",
                                                    color: DiscussionForumStore.getVotes ? DiscussionForumStore.getVotes[id] !== 1 ? "GrayText" : "primary" : "GrayText"
                                                }}
                                                size="small"
                                            >
                                                {DiscussionForumStore.getVotes && DiscussionForumStore.getVotes[id] !== 1 ? <ThumbUpOutlined sx={{ fontSize: "16px" }} /> : <ThumbUp sx={{ fontSize: "16px" }} />}
                                                {DiscussionForumStore.getDiscussion?.details?.votes?.up || 0}
                                            </Button>
                                            <Button
                                                disabled={votingInProgress}
                                                onClick={
                                                    () => {
                                                        if ( DiscussionForumStore.getVotes )
                                                            DiscussionForumStore.getVotes[id] === -1 ? vote( 0 ) : vote( -1 )
                                                    }}
                                                sx={{
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    gap: "5px",
                                                    alignItems: "center",
                                                    color: DiscussionForumStore.getVotes ? DiscussionForumStore.getVotes[id] !== -1 ? "GrayText" : "primary" : "GrayText"
                                                }}
                                                size="small"
                                            >
                                                {DiscussionForumStore.getVotes && DiscussionForumStore.getVotes[id] !== -1 ? <ThumbDownAltOutlined sx={{ fontSize: "16px" }} /> : <ThumbDown sx={{ fontSize: "16px" }} />}
                                                {DiscussionForumStore.getDiscussion?.details?.votes?.down || 0}
                                            </Button>
                                            {UserStore.getUser.user_id === DiscussionForumStore.getDiscussion?.details?.created_by && <Button
                                                disabled={deleting}
                                                color='error'
                                                onClick={() => setDeleteDialogState( true )}
                                                sx={{ textTransform: "capitalize", }}
                                                size="small"
                                            >
                                                <Delete sx={{ fontSize: "18px" }} />
                                            </Button>}
                                            {UserStore.getUser.user_id === DiscussionForumStore.getDiscussion?.details?.created_by && <Button
                                                onClick={() => setEditMode( true )}
                                                sx={{ textTransform: "capitalize", }}
                                                startIcon={Icons.default.EditIcon}
                                                size="small"
                                            >
                                                Edit
                                            </Button>}
                                        </Box>
                                    </Box>

                                    <Box padding="10px" marginTop="20px" className='wysiwyg-text-container' border="1px solid #d3d3d3" bgcolor={palette.contentBg} borderRadius="5px">
                                        <Box dangerouslySetInnerHTML={{ __html: replaceSpacesesLocationsToCDNLink( DiscussionForumStore.getDiscussion?.details?.content ) }}></Box>
                                        {/* <Box dangerouslySetInnerHTML={{ __html: DiscussionForumStore.getDiscussion?.details?.content }}></Box> */}
                                    </Box>
                                </Box>
                            </Box>}
                        <Divider />
                        <Box flexGrow={1}>
                            {DiscussionForumStore.getDiscussion?.comments?.length > 0 && <Box display="flex" flexDirection="column">
                                <Typography padding="10px 20px" borderBottom="1px solid #d3d3d3" variant='subtitle2' color="GrayText"> {DiscussionForumStore.getDiscussion?.comments?.length} Comments</Typography>
                                <Box className='custom-scrollbar' overflow="auto" flexGrow={1} position="relative">
                                    {DiscussionForumStore.getDiscussion?.comments?.map( ( comment, commentPos ) => (
                                        <DiscussionComment openCommentBox={openCommentBox} key={comment._id} commentPos={commentPos} comment={comment} />
                                    ) )}
                                </Box>

                            </Box>}

                            {DiscussionForumStore.getDiscussion?.comments?.length === 0 && <Box alignItems="center" padding="40px 20px" display="flex" flexDirection="column" gap="10px">
                                <img src={NoCommentsImg} alt="no-comments" width="130px" />
                                <Typography color="textSecondary" variant='subtitle2'>No comments yet</Typography>
                                <Button color='secondary' onClick={() => openCommentBox( "Commenting to original discussion" )} variant='outlined' startIcon={Icons.default.Comment} sx={{ textTransform: "capitalize" }}>Be the first one to comment.</Button>
                            </Box>}
                        </Box>
                        {commentBoxState && <CommentDialog setCommentingTo={setCommentingTo} commentingTo={commentingTo} commentBoxState={commentBoxState} setCommentBoxState={setCommentBoxState} />}



                        {DiscussionForumStore.getDiscussion?.comments?.length > 0 && <Box sx={{ zIndex: 100 }} onClick={() => openCommentBox( "Commenting to original discussion" )} borderTop="1px solid #d3d3d3" borderRadius="0 0 5px 5px" boxShadow="0 -1px 10px 0px rgba(0,0,0,0.1)" position="sticky" bottom="0" bgcolor={palette.common.bg} padding="20px">
                            <Typography fontSize="14px" fontWeight="500" display="flex" alignItems="center" gap="10px" > {Icons.default.Comment} Add new comment here...</Typography>
                        </Box>}

                    </Box>}
                {loading && <DataLoadingSpinner waitingMessage="Loading the discussion..." />}
            </Paper >}
            {errorCode === "forbidden" && <BlockMessage
                type='warning'
                actions={[
                    { icon: Icons.default.ArrowBack, label: "Back to discussions", action: navigateToDiscussions }
                ]}
                message={`You do not have permission to access this discussion(${id}).`}
            />}
            <Dialog open={errorCode === "notfound"}>
                <DialogContent>
                    <Box padding="20px" display="flex" flexDirection="column" gap="10px" alignItems="center" justifyContent="center">
                        <img src={DiscussionNotFoundImg} alt="Discussion not found" width="150px" />
                        <Typography color="textSecondary" variant='subtitle2'>Discussion you are looking for does not exists anymore!</Typography>
                        <Button variant='outlined' color='secondary' startIcon={Icons.default.ArrowBack} sx={{ textTransform: "capitalize" }} onClick={navigateToDiscussions}>Back to discussions</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box >
    )
} )

export default Discussion
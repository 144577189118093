import noAttachmentImg from '../../assets/no-attachments.svg'
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Drawer, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useUserStore } from '../../store/user.store'
import { CheckBox, CheckBoxOutlineBlank, InfoOutlined, OpenInNewOutlined } from '@mui/icons-material'
import ConfirmDialog from '../common/ConfirmDialog'
import TableHeader from '../common/TableHeader'
import { useDownloadStatusListStore } from '../../store/downloadstatuslistdialog.store'
import { Icons, attachmentThumbnails, convertToCDNLink, getFileSizeFromBytes, isIOS } from '../../utils/utilities'
import DetailsViewer from './DetailsViewer'
import { toast } from 'react-toastify'
import DocumentViewer from '../common/DocumentViewer'
import api from '../../service/api'

const columns = [
    { name: "Attachment name", padding: "10px 20px 10px 40px", minWidth: "200px", align: "left", borderRight: "1px solid rgba(0,0,0,0.2)" },
    { name: "Size", minWidth: "150px", align: "left", borderRight: "1px solid rgba(0,0,0,0.2)", styles: { "@media(max-width:700px)": { display: "none" } } },
    { name: "Uploaded on", minWidth: "170px", align: "left", borderRight: "1px solid rgba(0,0,0,0.2)", styles: { "@media(max-width:500px)": { display: "none" } } },
    { name: "Actions", padding: "10px 40px 10px 20px", align: "right", styles: { minWidth: "fit-content", position: "sticky" } }
]


const AttachmentRow = ( { setPreviewingFileDetails, setPreviewFile, material, markedAttachments, setMarkedAttachments, attachment, deleteAttachment, renameAttachment, setAttachmentsSelectedForDetails } ) => {

    const [deleting, setDeleting] = useState( false )
    const [renameValue, setRenameValue] = useState( "" )
    const [renameDialogState, setRenameDialogState] = useState( false )
    const [selectedAttachment, setSelectedAttachment] = useState( null )
    const [confirmDialogStatus, setConfirmDialogStatus] = useState( false )

    const marked = markedAttachments.find( att => att.id === attachment.study_material_attachment_id )

    const { palette, table, border, theme } = useTheme()

    const UserStore = useUserStore()

    const DownloadStatusListStore = useDownloadStatusListStore()

    const toggleMarked = () => {
        if ( marked ) {
            let index
            for ( let i = 0; i < markedAttachments.length; i++ ) {
                const att = markedAttachments[i]
                if ( att.id === attachment.study_material_attachment_id ) {
                    index = i
                    break
                }
            }
            const newMarkedList = [...markedAttachments]
            newMarkedList.splice( index, 1 )
            setMarkedAttachments( newMarkedList )
        } else {
            const newMarkedList = [...markedAttachments, { id: attachment.study_material_attachment_id, url: attachment.study_material_attachment_url, name: attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name, ext: attachment.study_material_attachment_name.split( "." ).pop(), ...attachment }]
            setMarkedAttachments( newMarkedList )
        }
    }


    const viewAttachment = async () => {
        try {
            const { data: { data: url } } = await api.downloadAttachment( attachment.study_material_attachment_id, { url: attachment.study_material_attachment_url } )
            setPreviewFile( convertToCDNLink( url ) )
            setPreviewingFileDetails( attachment )
        } catch ( err ) {
            toast( "Couldn't preview the file" )
        }
    }


    return (
        <TableRow className='table-row' sx={{ "&:hover": { background: palette.rowHover } }}>

            <ConfirmDialog
                status={confirmDialogStatus}
                confirmAction={async () => {
                    setDeleting( true )
                    await deleteAttachment( attachment.study_material_attachment_id )
                    setConfirmDialogStatus( false )
                    if ( marked ) {
                        toggleMarked()
                    }
                    setDeleting( false )
                }}
                cancelAction={() => setConfirmDialogStatus( false )}
                waiting={deleting}
                message={
                    <Typography variant="subtitle2">
                        Do you really want to delete <em>{attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name}</em>?
                    </Typography>}
                actionName="Delete"
            />
            <Dialog PaperProps={{ sx: { width: { md: "60%", xs: "85%" }, background: palette.form.formCardBg } }} open={renameDialogState}>
                <DialogTitle>Rename Attachment</DialogTitle>
                <DialogContent sx={{ padding: "20px" }}>
                    <Box sx={{ background: palette.form.formBg, borderRadius: "5px", padding: "20px", border: border[1] }}>
                        <TextField size="small" sx={{ marginTop: "2px" }} value={renameValue} onChange={( e ) => setRenameValue( e.target.value )} variant='outlined' fullWidth />
                        <Box marginTop="20px" display="flex" gap="20px" flexWrap="wrap">
                            <Button color="primary" disableElevation variant='contained' onClick={async () => { await renameAttachment( selectedAttachment, renameValue ); setRenameValue( "" ); setRenameDialogState( false ) }} sx={{ textTransform: "capitalize" }}>Update</Button>
                            <Button color="error" variant='contained' disableElevation onClick={() => { setRenameValue( "" ); setRenameDialogState( false ) }} sx={{ textTransform: "capitalize" }}>Cancel</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>


            <TableCell sx={{ color: table.color, padding: columns[0].padding || "10px 20px" }}>
                <Box onClick={toggleMarked} alignItems="center" gap="10px" display="flex">
                    <Box marginTop="5px">
                        {marked ? <CheckBox fontSize="small" sx={{ color: palette.primary.dark, cursor: "pointer" }} /> : <CheckBoxOutlineBlank fontSize="small" sx={{ color: palette.grey[500], '&:hover': { color: palette.grey[theme === 'dark' ? 200 : 800] }, cursor: "pointer" }} />}
                    </Box>
                    <Box marginTop="5px">
                        <img style={{ objectFit: "fit" }} width="35px" src={attachmentThumbnails[attachment.study_material_attachment_type] || attachmentThumbnails["default"]} alt={attachment.study_material_attachment_type} />
                    </Box>
                    <strong>
                        {attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name}
                    </strong>
                </Box>
            </TableCell>
            <TableCell sx={{ color: table.color, padding: "10px 20px", "@media(max-width:700px)": { display: "none" } }}>
                <strong>{getFileSizeFromBytes( +( attachment?.study_material_attachment_meta?.size || 0 ) )}</strong>
            </TableCell>
            <TableCell sx={{ color: table.color, padding: "10px 20px", "@media(max-width:500px)": { display: "none" } }}>
                <strong>{new Date( attachment.study_material_attachment_published_date ).toLocaleDateString( 'en-IN', { month: "2-digit", day: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" } )}</strong>
            </TableCell>
            <TableCell sx={{ color: table.color, padding: columns[3].padding || "10px 20px" }} align='right'>
                <Box display="flex" justifyContent="flex-end" alignItems="center" gap="0">
                    {( attachment?.study_material_attachment_type === 'pdf' || !isIOS() ) && <IconButton size='small' onClick={viewAttachment} color='greyed'><OpenInNewOutlined /></IconButton>}
                    <IconButton size='small' color="greyed" onClick={( e ) => { DownloadStatusListStore.downloadAttachment( attachment.study_material_attachment_id, attachment.study_material_attachment_url, attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name, attachment.study_material_attachment_name.split( "." ).pop() ) }} >{Icons.default.DownloadIconOutlined}</IconButton>
                    <IconButton size='small' color='greyed' onClick={() => setAttachmentsSelectedForDetails( attachment )} ><InfoOutlined /></IconButton>
                    {UserStore.getUser?.user_role !== "STUDENT" && UserStore.getUser.user_id === material.empcode && <IconButton size='small' color="greyed" onClick={e => { setSelectedAttachment( attachment.study_material_attachment_id ); setRenameValue( attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name ); setRenameDialogState( true ) }}>{Icons.default.EditIconOutlined}</IconButton>}
                    {UserStore.getUser?.user_role !== "STUDENT" && UserStore.getUser.user_id === material.empcode && <IconButton size='small' color="error" onClick={() => setConfirmDialogStatus( true )} >{Icons.default.DeleteIconOutlined}</IconButton>}
                </Box>
            </TableCell>
        </TableRow>
    )
}

const ListView = ( { setAddMode, material, attachments, allAttachments, removefilter, renameAttachment, deleteAttachment } ) => {

    const [attachmentsSelectedForDetails, setAttachmentsSelectedForDetails] = useState( null )
    const [confirmDialogStatus, setConfirmDialogStatus] = useState( false )
    const [markedAttachments, setMarkedAttachments] = useState( [] )
    const [previewingFileDetails, setPreviewingFileDetails] = useState( null )
    const [previewFile, setPreviewFile] = useState( null )
    const [deleting, setDeleting] = useState( false )

    const DownloadStatusListStore = useDownloadStatusListStore()
    const UserStore = useUserStore()

    const { border, palette } = useTheme()

    const downloadMultipleAttachments = () => {
        DownloadStatusListStore.downloadMultipleAttachments( markedAttachments )
    }

    const deleteMultipleAttachments = async () => {
        setDeleting( true )
        for ( const att of markedAttachments ) {
            await deleteAttachment( att.id, att.name )
        }
        setMarkedAttachments( [] )
        setDeleting( false )
        setConfirmDialogStatus( false )
    }

    const viewSelectedAttachment = async () => {
        try {
            const { data: { data: url } } = await api.downloadAttachment( markedAttachments[0].id, { url: markedAttachments[0].url } )
            setPreviewFile( convertToCDNLink( url ) )
            setPreviewingFileDetails( markedAttachments[0] )
        } catch ( err ) {
            toast( err?.response?.data?.message || "Couldn't preview the file" )
        }
    }

    const downloadViewingAttachment = async () => {
        setDeleting( true )
        await DownloadStatusListStore.downloadAttachment( previewingFileDetails.study_material_attachment_id, previewingFileDetails.study_material_attachment_url, previewingFileDetails.study_material_attachment_title ? previewingFileDetails.study_material_attachment_title : previewingFileDetails.study_material_attachment_name, previewingFileDetails.study_material_attachment_name.split( "." ).pop() )
        setDeleting( false )
    }

    return (
        <Box>

            <ConfirmDialog
                status={confirmDialogStatus}
                confirmAction={deleteMultipleAttachments}
                cancelAction={() => setConfirmDialogStatus( false )}
                waiting={deleting}
                message={
                    <Box>
                        Do you really want to delete these attachments:
                        <ul>{markedAttachments.map( att => (
                            <li key={att.id}> <b>{att.name}</b> </li>
                        ) )}
                        </ul>
                    </Box>}
                actionName="Delete"
            />

            <Drawer anchor='bottom' PaperProps={{ sx: { height: "calc(100% - 50px)" } }} sx={{ zIndex: 9000000 }} open={Boolean( previewFile )} >
                <Box bgcolor={palette.cardBackground} flexGrow={1} display="flex" overflow="auto" flexDirection="column" borderRadius="20px 20px 0 0">
                    <Box borderBottom={border[1]} alignItems="center" display="flex" justifyContent="space-between" gap="20px" padding="10px 20px">
                        <Typography variant='h6'>{previewingFileDetails?.study_material_attachment_name}</Typography>
                        <Box display="flex" gap="10px" alignItems="center">
                            <IconButton onClick={downloadViewingAttachment}>
                                {deleting ? <CircularProgress size={14} /> : Icons.default.DownloadIcon}
                            </IconButton>
                            <IconButton onClick={() => { setPreviewFile( null ); setPreviewingFileDetails( null ) }}>
                                {Icons.default.CloseIcon}
                            </IconButton>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" overflow="auto" flexGrow={1} padding="20px" height="300px">
                        <DocumentViewer loadingText="Loading material..." setFile={setPreviewFile} type={previewingFileDetails?.type || previewingFileDetails?.study_material_attachment_name?.split( "." ).pop()} file={previewFile} />
                    </Box>
                </Box>
            </Drawer>

            {attachmentsSelectedForDetails && <DetailsViewer attachment={attachmentsSelectedForDetails} setShowDetails={setAttachmentsSelectedForDetails} open={Boolean( attachmentsSelectedForDetails )} />}
            {markedAttachments.length > 0 && <Box bgcolor={palette.greyedOut} marginBottom="20px" padding="5px 10px" display="flex" gap="20px" alignItems="center" borderRadius="25px" border={border[1]}>
                <Box display="flex" gap="10px" alignItems="center" >
                    <IconButton size="small" onClick={() => setMarkedAttachments( [] )}>
                        {Icons.small.CloseIcon}
                    </IconButton>
                    <Typography variant='subtitle2' display="inline-block">{markedAttachments.length} selected</Typography>
                </Box>
                <Box display="flex" gap="10px" alignItems="center" >
                    <IconButton size="small" onClick={() => downloadMultipleAttachments()}>
                        {Icons.small.DownloadIcon}
                    </IconButton>
                    {UserStore.getUser.user_role === "FACULTY" && <IconButton size="small" onClick={() => setConfirmDialogStatus( true )}>
                        {Icons.small.DeleteIcon}
                    </IconButton>}
                    {markedAttachments.length === 1 && ( markedAttachments[0]?.study_material_attachment_type === 'pdf' || !isIOS() ) && <IconButton size="small" onClick={viewSelectedAttachment}>
                        {Icons.small.VisibilityIcon}
                    </IconButton>}
                    {markedAttachments.length === 1 && <IconButton size="small" onClick={() => setAttachmentsSelectedForDetails( markedAttachments[0] )}>
                        {Icons.small.InfoIcon}
                    </IconButton>}
                </Box>
            </Box>}
            {attachments && attachments.length > 0 && <TableContainer sx={{ bgcolor: palette.contentBg, maxHeight: "500px", border: border[1], borderRadius: "5px" }}>
                <Table stickyHeader>
                    <TableHeader columns={columns} />
                    <TableBody>
                        {attachments.map( ( attachment, index ) => (
                            <AttachmentRow setPreviewingFileDetails={setPreviewingFileDetails} setPreviewFile={setPreviewFile} material={material} deleteAttachment={deleteAttachment} setMarkedAttachments={setMarkedAttachments} markedAttachments={markedAttachments} renameAttachment={renameAttachment} setAttachmentsSelectedForDetails={setAttachmentsSelectedForDetails} key={index} attachment={attachment} />
                        ) )}
                    </TableBody>
                </Table>
            </TableContainer>}
            {( !attachments || attachments.length === 0 ) && <Box textAlign="center" display="flex" flexDirection="column" gap="20px" padding="20px" alignItems="center" justifyContent="center" width="100%">
                <img width="160px" src={noAttachmentImg} alt="No attachments" />
                <Typography variant='subtitle2' fontWeight="bold" color="textSecondary" >No attachments!</Typography>
                <Box display="flex" gap="10px" justifyContent="center" alignItems="center">
                    {UserStore.getUser.user_role !== 'STUDENT' && <Button variant='contained' color='secondary' disableElevation onClick={() => { setAddMode( mode => !mode ) }} sx={{ textTransform: "capitalize", marginTop: "10px" }}>Add Attachment</Button>}
                    {allAttachments.length > 0 && <Button variant='contained' color='errorMessage' disableElevation onClick={removefilter} sx={{ textTransform: "capitalize", marginTop: "10px" }}>Remove filter</Button>}
                </Box></Box>}
        </Box>
    )
}

export default ListView

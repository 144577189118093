import { Box, Card, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import api from '../../../service/api'
import { getCountString } from '../../../utils/utilities'
import { useUserStore } from '../../../store/user.store'


const colors = [
    { bg: "#ed731c4D", circleColor: "#ed731c" },
    { bg: "#DC35454D", circleColor: "#DC3545" },
    { bg: "#303fe34D", circleColor: "#303fe3" },
    { bg: "#0D6EFD4D", circleColor: "#0D6EFD" },
    { bg: "#4001914D", circleColor: "#400191" },
]

const apiCalls = {
    studymaterial: api.audit.fetchStudyMaterialCounts,
    exams: api.audit.getExamsCounts,
    assignments: api.audit.getAssignmentCounts,
    quizzes: api.audit.getQuizzesCounts,
    discussions: api.audit.getDiscussionsCounts,
}

const ResourceCard = ( { resource, label, sublabel, index } ) => {

    const [count, setCount] = useState( 0 )
    const [loading, setLoading] = useState( true )


    useEffect( () => {
        const getData = async () => {
            try {
                const apiCall = apiCalls[resource]
                const { data: { data } } = await apiCall( { last30dayscount: true, totalCount: true } )
                let { last30daysCount = 0, last30daysAttachmentsCount = 0 } = data
                if ( 'studymaterial' === resource )
                    last30daysCount = getCountString( last30daysAttachmentsCount )
                else
                    last30daysCount = getCountString( last30daysCount )
                setCount( last30daysCount )
            } catch ( err ) {
                console.log( `Error fetching ${resource} last 30 days count` )
            } finally {
                setLoading( false )
            }
        }
        getData()
    }, [resource] )

    return (
        <Box padding="20px" flexGrow={1} borderRadius="10px" bgcolor={colors[index].bg} >
            <Box width="50px" display="flex" alignItems="center" justifyContent="center" sx={{ aspectRatio: 1 }} borderRadius="50%" bgcolor={colors[index].circleColor} >
                <Typography variant="h6" fontSize="16px" color="white">{loading ? <CircularProgress color='warning' size={16} /> : count}</Typography>
            </Box>
            <Box marginTop="20px">
                <Typography color="primaryDark.main" variant="subtitle2">{label}</Typography>
                <Typography variant='subtitle2' fontWeight="normal" fontSize="12px" >{sublabel}</Typography>
            </Box>
        </Box>
    )
}


const ResourcesCount = () => {
    const UserStore = useUserStore()
    return (
        <Card sx={{ flexShrink: 0 }}>
            <Box padding="20px">
                <Box overflow="auto" display="flex" flexDirection="column" flexGrow={1}>
                    <Typography marginBottom="20px" fontSize="16px" fontWeight="bold" color="primaryDark.main">Last 30 days activities</Typography>
                    <Box overflow="auto" flexWrap="wrap" alignContent="center" justifyItems="center" display="flex" gap="10px" flexGrow={1} gridTemplateColumns="repeat(5,1fr)">
                        <ResourceCard index={0} resource="studymaterial" label="Study materials attachments" sublabel="uploads" />
                        <ResourceCard index={4} resource="assignments" label="Assignments" sublabel="posted" />
                        <ResourceCard index={1} resource="exams" label="Exams" sublabel="given" />
                        <ResourceCard index={2} resource="quizzes" label="Quizzes" sublabel="conducted" />
                        {UserStore.getUser.user_role !== 'HOD' && <ResourceCard index={3} resource="discussions" label="Discussions" sublabel="created" />}
                    </Box>
                </Box>
            </Box>
        </Card>
    )
}

export default ResourcesCount

import React, { useState } from 'react'
import { Drawer, IconButton, Box, Typography, useTheme, Collapse } from '@mui/material'
import { Icons } from '../../../utils/utilities'
import { Link, useLocation } from 'react-router-dom'
import { useUserStore } from '../../../store/user.store'
import aliveLogo from '../../../assets/alivelogo.png'
import { useEffect } from 'react'
import { useLayoutContext } from '../../../contexts/layout.context'
import { DomainAdd } from '@mui/icons-material'

const allMenu = [
    { id: 1, activeMatchRegexes: [/^(\/admin\/home)\/?$/], label: "Home", icon: Icons.default.HomeIconOutlined, activeIcon: Icons.default.HomeIcon, link: `/admin/home`, children: null },
    { id: 18, activeMatchRegexes: [/^(\/admin\/announcement)\/?$/], active: true, permission: "announcements", label: "Announcements", activeIcon: Icons.default.Announcement, icon: Icons.default.AnnouncementOutlined, link: `/admin/announcement`, children: null },
    { id: 2, activeMatchRegexes: [[/^(\/admin\/academic-rules)\/?$/, /^(\/admin\/academic-rules\/\w+)\/?$/, /^(\/admin\/academic-rule\/\d+\/checklist)\/?$/], [/^(\/admin\/academics\/auditors)\/?$/], [/^(\/admin\/academics\/audit)\/?$/]], active: true, permission: "academics", label: "Academics", activeIcon: Icons.default.School, icon: Icons.default.SchoolOutlined, link: [`/admin/academic-rules`, `/admin/academics/auditors`, `/admin/academics/audit`], children: ["Academic checklist", "Progress Auditors", "Audit"], childrenIcons: [{ default: Icons.default.RuleOutlined, activeIcon: Icons.default.Rule }, { default: Icons.default.AuditorOutlined, activeIcon: Icons.default.Auditor }, { default: Icons.default.InsightsIcon, activeIcon: Icons.default.InsightsIcon }] },
    { id: 3, activeMatchRegexes: [/^(\/admin\/audit)\/?$/], active: true, permission: "live_class_audit", label: "Live Class Audit", icon: Icons.default.OndemandVideoIconOutlined, activeIcon: Icons.default.OndemandVideoIcon, link: `/admin/audit`, children: null },
    { id: 17, activeMatchRegexes: [/^(\/admin\/assessment)\/?$/, /^(\/admin\/assessment)\/new\/?$/, /^(\/admin\/assessment)\/\d+\/?$/, /^(\/admin\/assessment)\/\d+\/participation\/?$/, /^(\/admin\/assessment)\/\d+\/evaluate\/\d+\/?$/], active: true, permission: "admission_exams", label: "Pre-Post Assessments", activeIcon: <DomainAdd />, icon: <DomainAdd />, link: `/admin/assessment`, children: null },
    { id: 4, activeMatchRegexes: [[/^(\/admin\/api-setting)\/?$/], [/^(\/admin\/feedback-setting)\/?$/], [/^(\/admin\/feedback)\/?$/], [/^(\/admin\/recordings)\/?$/]], active: true, permission: ["api_settings", "feedback", "feedback", "recordings"], label: "Online Classes", icon: Icons.default.VideocamIconOutlined, activeIcon: Icons.default.VideocamIcon, link: [`/admin/api-setting`, `/admin/feedback-setting`, `/admin/feedback`, `/admin/recordings`], children: ["API Settings", "Feedback Settings", "Feedback", "Recordings"], childrenIcons: [{ default: Icons.default.ApiOutlined, activeIcon: Icons.default.Api }, { default: Icons.default.SettingsOutlined, activeIcon: Icons.default.Settings }, { default: Icons.default.FeedbackIconOutlined, activeIcon: Icons.default.FeedbackIcon }, { default: Icons.default.FiberManualRecordIconOutlined, activeIcon: Icons.default.FiberManualRecordIcon }] },
    // { id: 5, activeMatchRegexes: [/^(\/admin\/study-material)\/?$/], active: true, permission: "study_material_audit", label: "Study Material", icon: Icons.default.DescriptionIconOutlined, activeIcon: Icons.default.DescriptionIcon, link: `/admin/study-material`, children: null },
    { id: 6, activeMatchRegexes: [/^(\/admin\/online-course)\/?$/], active: true, permission: "online_course", label: "Online Course", icon: Icons.default.PlayCircleFilledWhiteIconOutlined, activeIcon: Icons.default.PlayCircleFilledWhiteIcon, link: `/admin/online-course`, children: null },
    { id: 7, activeMatchRegexes: [[/^(\/admin\/resume-forms)\/?$/], [/^(\/admin\/resume-templates)\/?$/], [/^(\/admin\/resume-entities)\/?$/]], active: true, permission: "resume_builder", label: "Resume Builder", icon: Icons.default.ProtraitOutlined, activeIcon: Icons.default.Protrait, link: [`/admin/resume-forms`, `/admin/resume-templates`, `/admin/resume-entities`], children: ["Resume Forms", "Resume Templates", "Resume Entities"], childrenIcons: [{ default: Icons.default.FormOutlined, activeIcon: Icons.default.Form }, { default: Icons.default.TemplatesOutlined, activeIcon: Icons.default.Templates }, { default: Icons.default.EntityOutlined, activeIcon: Icons.default.Entity }] },
    // { id: 8, activeMatchRegexes: [/^(\/admin\/resume-forms)\/?$/], active: true, permission: "discussions", label: "Discussions", icon: Icons.default.Groups2Outlined, activeIcon: Icons.default.Groups2, link: `/admin/discussions`, children: null },
    // { id: 9, activeMatchRegexes: [/^(\/admin\/exam)\/?$/], active: true, permission: "exam_audit", label: "Exam Audit", activeIcon: Icons.default.QuizIcon, icon: Icons.default.QuizIconOutlined, link: `/admin/exam`, children: null },
    { id: 10, activeMatchRegexes: [/^(\/admin\/attendance)\/?$/], active: true, permission: "attendance_audit", label: "Attendance Audit", icon: Icons.default.EmojiPeopleIconOutlined, activeIcon: Icons.default.EmojiPeopleIcon, link: `/admin/attendance`, children: null },
    { id: 16, activeMatchRegexes: [/^(\/admin\/temporary-users)\/?$/], active: true, permission: "admin_permission", label: "Temporary Users", icon: Icons.default.TemporaryUsersOutlined, activeIcon: Icons.default.TemporaryUsers, link: `/admin/temporary-users`, children: null },
    { id: 11, activeMatchRegexes: [/^(\/admin\/accounts)\/?$/], active: true, permission: "admin_permission", label: "Admin Accounts", icon: Icons.default.ManageAccountsOutlined, activeIcon: Icons.default.ManageAccounts, link: `/admin/accounts`, children: null },
    { id: 12, activeMatchRegexes: [/^(\/admin\/password)\/?$/], active: true, permission: "admin_permission", label: "Password", icon: Icons.default.LockIconOutlined, activeIcon: Icons.default.LockIcon, link: `/admin/password`, children: null },
    { id: 13, activeMatchRegexes: [/^(\/admin\/debug)\/?$/], active: true, permission: "debug_user", label: "Debug User", icon: Icons.default.DeveloperModeIconOutlined, activeIcon: Icons.default.DeveloperModeIcon, link: `/admin/debug`, children: null },
    { id: 14, activeMatchRegexes: [/^(\/admin\/analytics)\/?$/], active: true, permission: "analytics", label: "Analytics", icon: Icons.default.AnalyticsIconOutlined, activeIcon: Icons.default.AnalyticsIcon, link: `/admin/analytics`, children: null },
    { id: 15, activeMatchRegexes: [/^(\/admin\/control-panel)\/?$/], active: true, permission: "control_panel", label: "Control Panel", icon: Icons.default.SettingsOutlined, activeIcon: Icons.default.Settings, link: `/admin/control-panel`, children: null },
]

const SidedrawerMenuItem = ( { item, isActive, sidebarOpenState, closeOnMobileScreen, active } ) => {

    const [collapseState, setCollapseState] = useState( false )
    const { palette } = useTheme()
    const { pathname, search } = useLocation()


    return <Box borderRadius="5px" marginX="20px" bgcolor={collapseState && palette.nav.sidebarHover}>
        <Link className='default-link' to={!item.children ? item.link : pathname + search}>
            <Box
                onClick={item.children ? () => setCollapseState( !collapseState ) : closeOnMobileScreen}
                bgcolor={isActive && palette.nav.activeSidebarItem}
                color={isActive ? palette.nav.selected : palette.common.white}
                borderRadius="5px"
                sx={{
                    cursor: "pointer",
                    position: "relative",
                    background: collapseState ? isActive ? palette.nav.activeSidebarItem : palette.nav.sidebarHover : isActive ? palette.nav.activeSidebarItem : "none",
                    "&:hover": {
                        background: !isActive ? palette.nav.sidebarHover : palette.nav.activeSidebarItem,
                    }
                }}
                display="flex"
                alignItems="center"
                padding="5px 10px"
                gap="15px"
                marginY="5px"
            >
                <IconButton
                    color="inherit"
                    size='small'
                    sx={{
                        padding: "8px",
                        borderRadius: "5px",
                        transform: "scale(0.9)",
                        position: "relative"
                    }}>
                    {isActive ? item.icon : item.icon}
                </IconButton>
                <Typography flexGrow={1} display="flex" alignItems="center" justifyContent='space-between' flexShrink={0} color="inherit" variant="subtitle2" fontWeight={isActive ? "700" : "300"} fontSize="14px" textAlign="center">
                    {item.label}
                    {item.children && Icons.small.KeyboardArrowDown}
                </Typography>
            </Box>
        </Link>
        {item.children && <Collapse in={collapseState}>
            <Box flexDirection="column" paddingBottom="10px" display="flex" gap="5px" paddingRight="20px" paddingLeft="30px">
                {item.children.map( ( sublink, index ) => {
                    const activeChild = item.activeMatchRegexes[index].some( re => active.match( re ) )
                    return <Link className='default-link' key={sublink} to={item.link[index]}>
                        <Box
                            display="flex"
                            onClick={closeOnMobileScreen}
                            borderRadius="5px"
                            padding="2px 5px"
                            gap="20px"
                            alignItems="center"
                            sx={{
                                color: activeChild ? "white" : "lightgrey",
                                cursor: "pointer",
                                position: "relative",

                                "&::before": {
                                    display: activeChild ? "inline-block" : "none",
                                    content: "''",
                                    position: "absolute",
                                    background: "#fff",
                                    height: "60%",
                                    borderRadius: "0 2px 2px 0",
                                    left: 0,
                                    width: "2px",
                                    zIndex: 324434,
                                    top: "50%",
                                    transform: "translate(0,-50%)"
                                },

                                "&:hover": {
                                    textDecoration: "underline",
                                }
                            }}
                        >
                            <Typography variant="subtitle2" flexShrink={0} color="inherit" fontWeight={activeChild ? "400" : "300"} fontSize="14px" textAlign="center">{sublink}</Typography>
                        </Box>
                    </Link>
                } )}
            </Box>
        </Collapse>}
    </Box>
}

const SideDrawer = function ( { closeSideMenu } ) {

    const UserStore = useUserStore()

    const { sidebarOpenState, setNavHeadText, active, setMenuState, menuState, sidebarWidth, minSidebarCollapseWidth } = useLayoutContext()

    const [menu, setMenu] = useState( [] )
    const { palette } = useTheme()

    const closeOnMobileScreen = () => {
        if ( window.innerWidth < minSidebarCollapseWidth )
            closeSideMenu()
    }

    useEffect( () => {
        if ( UserStore.getUser.user_role.toLowerCase() !== 'admin' && UserStore.getUser.user_permissions ) {
            let menuList = []
            for ( let i = 0; i < allMenu.length; i++ ) {
                const menuItem = allMenu[i]
                if ( menuItem.permission ) {
                    if ( typeof menuItem.permission === "string" ) {
                        if ( UserStore.getUser.user_permissions[menuItem.permission] ) {
                            menuList.push( menuItem )
                        }
                    } else {
                        let dropDownMenu = undefined
                        for ( let j = 0; j < menuItem.permission.length; j++ ) {
                            const permission = menuItem.permission[j]
                            if ( UserStore.getUser.user_permissions[permission] ) {
                                if ( !dropDownMenu ) {
                                    dropDownMenu = { active: true, label: menuItem.label, icon: menuItem.icon, link: [], children: [] }
                                }
                                dropDownMenu.link.push( menuItem.link[j] )
                                dropDownMenu.children.push( menuItem.children[j] )
                            }
                        }
                        if ( dropDownMenu )
                            menuList.push( dropDownMenu )
                    }
                } else {
                    menuList.push( menuItem )
                }
                setMenu( menuList )
            }
        } else {
            setMenu( allMenu )
        }
    }, [UserStore] )


    useEffect( () => {
        for ( let i = 0; i < menu.length; i++ ) {
            const item = menu[i]
            const isActive = item.activeMatchRegexes.flat().some( re => active.match( re ) )
            if ( isActive ) {
                console.log( item.label )
                setNavHeadText( item.label )
                break
            }
        }
    }, [menu, active, setNavHeadText] )


    return (
        <Box>
            <Drawer
                variant='persistent'
                sx={{ position: { md: "static", xs: "fixed" }, zIndex: { md: "initial", xs: "120000" }, overflow: "hidden", width: menuState ? sidebarWidth : 0, height: "100%", flexSrink: 0, display: "flex", flexDirection: "column" }}
                PaperProps={{ className: "custom-scrollbar", sx: { width: sidebarWidth, borderRadius: "0", background: palette.nav.sidebar, color: "white", borderRight: "none" } }}
                anchor="left"
                open={menuState}
                className="custom-scrollbar"
            >
                <Box position="relative" flexGrow={1} overflow="auto" borderRight="1px solid #d3d3d3" display="flex">
                    <Box className="sidebar-scrollbar" width="100%" overflow="auto" padding="20px 10px" color="GrayText" alignItems="stretch" gap="10px">

                        <Box padding="10px 20px 40px 20px" display="flex" gap="10px" alignItems="center">
                            <Box padding="5px" borderRadius="5px" bgcolor="white"><img width="30px" src={aliveLogo} alt="Logo" /></Box>
                            <Box>
                                <Typography color="white" variant='h6'>Alive</Typography>
                                <Typography color="white" marginTop="-7px" letterSpacing="1px" fontWeight="300" variant='body2'>Digital Classrooms</Typography>
                            </Box>
                            <IconButton sx={{ color: "white", display: { md: "none", xs: "block" } }} onClick={() => setMenuState( false )}>
                                {Icons.default.CloseIcon}
                            </IconButton>
                        </Box>

                        {menu.map( item => {
                            const isActive = item.children ? item.activeMatchRegexes.flat().some( re => active.match( re ) ) : item.activeMatchRegexes.some( re => active.match( re ) )
                            return ( item.permission || item.permission === undefined ) ? (
                                <SidedrawerMenuItem active={active} closeOnMobileScreen={closeOnMobileScreen} item={item} isActive={isActive} sidebarOpenState={sidebarOpenState} key={item.id} />
                            ) : ""
                        } )}
                    </Box>

                </Box >
            </Drawer >
        </Box >
    )
}

export default SideDrawer



// import DashboardIcon from '@mui/icons-material/Dashboard'
// import SchoolIcon from '@mui/icons-material/School'
// import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
// import EventIcon from '@mui/icons-material/Event'
// import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
// import MessageIcon from '@mui/icons-material/Message'
// import NotificationsIcon from '@mui/icons-material/Notifications'
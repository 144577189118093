import { Box, Button, CircularProgress, Drawer, IconButton, Paper, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../../../service/api'
import { attachmentThumbnails, convertToCDNLink, docViewerSupportedFormats, getFileSizeFromBytes, Icons, showDateInIndianDateFormat } from '../../../utils/utilities'
import BreadcrumbList from '../../../components/common/BreadcrumbList'
import ConfirmDialog from '../../../components/common/ConfirmDialog'
import { toast } from 'react-toastify'
import CreateOrEditAnnouncementDialog from '../../../components/admin/announcements/CreateOrEditAnnouncementDialog'
import DocumentViewer from '../../../components/common/DocumentViewer'
import CodeButton from '../../../components/common/CodeButton'
import TryAgainMessageBlock from '../../../components/common/TryAgainMessageBlock'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import { useUserStore } from '../../../store/user.store'
import BlockMessage from '../../../components/common/BlockMessage'

const AttachmentChip = ( { attachment, announcement, setPreviewingFileDetails, setPreviewFile } ) => {
    const [downloading, setDownloading] = useState( false )
    const [waitingForViewing, setWaitingForViewing] = useState( false )

    const { palette } = useTheme()

    const viewAttachment = async ( attachment ) => {
        setWaitingForViewing( true )
        try {
            const { data: { data } } = await api.announcements.fetchAnnouncementAttachment( announcement.id, attachment )
            setPreviewFile( convertToCDNLink( data ) )
            setPreviewingFileDetails( attachment )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Couldn't preview the file" )
        } finally {
            setWaitingForViewing( false )
        }
    }


    const downloadAttachment = async ( attachment ) => {
        setDownloading( true )
        try {
            const { data: { data } } = await api.announcements.fetchAnnouncementAttachment( announcement.id, attachment )
            const anchor = window.document.createElement( 'a' )
            anchor.href = convertToCDNLink( data )
            anchor.download = attachment.name
            anchor.click()
            anchor.remove()
        } catch ( err ) {
            toast( err?.response?.data?.message || "Couldn't preview the file" )
        } finally {
            setDownloading( false )
        }
    }

    const attachmentType = attachment.name?.split( "." ).pop()?.toLowerCase()

    return ( <Box sx={{ cursor: "pointer" }} bgcolor={palette.primary.light + 22} border={`2px solid ${palette.primary.main}`} display="flex" gap="5px" alignItems="center" borderRadius="20px" padding="2px 5px 2px 2px">
        <img width="35px" src={attachmentThumbnails[attachmentType] || attachmentThumbnails["default"]} alt={attachment.name} />
        <Box>
            <Typography title={attachment.name} maxWidth="150px" noWrap fontSize="14px" fontWeight="500">{attachment.name}</Typography>
            <Box display="flex" gap="5px" alignItems="center">
                <Typography title={attachment.mimetype} display="block" marginTop="-2px" maxWidth="100px" noWrap fontSize="12px"><em>{attachment.mimetype}</em></Typography>
                <Typography display="inline" marginTop="-2px" fontSize="12px"><em>. {getFileSizeFromBytes( attachment.size )}</em></Typography>
            </Box>
        </Box>
        {docViewerSupportedFormats.includes( attachmentType ) && <IconButton onClick={() => viewAttachment( attachment )} size="small">
            {waitingForViewing ? <CircularProgress size={14} /> : Icons.default.VisibilityIcon}
        </IconButton>}
        <IconButton onClick={() => downloadAttachment( attachment )} size="small">
            {downloading ? <CircularProgress size={14} /> : Icons.default.DownloadIcon}
        </IconButton>
    </Box> )
}

const Announcement = () => {

    const [deleteConfirmDialogState, setDeleteConfirmDialogState] = useState( false )
    const [editMode, setEditMode] = useState( false )
    const [previewFile, setPreviewFile] = useState( null )
    const [previewingFileDetails, setPreviewingFileDetails] = useState( null )
    const [publishing, setPublishing] = useState( false )
    const [announcement, setAnnouncement] = useState( null )
    const [hasPermission, setHasPermission] = useState( true )
    const [deleting, setDeleting] = useState( false )
    const [loading, setLoading] = useState( true )
    const [error, setError] = useState( null )

    const { announcement_id } = useParams()

    const { palette, border } = useTheme()

    const UserStore = useUserStore()

    const navigate = useNavigate()

    const publishAnnouncement = async () => {
        setPublishing( true )
        try {
            const payload = {}
            payload.updateType = 'statusupdate'
            payload.announcement_status = 'published'
            const { data } = await api.announcements.updateAnnouncement( announcement.id, payload )
            await fetchAnnouncement( false )
            toast( data.message )
        } catch ( err ) {
            toast( err?.response?.data?.message || "Something went wrong while deleting the announcement" )
        } finally {
            setPublishing( false )
        }
    }

    const deleteAnnouncement = async () => {
        setDeleting( true )
        try {
            await api.announcements.deleteAnnouncement( announcement_id )
            navigate( `/admin/announcement` )
            setDeleteConfirmDialogState( false )
        } catch ( err ) {
            console.log( err )

            toast( err?.response?.data?.message || "Something went wrong while deleting the announcement" )
        } finally {
            setDeleting( false )
        }
    }

    const fetchAnnouncement = useCallback( async ( showLoading = true ) => {
        if ( showLoading )
            setLoading( true )
        try {
            const { data: { data } } = await api.announcements.fetchAnnouncement( announcement_id )
            setAnnouncement( data )
            setError( null )
            return data
        } catch ( err ) {
            setError( { code: err?.response?.status, message: err?.response?.data?.message } )
        } finally {
            setLoading( false )
        }
    }, [announcement_id] )

    useEffect( () => {
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.announcements ) {
            setHasPermission( false )
        } else
            fetchAnnouncement()
    }, [fetchAnnouncement, UserStore] )

    return hasPermission ? (
        <Box overflow="auto" flexGrow={1} padding="20px">

            <BreadcrumbList items={[
                { label: "Announcements", link: `/admin/announcement` },
                announcement ? announcement.announcement_title : <CircularProgress size={12} />
            ]} />
            {editMode && <CreateOrEditAnnouncementDialog getUpdatedData={fetchAnnouncement} mode="edit" announcement={announcement} state={editMode} handleClose={() => setEditMode( false )} />}

            <ConfirmDialog
                status={deleteConfirmDialogState}
                actionName="Yes! delete it"
                confirmAction={deleteAnnouncement}
                waiting={deleting}
                message={<strong>After deletion the announcement will be removed from user's screen</strong>}
                cancelAction={() => setDeleteConfirmDialogState( false )} />

            {Boolean( previewingFileDetails ) && <Drawer anchor='bottom' PaperProps={{ sx: { height: "calc(100% - 50px)" } }} sx={{ zIndex: 9000000 }} open={Boolean( previewFile )} >
                <Box bgcolor={palette.cardBackground} flexGrow={1} display="flex" overflow="auto" flexDirection="column" borderRadius="20px 20px 0 0">
                    <Box borderBottom={border[1]} alignItems="center" display="flex" justifyContent="space-between" gap="20px" padding="10px 20px">
                        <Typography variant='h6'>{previewingFileDetails?.name}</Typography>
                        <Box display="flex" gap="10px" alignItems="center">
                            <IconButton onClick={() => { setPreviewFile( null ); setPreviewingFileDetails( null ) }}>
                                {Icons.default.CloseIcon}
                            </IconButton>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" overflow="auto" flexGrow={1} padding="20px" height="300px">
                        <DocumentViewer fileDetails={previewingFileDetails} loadingText="Loading attachment..." setFile={setPreviewFile} type={previewingFileDetails?.type || previewingFileDetails?.name?.split( "." ).pop()} file={previewFile} />
                    </Box>
                </Box>
            </Drawer>}

            <Paper sx={{ overflow: "clip" }}>
                {!loading && !error && announcement && <Box>
                    <Box position="relative" padding="20px" borderBottom={border[1]}>
                        <Box sx={{ zIndex: 10 }} position="relative">
                            <CodeButton size="small">{announcement.announcement_status}</CodeButton>
                            <Typography marginTop="10px" variant='h4'>
                                {announcement.announcement_title}
                            </Typography>
                            <Typography display="flex" alignItems='flex-start' gap="20px" variant='subtitle2' color="textSecondary">
                                <em>{`${showDateInIndianDateFormat( announcement.announcement_starts_at, { month: "short", showTime: true } )}-${showDateInIndianDateFormat( announcement.announcement_ends_at, { month: "short", showTime: true } )}, For: ${announcement?.announcement_for?.join( ", " )}`}</em>
                            </Typography>

                            <Box marginTop="10px" display="flex" gap="10px" alignItems="center" flexWrap="wrap" >
                                {announcement.announcement_status === 'draft' && <Button disabled={publishing} onClick={publishAnnouncement} sx={{ textTransform: "capitalize" }} size='small' startIcon={publishing && <CircularProgress size={14} />} disableElevation variant='contained' color='success' >{publishing ? "Publishing" : "Publish"}</Button>}
                                <Button onClick={() => setEditMode( true )} sx={{ textTransform: "capitalize" }} size='small' disableElevation variant='contained' color='secondary' >Edit</Button>
                                <Button onClick={() => setDeleteConfirmDialogState( true )} sx={{ textTransform: "capitalize" }} size='small' disableElevation variant='contained' color='errorMessage' >Delete</Button>
                            </Box>
                        </Box>

                        {announcement?.cover_image && <Box sx={{ position: "absolute", inset: 0, opacity: 0.3, pointerEvents: "none", zIndex: "1" }}>
                            <img style={{ objectFit: "cover" }} height="100%" width="100%" src={convertToCDNLink( announcement.cover_image )} alt="Cover banner" />
                        </Box>}
                        {announcement?.cover_image && <Box sx={{ position: "absolute", inset: 0, background: `linear-gradient(to right,${palette.common.bg},transparent 250%)`, pointerEvents: "none", zIndex: "2" }}>

                        </Box>}
                    </Box>
                    <Box padding="20px">
                        <Box >
                            <Box className="wysiwyg-text-container" marginTop="10px" dangerouslySetInnerHTML={{ __html: announcement.announcement_message }}></Box>
                        </Box>
                        {announcement.attachments && announcement.attachments?.length > 0 && <Box display="flex" gap="20px" flexWrap="wrap" marginTop="20px">
                            {announcement.attachments?.length > 0 && announcement.attachments?.map( ( attachment, attachmentIndex ) => {
                                return (
                                    <AttachmentChip announcement={announcement} setPreviewFile={setPreviewFile} setPreviewingFileDetails={setPreviewingFileDetails} key={`attachment-${attachmentIndex}`} attachment={attachment} />
                                )
                            } )}
                        </Box>}
                    </Box>
                </Box>}
                {error && <TryAgainMessageBlock err={error.message} code={error.code} getData={fetchAnnouncement} loading={loading} />}
                {!error && loading && <DataLoadingSpinner waitingMessage="Loading announcement details..." />}
            </Paper >
        </Box >
    ) : ( < Box padding="20px" >
        <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
    </Box > )
}

export default Announcement

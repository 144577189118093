import { toast } from "react-toastify"
import { base64ToFileAndBlob, convertToCDNLink } from "./utilities"
import api from "../service/api"


export const onDiscussionFilePasteOrDrop = async ( file, mime ) => {
    if ( ["image/jpeg", "image/png", "video/mp4", "video/webm"].includes( mime ) ) {
        const ext = mime?.split( "/" )[1]
        const mediaType = mime?.split( "/" )[0]

        try {
            let receivedFile
            if ( file instanceof File ) {
                receivedFile = file
            } else if ( typeof file === "string" && file.includes( 'base64' ) ) {
                const fileDetails = await base64ToFileAndBlob( file, `file-${Date.now()}.${ext}`, mime )
                receivedFile = fileDetails.file
            } else
                return ""

            // const newSrc = "https://images.pexels.com/photos/20189756/pexels-photo-20189756/free-photo-of-legs-of-girls-standing-in-traditional-dresses.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
            const newSrc = await discussionAttachmentUploader( mediaType, receivedFile )

            return newSrc ? { src: newSrc, tag: `<${mediaType} src="${newSrc}"/>` } : { src: "", tag: "" }
        } catch ( err ) {
            console.log( err )

            return ""
        }
    } else {
        toast( "Invalid attachment format..." )
        return ""
    }
}

export const discussionAttachmentUploader = async ( type, file, filesList ) => {
    try {
        if ( !file ) return ""

        var formData = new FormData()
        formData.append( type, file )

        const { data: { data: fileDetails } } = type === "image" ? await api.discussionForum.uploadImage( formData ) : await api.discussionForum.uploadVideo( formData )
        const url = convertToCDNLink( fileDetails.location )

        return url
    } catch ( err ) {
        console.log( err )
        return false
    }
}



export const onQuestionFilePasteOrDrop = async ( file, mime ) => {
    if ( ["image/jpeg", "image/png"].includes( mime ) ) {
        const ext = mime?.split( "/" )[1]
        const mediaType = mime?.split( "/" )[0]

        try {
            let receivedFile
            if ( file instanceof File ) {
                receivedFile = file
            } else if ( typeof file === "string" && file.includes( 'base64' ) ) {
                const fileDetails = await base64ToFileAndBlob( file, `file-${Date.now()}.${ext}`, mime )
                receivedFile = fileDetails.file
            } else
                return ""

            // const newSrc = "https://images.pexels.com/photos/20189756/pexels-photo-20189756/free-photo-of-legs-of-girls-standing-in-traditional-dresses.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
            const newSrc = await questionFileUploader( mediaType, receivedFile )

            return newSrc ? { src: newSrc, tag: `<${mediaType} src="${newSrc}"/>` } : { src: "", tag: "" }
        } catch ( err ) {
            console.log( err )

            return ""
        }
    } else {
        toast( "Invalid attachment format..." )
        return ""
    }
}

export const questionFileUploader = async ( type, file, module ) => {
    try {
        if ( !file ) return ""

        var formData = new FormData()
        formData.append( 'question_image', file )

        const { data: { data: fileDetails } } = await api.uploadQuestionImage( formData )
        const url = convertToCDNLink( fileDetails.location )

        return url
    } catch ( err ) {
        console.log( err )
        return false
    }
}
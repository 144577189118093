import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, IconButton, MenuItem, Paper, Select, TextField, Typography, useTheme } from '@mui/material'
import React, { useCallback } from 'react'
import { Icons } from '../../utils/utilities'
import { useSearchParams } from 'react-router-dom'
import ControlPanelSettingBox from '../../components/admin/control-panel/ControlPanelSettingBox'
import { useOrganizationStore } from '../../store/organization.store'
import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useState } from 'react'
import AddSetting from '../../components/admin/control-panel/AddSetting'
import api from '../../service/api'
import { toast } from 'react-toastify'
import { useSettingsStore } from '../../store/settings.store'
import { useUserStore } from '../../store/user.store'
import BlockMessage from '../../components/common/BlockMessage'

const AddSettingModule = ( { dialogState, setState } ) => {

    const [value, setValue] = useState( '' )
    const [adding, setAdding] = useState( false )

    const SettingsStore = useSettingsStore()

    const { palette } = useTheme()

    const addModule = async ( e ) => {
        try {
            if ( ( e.type === 'keyup' && e.key === 'Enter' ) || e.type === 'click' ) {
                if ( value.trim() !== '' ) {
                    setAdding( true )
                    await api.addSettingsModule( { label: value, module: value.replace( /[^a-zA-Z]/g, "_" ).toLocaleLowerCase() } )
                    await SettingsStore.fetchModules()
                    setValue( "" )
                    setState( false )
                }
            }
        } catch ( err ) {
            toast( err?.response?.data?.message || "An error occured while adding new module!" )
        } finally {
            setAdding( false )
        }
    }

    return (
        <Dialog PaperProps={{ sx: { width: "80vw" } }} open={dialogState}>
            <DialogTitle>
                Add new module
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth>
                    <label htmlFor="module-name"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}>Module Name *</Typography></label>
                    <TextField autoFocus onKeyUp={addModule} id="module-name" size='small' placeholder='Enter module name' value={value} onChange={e => setValue( e.target.value )} />
                    {value && <Typography>Key: {value.replace( /[^a-zA-Z]/g, "_" ).toLocaleLowerCase()}</Typography>}
                </FormControl>
                <Box marginTop="40px" display="flex" gap="10px" alignItems="center">
                    <Button disabled={adding} onClick={addModule} disableElevation sx={{ textTransform: "capitalize" }} variant='contained'> {adding && <CircularProgress size={14} />} {adding ? "Adding..." : "Add module"}</Button>
                    <Button onClick={() => setState( false )} color="error" sx={{ textTransform: "capitalize" }} variant="outlined">Cancel</Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

const ControlPanel = observer( () => {

    const [searchParams, setSearchParams] = useSearchParams( { selectedModule: 'general', selectedInstitute: 'AIT' } )
    const selectedModule = searchParams.get( 'selectedModule' )
    const selectedInstitue = searchParams.get( 'selectedInstitute' )
    const [addDialogState, setAddDialogState] = useState( false )
    const [addModuleDialogState, setAddModuleDialogState] = useState( false )
    const [loading, setLoading] = useState( true )
    const [isLoadingSettings, setIsLoadingSettings] = useState( false )
    const [parents, setParents] = useState( [] )
    const [hasPermission, setHasPermission] = useState( true )

    const OrganizationStore = useOrganizationStore()
    const SettingsStore = useSettingsStore()
    const UserStore = useUserStore()

    const setSearchParam = ( key, val ) => {
        setSearchParams( prev => {
            prev.set( key, val )
            return prev
        } )
    }

    const handleInstituteChange = async ( e ) => {
        if ( selectedModule !== "general" )
            setSearchParam( 'selectedInstitute', e.target.value )
    }

    const deleteSettingModule = async ( module ) => {
        try {
            await api.deleteSettingModule( module.id )
            await SettingsStore.fetchModules()
        } catch ( err ) {
            toast( err?.response?.data?.message || "Something went wrong while deleting setting module! please try again." )
        }
    }

    const getSelectedSettings = useCallback( async ( filter, newSettingAdded = false ) => {
        setIsLoadingSettings( true )
        await SettingsStore.fetchSettings( { filter, module: selectedModule, institute: selectedInstitue } )
        if ( newSettingAdded ) {
            const { data } = await api.getSettings( { filter: "parent" } )
            setParents( data.data )
        }
        setIsLoadingSettings( false )
    }, [SettingsStore, selectedInstitue, selectedModule] )

    useEffect( () => {
        const getData = async () => {
            await getSelectedSettings( "instituteandmodule", true )
        }
        getData()
    }, [selectedInstitue, selectedModule, getSelectedSettings] )

    useEffect( () => {
        const getData = async () => {
            if ( OrganizationStore.getOrganization?.length === 0 )
                await OrganizationStore.fetchOrganization()
            await SettingsStore.fetchModules()
            setLoading( false )
        }
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.control_panel ) {
            setHasPermission( false )
        } else
            getData()
    }, [OrganizationStore, SettingsStore, UserStore] )

    return (
        hasPermission ? (
            loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" width='100vw' flexDirection="column">
                    <CircularProgress />
                    <Typography marginTop={2}>Loading...</Typography>
                </Box>
            ) : (
                <Box display="flex" flexDirection="column" overflow="auto" flexGrow={1} padding="20px">
                    <Paper sx={{ minHeight: !loading && "350px", height: "100%", display: "flex", flexDirection: "column" }}>
                        <Box alignItems="center" gap="20px" flexWrap="wrap" display="flex" justifyContent="space-between" padding='20px' borderBottom="1px solid #d3d3d3">
                            <Box>
                                <Typography variant='h5'>Control panel</Typography>
                                <Typography color="textSecondary" variant='subtitle2'>Configure general settings of the modules</Typography>
                            </Box>
                            <Box display="flex" flexWrap="wrap" gap="10px" alignItems="center">
                                {selectedModule !== "general" && (
                                    <FormControl sx={{ width: { xs: "100%", sm: "fit-content" } }}>
                                        <Select onChange={handleInstituteChange} sx={{ textTransform: "uppercase", fontSize: "14px" }} value={selectedInstitue} size='small'>
                                            {loading && <MenuItem sx={{ textTransform: "capitalize", fontSize: "14px" }} value="AIT" disabled> <CircularProgress c /> Loading </MenuItem>}
                                            {!loading && OrganizationStore.getOrganization?.length > 0 && OrganizationStore.getOrganization.map( inst => (
                                                <MenuItem key={inst.institute_name_short} value={inst.institute_name_short} sx={{ textTransform: "capitalize", fontSize: "14px" }}>{inst.institute_name.toLowerCase()}</MenuItem>
                                            ) )}
                                        </Select>
                                    </FormControl>
                                )}
                                <Button onClick={() => setAddDialogState( true )} disableElevation sx={{ textTransform: "capitalize", width: { xs: "100%", sm: "fit-content" } }} startIcon={Icons.default.AddBox} variant="contained">Create new setting</Button>
                            </Box>
                        </Box>
                        {!loading && <Box overflow="hidden" flexGrow={1} flexDirection={{ md: "row", xs: "column" }} display="flex">
                            <AddSettingModule setState={setAddModuleDialogState} dialogState={addModuleDialogState} />
                            <AddSetting defaultModule={selectedModule} parents={parents} setAddModuleDialogState={setAddModuleDialogState} getSelectedSettings={getSelectedSettings} setState={setAddDialogState} dialogState={addDialogState} />
                            <Box display="flex" borderBottom={{ md: "none", xs: "2px solid #d3d3d3" }} flexDirection={{ md: "column", xs: "row" }}>
                                {SettingsStore.getModules.length > 0 && SettingsStore.getModules.map( item => (
                                    <Box
                                        onClick={() => {
                                            setSearchParam( 'selectedModule', item.module )
                                        }}
                                        sx={{ cursor: "pointer", background: selectedModule === item.module ? "#d3d3d377" : "none", "&:hover": { background: "#d3d3d333" } }}
                                        borderRight="1px solid #d3d3d3"
                                        borderBottom="1px solid #d3d3d3"
                                        key={item.module}
                                        display="flex"
                                        gap="10px"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        padding="5px 10px"
                                    >
                                        <Typography>{item.label}</Typography>
                                        <Box>
                                            <IconButton onClick={() => deleteSettingModule( item )} disabled={selectedModule !== item.module} color="errorMessage" size="small">
                                                {Icons.small.DeleteIcon}
                                            </IconButton>
                                        </Box>
                                    </Box>
                                ) )}
                                <Box
                                    flexShrink={1}
                                    borderRight="1px solid #d3d3d3"
                                    flexGrow={1}
                                    padding="10px 20px"
                                >
                                    {SettingsStore.getModules.length === 0 && <Typography textAlign="center" gutterBottom variant='subtitle2'>No Settings Modules</Typography>}
                                    <Button variant="outlined" onClick={() => setAddModuleDialogState( true )} startIcon={Icons.default.AddBox} sx={{ textTransform: "capitalize" }}>Add Setting Module</Button>
                                </Box>
                            </Box>
                            {SettingsStore.getSettings && !isLoadingSettings && <Box className="custom-scrollbar" overflow="auto" maxHeight="100%" flexGrow={1}>
                                {SettingsStore.getSettings?.length > 0 && <Box padding="10px" gap="10px" display="flex" flexDirection="column">
                                    {SettingsStore.getSettings?.map( setting => (
                                        <ControlPanelSettingBox getSelectedSettings={getSelectedSettings} key={setting.id} setting={setting} />
                                    ) )}
                                </Box>}
                                <Box padding="10px 20px">
                                    {SettingsStore.getSettings?.length === 0 && <Typography gutterBottom variant='subtitle2'>No settings to config!</Typography>}
                                    <Button variant="outlined" color="secondary" onClick={() => setAddDialogState( true )} startIcon={Icons.default.AddBox} sx={{ textTransform: "capitalize" }}>Add new Settings</Button>
                                </Box>
                            </Box>}
                            {isLoadingSettings && <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1} flexDirection="column">
                                <CircularProgress />
                                <Typography marginTop={2}>Loading...</Typography>
                            </Box>}
                        </Box>}
                    </Paper>
                </Box>
            )
        ) : (
            <Box padding="20px">
                <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
            </Box>
        )
    )
} )

export default ControlPanel
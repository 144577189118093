import { Box, Button, Collapse, Dialog, IconButton, Typography, useTheme } from '@mui/material'
import TryAgainMessageBlock from '../../components/common/TryAgainMessageBlock'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Icons, flatColorsAndBackgrounds } from '../../utils/utilities'
import limitsReachedImg from '../../assets/limits-reached.svg'
import { useSubjectStore } from '../../store/subject.store'
import api from '../../service/api'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import noDetailsImg from '../../assets/no-details.svg'
import shadows from '@mui/material/styles/shadows'
import { parse as markdownParse } from 'marked'
import DOMPurify from 'dompurify'
import AiMentorChatTypeWriter from '../../components/ai-mentor/AiMentorChatTypeWriter'
import DataLoadingDots from '../../components/common/DataLoadingDots'
import { toast } from 'react-toastify'
import { convert } from 'html-to-text'
import { CampaignOutlined } from '@mui/icons-material'
import DarkTooltip from '../../components/common/DarkTooltip'

// const markdown = "**Classification of Data Structure**\n\n```kotlin\nfun main() {\n    println(\"Hello, World!\")\n}\n```\n\nIn this example, the `println` function is used to output the text \"Hello, World!\" to the console when the application is run. Kotlin's simplicity and expressiveness can greatly enhance the development of mobile applications.\n"

const ChapterBox = ( { chapter, subject } ) => {

    const [learnTopic, setLearnTopic] = useState( null )
    const [limitsReached, setLimitsReached] = useState( false )
    const [readOutResponse, setReadOutResponse] = useState( false )
    const [fetchingResponse, setFetchingResponse] = useState( false )
    const [aiResponseTagAndContent, setAiResponseTagAndContent] = useState( [] )

    const { border, palette } = useTheme()

    const aiResponseContentContainerRef = useRef()

    const fetchAnswerFromAI = async ( chapter, topic, readOut = false ) => {
        setFetchingResponse( true )
        try {
            setLimitsReached( false )
            setReadOutResponse( readOut )
            setLearnTopic( topic )
            const { data: { data } } = await api.aimentor.askAiMentor( { subject: subject.subject_name, topic: chapter, subtopic: topic } )
            let cleanedResponse = data.response
            if ( data.response?.match( /^```markdown\n?/ ) ) {
                cleanedResponse = data.response?.replace( /^```markdown\n?/, "" )?.replace( /(```[\n\s]*)$/, "" )
            }
            const parsedMarkdown = DOMPurify.sanitize( markdownParse( cleanedResponse ) )
            if ( readOut ) {
                const utterance = new SpeechSynthesisUtterance( convert( parsedMarkdown )?.replace( /[*#]/g, "" ) )
                // Select a voice
                const voices = speechSynthesis.getVoices()
                utterance.voice = voices[0]
                speechSynthesis.speak( utterance )
            }
            let parts = parsedMarkdown.split( /(<[^>]+>)/ )
            parts = parts.filter( p => Boolean( p ) )

            const tagContentPairs = []
            for ( let i = 0; i < parts.length; i++ ) {
                const tag = parts[i].match( /<[^/][^>]*>/ )
                if ( parts[i].match( "<pre>" ) && parts[i + 1]?.match( /\sclass="language-[\w-]+"\s?/ ) ) {
                    const language = parts[i + 1]?.match( /\sclass="language-[\w-]+"\s?/ )[0]?.split( "-" ).pop()
                    const tag = `<div class="ai-response-language">${language?.replace( /["']/g, "" )}</div>`

                    tagContentPairs.push( {
                        content: tag,
                        type: 'tag'
                    } )
                }



                if ( tag ) {
                    tagContentPairs.push( {
                        content: parts[i],
                        type: 'tag'
                    } )
                } else {
                    tagContentPairs.push( {
                        content: parts[i],
                        type: 'text'
                    } )
                }
            }

            setAiResponseTagAndContent( tagContentPairs )

        } catch ( err ) {
            console.log( err )
            if ( err?.response?.data?.limitsReached ) {
                setLimitsReached( true )
            } else
                toast( err?.response?.data?.message || "AI Mentor couldn't get you an answer! please try again later" )
        } finally {
            setFetchingResponse( false )
        }
    }

    const handleAiDialogClose = () => {
        setLearnTopic( null )
        setAiResponseTagAndContent( [] )
        speechSynthesis.cancel()
    }

    return (
        <Box>

            <Dialog onClose={handleAiDialogClose} sx={{ background: "rgba(0,0,0,0.7)", display: "flex", flexDirection: "column" }} PaperProps={{ sx: { height: "85%", width: "90%", maxWidth: "100%" } }} open={Boolean( learnTopic )}>
                <Box display="flex" gap="20px" justifyContent="space-between" alignItems="center" padding="10px 20px" borderBottom={border[1]}>
                    <Typography variant='h6' fontSize="18px">
                        <em>Explain {learnTopic}</em>
                    </Typography>
                    <IconButton onClick={handleAiDialogClose}>
                        {Icons.default.CloseIcon}
                    </IconButton>
                </Box>
                <Box overflow="auto" ref={aiResponseContentContainerRef} flexGrow={1} display="flex" flexDirection="column" gap="10px" padding="20px">
                    {fetchingResponse ? <Typography fontWeight="500" variant='subtitle2' padding="10px" borderRadius="5px">
                        <DataLoadingDots spaceBetween={5} dotSize={7} waitingMessage="Working on your question..." color='grey' />
                    </Typography> : limitsReached ?
                        <Box flexGrow={1} display="flex" gap="20px" alignItems="center" flexDirection="column" padding="20px" justifyContent="center">
                            <img alt='daily-limits-reached' src={limitsReachedImg} width="200px" />
                            <Typography variant='subtitle2'>You reached the limit for the day! You can make upto 100 requests per day.</Typography>
                            <Button variant='contained' color='secondary' sx={{ textTransform: "capitalize" }} disableElevation onClick={() => setLearnTopic( null )} >Okay</Button>
                        </Box>
                        : <AiMentorChatTypeWriter containerRef={aiResponseContentContainerRef.current} tagContentPairs={aiResponseTagAndContent} speed={readOutResponse ? 60 : 10} text={''} />}
                </Box>
            </Dialog>

            <Box display="flex" gap="10px" marginBottom="10px" alignItems="center" flexWrap="wrap">
                <Box sx={{ cursor: "pointer" }} display="flex" gap="10px" alignItems="center">
                    <Typography variant='h6' fontSize='18px' color="primaryDark.main">{chapter.name}</Typography>
                </Box>
            </Box>
            <Box display="flex" gap="10px" flexWrap="wrap">
                {chapter.topics.map( ( topic, index ) => {
                    const { bg, color } = flatColorsAndBackgrounds[index % flatColorsAndBackgrounds.length]
                    return (
                        <Box key={index} padding="0 10px" gap="10px" sx={{ cursor: "pointer", '&:hover': { borderColor: color, background: bg, color, boxShadow: shadows[1], '& .show-on-hover': { display: "inline-block" } } }} display="flex" alignItems="center" borderRadius="10px" border={border[1]}>
                            <DarkTooltip title={<Typography color="inherit" fontSize="12px" className='m-0'>Ask AI Tutor: <strong style={{ color: palette.primary.dark }}>Explain {topic}</strong></Typography>} >
                                <Typography onClick={() => fetchAnswerFromAI( chapter.name, topic )} padding="10px 0" color="inherit" variant='subtitle2'>{topic}</Typography>
                            </DarkTooltip>
                            <DarkTooltip title={<Typography color="inherit" fontSize="12px" className='m-0'>Ask AI Tutor: <strong style={{ color: palette.primary.dark }}>Explain {topic} and read out loud</strong></Typography>}>
                                <IconButton onClick={() => fetchAnswerFromAI( chapter.name, topic, true )} sx={{ color: "gray", display: "none", width: "30px", height: "30px" }} className='show-on-hover' size='small' > <CampaignOutlined fontSize='small' /> </IconButton>
                            </DarkTooltip>
                        </Box>
                    )
                } )}
                {( !chapter.topics || chapter.topics.length === 0 ) && <Typography variant='subtitle2' color="textSecondary" >Topics not added yet!</Typography>}

            </Box>
        </Box>
    )
}

const ModuleBox = ( { module, moduleIndex, subject } ) => {

    const [collapseState, setCollapseState] = useState( false )

    const { border, shadows } = useTheme()
    return (
        <Box boxShadow={shadows[1]} borderRadius="5px">
            <Box padding="10px 20px" alignItems="center" display="flex" gap="10px">
                <Box sx={{ cursor: "pointer" }} display="flex" gap="10px" alignItems="center" onClick={() => setCollapseState( !collapseState )} >
                    {/* {collapseState ? Icons.default.DropUp : Icons.default.DropDown} */}
                    <Typography variant='h6' fontSize='16px' color="primaryDark.main">{module.name}</Typography>
                </Box>
            </Box>
            <Collapse in={true}>
                <Box display="flex" borderTop={border[1]} flexDirection="column" gap="20px" padding="20px">
                    {module.chapters.map( ( chapter, index ) => (
                        <ChapterBox subject={subject} key={index} chapter={chapter} chapterIndex={index} moduleIndex={moduleIndex} />
                    ) )}
                    {( !module.chapters || module.chapters.length === 0 ) && <Typography padding="20px" variant='subtitle2' color="textSecondary" >No contents added yet!</Typography>}
                </Box>
            </Collapse >
        </Box >
    )
}

const AiMentorStudent = () => {

    const [error, setError] = useState( null )
    const [loading, setLoading] = useState( true )
    const [subjectSyllabus, setSubjectSyllabus] = useState( [] )

    const { palette, } = useTheme()
    const SubjectStore = useSubjectStore()

    const getData = useCallback( async () => {
        setLoading( true )
        try {
            const { data } = await api.aimentor.getSubjectSyllabus( SubjectStore.getSubject.subject_code )
            setSubjectSyllabus( data.data?.modules || [] )
            setError( null )
        } catch ( err ) {
            setError( { code: err?.response?.status, message: err?.response?.data?.message || "Something went wrong while fetching subject syllabus data." } )
        } finally {
            setLoading( false )
        }
    }, [SubjectStore] )

    useEffect( () => {
        getData()
    }, [getData] )

    return (
        <Box display="flex" overflow="auto" flexDirection="column" flexGrow={1}>
            <Box bgcolor={palette.greyedOut} padding="10px 20px" display="flex" gap="10px" alignItems="center" justifyContent="space-between">
                <Typography variant='h6'>Subject Syllabus</Typography>
            </Box>
            {!error && !loading && <Box overflow="auto" padding="10px" flexGrow={1}>
                {subjectSyllabus.map( ( module, moduleIndex ) => {
                    return (
                        <ModuleBox subject={SubjectStore.getSubject} key={moduleIndex} moduleIndex={moduleIndex} module={module} />
                    )
                } )}
                {subjectSyllabus.length === 0 && <Box display="flex" flexDirection="column" padding="20px" borderRadius="5px" bgcolor={palette.contentBg} margin="10px" gap="20px" alignItems="center">
                    <img height="120px" src={noDetailsImg} alt="no-details" />
                    <Typography variant="subtitle2" padding="20px" paddingBottom="0" >No modules added yet!</Typography>
                </Box>}
            </Box>}
            {loading && !error && <DataLoadingSpinner waitingMessage="Loading data..." />}
            {error && <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center">
                <TryAgainMessageBlock err={error.message} code={error.code} loading={loading} getData={getData} />
            </Box>}
        </Box>
    )
}

export default AiMentorStudent

import { Box, Collapse, IconButton, Typography, useTheme } from '@mui/material'
import { Icons, latexDelimiters } from '../../utils/utilities'
import React, { useState } from 'react'
import ConfirmDialog from '../../components/common/ConfirmDialog'
import Latex from 'react-latex-next'
import { useUserStore } from '../../store/user.store'
import EditQuestionDialog from '../exams-components/EditQuestionDialog'
import { useAdmissionExamStore } from '../../store/admission-exam.store'
import { useParams } from 'react-router-dom'
// import { useQuizAndQuestionbankStore } from '../../store/quiz.store'

const optionsList = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

const QuestionBlock = ( { parent = 'create', readOnly = false, deleteQuestion, question, index, colors = false, defaultCollapseState = false, parentComponents = 'addexam' } ) => {

    const [deleting, setDeleting] = useState( false )
    const [open, setOpen] = useState( defaultCollapseState )
    const [editQuestionDialogState, setEditQuestionDialogState] = useState( false )
    const [deleteDialogState, setDeleteDialogState] = useState( false )

    const AdmissionExamStore = useAdmissionExamStore()
    const UserStore = useUserStore()

    const { palette } = useTheme()
    const { id } = useParams()

    const deleteQuestionProxy = () => {
        setDeleting( true )
        deleteQuestion( question.id )
        setDeleteDialogState( false )
        setDeleting( false )
    }

    const getQuestions = async () => {
        await AdmissionExamStore.fetchExam( id )
    }

    return (
        <Box bgcolor={open && palette.rowHover} sx={{ '&:hover': { bgcolor: palette.rowHover } }} padding="10px" borderRadius="5px">

            <ConfirmDialog waiting={deleting} status={deleteDialogState} cancelAction={() => setDeleteDialogState( false )} confirmAction={deleteQuestionProxy} actionName="Yes, Delete it!" message={`Do you really want to delete this question?`} />
            {editQuestionDialogState && <EditQuestionDialog module='admission-exam' getQuestions={getQuestions} state={editQuestionDialogState} setState={setEditQuestionDialogState} question={question} />}

            <Typography variant="subtitle2" color="textSecondary" fontSize="12px"> <em>Question {index + 1}</em> </Typography>
            <Box sx={{ cursor: "pointer" }} display="flex" gap="10px" paddingRight="10px" alignItems="center">
                <Box flexGrow={1} onClick={() => setOpen( !open )} display="flex" gap="10px" alignItems="flex-start">
                    <Box flexGrow={1}>
                        {/* <Box dangerouslySetInnerHTML={{ __html: question.question_text || question.question }}></Box> */}
                        <Box className='wysiwyg-text-container' fontWeight="bold" marginTop="-5px" fontSize="14px" >
                            <Latex delimiters={latexDelimiters} >{question.question_text || question.question}</Latex>
                        </Box>
                        <Typography marginBottom="4px" fontSize="10px" color="secondary">{question.question_type || question.questionType}</Typography>
                    </Box>
                </Box>

                <Box display="flex" gap="10px" alignItems="center">
                    {UserStore.getUser?.user_role === 'admin' && parent !== 'create' && <IconButton color="secondary" onClick={() => setEditQuestionDialogState( true )} sx={{ textTransform: "capitalize" }}>
                        {Icons.default.EditIconOutlined}
                    </IconButton>}
                    {!readOnly && <IconButton color="errorMessage" onClick={() => setDeleteDialogState( true )} sx={{ textTransform: "capitalize" }}>
                        {Icons.default.RemoveCircleIcon}
                    </IconButton>}
                </Box>
            </Box>
            <Collapse in={open}>
                <Box padding="10px" borderTop="1px solid #d3d3d3">
                    {( question.question_type || question.questionType ) === 'MCQ' && question.options.map( ( option, optionIndex ) => {

                        const isCorrect = ( question.selectionType || question.selection_type ) === "single" ? +( question.correctAnswer + "" || question.correct_answer ) === optionIndex : ( question["options_status"] || question["answersStatus"] )[optionIndex]
                        return (
                            <Box display="flex" marginBottom="2px" alignItems="flex-start" gap="10px" key={optionIndex}>
                                <Typography marginBottom="2px" variant="subtitle2" fontSize="12px" sx={{ aspectRatio: 1 / 1 }} display="flex" alignItems="center" justifyContent="center" width='20px' borderRadius="50%" margin="0" bgcolor={isCorrect && "success.light"} fontWeight={isCorrect ? "bolder" : "regular"} color={isCorrect ? "white" : "textSecondary"}>{optionsList[optionIndex]}</Typography>
                                <Box variant="subtitle2" className='wysiwyg-text-container'  >
                                    <Latex delimiters={latexDelimiters} >{option.option_text || option.answer_text}</Latex>
                                </Box>
                            </Box>
                        )
                    } )}
                    {( question.question_type || question.questionType ) !== 'MCQ' && <Box marginBottom="2px" gap="10px" >
                        <Typography marginBottom="2px" variant="subtitle2" color="secondary" fontSize="12px" >Key Answer</Typography>
                        <Box variant="subtitle2" className='wysiwyg-text-container'  >
                            <Latex delimiters={latexDelimiters} >{question.keyAnswer || question?.options[0]?.answer_text}</Latex>
                        </Box>
                    </Box>}
                </Box>
            </Collapse>
        </Box >
    )
}


export default QuestionBlock

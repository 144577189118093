import { Paper, Typography, Box, Button, IconButton, useTheme, TableRow, Table, TableContainer, TableBody, TableCell } from '@mui/material'
import { useAssignmentStore } from '../../store/assignment.store'
import { Link, useSearchParams } from 'react-router-dom'
import DataLoadingSpinner from '../common/DataLoadingSpinner'
import noASsignmentImg from '../../assets/noassignment.svg'
import { Icons, showDateInIndianDateFormat } from '../../utils/utilities'
import CustomSearchBar from '../common/CustomSearchBar'
import ConfirmDialog from '../common/ConfirmDialog'
import React, { useCallback, useEffect, useState } from 'react'
import AssignmentViewer from '../common/AssignmentViewer'
import { observer } from 'mobx-react'
import TableHeader from '../common/TableHeader'
import { useUserStore } from '../../store/user.store'

const columns = [
    { name: "ASSIGNMENT NAME", minWidth: "150px", align: "left", padding: "10px 20px 10px 40px" },
    { name: "Subject", align: "left" },
    { name: "DUE DATE", minWidth: "120px", align: "left" },
    { name: "Submissions", align: "center" },
    { name: "Assignment for", minWidth: "150px", align: "left" },
    { name: "ACTION", align: "right", padding: "10px 40px 10px 20px" },
]

const AssignmentsList = observer( ( { editAssignment, setView } ) => {

    const AssignmentStore = useAssignmentStore()
    const UserStore = useUserStore()

    // const [open, setOpen] = useState( false )
    const [selectedValue, setSelectedValue] = useState( null )
    const [deleting, setDeleting] = useState( false )
    const [loaded, setLoaded] = useState( false )
    const [confirmDialogStatus, setConfirmDialogStatus] = useState( false )
    const [confirmAction, setConfirmAction] = useState( null )
    const [confirmMessage, setConfirmMessage] = useState( null )

    const [searchParams, setSearchParams] = useSearchParams( { assignmentFilter: 'due', searchText: '', selectedAssignmentId: null, open: false } )
    const searchText = searchParams.get( 'searchText' ) || ""
    const selectedAssignmentId = searchParams.get( 'selectedAssignmentId' ) || null
    const assignmentFilter = searchParams.get( 'assignmentFilter' ) || "due"
    const open = searchParams.get( 'open' ) || false

    const { palette, table, border } = useTheme()

    const setSearchParamValue = useCallback( ( key, val ) => {
        setSearchParams( prev => {
            prev.set( key, val )
            return prev
        }, { replace: true } )
    }, [setSearchParams] )

    const handleClickOpen = ( value ) => {
        setSelectedValue( value )
        setSearchParamValue( 'selectedAssignmentId', value.assignment_id )
        setSearchParamValue( 'open', true )
    }

    const handleClose = ( value ) => {
        setSearchParamValue( 'open', false )
        setSearchParamValue( 'selectedAssignmentId', null )
        setSelectedValue( null )
    }

    const deleteAssignment = async ( id ) => {
        setDeleting( true )
        let payload = { "assignment_id": id }
        await AssignmentStore.deleteAssignment( payload )
        setDeleting( false )
    }

    const openDeleteDialog = ( id, name ) => {
        setConfirmAction( () => async () => {
            await deleteAssignment( id )
            setConfirmDialogStatus( false )
        } )
        setConfirmMessage(
            <p>
                Assignment <u><b><i>{name}</i></b></u> will be deleted permanently.
            </p>
        )
        setConfirmDialogStatus( true )
    }

    const assignmentFilterFunc = ( item ) => {
        if ( assignmentFilter === "all" ) return item["assignment_name"]?.toLowerCase()?.indexOf( searchText?.toLowerCase() ) > -1 || item["assignment_subject"]["subject_name"]?.toLowerCase()?.indexOf( searchText?.toLowerCase() ) > -1
        if ( assignmentFilter === "due" ) {
            let dueDate = new Date( item.assignment_due_date )
            dueDate = new Date( dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate() + 1 )

            return dueDate > new Date() &&
                ( item["assignment_name"]?.toLowerCase()?.indexOf( searchText?.toLowerCase() ) > -1 || item["assignment_subject"]["subject_name"]?.toLowerCase()?.indexOf( searchText?.toLowerCase() ) > -1 )

        }
        if ( assignmentFilter === "completed" ) {
            let dueDate = new Date( item.assignment_due_date )
            dueDate = new Date( dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate() + 1 )

            return ( item.submission_exists > 0 || dueDate < new Date() )
                && ( item["assignment_name"]?.toLowerCase()?.indexOf( searchText?.toLowerCase() ) > -1 || item["assignment_subject"]["subject_name"]?.toLowerCase()?.indexOf( searchText?.toLowerCase() ) > -1 )
        }
        return true
    }

    const getAssignments = useCallback( async () => {
        if ( AssignmentStore.getErrors.assignments )
            await AssignmentStore.fetchAssignmentByAuthor()
        if ( "" + selectedAssignmentId !== 'null' || selectedAssignmentId ) {
            setSelectedValue( AssignmentStore.getAssignment.find( assignment => +assignment.assignment_id === +selectedAssignmentId ) )
            setSearchParams( prev => {
                const newVals = new URLSearchParams( prev )
                newVals.set( 'open', true )
                return newVals
            }, { replace: true } )
        } else {
            setSearchParams( prev => {
                const newVals = new URLSearchParams( prev )
                newVals.set( 'open', false )
                return newVals
            }, { replace: true } )
        }
        if ( UserStore.getErrorsStatuses?.academicYearsAndBatches === true )
            await UserStore.fetchUsersAllBatchesAndSectionList()
        setLoaded( true )
    }, [setSearchParams, AssignmentStore, UserStore, selectedAssignmentId] )

    useEffect( () => {
        getAssignments( true )
    }, [getAssignments] )

    return (
        <Paper sx={{
            flexGrow: AssignmentStore.getAssignment.filter( ( current_assignment ) => {
                return current_assignment["assignment_name"]?.toLowerCase()?.indexOf( searchText?.toLowerCase() ) > -1 || searchText === ""
            } ).length === 0 ? "initial" : 1,
            overflow: "hidden"
        }}>
            {/* Dialogs, Modals, Poppers */}
            <ConfirmDialog waiting={deleting} status={confirmDialogStatus} confirmAction={confirmAction} cancelAction={() => setConfirmDialogStatus( false )} message={confirmMessage} actionName="Yes, Delete it" />
            {open === 'true' && <AssignmentViewer loaded={loaded} selectedValue={selectedValue} open={open === 'true' && selectedAssignmentId !== 'null'} onClose={handleClose} />}

            {/* Main Content */}
            <Box sx={{
                background: `linear-gradient(322.12deg, #F3C400 -21.29%, #E25A26 124.22%)`
            }} padding="20px">
                <Box display="flex" flexWrap="wrap" gap="20px" justifyContent="space-between" alignItems="center">
                    <Box flexGrow={1}>
                        <Typography color="white" variant="h5">Assignments</Typography>
                        <Typography variant="body2" color="white">Manage all your assignments here.</Typography>
                    </Box>
                    <Button disableElevation startIcon={Icons.default.AddIcon} sx={{ background: "#BEFFC7", color: "black", '&:hover': { background: "#a6ffb2" } }} onClick={() => setView( "create" )} variant='contained'>Create</Button>
                </Box>
            </Box>
            {loaded && AssignmentStore?.getAssignment.length > 0 && <Box marginTop="20px" maxHeight="500px" overflow="auto" flexGrow={1} className="custom-scrollbar" gap="20px" padding="0 20px 20px 20px">

                <Box display="flex" gap="20px" justifyContent="space-between" alignItems="center">
                    <Box borderRadius="5px" border={`1px solid ${palette.borderColor}`} display="flex" gap="10px" height="30px" padding="5px">
                        <Typography width="100px" display="flex" alignItems="center" justifyContent="center" textAlign="center" onClick={() => setSearchParamValue( 'assignmentFilter', 'all' )} sx={{ color: assignmentFilter === "all" ? `${palette.primary.contrastText}` : 'inherit', cursor: "pointer", borderRadius: "3px", "&:hover": { background: assignmentFilter !== "all" && palette.rowHover } }} padding="3px" bgcolor={assignmentFilter === "all" ? `${palette.primary.main}` : palette.common.default} variant="subtitle2">All</Typography>
                        <Typography width="100px" display="flex" alignItems="center" justifyContent="center" textAlign="center" onClick={() => setSearchParamValue( 'assignmentFilter', 'due' )} sx={{ color: assignmentFilter === "due" ? `${palette.primary.contrastText}` : 'inherit', cursor: "pointer", borderRadius: "3px", "&:hover": { background: assignmentFilter !== "due" && palette.rowHover } }} padding="3px" bgcolor={assignmentFilter === "due" ? `${palette.primary.main}` : palette.common.default} variant="subtitle2">Due</Typography>
                        <Typography width="100px" display="flex" alignItems="center" justifyContent="center" textAlign="center" onClick={() => setSearchParamValue( 'assignmentFilter', 'completed' )} sx={{ color: assignmentFilter === "completed" ? `${palette.primary.contrastText}` : 'inherit', cursor: "pointer", borderRadius: "3px", "&:hover": { background: assignmentFilter !== "completed" && palette.rowHover } }} padding="3px" bgcolor={assignmentFilter === "completed" ? `${palette.primary.main}` : palette.common.default} variant="subtitle2">Completed</Typography>
                    </Box>
                    <Box display="flex" gap="20px" alignItems="center">
                        <CustomSearchBar onChange={( e ) => setSearchParamValue( 'searchText', e.target.value )} value={searchText} placeholder="Subject or title" border='1px solid #ddd' />
                    </Box>

                </Box>

                {AssignmentStore?.getAssignment.filter( assignmentFilterFunc ).length > 0 && <Box borderRadius="5px" marginTop="20px" overflow="hidden" border={border[1]}>
                    <TableContainer sx={{ overflow: "auto" }} >
                        <Table stickyHeader>
                            <TableHeader columns={columns} />
                            <TableBody>
                                {AssignmentStore?.getAssignment.filter( assignmentFilterFunc ).map( ( assignment, aindex ) => {
                                    let accessGroups = []
                                    for ( const groupId of assignment.assignment_batch_id?.split( "," ) ) {
                                        const access = UserStore.getUsersAllBatchesAndSectionList?.find( i => ( i.batch_assignment_id === groupId || `${i.section_assignment_id}-${i.section_short_name}` === groupId ) )
                                        let title = ''
                                        if ( access?.section_assignment_id ) {
                                            title = `${access.course_branch_short_name ? access.course_branch_short_name + "," : ""} ${access?.current_year ? `year: ${access?.current_year},` : ""} ${access.current_sem ? `Sem: ${access.current_sem},` : ""} Sec: ${access.section_short_name}`
                                        } else if ( access?.batch_assignment_id )
                                            title = `${access.course_name_short ? access.course_name_short + "," : ""} ${access?.current_year ? `year: ${access?.current_year},` : ""} ${access.current_sem ? `Sem: ${access.current_sem},` : ""} Batch: ${access.batch_short_name}`
                                        accessGroups.push( title )
                                    }
                                    return (
                                        <TableRow className='table-row' key={assignment.assignment_id}>
                                            <TableCell onClick={() => handleClickOpen( assignment )} sx={{ fontSize: "12px", color: table.color, padding: columns[0].padding || "10px 20px" }}><strong>{assignment.assignment_name}</strong></TableCell>
                                            <TableCell onClick={() => handleClickOpen( assignment )} sx={{ fontSize: "12px", color: table.color, padding: columns[1].padding || "10px 20px" }}><strong>{assignment.assignment_subject?.subject_name}</strong></TableCell>
                                            <TableCell onClick={() => handleClickOpen( assignment )} sx={{ fontSize: "12px", color: table.color, padding: columns[2].padding || "10px 20px" }}><strong>{showDateInIndianDateFormat( assignment.assignment_due_date, { month: "short" }, 0 )}</strong></TableCell>
                                            <TableCell onClick={() => handleClickOpen( assignment )} sx={{ fontSize: "12px", color: table.color, padding: columns[3].padding || "10px 20px" }} align="center"><strong>{assignment.assignment_submission || 0}</strong></TableCell>
                                            <TableCell onClick={() => handleClickOpen( assignment )} sx={{ fontSize: "12px", color: table.color, padding: columns[4].padding || "10px 20px" }}>
                                                <Box display="flex" flexDirection="column" gap="5px" >
                                                    {accessGroups?.map( g => ( <strong key={g}>{g}</strong> ) )}
                                                </Box>
                                            </TableCell>
                                            <TableCell align="right" sx={{ fontSize: "12px", color: table.color, padding: columns[5].padding || "10px 20px" }}>
                                                <Box display="flex" alignItems="center" gap="10px" justifyContent="flex-end">
                                                    <Link to={`/faculty/assignment/${assignment.assignment_id}`} className='default-link'>
                                                        <Button sx={{ textTransform: "capitalize" }} size="small" color="secondary" variant="outlined">
                                                            Evaluate
                                                        </Button>
                                                    </Link>
                                                    <Box display="flex" alignItems="center">
                                                        <IconButton size="small" onClick={() => editAssignment( assignment )} color="secondary">
                                                            {Icons.default.EditIconOutlined}
                                                        </IconButton>
                                                        <IconButton onClick={() => openDeleteDialog( assignment.assignment_id, assignment.assignment_name )} size="small" color="error">
                                                            {Icons.default.DeleteIconOutlined}
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                } )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>}
            </Box>}
            {loaded && AssignmentStore?.getAssignment.filter( assignmentFilterFunc ).length === 0 && <Box padding="20px" display="flex" flexDirection="column" bgcolor={palette.contentBg} margin="10px" borderRadius="5px" alignItems="center" justifyContent="center">
                <img src={noASsignmentImg} width="200px" alt="No Assignments!" />
                <Typography marginY="20px" variant='subtitle2' color="textSecondary">No assignments!</Typography>
                {searchText !== "" && AssignmentStore?.getAssignment.length > 0 && <Button onClick={() => setSearchParamValue( 'searchText', "" )} variant='outlined' sx={{ fontSize: "12px", marginBottom: "20px", textTransform: "capitalize" }} color="error" startIcon={Icons.default.BlockIcon}>Clear filter</Button>}
            </Box>}
            {/* {loaded && <Box paddingTop="0" display="flex" flexWrap="wrap">
                {AssignmentStore.getAssignment.filter( ( current_assignment ) => {
                    return current_assignment["assignment_name"]?.toLowerCase()?.indexOf( searchText?.toLowerCase() ) > -1
                } ).length > 0 && <Box padding="20px" display="flex" flexWrap="wrap" gap="10px">
                        {
                            AssignmentStore.getAssignment.filter( ( current_assignment ) => {
                                return current_assignment["assignment_name"]?.toLowerCase().indexOf( searchText?.toLowerCase() ) > -1
                            } ).map( ( assignment, k ) => {
                                return (
                                    <Paper elevation={2} sx={{ marginBottom: "10px", minWidth: "200px", width: "250px", "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" } }} key={assignment["assignment_id"]}>
                                        <Box className="card-body">
                                            <Box onClick={( e ) => handleClickOpen( assignment )} borderRadius="5px 5px 0 0" sx={{ cursor: "pointer" }} color="white" bgcolor={flatColors[k % flatColors.length]} padding="20px">
                                                <Tooltip title={assignment["assignment_name"]} PopperProps={{ sx: { "& .MuiTooltip-tooltip": { background: "#000", padding: "10px" } } }} placement='top'>
                                                    <Typography variant="h5" noWrap >{assignment["assignment_name"]}</Typography>
                                                </Tooltip>
                                                <Typography variant="body2">Due Date : {assignment["assignment_due_date"].split( "T" )[0]}</Typography>
                                            </Box>
                                            <Box onClick={( e ) => handleClickOpen( assignment )} sx={{ cursor: "pointer" }} marginTop="10px" padding="10px 20px 20px 20px" fontSize="12px" fontWeight="bold">
                                                {assignment["author_name"] + ' '}
                                                <br />
                                                <Typography color="textSecondary" fontSize="12px">Created On: {new Date( assignment["assignment_created_at"] ).toLocaleDateString( "en-IN", { day: "2-digit", month: "2-digit", year: "numeric" } ).replace( /\//g, "-" )}</Typography>
                                            </Box>
                                            <Divider sx={{ background: "rgba(0,0,0,0.15)", height: "2px" }} />
                                            <Box display="flex" flexGrow={1} alignItems="flex-end" justifyContent="flex-start" padding="0 20px 10px 20px" gap="10px" marginTop="10px">
                                                
                                                <Tooltip title="Evaluate">
                                                    <Button variant='contained' color='success' disableElevation size="small" onClick={() => navigate( `/faculty/assignment/${assignment["assignment_id"]}` )}>
                                                        Evaluate
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Edit">
                                                    <IconButton sx={{ color: "secondary.main" }} size="small" onClick={() => editAssignment( assignment )}  >
                                                        {Icons.default.EditIcon}
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <IconButton
                                                        sx={{ color: "error.main" }}
                                                        size="small"
                                                        onClick={() => openDeleteDialog( assignment["assignment_id"], assignment["assignment_name"] )}
                                                    >
                                                        {Icons.default.DeleteIcon}
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </Paper>
                                )
                            } )
                        }
                    </Box>}

                {
                    loaded && AssignmentStore.getAssignment.filter( ( current_assignment ) => {
                        return current_assignment["assignment_name"]?.toLowerCase().indexOf( searchText?.toLowerCase() ) > -1 || searchText === ""
                    } ).length === 0 &&
                    <Box padding="20px" display="flex" flexDirection="column" bgcolor={palette.contentBg} width="100%" alignItems="center" justifyContent="center">
                        <img src={noASsignmentImg} width="200px" alt="No Assignments!" />
                        <Typography marginY="20px" variant='subtitle2' color="textSecondary">No assignments!</Typography>
                        {searchText !== "" && <Button onClick={() => setSearchParamValue( 'searchText', "" )} variant='outlined' sx={{ fontSize: "12px", marginBottom: "20px", textTransform: "capitalize" }} color="error" startIcon={Icons.default.BlockIcon}>Clear filter</Button>}
                    </Box>
                }
            </Box>} */}
            {
                !loaded && <DataLoadingSpinner waitingMessage="Loading assignments..." />
            }
        </Paper>
    )
} )

export default AssignmentsList

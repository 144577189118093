import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useTheme } from '@mui/material'
import MultipleChecklistDeleteDialog from '../../../components/admin/academic-program/MultipleChecklistDeleteDialog'
import ImportChecklistItemsDialog from '../../../components/admin/academic-program/ImportChecklistItemsDialog'
import { CheckBox, CheckBoxOutlineBlankOutlined, CloseOutlined, Delete } from '@mui/icons-material'
import ChecklistTableRow from '../../../components/admin/academic-program/ChecklistTableRow'
import AddChecklist from '../../../components/admin/academic-program/AddChecklist'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import BlockMessage from '../../../components/common/BlockMessage'
import noChecklistImg from '../../../assets/no-rules.svg'
import { useUserStore } from '../../../store/user.store'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Icons } from '../../../utils/utilities'
import { toast } from 'react-toastify'
import api from '../../../service/api'
import { useCallback, useEffect } from 'react'
import { useState } from 'react'
import React from 'react'
import ConfirmDialog from '../../../components/common/ConfirmDialog'

let defaultColumns = [
    { label: "Session No.", minWidth: "120px" },
    { label: "Input required", minWidth: "150px", maxWidth: "200px", padding: "10px 20px" },
    { label: "Label", minWidth: "80px", maxWidth: "300px", padding: "10px 20px" },
    { label: "Type", minWidth: "80px", maxWidth: "100px", padding: "10px 20px" },
    { label: "Attachment", padding: "10px 20px" },
    { label: "Att. label", minWidth: "100px", maxWidth: "300px", padding: "10px 20px" },
    { label: "File size", minWidth: "80px", maxWidth: "100px", padding: "10px 20px" },
    { label: "File extentions", minWidth: "130px", maxWidth: "100px", padding: "10px 20px" },
    { label: "Att. Required", minWidth: "120px", maxWidth: "150px", padding: "10px 20px" },
    { label: "Required", minWidth: "80px", maxWidth: "100px", padding: "10px 20px" },
    { label: "Actions", className: "shadow-on-left-border", padding: "10px 20px", styles: { flexDirection: "column", display: "table-cell", position: "sticky", right: "0", alignItems: "flex-end" } }
]

const DepartmentAcademicRuleChecklist = () => {

    const [rule, setRule] = useState( null )
    const [noRule, setNoRule] = useState( false )
    const [showShadow, setShowShadow] = useState( false )
    const [loading, setLoading] = useState( true )
    const [checklist, setChecklist] = useState( [] )
    const [hasPermission, setHasPermission] = useState( true )
    const [addDialogState, setAddDialogState] = useState( false )
    const [importDialogState, setImportDialogState] = useState( false )
    const [uniqueChecklistItem, setUniqueChecklistItem] = useState( [] )
    const [deleteConfirmDialogState, setDeleteConfirmDialogState] = useState( false )
    const [multipleDeleteDialogState, setMultipleDeleteDialogState] = useState( false )
    const [itemsSelectedForDeletion, setItemsSelectedForDeletion] = useState( [] )
    const [columns, setColumns] = useState( defaultColumns )
    const [isLoadingUniqueChecklistItems, setIsLoadingUniqueChecklistItems] = useState( false )

    const { id } = useParams()
    const { palette, table, theme } = useTheme()
    const navigate = useNavigate()
    const UserStore = useUserStore()

    const openImportChecklistDialog = async () => {
        try {
            setIsLoadingUniqueChecklistItems( true )
            setImportDialogState( true )
            const { data } = await api.getUniqueChecklistItems()
            setUniqueChecklistItem( data.data )
        } catch ( err ) {
            if ( err.response )
                toast( err.response?.data?.message || "Error occured while adding new rule!" )
            else
                toast( "Network Error!" )
        } finally {
            setIsLoadingUniqueChecklistItems( false )
        }
    }

    const selectAllItemsForDeletion = () => {
        try {
            let itemsIds = checklist.map( i => i.id )
            setItemsSelectedForDeletion( itemsIds )
        } catch ( err ) {

        }
    }

    const deleteChecklist = async ( ids ) => {
        try {
            const { data: { success, message, data } } = await api.deleteChecklistItem( ids )
            if ( !success ) {
                toast( message || "Something went wrong while deleting checklist item with ID " + id )
            } else if ( !data || !data[0] )
                toast( "Student data already associated with this item and can not be deleted." )
            else
                await getData()
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong! An error occured while deleting checklist." )
        }
    }

    const deleteRule = async () => {
        try {
            await api.deleteAcademicRule( id )
            navigate( `/admin/academic-rules?instituteCode=${rule?.institute_code}` )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong! An error occured while deleting checklist." )
        }
    }

    const getData = useCallback( async () => {
        try {
            const { data } = await api.getDepartmentAcademicRuleChecklist( id )
            setRule( data.data.rule )
            setColumns( columns => {
                columns[0] = { label: `${data.data.rule.session_type} No.`, minWidth: "120px", padding: "10px 20px 10px 40px" }
                return columns
            } )
            setChecklist( data.data.checklist )
        } catch ( err ) {
            setNoRule( err?.response?.data?.norule ? true : false )
            toast( err.response?.data?.message || "Error occured while fetching rules!" )
        } finally {
            setLoading( false )
        }
    }, [id] )

    useEffect( () => {
        const table = document.getElementById( "ac-table-container" )
        if ( table && table.getBoundingClientRect().width < table.scrollWidth )
            setShowShadow( true )
    }, [] )

    useEffect( () => {
        setColumns( columns => {
            columns[columns.length - 1] = { label: "Actions", className: `shadow-on-left-border ${theme}`, padding: "10px 20px", styles: { flexDirection: "column", display: "table-cell", position: "sticky", right: "0", alignItems: "flex-end", boxShadow: `-5px 0px 5px 0px ${theme === 'dark' ? "#2d2d2dd7" : "#DBDBDBAB"}` } }
            return columns
        } )
    }, [theme] )

    useEffect( () => {
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.academics ) {
            setHasPermission( false )
        } else
            getData()

    }, [UserStore, getData] )

    return (
        hasPermission ? <Box flexGrow={1} padding="20px" overflow="auto" display="flex" height="100%" flexDirection="column">
            {/* Dialogs */}
            <ImportChecklistItemsDialog getItems={getData} uniqueItems={uniqueChecklistItem} allItems={checklist} isLoadingUniqueChecklistItems={isLoadingUniqueChecklistItems} setChecklistItems={setUniqueChecklistItem} checklistItems={uniqueChecklistItem} state={importDialogState} setState={setImportDialogState} />
            <MultipleChecklistDeleteDialog setSelectedItems={setItemsSelectedForDeletion} getData={getData} setState={setMultipleDeleteDialogState} items={checklist} selectedItems={itemsSelectedForDeletion} state={multipleDeleteDialogState} />
            <ConfirmDialog actionName={"Yes, Delete it!"} cancelAction={() => setDeleteConfirmDialogState( false )} status={deleteConfirmDialogState} confirmAction={deleteRule} message="All the associated checklist items will be deleted too!" />
            <AddChecklist rule={rule} getItems={getData} state={addDialogState} setState={setAddDialogState} />

            {/* Content */}
            {!noRule && <Typography marginBottom="10px" fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary"> <Link className="breadcrumb-link" to="/admin/academic-rules">Academic rules</Link>{Icons.small.ChevronRightIcon} {loading ? <DataLoadingSpinner size={14} /> : rule && <Link className="breadcrumb-link" to={`/admin/academic-rules/?instituteCode=${rule.institute_code}`}>{rule.institute_code}</Link>}{Icons.small.ChevronRightIcon} {loading ? <DataLoadingSpinner size={14} /> : rule && rule.department_code}{Icons.small.ChevronRightIcon} {loading ? <DataLoadingSpinner size={14} /> : rule && `${rule.academic_year} (${rule.session_type})`} </Typography>}
            <Paper sx={{ minHeight: !loading && "450px", flexGrow: loading || ( !loading && checklist.length === 0 ) ? "0" : "1", display: "flex", flexDirection: "column", overflow: ( !loading && checklist.length > 0 ) ? "auto" : "initial" }}>
                <Box padding="20px" display="flex" flexDirection={{ sm: "row", xs: "column" }} justifyContent="space-between" gap="20px" borderBottom="1px solid #d3d3d3">
                    <Box>
                        <Typography variant='h5'>Academic rule</Typography>
                        {rule && <Typography variant='subtitle2' color="textSecondary">{`${rule.institute_code}-${rule.department_code}-${rule.academic_year}-${rule.session_type}`}</Typography>}
                    </Box>
                    {rule && <Box display="flex" justifyContent={"stretch"} gap="10px" alignItems="center">
                        <Button onClick={openImportChecklistDialog} variant='outlined' startIcon={Icons.default.DownloadIcon} color="secondary" sx={{ textTransform: "capitalize", flexGrow: 1 }}>Import</Button>
                        <Button disableElevation onClick={() => setAddDialogState( true )} variant='contained' startIcon={Icons.default.AddIcon} sx={{ textTransform: "capitalize", flexGrow: 1 }}>Create</Button>
                        <Button disableElevation onClick={() => setDeleteConfirmDialogState( true )} variant='contained' startIcon={Icons.default.DeleteIcon} color='error' sx={{ textTransform: "capitalize", flexGrow: 1 }}>Delete</Button>
                    </Box>}
                </Box>

                {loading && <DataLoadingSpinner waitingMessage="Loading rule checklist..." />}

                {!loading && checklist.length === 0 && <Box justifyContent="center" padding="20px" display="flex" alignItems="center" flexDirection="column" gap="10px" bgcolor="#eee" flexGrow="1">
                    <img width="200px" src={noChecklistImg} alt="No checklist" />
                    <Typography variant='subtitle2' color="GrayText">No {noRule ? `Rule exists with id ${id}!` : "checklists yet"}</Typography>
                    <Button variant="outlined" onClick={noRule ? () => navigate( `/admin/academic-rules` ) : () => setAddDialogState( true )} sx={{ textTransform: "capitalize" }} startIcon={noRule ? Icons.default.ArrowBack : Icons.default.AddIcon}>{noRule ? "Back to rules" : "Create new checklist"}</Button>
                </Box>}

                {itemsSelectedForDeletion.length > 0 && <Box gap="10px" padding="10px 20px" borderRadius="5px"  >
                    <Box display="flex" justifyContent="space-between" borderRadius="5px" padding="10px" alignItems="center" border={`2px solid ${palette.primary.light}33`} bgcolor={`${palette.primary.light}22`}>
                        <Box display="flex" alignItems="center" gap="10px">
                            <IconButton onClick={() => setItemsSelectedForDeletion( [] )} size="small"> <CloseOutlined fontSize='small' /> </IconButton>
                            <Typography color="textSecondary" variant="subtitle2">{itemsSelectedForDeletion.length} selected</Typography>
                        </Box>
                        <Box display={{ sm: "flex", xs: "none" }} alignItems="center" gap="10px">
                            <Button size="small" onClick={() => setItemsSelectedForDeletion( [] )} color='inherit' startIcon={<CheckBoxOutlineBlankOutlined />} sx={{ textTransform: "capitalize" }}>Unselect all</Button>
                            {itemsSelectedForDeletion.length !== checklist.length && <Button size="small" onClick={selectAllItemsForDeletion} color='inherit' startIcon={<CheckBox />} sx={{ textTransform: "capitalize" }}>Select all</Button>}
                            <Button size="small" onClick={() => setMultipleDeleteDialogState( true )} startIcon={Icons.default.DeleteIcon} color="error" sx={{ textTransform: "capitalize" }}>Delete selected</Button>
                        </Box>
                        <Box display={{ sm: "none", xs: "flex" }} alignItems="center" gap="10px">
                            <Tooltip title="Unselect all">
                                <IconButton size="small" onClick={() => setItemsSelectedForDeletion( [] )} color='inherit'>
                                    <CheckBoxOutlineBlankOutlined fontSize='small' />
                                </IconButton>
                            </Tooltip>
                            {itemsSelectedForDeletion.length !== checklist.length &&
                                <Tooltip title="Select all">
                                    <IconButton size="small" onClick={selectAllItemsForDeletion} color='inherit'>
                                        <CheckBox fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            }
                            <Tooltip title="Delete selected">
                                <IconButton size="small" onClick={() => setMultipleDeleteDialogState( true )} color="error">
                                    <Delete fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>}

                {!loading && checklist.length > 0 && <Box flexGrow={1} overflow="hidden">
                    <TableContainer className="custom-scrollbar" id="ac-table-container" sx={{ height: "100%", overflow: "auto" }}>
                        <Table stickyHeader>
                            <TableHead sx={{ zIndex: 5 }}>
                                <TableRow sx={{ position: "sticky", top: "0", zIndex: 5 }}>
                                    {columns.map( col => (
                                        <TableCell className={col.className} align={col.align} key={col.label} sx={{ paddingY: "10px", minWidth: col.minWidth, maxWidth: col.maxWidth, background: table.headerBg, color: table.headerColor, ...col.styles }} >{col.label}</TableCell>
                                    ) )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {checklist.map( ( item ) => (
                                    <ChecklistTableRow showShadow={showShadow} rule={rule} deleteChecklist={deleteChecklist} itemsSelectedForDeletion={itemsSelectedForDeletion} setItemsSelectedForDeletion={setItemsSelectedForDeletion} getData={getData} key={item.id} item={item} />
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>}
            </Paper>
        </Box>
            :
            <Box padding="20px">
                <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
            </Box>
    )
}

export default DepartmentAcademicRuleChecklist

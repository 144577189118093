import { Box, Button, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, Paper, TextField, Typography } from '@mui/material'
import React from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useState } from 'react'
import { toast } from 'react-toastify'
import api from '../../../service/api'
import ConfirmDialog from '../../common/ConfirmDialog'

const EditSetting = ( { dialogState, setState, setting, getSelectedSettings } ) => {

    const [saving, setSaving] = useState( false )
    const [confirmDialogState, setConfirmDialogState] = useState( false )
    const [deleting, setDeleting] = useState( false )

    const handleKeyDownForOptionsInput = ( e, setFieldValue, options ) => {
        if ( e.key === "Enter" )
            if ( /^[a-zA-Z0-9]+$/.test( e.target.value ) && !options.includes( e.target.value ) ) {
                e.preventDefault()
                console.log( "Options", options )
                setFieldValue( 'setting_options', [...options, e.target.value] )
                e.target.value = ''
                e.target.focus()
            } else {
                toast.error( "Options should be unique and should only contain numbers and alphpabets" )
            }
    }

    const deleteOption = ( options, setFieldValue, index ) => {
        let newOptions = [...options]
        newOptions.splice( index, 1 )
        setFieldValue( 'setting_options', newOptions )
    }

    const editSetting = async ( values, { resetForm } ) => {
        setSaving( true )
        try {
            await api.updateSettingDetails( setting.id, values )
            await getSelectedSettings( 'instituteandmodule' )
            toast.success( `Updated setting successfully` )
            resetForm()
            setState( null )
        } catch ( err ) {
            toast( "Something went wrong!" )
        } finally {
            setSaving( false )
        }
    }

    const deleteSetting = async () => {
        setDeleting( true )
        try {
            const res = await api.deleteSettings( setting.id )
            toast.success( res.data.message || "Settings deleted successfully" )
            await getSelectedSettings( 'instituteandmodule' )
            setConfirmDialogState( false )
            setState( false )
        } catch ( err ) {
            console.log( err.message )
            toast( "Something went wrong!" )
        } finally {
            setDeleting( false )
        }
    }

    const initialValues = {
        setting_label: setting.setting_label,
        setting_description: setting.setting_description,
        setting_unit: setting.unit || "",
        setting_options: setting.setting_options
    }

    const validationSchema = Yup.object().shape( {
        setting_label: Yup.string().required( "Setting label is required!" ),
    } )

    return (
        <Dialog PaperProps={{ sx: { width: "90vw", maxWidth: "800px" } }} open={dialogState}>
            <ConfirmDialog waiting={deleting} actionName="Confirm" status={confirmDialogState} message="This will delete all the associated settings data." cancelAction={() => setConfirmDialogState( false )} confirmAction={deleteSetting} />
            <DialogTitle sx={{ borderBottom: "1px solid #d3d3d3" }} >Edit setting details</DialogTitle>
            <DialogContent sx={{ marginTop: "20px" }}>
                <Formik onSubmit={editSetting} initialValues={initialValues} validationSchema={validationSchema}>
                    {( { values, setFieldValue } ) => (
                        <Form>
                            <FormControl sx={{ marginBottom: "10px", marginTop: "10px" }} fullWidth>
                                <Field sx={{ fontSize: "14px" }} as={TextField} name='setting_label' label="Setting label*" type='text' />
                                <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='setting_label' /></Typography>
                            </FormControl>
                            <FormControl sx={{ marginBottom: "10px", marginTop: "10px" }} fullWidth>
                                <Field sx={{ fontSize: "14px" }} as={TextField} name='setting_description' rows={4} multiline label="Setting Description" type='text' />
                                <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='setting_description' /></Typography>
                            </FormControl>
                            {setting.setting_data_type === 'select' && <Box>
                                <Paper>
                                    <Box padding="10px" display="flex" alignItems="center" flexWrap="wrap" gap="10px">
                                        {values.setting_options.map( ( option, index ) => (
                                            <Chip key={option} onDelete={() => deleteOption( values.setting_options, setFieldValue, index )} size="small" label={option} />
                                        ) )}
                                        <TextField onKeyDown={( e ) => { handleKeyDownForOptionsInput( e, setFieldValue, values.setting_options ) }} type="text" variant='standard' InputProps={{ disableUnderline: true, sx: { border: "none", outline: "none", fontSize: "14px" } }} sx={{ width: "300px", minWidth: "200px", border: "none", outline: "none", padding: "5px" }} size="small" placeholder='Type new options and press enter to add...' />
                                    </Box>
                                </Paper>
                            </Box>}
                            {!['boolean', 'select', 'multiple', 'file'].includes( setting.setting_data_type ) && <FormControl sx={{ marginBottom: "10px", marginTop: "10px" }} fullWidth>
                                <Field sx={{ fontSize: "14px" }} as={TextField} name='setting_unit' label="Setting unit" type='text' />
                                <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='setting_unit' /></Typography>
                            </FormControl>}

                            <Box marginTop="10px" display="flex" gap="10px" alignItems="center">
                                <Button disabled={saving || deleting} type="submit" disableElevation sx={{ textTransform: "capitalize", display: "flex", alignItems: "center", gap: "10px" }} variant='contained'> {saving && <CircularProgress size={14} />} {saving ? "Saving changes..." : "Save changes"}</Button>
                                <Button disabled={deleting || saving} disableElevation sx={{ textTransform: "capitalize", display: "flex", alignItems: "center", gap: "10px" }} onClick={() => setConfirmDialogState( true )} color="error" variant='contained'> {deleting && <CircularProgress size={14} />} {deleting ? "Deleteing..." : "Delete setting"}</Button>
                                <Button onClick={() => setState( null )} color="error" sx={{ textTransform: "capitalize" }} variant="outlined">Cancel</Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

export default EditSetting